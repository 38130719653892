import React, {
	useContext,
	useState,
} from 'react';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// API
import {
	UserContext,
	authenticate,
	getRequest,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';
import { labels as labelsLogin } from '../../i18n/Login';


// LoginForm
function LoginForm({
	lang,
	session,
	redirect,
	setShowLogin,
}) {

	const User = useContext(UserContext);

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// variables
	const [rememberMe,setRememberMe] = useState(
		localStorage.getItem('rememberMe') === 'true'
	);

	// error handling
	const [loginError, setLoginError] = useState(false);

	// form schema
	const schema = yup.object().shape({
		username: yup.string().required(),
		password: yup.string().required(),
	});

	// login submit handler
	const handleLoginSubmit = (values) => {

		setLoginError(false);

		// set rememberMe in localStorage
		localStorage.setItem( 'rememberMe', rememberMe );

		// map for default oAuth error messages
		const messages = {
			'The token request was rejected by the authentication server.': text.noAccount,
			'The specified username/password couple is invalid.': text.invalidPw,
		};

		authenticate( values.username, values.password )

			.then((response) => {

				if( response && response.success ) {

					const max_age = (response.expires_in * 1000);

					const session = {
						expires: rememberMe ? false : true,
						expires_in: ( new Date() ).getTime() + max_age,
						access_token: response.access_token,
						refresh_token: response.refresh_token,
						email: values.username,
					};

					// get user account and store UserId to session
					getRequest( `/userAccounts`, {
						email: encodeURIComponent(session.email),
					}, session.access_token ).then((response) => {

						if( response.RoleNames.indexOf('Member Limited Access') === -1 ) {

							User.login({
								...session,
								Profile: response.UserProfile,
								UserId: response.UserId,
								Groups: response.managedGroups.join(','),
								Roles: response.RoleNames.join(','),
								Admin: response.RoleNames.indexOf('Administrator') > -1,
								PlatformAdmin: response.RoleNames.indexOf('Platform Manager') > -1,
							});

							const middle = Math.floor(session.access_token.length / 2);
							const token1 = session.access_token.substr(0, middle);
							const token2 = session.access_token.substr(middle);

							document.cookie = `iasg_token1=${token1};path=/;max-age=${max_age}`;
							document.cookie = `iasg_token2=${token2};path=/;max-age=${max_age}`;
							document.cookie = `iasg_access=true;path=/;max-age=${max_age}`;

						}
						else {

							redirect = `/login/suspended`;

						}

						setShowLogin(false);

						if( redirect ) {
							window.location = redirect;
						}

					}).catch((error) => {

						setLoginError(error.toString());

					});

				}
				else {

					let error_description = response.error_description in messages ? messages[response.error_description] : ( response.error_description ? response.error_description : 'Something went wrong…' );

					setLoginError(error_description);

				}

			}

		).catch((error) => {

			setLoginError(error.toString());

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={handleLoginSubmit}
			initialValues={{
				username: '',
				password: '',
				redirect: redirect,
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='alert alert-danger' hidden={!loginError}>
					{loginError}
				</div>

				<Form.Group>
					<Form.Label htmlFor='username'>{text.username}</Form.Label>
					<Form.Control
						type='email'
						name='username'
						id='username'
						value={values.username}
						onChange={handleChange}
						isValid={touched.username && !errors.username}
						isInvalid={touched.username && errors.username}
					/>
					<Form.Control.Feedback type='invalid'>
						{errors.username}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group>
					<Form.Label htmlFor='password'>{text.Password}</Form.Label>
					<Form.Control
						type='password'
						name='password'
						id='password'
						value={values.password}
						onChange={handleChange}
						isValid={touched.password && !errors.password}
						isInvalid={touched.password && errors.password}
					/>
					<Form.Control.Feedback type='invalid'>
						{errors.password}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className='d-flex'>
					<div className="custom-control custom-checkbox">
						<input type="checkbox"
							className="custom-control-input"
							id="rememberMe"
							value="1"
							checked={rememberMe}
							onChange={(e) => setRememberMe(e.currentTarget.checked)}
							/>
						<label className="custom-control-label" htmlFor="rememberMe">{text.rememberMe}</label>
					</div>
					<small className="form-text text-muted ml-auto"><a href={`/forgot-password`}>{text.forgotPassword}</a></small>
				</Form.Group>

				<Form.Group>
					<button type='submit' className='btn btn-primary btn-block' disabled={isSubmitting&&!loginError}>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.login}</span>
					</button>
				</Form.Group>

				<p className='text-center'>{text.noAccountPrompt} <a href={`/register`}>{text.noAccountSignUp}</a></p>

			</Form>
		)}
		</Formik>
	)
}

export default LoginForm;
