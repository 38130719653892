import React, {
	useState,
} from 'react';


// API
import {
	deleteBlend,
} from '../../api/Api';


// partials
import {
	ConfirmModal,
	ProgramsTable,
} from './Partials';


// BlendsTable
function BlendsTable({
	lang='en',
	session={},
	columns=[],
	rows=[],
	sortBy='',
	orderBy='',
	toggleSortBy=null,
	copyBlend=null,
	setCurrentBlend=null,
	setRows=null,
}) {

	// confirmations
	const [blend, setBlend] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// functions
	const handleCloseConfirm = () => {
		setBlend(false);
		setShowConfirm(false);
	};

	const handleShowConfirm = (data) => {
		setBlend(data);
		setShowConfirm(true);
	};

	const _deleteBlend = () => {

		if( !blend ) return;

		deleteBlend(blend.blendId, session.access_token).then(() => {

			let updatedRows = rows.filter((row) => {
				return row !== blend;
			});

			setRows(updatedRows);
			setShowConfirm(false);

		});

	};

	return (<>
		<ConfirmModal
			lang={lang}
			showConfirm={showConfirm}
			handleCloseConfirm={handleCloseConfirm}
			confirmCallback={_deleteBlend}
			/>

		<ProgramsTable
			lang={lang}
			columns={columns}
			rows={rows}
			sortBy={sortBy}
			orderBy={orderBy}
			toggleSortBy={toggleSortBy}
			copyBlend={copyBlend}
			setCurrentBlend={setCurrentBlend}
			removeRow={handleShowConfirm}
			chartKey={'blendId'}
			/>
	</>)


};

export default BlendsTable;
