import React, {
	useState,
} from 'react';


// helpers
import {
	TableHeader,
	TableCell,
} from '../../helpers/Tables';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsTables } from '../../i18n/Tables';


// Table
function Table(props) {

	const text = {...labelsGlobal[props.lang], ...labelsTables[props.lang]};

	const [columns] = useState( props.columns.filter((column) => {
		return column.visible !== false;
	}) );

	const [rows,setRows] = useState( props.rows );

	const [showRows,setShowRows] = useState( props.showRows ? props.showRows : false );

	const [orderBy,setOrderBy] = useState( props.orderBy ? props.orderBy : '' );
	const [sortBy,setSortBy] = useState( props.sortBy ? props.sortBy : '' );

	const toggleSortBy = (field) => {

		let sortedRows = 'DESC' === orderBy ? rows.sort((a,b) => {
			return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
		}) : rows.sort((a,b) => {
			return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
		});

		setRows(sortedRows);
		setSortBy(field);
		setOrderBy('DESC' === orderBy ? 'ASC' : 'DESC');

	};

	const selectRow = (row) => {

		let selectedRows = rows.map(r => {
			return r === row ? { ...r, selected: !r.selected } : { ...r};
		});

		setRows(selectedRows);

		if( 'function' === typeof props.selectRow) {

			props.selectRow(row);

		}

	};

	return (
		<div className={'table-responsive mb-3' + (props.sticky === false ? '' : ' sticky-thead')}>
			<table className='table table-sm table-striped' hidden={!rows.length}>
				<thead>
					<tr>
					{columns.map((column) =>
						<TableHeader
							key={column.field}
							column={column}
							sortBy={sortBy}
							orderBy={orderBy}
							toggleSortBy={toggleSortBy}
							selectAll={props.selectAll} />
					)}
					</tr>
				</thead>
				<tbody>
				{rows.map((row,i) =>
					<tr key={i} hidden={showRows&&i>=showRows}>
					{columns.map((column) =>
						<TableCell
							lang={props.lang}
							key={`${row.programId}-${column.field}`}
							column={column}
							row={row}
							selectRow={selectRow} />
					)}
					</tr>
				)}
				</tbody>
			</table>
			<button
				className='btn btn-link btn-block d-print-none'
				onClick={() => setShowRows(false)} hidden={!showRows || showRows >= rows.length}>
				{text.showAll}
			</button>
			<div className='alert alert-warning mt-2' hidden={rows.length}>
				{`No data available in table`}
			</div>
		</div>
	)

};

export default Table;
