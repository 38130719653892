export const StatsTable = (props) => {

	return (
		<div className='table-responsive'>
			<table className='table table-sm table-stats'>
				<tbody>
				{props.stats.map((stat,i) =>
					<tr key={i}>
						<th className='pl-0 font-weight-normal' scope='row'>
							{stat.label}
						</th>
						<td className='pr-0 font-weight-bold text-right'>
							{stat.value}
						</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)

};
