import React, {
	useContext,
	useEffect,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/tabs/
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Tabs,
	Tab,
	Toast,
} from 'react-bootstrap';


// API
import {
	UserContext,
	getRequest,
	updateUser,
	editPassword,
	mailchimpApiRequest,
} from '../api/Api';


// forms
import {
	UserForm,
	PasswordForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsProfile } from '../i18n/Profile';


// Profile
function Profile({
	lang,
	session,
	tab,
}) {

	const User = useContext(UserContext);

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsProfile[lang]};


	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');


	// run our queries
	const [
		{
			isLoading,
			isError,
		},
	] = useQueries([
		{
			queryKey: ['profile', session.access_token],
			queryFn: () => getRequest( `/userAccounts`, {
				email: encodeURIComponent(session.email),
			}, session.access_token ),
			onSuccess: (response) => {

				let User = response.UserProfile;

				for (var prop in User) {
					switch(prop) {
						case 'country':
							User.country = 'USA' === User.country ? 'US' : User.country;
						break;
						case 'website':
							User.website = User.website ? (User.website.indexOf('http') === 0 ? User.website : 'http://' + User.website) : '';
						break;
						default:
							User[prop] = User[prop] ? User[prop] : '';
					}
				}

				setInitialValues({
					...User,
					Email: session.email,
				});

			},
			enabled: !!session.access_token
		}
	]);


	// form data
	const [initialValues, setInitialValues] = useState(null);


	// submit handlers
	const refresh = (Email) => {

		User.login({
			...session,
			email: Email,
		});

	};

	const handleUpdateUser = (values) => {

		// did email change?
		const emailChanged = initialValues.Email !== values.Email;

		// use object destructuring and the spread operator to omit Email from values
		const {Email, ...UserProfile} = values;

		// updateUser
		updateUser(session.UserId, {
			Email: Email,
			UserProfile: JSON.stringify(UserProfile)
		}, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(text.profileSaved);
		});

		// add user to MailChimp list
		if( UserProfile.getNewsletter === true ) {

			mailchimpApiRequest({
				method: 'setListMember',
				email: initialValues.getNewsletter === true ? initialValues.Email : Email,
				FNAME: UserProfile.firstName,
				LNAME: UserProfile.lastName,
				new_email: emailChanged ? Email : '',
			}).then((response) => {

				// update session if email changed…
				if( emailChanged ) {

					refresh(Email);

				}

			});

		}
		// remove from MailChimp list
		else if( initialValues.getNewsletter === true && UserProfile.getNewsletter !== true ) {

			mailchimpApiRequest({
				method: 'deleteListMember',
				email: Email,
			}).then((response) => {

				// update session if email changed…
				if( emailChanged ) {

					refresh(Email);

				}

			});

		}

		// update initialValues to prevent weirdness on re-saves…
		setInitialValues({
			...initialValues,
			Email: Email,
			getNewsletter: UserProfile.getNewsletter,
		});

	};

	const handleUpdatePassword = (values) => {

		editPassword({
			Email: session.email,
			...values
		}, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(text.passwordSaved);
		});

	};


	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/profile`;
		}
	}, [
		lang,
		session,
	]);

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	if ( isLoading || !session.access_token || !initialValues ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					{text.pageContent}

					<div
						className='position-relative'
						aria-live='polite'
						aria-atomic='true'>

						<div
							className='position-absolute d-flex justify-content-center'
							style={{left:0,right:0}}>

							<Toast
								show={showToast}
								onClose={() => {setShowToast(false);}}
								delay={3000}
								autohide
								className='border-success position-absolute mt-n3'>

								<Toast.Body
									className='text-success text-center py-1'>
									<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
									{toastMessage}
								</Toast.Body>

							</Toast>

						</div>

					</div>

					<Tabs
						className={'iasg-tabs show-' + tab}
						defaultActiveKey={tab ? tab : 'profile'}>

						<Tab
							className='pb-4'
							eventKey='profile'
							title={text.profile}>

							<UserForm
								text={text}
								initialValues={initialValues}
								onSubmit={handleUpdateUser}
								/>

						</Tab>

						<Tab
							className='pb-4'
							eventKey='password'
							title={text.Password}>

							<PasswordForm
								text={text}
								initialValues={{
									NewPassword: '',
									PasswordConfirmation: '',
								}}
								onSubmit={handleUpdatePassword}
								/>

						</Tab>

					</Tabs>

				</div>

			</div>

		</div>
	)

}

export default Profile;
