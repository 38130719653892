export const NotFound = ({
	lang,
}) => {

	return (
		<section className="d-flex align-items-center py-5">

			<div className="container text-center">

				<h1 className="mb-4">{`Looks Like You Might Be Lost`}</h1>

				<p className="mb-4">{`Let us help you find your way back.`}</p>

				<a className="btn btn-dark" href={`/managed-futures/performance`}>{`View Programs`}</a>

			</div>

		</section>
	)

};
