export const labels = {
	'en': {
		pageTitle: 'Screener & CTA Database',
		pageSubTitle: 'Managed Futures',
		seoTitle: 'Managed Futures Screener & CTA Database',
	},
	'zh-hans': {
		pageTitle: '筛选器和 CTA 数据库',
		pageSubTitle: '管理期货',
		seoTitle: '托管期货筛选器和 CTA 数据库',
	}
};
