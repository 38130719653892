import React, {
	useEffect,
} from 'react';


// forms
import {
	CTAForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsRegisterCTA } from '../i18n/RegisterCTA';


// RegisterCTA
function RegisterCTA({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsRegisterCTA[lang]};

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( session.access_token ) {
			setTimeout(() => {
				window.location = `/tools/dashboard`;
			}, 3000);
		}
	}, [
		lang,
		session
	]);

	if ( session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					{text.pageContent}

					<CTAForm
						lang={lang}
						initialValues={{
							firstName: '',
							lastName: '',
							Email: '',
							phone: '',
							address1: '',
							address2: '',
							cityTown: '',
							country: 'US',
							stateProvince: '',
							postalCode: '',
							Password: '',
							ConfirmPassword: '',
							groupName: '',
							webUrl: '',
							descriptionLong: '',
							groupsTypes: [],
							groupsMemberships: [],
							contacts: [],
							AcceptedAgreement: false,
							getNewsletter: false,
						}}
						/>

				</div>

			</div>

		</div>
	)

}

export default RegisterCTA;
