import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	navigate,
} from 'hookrouter';


/*
 * react-meta-tags
 *
 * @link https://www.npmjs.com/package/react-meta-tags
 *
 */
import MetaTags from 'react-meta-tags';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/tabs/
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Tabs,
	Tab,
	Toast,
} from 'react-bootstrap';


/*
 * React Bootstrap Typeahead
 *
 * @link https://github.com/ericgio/react-bootstrap-typeahead
 * @link https://ericgio.github.io/react-bootstrap-typeahead
 *
 */
import {
	AsyncTypeahead,
} from 'react-bootstrap-typeahead';


// Constants
import {
	BASE_URL,
	WP_BASE_URL,
	GROUP_ACTIVE_STATUS_ID,
	GROUP_PENDING_STATUS_ID,
	MANAGED_ACCOUNT_TYPE_ID,
	PROGRAM_OPEN_STATUS_ID,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_ARCHIVE_STATUS_ID,
	PROGRAM_STATIC_STATUS_ID,
	PROGRAM_PENDING_STATUS_ID,
	PROGRAM_DELETED_STATUS_ID,
	PROGRAM_METHODOLOGY_TYPES,
	PROGRAM_COMPOSITION_TYPES,
	PROGRAM_HOLDING_TYPES,
	ytdReturnHeaderName,
} from '../helpers/Constants';


// API
import {
	UserContext,
	getRequest,
	putGroupAdministrators,
	putRoles,
	deleteGroupAdministrators,
	wpAjax,
} from '../api/Api';


// helpers
import {
	countries,
	he,
	dateFormat,
	subMonths,
	Flag,
} from '../helpers/Helpers';


// forms
import {
	GroupForm,
	LogoForm,
	ProgramForm,
	RequestForm,
} from './forms/Forms';


// partials
import {
	ConfirmModal,
	Loader,
	NotFound,
	ProgramsTable,
	RelatedArticles,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsGroup } from '../i18n/Group';
import { labels as labelsForms } from '../i18n/Forms';


// Group
function Group({
	lang,
	session,
	groupUri,
	mode='view',
}) {

	const User = useContext(UserContext);

	const text = {...labelsGlobal[lang],...labelsGroup[lang],...labelsForms[lang]};

	// notifications
	const [showToast, setShowToast] = useState(false);
	const [toastDelay, setToastDelay] = useState(3000);
	const [toastMessage, setToastMessage] = useState('');

	const [group, setGroup] = useState(false);
	const [portfolioManager, setPortfolioManager] = useState(false);
	const [columns] = useState([
		{
			field: 'programName',
			headerName: 'Program',
			sortable: true,
		},
		{
			field: 'past12Months',
			headerName: 'Past 12 Months',
		},
		{
			field: 'monthReturn',
			headerName: dateFormat(subMonths(new Date(), 1), 'MMM'),
			type: 'int',
			format: 'plusMinus',
			sortable: true,
		},
		{
			field: 'ytdReturn',
			headerName: ytdReturnHeaderName,
			type: 'percent',
			format: 'plusMinus',
			sortable: true,
		},
		{
			field: 'compoundReturn',
			headerName: 'CAROR',
			type: 'percent',
			sortable: true,
		},
		{
			field: 'maxDrawDown',
			headerName: 'WDD',
			type: 'percent',
			sortable: true,
		},
		{
			field: 'assets',
			headerName: 'AUM',
			type: 'currency',
			sortable: true,
		},
		{
			field: 'investmentMin',
			headerName: 'Min Inv',
			type: 'int',
			sortable: true,
		},
		{
			field: 'visits',
			headerName: 'Visits',
			type: 'int',
			sortable: true,
		},
	]);
	const [allArchived, setAllArchived] = useState(false);
	const [hasArchived, setHasArchived] = useState(false);
	const [showArchived, setShowArchived] = useState(false);
	const [allDeleted, setAllDeleted] = useState(false);
	const [hasDeleted, setHasDeleted] = useState(false);
	const [showDeleted, setShowDeleted] = useState(false);
	const [programs, setPrograms] = useState(false);
	const [sortProgramsBy, setSortProgramsBy] = useState('monthReturn');
	const [orderProgramsBy, setOrderProgramsBy] = useState('DESC');

	const [initialGroupValues,setInitialGroupValues] = useState({});

	// users
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(false);
	const [usersResults, setUsersResults] = useState([]);
	const [showUserConfirm, setShowUserConfirm] = useState(false);
	const confirmDeleteUser = (user) => {
		setUser(user);
		setShowUserConfirm(true);
	};
	const closeUserConfirm = () => {
		setUser(false);
		setShowUserConfirm(false);
	};
	const deleteUser = () => {

		if( !user ) return;

		deleteGroupAdministrators(group.groupId, user.userId, session.access_token).then((response) => {
			setUsers(users.filter((u) => {
				return u !== user;
			}));
			// did I just remove myself?
			if( user.userId === session.UserId ) {
				let Groups = session.Groups ? session.Groups.split(',') : [];
				User.login({
					...session,
					Groups: Groups.filter(groupId => groupId !== group.groupId).join(','),
				});
			}
			setShowUserConfirm(false);
			setShowToast(true);
			setToastMessage('User removed');
		});

	};

	// handleUsersSearch
	const [isSearchingUsers, setIsSearchingUsers] = useState(false);
	const filterByUsers = () => true;
	const usersSearch = useRef(null);
	const handleUsersSearch = (query) => {

	    setIsSearchingUsers(true);

		getRequest('/userAccounts', {
			search: query,
			pageSize: 50,
		}, session.access_token).then((response) => {
			// filter existing users from search results…
			setUsersResults(response.items.filter(r => !users.find(u => (u.userId === r.userId) )));
			setIsSearchingUsers(false);
		});

	};

	// canEdit
	const canEdit = (groupId) => {
		const managedGroups = session.Groups ? session.Groups.split(',') : [];
		const roles = session.Roles ? session.Roles.split(',') : [];
		return session.Admin || ( managedGroups.indexOf(groupId) > -1 && roles.indexOf('CTA Manager') > -1 );
	};

	// badges
	const isPending = (groupStatusId) => {

		if( groupStatusId && GROUP_PENDING_STATUS_ID === groupStatusId )

			return (
				<span className='badge badge-warning ml-2' style={{fontSize:'1rem',backgroundColor:'#ffee58'}}>{`Pending`}</span>
			);

		return false;

	};

	// posts
	const [recentPosts, setRecentPosts] = useState(null);

	// run our queries
	const [
		{
			isLoading: groupLoading,
			isError: isGroupError,
		},
		{
			isLoading: programsLoading,
		},
		{
			isLoading: usersLoading,
		},
		{
			isLoading: postsLoading,
		},
	] = useQueries([
		{
			queryKey: ['group', groupUri, session.access_token],
			queryFn: () => getRequest(`/groups/${groupUri}`, {}, session.access_token),
			onSuccess: (response) => {

				// webUrl
				response.webUrl = response.webUrl ? (response.webUrl.indexOf('http') === 0 ? response.webUrl : 'http://' + response.webUrl) : '';

				// edit mode prep
				setInitialGroupValues({
					...response,
					groupsTypes: response.groupsTypes.map((object) => {
						return object.grouptypeId;
					}),
					platforms: [],
					AcceptedAgreement: false,
				});

				// set group
				setGroup(response);

				// set Portfolio Manager
				setPortfolioManager(response.contacts.find(contact => 'b90bef99-7380-4377-b876-f3881fc86d0f' === contact.contactTypeId));

			}
		},
		{
			queryKey: ['programs', groupUri, session.access_token],
			queryFn: () => getRequest(`/programsListings/group/${groupUri}`, {
				filterByGroupStatusIds: (canEdit(group.groupId) ? [GROUP_ACTIVE_STATUS_ID, GROUP_PENDING_STATUS_ID] : [GROUP_ACTIVE_STATUS_ID]).join('&filterByGroupStatusIds='),
				filterByProgramStatusIds: (canEdit(group.groupId) ? [PROGRAM_ACTIVE_STATUS_ID, PROGRAM_ARCHIVE_STATUS_ID, PROGRAM_PENDING_STATUS_ID, PROGRAM_DELETED_STATUS_ID] : [PROGRAM_STATIC_STATUS_ID]).join('&filterByProgramStatusIds='),
				sortBy: 'monthReturn',
				orderBy: 'DESC',
			}, session.access_token),
			onSuccess: (response) => {
				if( canEdit(group.groupId) ) {
					const getProgramsWithViewLogs = async () => {
						const programs = response.items;
						for (let program of programs) {
							const viewLogCount = await getRequest('/viewLog/count', {filterByProgramId:program.programId}, session.access_token);
							program.visits = viewLogCount;
						}
						return programs;
					};
					getProgramsWithViewLogs().then((response) => {
						setPrograms(response);
						setHasArchived(response.some(p => p.programStatusId === PROGRAM_ARCHIVE_STATUS_ID));
						setAllArchived(response.every(p => p.programStatusId === PROGRAM_ARCHIVE_STATUS_ID));
						setHasDeleted(response.some(p => p.programStatusId === PROGRAM_DELETED_STATUS_ID));
						setAllDeleted(response.every(p => p.programStatusId === PROGRAM_DELETED_STATUS_ID));
					});
				}
				else {
					setPrograms(response.items);
					setHasArchived(response.items.some(p => p.programStatusId === PROGRAM_ARCHIVE_STATUS_ID));
					setAllArchived(response.items.every(p => p.programStatusId === PROGRAM_ARCHIVE_STATUS_ID));
				}
			},
			enabled: (['edit','logo','users','add-program'].indexOf(mode) === -1 && !!group) || !session.access_token
		},
		{
			queryKey: ['userAccounts', {
				filterByGroupId: group.groupId
			}],
			queryFn: () => getRequest('/userAccounts', {
				filterByGroupId: group.groupId
			}, session.access_token),
			onSuccess: (response) => {
				setUsers(response.items);
			},
			enabled: ('users' === mode) && !!(group) && !!(session.Admin)
		},
		{
			queryKey: ['wpAjax', {group:group}],
			queryFn: () => wpAjax({
				action: 'wp_query',
				lang: lang,
				posts_per_page: 3,
				tag: group.groupUri.toLowerCase(),
				orderby: 'DATE',
				post_type: 'post',
			}),
			onSuccess: (response) => {
				setRecentPosts(response);
			},
			enabled: ('view' === mode) && !!(group)
		},
	]);

	// access + redirects
	useEffect(() => {
		if( !session.access_token && 'view' !== mode ) {
			window.location = `/login?redirect=/groups/${groupUri}/${mode}`;
		}
		document.body.classList.remove('overflow-hidden');
	}, [
		lang,
		session,
		mode,
		groupUri,
	]);

	const isLoading = groupLoading || programsLoading;
	const isError = isGroupError;

	if (isError) {
		return (
			<NotFound
				lang={lang}
			/>
		)
	}

	if (isLoading) {
		return (
			<Loader/>
		)
	}

	if ( ['edit','logo','users','add-program'].indexOf(mode) > -1 && canEdit(group.groupId) ) return (
		<div className='container py-5'>

			<MetaTags>
				<title>{[group.groupName, text.siteTitle].join(text.titleSeparator)}</title>
			</MetaTags>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10'>

					<h1 className='mb-4 d-flex'>
						<i className='fa-solid fa-edit mr-2 mt-2' style={{fontSize:'75%'}}></i>
						<span><a href={`/groups/${groupUri}`}>{group.groupName}</a></span>
					</h1>

					<div
						className='position-relative'
						aria-live='polite'
						aria-atomic='true'>

						<div
							className='position-absolute d-flex justify-content-center'
							style={{left:0,right:0}}>

							<Toast
								show={showToast}
								onClose={() => {setShowToast(false);setToastDelay(3000);}}
								delay={toastDelay}
								autohide
								className='border-success position-absolute mt-3'>

								<Toast.Body
									className='text-success text-center py-1'>
									<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
									{toastMessage}
								</Toast.Body>

							</Toast>

						</div>

					</div>

					<Tabs
						className={'iasg-tabs'}
						defaultActiveKey={mode ? mode : 'edit'}
						onSelect={(k) => {
							navigate(`/groups/${groupUri}/${k}`);
						}}>

						<Tab
							className='pb-5'
							eventKey='edit'
							title={`Profile`}>
						{'edit' === mode &&
							<GroupForm
								lang={lang}
								session={session}
								initialValues={initialGroupValues}
								/>
						}
						</Tab>

						<Tab
							className='pb-5'
							eventKey='logo'
							title={`Logo`}>
						{'logo' === mode && group &&
							<LogoForm
								lang={lang}
								session={session}
								initialValues={{
									groupIdOrgroupUri: group.groupId,
									logo: false,
								}}
								/>
						}
						</Tab>

					{session.Admin &&
						<Tab
							className='py-5'
							eventKey='users'
							title={`Users`}>

							<ConfirmModal
								lang={lang}
								showConfirm={showUserConfirm}
								handleCloseConfirm={closeUserConfirm}
								confirmCallback={deleteUser}
								message={`Are you sure you want to remove this user from ${group.groupName}?`}
								/>

						{users.length > 0 &&
							<div className={'table-responsive sticky-thead mb-3'}>
								<table className='table table-sm table-striped'>
									<thead>
										<tr>
											<th scope='col'>
												<span>{`Name`}</span>
											</th>
											<th scope='col'>
												<span>{`Email`}</span>
											</th>
											<th scope='col'>
												<span>{`Phone`}</span>
											</th>
											<th scope='col' style={{width:'1%'}}></th>
										</tr>
									</thead>
									<tbody>
									{users.map(user =>
										<tr key={user.userId}>
											<td>
												<a className='d-block' href={`/admin/users/${user.userId}`}>{user.firstName} {user.lastName}</a>
											</td>
											<td>
												<a className='d-block' href={`mailto:${user.email}`}>{user.email}</a>
											</td>
											<td>
												<a className='d-block' href={`tel:${user.phone}`}>{user.phone}</a>
											</td>
											<td>
												<div className='d-flex justify-content-center'>
													<button className='btn btn-link py-0 text-danger'
														onClick={(e) => {confirmDeleteUser(user);}}>
														<i className='fa-solid fa-times-circle'></i>
													</button>
												</div>
											</td>
										</tr>
									)}
									</tbody>
								</table>
							</div>
						}
						{!usersLoading && !users.length &&
							<div className='alert alert-warning mx-2'>
								{`No users found`}
							</div>
						}
						{usersLoading &&
							<div style={{padding:'.75rem .5rem'}}>{text.loading}</div>
						}
						{!usersLoading &&
							<div className='mt-3 mx-2'>
								<AsyncTypeahead
									filterBy={filterByUsers}
									id='usersSearch'
									isLoading={isSearchingUsers}
									labelKey={(option) => `${option.firstName} ${option.lastName}`}
									ref={usersSearch}
									minLength={3}
									onChange={(selected) => {
										if( !selected.length ) return;
										let user = selected[0];
										putGroupAdministrators({
											groupId: group.groupId,
											UserId: user.userId,
										}, session.access_token).then((response) => {
											// add 'CTA Manager' role
											putRoles(user.userId, ['CTA Manager'], session.access_token);
											setUsers([
												...users,
												user,
											]);
											// did I just add myself?
											if( user.userId === session.UserId ) {
												let Groups = session.Groups ? session.Groups.split(',') : [];
												User.login({
													...session,
													Groups: [...new Set([
														...Groups,
														group.groupId,
													])].join(','),
												});
											}
											setShowToast(true);
											setToastMessage('User added');
											usersSearch.current.clear();
										});
									}}
									onSearch={handleUsersSearch}
									options={usersResults}
									placeholder={`Add user…`}
									renderMenuItemChildren={(option, props) => (
										<>
											<div className='userFullName'>{option.firstName} {option.lastName}</div>
											<small className='userEmail'>{option.email}</small>
										</>
									)}
									/>
							</div>
						}
						</Tab>
					}
						<Tab
							className='pb-5'
							eventKey='add-program'
							title={`Add Program`}>
						{group &&
							<ProgramForm
								lang={lang}
								session={session}
								add={true}
								fromGroupUri={group.groupUri}
								initialValues={{
									programId: '00000000-0000-0000-0000-000000000000',
									groupId: group.groupId,
									programStatusId: PROGRAM_PENDING_STATUS_ID,
									programName: '',
									programTypeId: MANAGED_ACCOUNT_TYPE_ID,
									membershipStatusId: PROGRAM_OPEN_STATUS_ID,
									symbol: '',
									currency: 'USD',
									investmentMin: '',
									tradingLevelIncrease: '',
									subscriptionFrequencyTypeId: '',
									redemptionFrequencyTypeId: '',
									investorRequirementsTypeId: '',
									lockupPeriod: 0,
									wddExpected: '',
									worstPeak2Trough: '',
									sectorFocusTypeId: '',
									carorType: 0,
									fundingNotional: false,
									fundingMaxNotional: 0,
									usInvest: 1,
									proprietary: false,
									hideProgram: false,
									feeManagement: '',
									feePerformance: '',
									averageCommission: '',
									highwaterMark: 'yes',
									methodologies: [
										...PROGRAM_METHODOLOGY_TYPES.map((type) => {
											return {
												methodologyTypeId: type.methodologyTypeId,
												title: type.methodology,
												value: 0,
											}
										})
									],
									allocationStrategies: [],
									compositions: [
										...PROGRAM_COMPOSITION_TYPES.map((type) => {
											return {
												compositionTypeId: type.compositionTypeId,
												title: type.composition,
												value: 0,
											}
										})
									],
									roundturnYearMillion: '',
									marginEquityRatio: '',
									holdings: [
										...PROGRAM_HOLDING_TYPES.map((type) => {
											return {
												holdingTypeId: type.holdingTypeId,
												title: type.holding,
												value: 0,
											}
										})
									],
									description: '',
									strategyInvestment: '',
									strategyRisk: '',
									platforms: [],
									AcceptedAgreement: false,
								}}
								/>
						}
						</Tab>

					</Tabs>

				</div>

			</div>

		</div>
	)

	return (
		<div className={'container'}>

			<MetaTags>
				<title>{[group.groupName, text.siteTitle].join(text.titleSeparator)}</title>
				<link rel='canonical' href={`${WP_BASE_URL}/groups/${group.groupUri}`} />
				<meta name='description' content={group.descriptionLong} />
				<meta name='keywords' content={`${group.groupName}, managed futures`} />
				<meta name='robots' content='INDEX, FOLLOW' />
				<meta property='og:title' content={group.groupName} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content={`${WP_BASE_URL}/groups/${group.groupUri}`} />
			{group.logo &&
				<meta property='og:image' content={`${BASE_URL}/assets/images/logos/${group.logo}`} />
			}
			</MetaTags>

			<div className={'row'}>

				<div className='col-12'>

					<div className='row mb-3'>

						<div className={'col-12 col-lg-9 order-2 order-lg-1'}>

							<h1 className='mb-0 d-flex align-items-center'>
								<span>{group.groupName}</span>
							{canEdit(group.groupId) &&
								<a href={`/groups/${groupUri}/edit`}
									style={{fontSize:'75%'}}
									className='d-print-none'>
									<i className='fa-solid fa-edit ml-2 mt-1'></i>
								</a>
							}
								{session.Admin && isPending(group.groupStatusId)}
							</h1>
						</div>
					{group.logo &&
						<div className='col-12 col-lg-auto ml-lg-auto mb-3 mb-lg-0 order-1 order-lg-2'>
							<img className='img-fluid' src={`${BASE_URL}/assets/images/logos/${group.logo}`} alt={group.groupName} width={160}/>
						</div>
					}
					</div>

					<div className='break-out bg-light-blue py-4'>

						<div className='container'>
						{!programs &&
							<div style={{padding:'.75rem .5rem'}}>{text.loading}</div>
						}
						{!!programs.length && <>
							<ProgramsTable
								lang={lang}
								columns={canEdit(group.groupId) ? columns : columns.filter(column => 'visits' !== column.field)}
								rows={programs.filter((p) => p.programStatusId !== (showArchived || allArchived ? null : PROGRAM_ARCHIVE_STATUS_ID)).filter((p) => p.programStatusId !== (showDeleted || allDeleted ? null : PROGRAM_DELETED_STATUS_ID))}
								sortBy={sortProgramsBy}
								orderBy={orderProgramsBy}
								toggleSortBy={(field) => {
									setPrograms('DESC' === orderProgramsBy ? programs.sort((a,b) => {
										return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
									}) : programs.sort((a,b) => {
										return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
									}));
									setSortProgramsBy(field);
									setOrderProgramsBy('DESC' === orderProgramsBy ? 'ASC' : 'DESC');
								}}
								/>

							<div className='d-flex flex-column flex-md-row text-muted mb-4'>
								<small className='pr-3'>
									<i className='fa-solid fa-lg fa-caret-up mr-1 text-success'></i>
									{text.performanceHigh}
								</small>
								<small className='pr-3'>
									<i className='fa-solid fa-lg fa-caret-down mr-1 text-danger'></i>
									{text.performanceLow}
								</small>
								<small className='pr-3'>
									<i className='fa-solid fa-circle mr-1 text-primary'></i>
									{text.last30Days}
								</small>
								<small className='pr-3'>
									<span className='badge text-danger mr-1' style={{verticalAlign:'.05rem'}}>{`DD`}</span>
									{text.newMaxDD}
								</small>
								<div className='ml-md-auto'>
								{hasArchived && !allArchived &&
									<small>
										<button className='btn btn-outline-dark py-0 px-1'
											style={{fontSize:'1em'}}
											onClick={(e) => {setShowArchived(!showArchived);e.currentTarget.blur();}}>
											<span>{showArchived ? text.hideArchivedPrograms : text.showArchivedPrograms}</span>
										</button>
									</small>
								}
								{hasDeleted && !allDeleted &&
									<small className={'ml-1'}>
										<button className='btn btn-outline-dark py-0 px-1'
											style={{fontSize:'1em'}}
											onClick={(e) => {setShowDeleted(!showDeleted);e.currentTarget.blur();}}>
											<span>{showDeleted ? text.hideDeletedPrograms : text.showDeletedPrograms}</span>
										</button>
									</small>
								}
								</div>
							</div>

							<p className='text-muted'>
								<small>{text.performanceDisclaimer}</small>
							</p>
						</>}
						</div>

					</div>

					<div className='row py-5'>

						<div className='col-12 col-lg-9'>
						{!!group.descriptionLong &&
							<div className='mb-5'>
								<h4>
									{text.descriptionHeading}
								</h4>

								<div dangerouslySetInnerHTML={{__html: group.descriptionLong ? he.decode(group.descriptionLong) : ''}}></div>
							</div>
						}
							<div className='row my-3 font-size-small'>
							{portfolioManager &&
								<div className='col-12 col-md-6 col-lg-3 mb-3'>
									<dl>
										<dt className='h6 border-bottom'>{text[portfolioManager.contactTitle]||portfolioManager.contactTitle}</dt>
										<dd>{portfolioManager.firstName} {portfolioManager.lastName}</dd>
									</dl>
								</div>
							}
							{group && group.groupsTypes.length > 0 &&
								<div className='col-12 col-md-6 col-lg-3 mb-3'>
									<dl>
										<dt className='h6 border-bottom'>
											{text.typeHeading}
										</dt>
										<dd>
										{group.groupsTypes.map((groupsType,index) =>
											<div key={index}>
												{groupsType.title}
											</div>
										)}
										</dd>
									</dl>
								</div>
							}
							{group && group.groupsMemberships.length > 0 &&
								<div className='col-12 col-md-6 col-lg-3 mb-3'>
									<dl>
										<dt className='h6 border-bottom'>
											{text.registrationsHeading}
										</dt>
										<dd>
										{group.groupsMemberships.map((groupsMembership,index) =>
											<div key={index}>
												{groupsMembership.title} {groupsMembership.info}
											</div>
										)}
										</dd>
									</dl>
								</div>
							}
							{group && group.country &&
								<div className='col-12 col-md-6 col-lg-3 mb-3'>
									<dl>
										<dt className='h6 border-bottom'>
											{text.locationsHeading}
										</dt>
										<dd>
											<div className='d-flex align-items-center'>

												<Flag
													code={['GB','UK'].indexOf(group.country) > -1 ? 'GB-UKM' : group.country}
													size='m'
													className='mr-1'
													hasBorder={false}
													hasBorderRadius={false}
													/>

												<div>{countries[group.country].name}</div>

											</div>
										</dd>
									</dl>
								</div>
							}
							</div>
						{postsLoading && text.loading}
						{!!recentPosts && recentPosts.found_posts > 0 &&
							<RelatedArticles
								lang={lang}
								data={recentPosts}
								tag={group.groupUri.toLowerCase()}
								/>
						}
						</div>

						<div className='col-12 col-lg-3 d-print-none'>

							<RequestForm
								lang={lang}
								session={session}
								group={group}
								confirmEmail={'cta-info-request'}
								/>

						</div>

					</div>

				</div>

			</div>

		</div>
	);
}

export default Group;
