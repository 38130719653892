import React, {
	useState,
} from 'react';


// helpers
import {
	dateFormat,
	subMonths,
	compareAsc,
} from '../../helpers/Helpers';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsTables } from '../../i18n/Tables';


// PerformanceTable
export const PerformanceTable = ({
	lang,
	returns,
	showRows,
	awards,
	portfolioUri,
}) => {

	const text = { ...labelsGlobal[lang], ...labelsTables[lang] };

	const [showAllRows, setShowAllRows] = useState(showRows ? false : true);

	const lastFilterByDate = subMonths(new Date(), 3);

	const returnValue = (monthReturn, dateReturn) => {

		monthReturn = 'number' === typeof monthReturn ? monthReturn.toFixed(2) : '';

		if (awards && dateReturn) {

			dateReturn = new Date(dateReturn);
			
			if( compareAsc(lastFilterByDate, dateReturn ) === 1 ) {

				let filterByDateEnd = dateFormat(dateReturn, 'yyyy-MM-dd');

				return (
					<a className='d-block' href={`/indexes/${portfolioUri}/rankings?filterByDateEnd=${filterByDateEnd}`}>
						{monthReturn}
					</a>
				)

			}
			else {
				return monthReturn;
			}
			
		}

		return monthReturn;

	};

	return (
		<div className='table-responsive sticky-thead mb-3'>
			<table className='table table-sm table-striped'>
				<thead>
					<tr>
					{[
						text.Year,
						text.Jan,
						text.Feb,
						text.Mar,
						text.Apr,
						text.May,
						text.Jun,
						text.Jul,
						text.Aug,
						text.Sep,
						text.Oct,
						text.Nov,
						text.Dec,
						text.YTD,
						text.DD,
					].map((column,i) =>
						<th data-col={column} className='text-right' key={i} style={{width:'1%'}}>
							{column}
						</th>
					)}
					</tr>
				</thead>
				<tbody>
				{returns.map((row,i) =>
					<tr key={`return-${row.year}`} hidden={!showAllRows&&i>=showRows}>
						<th className='text-right' scope='row'>{row.year}</th>
					{row.returns.map((performance,i) =>
						<td className={'text-right' + ( performance.monthReturn < 0 ? ' text-danger' : '' )} key={i}>
							{returnValue(performance.monthReturn, performance.dateReturn)}
						</td>
					)}
						<td data-cell='YTD' className={'text-right' + ( row.ytd < 0 ? ' text-danger' : '' )}>
							{row.ytd.toFixed(2)}
						</td>
						<td data-cell='DD' className='text-right'>
							{row.maxDrawDown.toFixed(2)}
						</td>
					</tr>
				)}
				</tbody>
			</table>
			<button
				className='btn btn-link btn-block d-print-none'
				onClick={() => setShowAllRows(true)} hidden={showAllRows||returns.length<showRows}>
				{text.showAll}
			</button>
		</div>
	)

};
