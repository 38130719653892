// Constants
import {
	API_URL,
} from '../helpers/Constants';

// postGroup
export const postGroup = async (body, access_token) => {

	const response = await fetch(`${API_URL}/groups`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// patchGroup
export const patchGroup = async (groupId, data, access_token) => {

	const response = await fetch(`${API_URL}/groups/${groupId}`, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// updateGroup
export const updateGroup = async (body, access_token) => {

	const response = await fetch(`${API_URL}/groups`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// uploadLogo
export const uploadGroupLogo = async (groupIdOrgroupUri, body, access_token) => {

	const response = await fetch(`${API_URL}/groups/${groupIdOrgroupUri}/logo`, {
		method: 'PUT',
		body: body,
		headers: {
			'Authorization': 'Bearer ' + access_token,
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteGroup
export const deleteGroup = async (groupId, access_token) => {

	const response = await fetch(`${API_URL}/groups/${groupId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// putGroupAdministrators
export const putGroupAdministrators = async (body, access_token) => {

	const response = await fetch(`${API_URL}/groupsAdministrators`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteGroupAdministrators
export const deleteGroupAdministrators = async (groupId, userId, access_token) => {

	const response = await fetch(`${API_URL}/groupsAdministrators/${groupId}/${userId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// addGroupToPlatform
export const addGroupToPlatform = async (platformId, groupId, access_token) => {

	const response = await fetch(`${API_URL}/groupsPlatform`, {
		method: 'PUT',
		body: JSON.stringify({
			platformId: platformId,
			groupId: groupId,
			isManageable: true,
		}),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteGroupFromPlatform
export const deleteGroupFromPlatform = async (platformId, groupId, access_token) => {

	const response = await fetch(`${API_URL}/groupsPlatform/${platformId}/${groupId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
