import React, {
	useState,
} from 'react';


// API
import {
	deleteFromPortfolio,
} from '../../api/Api';


// partials
import {
	ConfirmModal,
	ProgramsTable,
} from './Partials';


// PortfolioTable
function PortfolioTable({
	lang='en',
	session={},
	columns=[],
	rows=[],
	updatePortfolio=null,
	setRows=null,
}) {

	// confirmations
	const [program, setProgram] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// functions
	const handleCloseConfirm = () => {
		setProgram(false);
		setShowConfirm(false);
	};

	const handleShowConfirm = (data) => {
		setProgram(data);
		setShowConfirm(true);
	};

	const deleteProgram = () => {

		if( !program ) return;

		deleteFromPortfolio(program.portfolioOrderId, session.access_token).then(() => {

			let updatedRows = rows.filter((row) => {
				return row !== program;
			});

			setRows(updatedRows);
			setShowConfirm(false);

		});

	};

	return (<>
		<ConfirmModal
			lang={lang}
			showConfirm={showConfirm}
			handleCloseConfirm={handleCloseConfirm}
			confirmCallback={deleteProgram}
			/>

		<ProgramsTable
			lang={lang}
			columns={columns}
			rows={rows}
			removeRow={handleShowConfirm}
			updateProperty={(program, key, value) => {

				setRows(rows.map(row => {
					if( row.portfolioOrderId === program.portfolioOrderId ) {
						row[key] = value;
						return row;
					}
					else {
						return row;
					}
				}));

			}}
			updatePropertyCallback={(program, key, value) => {

				updatePortfolio.mutate({
					portfolioOrderId: program.portfolioOrderId,
					patch: [
						{
							op: 'add',
							path: '/' + key,
							value: value
						}
					]
				});

			}}
			footer={'portfolio'}
			/>
	</>)


};

export default PortfolioTable;
