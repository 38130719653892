export const labels = {
	'en': {
		pageTitle: 'Archived Programs',
		pageSubTitle: 'Managed Futures',
		seoTitle: 'Archived Programs | Managed Futures Screener & CTA Database',
	},
	'zh-hans': {
		pageTitle: '存档程序',
		pageSubTitle: '管理期货',
		seoTitle: '托管期货存档程序',
	}
};
