export const labels = {
	'en': {
		pageTitle: 'Register for a CTA Account',
		pageContent: <>
			<p>Sign up to list your program or fund for free on IASG.com, and get in front of thousands of potential new customers each month.</p>
		</>,

		certify: 'I certify that all information I have furnished to IASG is accurate, to the best of my knowledge.',
	},
	'zh-hans': {
		pageTitle: '注册 CTA 帐户',
		pageContent: <>
			<p>注册以在 IASG.com 上免费列出您的计划或基金，并每月在数千名潜在新客户面前展示。</p>
		</>,

		certify: '本人证明，据本人所知，本人向 IASG 提供的所有信息均准确无误。',
	}
};
