import React, {
	useEffect,
	useState,
} from 'react';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
} from 'hookrouter';


// API
import {
	apiRequest,
} from '../api/Api';


// forms
import {
	ResetPasswordForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsResetPassword } from '../i18n/ResetPassword';


// ResetPassword
function ResetPassword({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsResetPassword[lang]};

	// error handling
	const [successMessage, setSuccessMessage] = useState(false);
	const [resetError, setResetError] = useState(false);

	// queryParams
	const [queryParams] = useQueryParams();

	// Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		email = '',
		ResetToken = '',
	} = queryParams;

	// login submit handler
	const handleResetRequest = (values, formikBag) => {

		setSuccessMessage(false);
		setResetError(false);

		// make API call
		apiRequest({
			path: '/userAccounts/resetpasswordwithtoken',
			data:  JSON.stringify({
				...values,
				ResetToken: ResetToken
			})
		}).then((response) => {

			if( response.error ) {

				setResetError( text[response.error] ? text[response.error] : response.error );

			}
			// gaslighting…
			else if( response.status === 404 ) {

				setResetError(text.errorMessage);

			}
			else {

				setSuccessMessage(text.passwordReset);

				setTimeout(() => {
					window.location = `/login`;
				}, 3000);

			}

			// reset 'isSubmitting'
			formikBag.setSubmitting(false);

		});

	};

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( session.access_token ) {
			window.location = `/managed-futures/performance`;
		}
		if( !ResetToken ) {
			window.location = `/forgot-password`;
		}
	}, [
		lang,
		session,
		ResetToken,
	]);

	if ( session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col' style={{maxWidth:'30rem'}}>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					{text.pageContent}

					<div className='alert alert-danger' hidden={!resetError}>
						{resetError}
					</div>

					<div className='alert alert-success' hidden={!successMessage}>
						{successMessage}
					</div>

					<ResetPasswordForm
						text={text}
						initialValues={{
							Email: email,
							NewPassword: '',
							PasswordConfirmation: '',
						}}
						onSubmit={handleResetRequest}
						/>

				</div>

			</div>

		</div>
	)

}

export default ResetPassword;
