// Helpers
import {
	dateFormat,
} from './Helpers';

// prepInvestmentReturns
export const prepInvestmentReturns = (investmentReturns = []) => {

	const returnSchema = {
		assets: null,
		cumulativeROR: null,
		dateCreated: '',
		dateReturn: '',
		monthReturn: null,
		vami: null,
	};

	let dateCreated = dateFormat((new Date()), 'yyyy-MM-dd') + 'T00:00:00';
	
	let firstReturn = investmentReturns[0];
	let lastReturn = investmentReturns[investmentReturns.length-1];

	let dateFirstReturn = new Date( firstReturn.dateReturn ), yearFirstReturn = dateFirstReturn.getFullYear(), monthFirstReturn = dateFirstReturn.getMonth();
	let dateLastReturn = new Date( lastReturn.dateReturn ), yearLastReturn = dateLastReturn.getFullYear(), monthLastReturn = dateLastReturn.getMonth();

	// is the first data point after January?
	if( monthFirstReturn > 0 ) {
		let firstMonths = [];
		// fill empty months…
		for( let i = 0; i < monthFirstReturn; i++ ) {
			let realFirstMonth = (i<9?'0':'') + (i + 1);
			firstMonths.push({
				...returnSchema,
				dateCreated: dateCreated,
				dateReturn: `${yearFirstReturn}-${realFirstMonth}-01T00:00:00`,
			});
		}
		investmentReturns = [
			...firstMonths,
			...investmentReturns
		];
	}

	// is the last data point before December?
	if( monthLastReturn < 11 ) {
		// fill empty months…
		for( let i = monthLastReturn; i < 11; i++ ) {
			let realLastMonth = (i<8?'0':'') + (i + 2);
			investmentReturns.push({
				...returnSchema,
				dateCreated: dateCreated,
				dateReturn: `${yearLastReturn}-${realLastMonth}-01T00:00:00`,
			});
		}
	}

	// fill in any empty months…
	for( let i = 0, j = investmentReturns.length; i < j; i++ ) {
		let dateReturn = new Date( investmentReturns[i].dateReturn ), yearReturn = dateReturn.getFullYear(), monthReturn = dateReturn.getMonth();
		let k = i>11?i-(Math.floor(i/12)*12):i;

		if( k !== monthReturn ) {
			let realMonth = (k<9?'0':'') + (k + 1);
			investmentReturns.splice(i, 0, {
				...returnSchema,
				dateCreated: dateCreated,
				dateReturn: `${yearLastReturn}-${realMonth}-01T00:00:00`,
			});
			if( k === 11 ) {
				yearLastReturn++;
			}
		}
		else {
			yearLastReturn = yearReturn;
			monthLastReturn = monthReturn;
		}

	}

	let y = false;

	// fill in any empty years…
	for( let i = 0, j = investmentReturns.length; i < j; i++ ) {
		let dateReturn = new Date( investmentReturns[i].dateReturn ), yearReturn = dateReturn.getFullYear();
		let k = i>11?i-(Math.floor(i/12)*12):i;

		if( k === 0 && y && y !== yearReturn ) {

			let nextReturn = new Date( investmentReturns[i+1].dateReturn ), yearNextReturn = nextReturn.getFullYear();

			let years = yearNextReturn - y;
			
			for( let yy = 1; yy < years; yy++ ) {
				let insert = [];
				for( let m = 0; m < 12; m++ ) {
					let Y = (y+yy), M = (m<9?'0':'') + (m + 1);
					insert.push({
						...returnSchema,
						dateCreated: dateCreated,
						dateReturn: `${Y}-${M}-01T00:00:00`,
					});
				}
				investmentReturns = [
					...investmentReturns.slice(0, i),
					...insert,
					...investmentReturns.slice(i),
				];
			}
			y = y + years;
		}
		else {
			y = yearReturn;
		}

	}

	

	return investmentReturns;
};

// parseInvestmentReturns
export const parseInvestmentReturns = (investmentReturns = [], carorType = 0) => {

	// default return schema
	const schema = {
		dateReturn: '',
		monthReturn: null,
		cumulativeROR: null,
		vami: null,
		assets: null,
		dateCreated: '',
	};

	// set 'assets' equal to the latest assets value in investmentReturns
	let lastReturn = investmentReturns[investmentReturns.length - 1];

	// build table data…
	let yearReturnsList = [];

	investmentReturns.forEach((monthlyReturn) => {

		let dateReturn = new Date( monthlyReturn.dateReturn ), yearReturn = dateReturn.getFullYear(), monthReturn = dateReturn.getMonth();

		// any data for this year in yearReturnsList?
		let yearReturns = yearReturnsList.find(obj => { return obj.year === yearReturn; });

		// first data point for this year…
		if( 'undefined' === typeof yearReturns ) {

			yearReturns = {
				year: yearReturn,
				returns: []
			};

			// is the first data point for this year after January?
			if( monthReturn > 0 ) {
				// fill empty months with placeholder objects to make building a table easier…
				for( let i = 0; i < monthReturn; i++ ) {
					let realMonth = (i<9?'0':'') + (i + 1);
					yearReturns.returns.push({
						...schema,
						dateReturn: `${yearReturn}-${realMonth}-01T00:00:00`,
					});
				}
			}

			// add to yearReturnsList
			yearReturnsList.push(yearReturns);
		}

		// get the object index
		let year = yearReturnsList.indexOf(yearReturns);

		// add the return data
		yearReturnsList[year].returns.push(monthlyReturn);

		// is the last data point before December?
		if( ( investmentReturns.indexOf(monthlyReturn) + 1 ) === investmentReturns.length && ( monthReturn < 11 ) ) {
			// fill empty months…
			for( let i = monthReturn; i < 11; i++ ) {
				let realMonth = (i<8?'0':'') + (i + 2);
				yearReturnsList[year].returns.push({
					...schema,
					dateReturn: `${yearReturn}-${realMonth}-01T00:00:00`,
				});
			}
		}

	});

	// now we have our table data, let's make some calculations…
	yearReturnsList.forEach((yearReturns) => {

		// missing 12 months, something's wrong…
		if( yearReturns.returns.length < 12 ) {
			for( let i = 0, j = yearReturns.returns.length; i < j; i++ ) {
				let dateReturn = new Date( yearReturns.returns[i].dateReturn ), yearReturn = dateReturn.getFullYear(), monthReturn = dateReturn.getMonth();
				if( i !== monthReturn) {
					let realMonth = (monthReturn<8?'0':'') + (monthReturn + 2);
					yearReturns.returns.splice(i, 0, {
						...schema,
						dateReturn: `${yearReturn}-${realMonth}-01T00:00:00`,
					});
				}
			}
		}
		if( yearReturns.returns.length < 12 ) {
			// fill empty months…
			for( let i = yearReturns.returns.length - 1; i < 11; i++ ) {
				let dateReturn = new Date( yearReturns.returns[i].dateReturn ), yearReturn = dateReturn.getFullYear();
				let realMonth = (i<8?'0':'') + (i + 2);
				yearReturns.returns.splice(i, 0, {
					...schema,
					dateReturn: `${yearReturn}-${realMonth}-01T00:00:00`,
				});
			}
		}

		// ytd
		let ytd = 1;

        for( let i = 0, j = yearReturns.returns.length; i < j; i++ ) {

            if (yearReturns.returns[i].monthReturn !== null) {
                if ( carorType === 2 ) {
                    ytd = ytd + +yearReturns.returns[i].monthReturn;
                }
                else {
                    ytd = ytd * ((+yearReturns.returns[i].monthReturn / 100) + 1);
                }
            }
        }

        if ( carorType === 2 ) {
            ytd = (ytd - 1);
        }
        else {
            ytd = ((ytd - 1) * 100);
        }

		// set ytd
		yearReturns.ytd = ytd;

		// maxDrawDown
		let peak = 1000;
		let vami = 1000;
		let drawDown = 0;
		let maxDrawDown = 0;

		for( let i = 0, j = yearReturns.returns.length; i < j; i++ ) {

		    if (yearReturns.returns[i].monthReturn !== null) {

		        vami = vami * (1 + (+yearReturns.returns[i].monthReturn / 100))

		        if (vami > peak) { peak = vami; };
		        drawDown = (1 - vami / peak) * 100;
		        if (drawDown < 0) { drawDown = drawDown * 1; }

		        if (drawDown > maxDrawDown) {
		            maxDrawDown = drawDown;
		        }
		    }
		}

		// set maxDrawDown
		yearReturns.maxDrawDown = (maxDrawDown * -1);
	});

	// sort yearReturnsList by year (DESC)
	yearReturnsList.sort( (a, b) => {return b.year - a.year; });

	// return data
	return {
		assets: lastReturn.assets ? lastReturn.assets : null,
		monthlyReturns: yearReturnsList,
	};

};

// parseStatisticsRatios
export const parseStatisticsRatios = (statisticsRatios = []) => {

	let monthlyStats = statisticsRatios.find(stat => stat.months === 1);
	let annualStats = statisticsRatios.find(stat => stat.months === 12);

	[
		'arithmeticMean',
		'avgGain',
		'avgLoss',
		'bestPeriod',
		'compoundReturn',
		'gainDeviation',
		'lossDeviation',
		'standardDeviation',
		'worstPeriod',
	].forEach((metric) => {
		monthlyStats[metric] = monthlyStats[metric] ? (monthlyStats[metric] * 100).toFixed(2) + '%' : '';
		annualStats[metric] = annualStats[metric] ? (annualStats[metric] * 100).toFixed(2) + '%' : '';
	});

	[
		'sharpeRatio',
		'skewness',
		'kurtosis',
	].forEach((metric) => {
		monthlyStats[metric] = monthlyStats[metric] ? (monthlyStats[metric]).toFixed(2) : '';
		annualStats[metric] = annualStats[metric] ? (annualStats[metric]).toFixed(2) : '';
	});

	monthlyStats.gainFrequency = (monthlyStats.profitablePeriods / monthlyStats.periods * 100).toFixed(2) + '%';
	annualStats.gainFrequency = (annualStats.profitablePeriods / annualStats.periods * 100).toFixed(2) + '%';

	monthlyStats.lossFrequency = (monthlyStats.lossPeriods / monthlyStats.periods * 100).toFixed(2) + '%';
	annualStats.lossFrequency = (annualStats.lossPeriods / annualStats.periods * 100).toFixed(2) + '%';

	return {
		monthlyStats: monthlyStats,
		annualStats: annualStats
	};

};
