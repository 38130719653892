import React, {
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
	Collapse,
	Toast,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
	FieldArray,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


/*
 * react-country-region-selector
 *
 * @link https://github.com/country-regions/react-country-region-selector
 *
 */
import {
	CountryDropdown,
	RegionDropdown,
} from 'react-country-region-selector';


// Constants
import {
	GROUP_TYPES,
	GROUP_MEMBERSHIPS,
	GROUP_STATUS_IDS,
	GROUP_CONTACT_TYPES,
} from '../../helpers/Constants';


// API
import {
	getRequest,
	postGroup,
	updateGroup,
	addGroupToPlatform,
	deleteGroupFromPlatform,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';


// GroupForm
function GroupForm({
	lang,
	session,
	initialValues,
	callback=false,
}) {

	const text = {...labelsGlobal[lang],...labelsForms[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');


	// section toggles
	const [groupInformationOpen,setGroupInformationOpen] = useState(true);
	const [groupContactsOpen,setGroupContactsOpen] = useState(true);
	const [groupMembershipsOpen,setGroupMembershipsOpen] = useState(true);
	const [groupPlatformOpen,setGroupPlatformOpen] = useState(true);


	// platforms
	const [platforms, setPlatforms] = useState([]);


	// run our queries
	useQueries([
		{
			queryKey: ['platforms'],
			queryFn: () => getRequest('/platforms', {}, session.access_token),
			onSuccess: (response) => {
				setPlatforms(response.items);
			},
			enabled: !!(session.PlatformAdmin)
		},
		{
			queryKey: ['groupPlatforms'],
			queryFn: () => getRequest('/groupsPlatform', {
				filterByGroupId: initialValues.groupId
			}, session.access_token),
			onSuccess: (response) => {
				initialValues.platforms = response.items.map((el) => {return el.platformId;});
			},
			enabled: !!(session.PlatformAdmin) && !!initialValues.groupId
		},
	]);


	// form schema
	const schema = yup.object().shape({
		groupName: yup.string().required(),
		webUrl: yup.string().nullable(),
		descriptionLong: yup.string().required(),

		groupsTypes: yup.array(),

		groupsMemberships: yup.array().of(
			yup.object().shape({
				membershipTypeId: yup.string().required(),
				title: yup.string().required(),
				info: yup.string().required(),
			})
		),

		contacts: yup.array().of(
			yup.object().shape({
				contactTypeId: yup.string().required(),
				firstName: yup.string().required(),
				lastName: yup.string().required(),
				emailAddress: yup.string().email().required(text.invalidEmail),
				phoneDirect: yup.string().nullable().required(text.invalidPhone),
				address1: yup.string().required(),
				address2: yup.string().nullable(),
				city: yup.string().required(),
				country: yup.string().required(),
				state: yup.string().required(),
				zip: yup.string().required(),
			})
		).min(0),

		platforms: yup.array().min(session.PlatformAdmin ? 1 : 0),

		AcceptedAgreement: yup.boolean().required().oneOf([true], 'You must certify that all information is accurate'),
	});


	// onSubmit handler
	const onSubmit = (values, formikBag) => {

		// massage groupsTypes
		let groupsTypes = [];

		values.groupsTypes.forEach((grouptypeId) => {
			groupsTypes.push({
				grouptypeId: grouptypeId
			});
		});

		// define our data object
		let data = {
			...values,
			country: values.contacts.length ? values.contacts[0].country : 'US',
			groupsTypes: groupsTypes,
		};

		// store platforms
		let groupPlatforms = data.platforms;

		delete data.platforms;
		delete data.AcceptedAgreement;

		if( !data.groupId ) {

			// postGroup
			postGroup(data, session.access_token).then((response) => {

				// callback
				if( 'function' === typeof callback ) {
					callback(response);
				}

			});

		}
		else {

			// updateGroup
			updateGroup(data, session.access_token).then((response) => {

				window.scrollTo(0, 0);

				setShowToast(true);
				setToastMessage(text.profileSaved);

				// platform settings
				if( session.PlatformAdmin && groupPlatforms ) {
					let toDelete = initialValues.platforms.filter(x => !groupPlatforms.includes(x));
					let toAdd = groupPlatforms.filter(x => !initialValues.platforms.includes(x));
					if( toAdd.length ) {
						[...new Set(toAdd)].forEach((platformId) => {
							addGroupToPlatform(platformId, values.groupId, session.access_token);
						});
					}
					if( toDelete.length ) {
						[...new Set(toDelete)].forEach((platformId) => {
							deleteGroupFromPlatform(platformId, values.groupId, session.access_token);
						});
					}
				}

				// reset 'isSubmitting'
				formikBag.setSubmitting(false);

			});

		}

	}


	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-5'
				noValidate
				onSubmit={handleSubmit}>

				<div
					className='position-relative'
					aria-live='polite'
					aria-atomic='true'>

					<div
						className='position-absolute d-flex justify-content-center'
						style={{top:'-3.25rem',left:0,right:0}}>

						<Toast
							show={showToast}
							onClose={() => {setShowToast(false);}}
							delay={3000}
							autohide
							className='border-success position-absolute'>

							<Toast.Body
								className='text-success text-center py-1'>
								<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
								{toastMessage}
							</Toast.Body>

						</Toast>

					</div>

				</div>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3'
					aria-controls="group-information"
					aria-expanded={groupInformationOpen}
					onClick={() => setGroupInformationOpen(!groupInformationOpen)}
				>
					<span className='h5'>{text.groupInformation}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupInformationOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupInformationOpen}>

					<div className='p-3' id='group-information'>

						<Form.Group>
							<Form.Label htmlFor='groupName'>{text.groupName}*</Form.Label>
							<Form.Control
								type='text'
								name='groupName'
								id='groupName'
								autoComplete='off'
								value={values.groupName}
								onChange={handleChange}
								isValid={touched.groupName && !errors.groupName}
								isInvalid={touched.groupName && errors.groupName}
							/>
						</Form.Group>

						<Form.Group controlId='webUrl'>
							<Form.Label>{text.website}</Form.Label>
							<Form.Control
								type='url'
								placeholder={'https://www.example.com/'}
								value={values.webUrl}
								onChange={handleChange}
								isValid={touched.webUrl && !errors.webUrl}
								isInvalid={touched.webUrl && errors.webUrl}
							/>
						</Form.Group>

						<Form.Group controlId='descriptionLong'>
							<Form.Label>{text.groupDescription}*</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								value={values.descriptionLong||''}
								onChange={handleChange}
								isValid={touched.descriptionLong && !errors.descriptionLong}
								isInvalid={touched.descriptionLong && errors.descriptionLong}
							/>
						</Form.Group>
					{session.Admin &&
						<Form.Group controlId='groupStatusId'>
							<Form.Label>{text.groupStatus}</Form.Label>
							<Form.Control
								as='select'
								custom
								value={values.groupStatusId}
								onChange={handleChange}
								isValid={touched.groupStatusId && !errors.groupStatusId}
								isInvalid={touched.groupStatusId && errors.groupStatusId}
							>
							{GROUP_STATUS_IDS.map((groupStatus) =>
								<option key={groupStatus.groupStatusId} value={groupStatus.groupStatusId}>{groupStatus.groupStatus}</option>
							)}
							</Form.Control>
						</Form.Group>
					}
						<Form.Group>
							<Form.Label>{text.groupType}</Form.Label>
						{GROUP_TYPES.map((groupType) =>
							<Form.Check 
								key={groupType.grouptypeId}
								custom
								type='checkbox'
								name='groupsTypes'
								id={`groupsTypes-${groupType.grouptypeId}`}
								value={groupType.grouptypeId}
								checked={values.groupsTypes.indexOf(groupType.grouptypeId)>-1}
								onChange={(e) => {
									const isChecked = values.groupsTypes.indexOf(groupType.grouptypeId) > -1;
									setFieldValue('groupsTypes', isChecked ? values.groupsTypes.filter((e) => {return e !== groupType.grouptypeId;}) : [...values.groupsTypes,groupType.grouptypeId] );
								}}
								label={<>
									{groupType.title}
								</>}
							/>
						)}
						</Form.Group>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-contacts"
					aria-expanded={groupContactsOpen}
					onClick={() => setGroupContactsOpen(!groupContactsOpen)}
				>
					<span className='h5'>{text.groupContacts}</span>
					<small className='text-danger pl-2' hidden={!errors.contacts}>{`please complete the information below`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupContactsOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupContactsOpen}>

					<div className='pb-3' id='group-contacts'>

						<FieldArray
							name='contacts'
							render={arrayHelpers => {
								const contacts = values.contacts;
								const isValid = (index, key) => {
									return touched.contacts && touched.contacts[index] && touched.contacts[index][key] && errors.contacts && errors.contacts[index] && !errors.contacts[index][key];
								};
								const isInvalid = (index, key) => {
									return touched.contacts && touched.contacts[index] && touched.contacts[index][key] && errors.contacts && errors.contacts[index] && errors.contacts[index][key];
								};
								return (
									<div className='mb-4'>
									{contacts && contacts.length > 0 ? contacts.map((contact, index) => (
										<div className={'p-3' + ( index % 2 === 0 ? ' bg-light-blue' : '' )} key={index}>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-contactTypeId`}>
															{text.groupContactType}
														</Form.Label>
														<Form.Control
															as='select'
															custom
															name={`contacts.${index}.contactTypeId`}
															id={`contacts-${index}-contactTypeId`}
															value={values.contacts[index].contactTypeId}
															onChange={handleChange}
															isValid={isValid(index,'contactTypeId')}
															isInvalid={isInvalid(index,'contactTypeId')}
														>
														{GROUP_CONTACT_TYPES.map((contactType) =>
															<option
																key={contactType.contactTypeId}
																disabled={false}
																value={contactType.contactTypeId}>{contactType.contactTitle}</option>
														)}
														</Form.Control>
													</Form.Group>

												</div>

												<div className='col-auto ml-auto'>
													<button
														className='btn btn-link py-0 mr-n2 text-danger'
														type='button'
														onClick={() => arrayHelpers.remove(index)}
														>
														<i className='fa-solid fa-times-circle'></i>
													</button>
												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-firstName`}>
															{text.firstName}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.firstName`}
															id={`contacts-${index}-firstName`}
															value={values.contacts[index].firstName||''}
															onChange={handleChange}
															isValid={isValid(index,'firstName')}
															isInvalid={isInvalid(index,'firstName')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-lastName`}>
															{text.lastName}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.lastName`}
															id={`contacts-${index}-lastName`}
															value={values.contacts[index].lastName||''}
															onChange={handleChange}
															isValid={isValid(index,'lastName')}
															isInvalid={isInvalid(index,'lastName')}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-emailAddress`}>
															{text.Email}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='email'
															name={`contacts.${index}.emailAddress`}
															id={`contacts-${index}-emailAddress`}
															value={values.contacts[index].emailAddress||''}
															onChange={handleChange}
															isValid={isValid(index,'emailAddress')}
															isInvalid={isInvalid(index,'emailAddress')}
														/>
														<Form.Control.Feedback type='invalid'>
															{text.invalidEmail}
														</Form.Control.Feedback>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-phoneDirect`}>
															{text.phone}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='tel'
															name={`contacts.${index}.phoneDirect`}
															id={`contacts-${index}-phoneDirect`}
															value={values.contacts[index].phoneDirect||''}
															onChange={handleChange}
															isValid={isValid(index,'phoneDirect')}
															isInvalid={isInvalid(index,'phoneDirect')}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-address1`}>
															{text.address1}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.address1`}
															id={`contacts-${index}-address1`}
															value={values.contacts[index].address1||''}
															onChange={handleChange}
															isValid={isValid(index,'address1')}
															isInvalid={isInvalid(index,'address1')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-address2`}>
															{text.address2}
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.address2`}
															id={`contacts-${index}-address2`}
															value={values.contacts[index].address2||''}
															onChange={handleChange}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-city`}>
															{text.cityTown}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.city`}
															id={`contacts-${index}-city`}
															value={values.contacts[index].city||''}
															onChange={handleChange}
															isValid={isValid(index,'city')}
															isInvalid={isInvalid(index,'city')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-country`}>
															{text.country}
															<span>*</span>
														</Form.Label>
														<CountryDropdown
															classes='custom-select'
															name={`contacts.${index}.country`}
															id={`contacts-${index}-country`}
															value={values.contacts[index].country||''}
															valueType='short'
															onChange={(val) => setFieldValue(`contacts[${index}].country`, val)}
															/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-state`}>
															{text.stateProvince}
															<span>*</span>
														</Form.Label>
														<RegionDropdown
															classes='custom-select'
															name={`contacts.${index}.state`}
															id={`contacts-${index}-state`}
															value={values.contacts[index].state||''}
															valueType='short'
															country={values.contacts[index].country||''}
															countryValueType='short'
															onChange={(val) => setFieldValue(`contacts[${index}].state`, val)}
															/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-zip`}>
															{text.postalCode}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.zip`}
															id={`contacts-${index}-zip`}
															value={values.contacts[index].zip||''}
															onChange={handleChange}
															isValid={isValid(index,'zip')}
															isInvalid={isInvalid(index,'zip')}
														/>
													</Form.Group>

												</div>

											</div>

										</div>
									)) : null}
										<div className='mt-3 ml-3'>
											<button
												className='btn btn-sm btn-outline-primary'
												hidden={contacts.length===3}
												disabled={contacts.length===3}
												type='button'
												onClick={() => {
													arrayHelpers.push({
														contactTypeId: GROUP_CONTACT_TYPES[0].contactTypeId,
														firstName: '',
														lastName: '',
														emailAddress: '',
														phoneDirect: '',
														address1: '',
														address2: '',
														city: '',
														zip: '',
														country: 'US',
														state: '',
													})
												}}
												>
												{text.addAnother}
											</button>
										</div>
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-memberships"
					aria-expanded={groupMembershipsOpen}
					onClick={() => setGroupMembershipsOpen(!groupMembershipsOpen)}
				>
					<span className='h5'>{text.groupMemberships}</span>
				{errors.groupsMemberships &&
					<small className='text-danger pl-2'>{`please complete the information below`}</small>
				}
					<i className={'fas float-right mt-1 fa-chevron-' + (groupMembershipsOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupMembershipsOpen}>

					<div className='pb-3' id='group-memberships'>

						<FieldArray
							name='groupsMemberships'
							render={arrayHelpers => {
								const groupsMemberships = values.groupsMemberships;
								const isValid = (index, key) => {
									return touched.groupsMemberships && touched.groupsMemberships[index] && touched.groupsMemberships[index][key] && errors.groupsMemberships && errors.groupsMemberships[index] && !errors.groupsMemberships[index][key];
								};
								const isInvalid = (index, key) => {
									return touched.groupsMemberships && touched.groupsMemberships[index] && touched.groupsMemberships[index][key] && errors.groupsMemberships && errors.groupsMemberships[index] && errors.groupsMemberships[index][key];
								};
								const membershipTitle = (membershipTypeId) => {
									return (GROUP_MEMBERSHIPS.find(obj => {
										return obj.membershipTypeId === membershipTypeId
									})).title;
								};
								return (
									<div>
									{groupsMemberships && groupsMemberships.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<tbody>
												{groupsMemberships.map((membership, index) =>
													<tr key={index}>
														<th scope='row' style={{width:'10rem'}}>
															<Form.Control
																hidden={'11a1138e-2635-4f4e-aea9-413f590ad1b9'===values.groupsMemberships[index].membershipTypeId}
																as='select'
																custom
																name={`groupsMemberships.${index}.membershipTypeId`}
																id={`groupsMemberships-${index}-membershipTypeId`}
																value={values.groupsMemberships[index].membershipTypeId}
																onChange={(e) => {
																	setFieldValue(`groupsMemberships[${index}].membershipTypeId`, e.currentTarget.value);
																	setFieldValue(`groupsMemberships[${index}].title`, membershipTitle(e.currentTarget.value));
																}}
																isValid={isValid(index,'membershipTypeId')}
																isInvalid={isInvalid(index,'membershipTypeId')}
															>
															{GROUP_MEMBERSHIPS.map((membershipType) =>
																<option
																	key={membershipType.membershipTypeId}
																	disabled={false}
																	value={membershipType.membershipTypeId}>{membershipType.title}</option>
															)}
															</Form.Control>
															<Form.Control
																hidden={'11a1138e-2635-4f4e-aea9-413f590ad1b9'!==values.groupsMemberships[index].membershipTypeId}
																disabled={'11a1138e-2635-4f4e-aea9-413f590ad1b9'!==values.groupsMemberships[index].membershipTypeId}
																placeholder={`Other`}
																type='text'
																name={`groupsMemberships.${index}.title`}
																id={`groupsMemberships-${index}-title`}
																value={values.groupsMemberships[index].title}
																onChange={handleChange}
																isValid={isValid(index,'title')}
																isInvalid={isInvalid(index,'title')}
															/>
														</th>
														<td>
															<Form.Control
																type='text'
																name={`groupsMemberships.${index}.info`}
																id={`groupsMemberships-${index}-info`}
																value={values.groupsMemberships[index].info||''}
																onChange={handleChange}
																isValid={isValid(index,'info')}
																isInvalid={isInvalid(index,'info')}
															/>
														</td>
														<td style={{width:'1%'}}>
															<button
																className='btn btn-link py-0 mr-n2 text-danger'
																type='button'
																onClick={() => arrayHelpers.remove(index)}
																>
																<i className='fa-solid fa-times-circle'></i>
															</button>
														</td>
													</tr>
												)}
												</tbody>
											</table>
										</div>
									) : null}
										<div className='mt-3 ml-3'>
											<button
												className='btn btn-sm btn-outline-primary'
												hidden={groupsMemberships.length===3}
												disabled={groupsMemberships.length===3}
												type='button'
												onClick={() => {
													arrayHelpers.push({
														membershipTypeId: GROUP_MEMBERSHIPS[0].membershipTypeId,
														membershipUrl: null,
														title: GROUP_MEMBERSHIPS[0].title,
														info: '',
													})
												}}
												>
												{text.addAnother}
											</button>
										</div>
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

			{session.PlatformAdmin && platforms && <>
				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-platform"
					aria-expanded={groupPlatformOpen}
					onClick={() => setGroupPlatformOpen(!groupPlatformOpen)}
				>
					<span className='h5'>{text.platform}</span>
					<small className='text-danger pl-2' hidden={!errors.platforms}>{`you must select at least one platform`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupPlatformOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupPlatformOpen}>

					<div className='p-3' id='group-platform'>
						<Form.Group>
						{platforms.map((platform) =>
							<Form.Check 
								key={platform.platformId}
								custom
								type='checkbox'
								name='platforms'
								id={`platform-${platform.platformId}`}
								value={platform.platformId}
								checked={values.platforms.indexOf(platform.platformId)>-1}
								onChange={(e) => {
									const isChecked = values.platforms.indexOf(platform.platformId) > -1;
									setFieldValue('platforms', isChecked ? values.platforms.filter((e) => {return e !== platform.platformId;}) : [...values.platforms,platform.platformId] );
								}}
								label={<>
									{platform.platformTitle}
								</>}
							/>
						)}
						</Form.Group>
					</div>

				</Collapse>

			</>}
				<Form.Group className='mt-3 py-3 px-3'>
					<Form.Check 
						custom
						type='checkbox'
						name='AcceptedAgreement'
						id='AcceptedAgreement-Group'
						value={false}
						checked={values.AcceptedAgreement}
						onChange={(e) => setFieldValue('AcceptedAgreement', !values.AcceptedAgreement)}
						label={<>
							{`I certify that all information I have furnished to ${text.siteTitle} is accurate, to the best of my knowledge.`}
						</>}
					/>
					<small className='pl-4 text-danger' hidden={!errors.AcceptedAgreement}>{`You must certify that all information is accurate`}</small>
				</Form.Group>

				{ (errors.groupName || errors.descriptionLong || errors.groupsMemberships || errors.contacts) &&
					<div className='alert alert-danger'>
					{(errors.groupName || errors.descriptionLong) &&
						<div>{`Please complete your company information above.`}</div>
					}
					{errors.groupsMemberships &&
						<div>{`There is an error with your registrations. Please see above.`}</div>
					}
					{errors.contacts &&
						<div>{`There is an error with your contacts. Please see above.`}</div>
					}
					</div>
				}

				<Form.Group>
					<button
						type='submit'
						disabled={!values.AcceptedAgreement||isSubmitting}
						className='btn btn-primary btn-block mt-3'>
					{isSubmitting &&
						<span>{text.working}</span>
					}
					{!isSubmitting && <>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveChanges}</span>
					</>}
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default GroupForm;
