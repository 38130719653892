export const labels = {
	'en': {
		pageTitle: 'Register for an Account',
		pageContent: <>
			<p>Sign up for free access to our portfolio tools and complete CTA database of over 600 <a href='/en/managed-futures/performance'>programs</a>.</p>
			<p>Are you a CTA looking to list your program on IASG? <a href='/en/join/cta'>Register here</a>.</p>
		</>,
	},
	'zh-hans': {
		pageTitle: '注册一个帐户',
		pageContent: <>
			<p>注册免费访问我们的投资组合工具和包含 600 多个<a href='/zh-hans/managed-futures/performance'>程序</a>的完整 CTA 数据库.</p>
			<p>您是希望在 IASG 上列出您的计划的 CTA 吗？ <a href='/zh-hans/join/cta'>在此处注册</a>.</p>
		</>,
	}
};
