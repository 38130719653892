/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/buttons/
 * @link https://react-bootstrap.github.io/components/button-group/
 *
 */
import {
	Button,
	ButtonGroup,
} from 'react-bootstrap';


// API
import {
	getRequest,
} from '../../api/Api';


// StatsAndRatios
function StatsAndRatios({
	text,
	session,
	blend,
	program,
	statsType,
	setStatsType,
	comparisonStats,
	setComparisonStats,
	setComparisonStatsLoading,
	parseStatisticsRatios,
	monthlyStats,
	annualStats,
	comparisonStatsLoading,
	AsyncTypeahead,
	filterBy,
	isSearching,
	compareStatsSearch,
	handleProgramSearch,
	searchResults,
	programsCompareItems,
}) {

	// statistic comparisons
	const removeComparisonStats = (stat) => {

		let stats = [...comparisonStats];

		if ( stats.indexOf(stat) === -1 ) return;

		// remove index
		stats.splice( stats.indexOf(stat), 1 );

		// update comparisonStats
		setComparisonStats(stats);

	};

	const addComparisonStats = ({programId}) => {

		setComparisonStatsLoading(true);

		getRequest(`/programs/${programId}`, {}, session.access_token).then((response) => {

			if( response.statisticsRatios.length ) {

				// get statisticsRatios
				let statisticsRatios = parseStatisticsRatios(response.statisticsRatios);

				// setComparisonStats
				setComparisonStats([
					...comparisonStats,
					{
						programId: programId,
						programName: response.programName,
						groupName: response.groupName,
						...statisticsRatios,
					}
				]);

			}

			setComparisonStatsLoading(false);

		});

	};

	const handleStatComparison = (e) => {

		let select = e.currentTarget;
		let programId = select.value;

		if( !programId ) return;

		if( comparisonStats.find(obj => { return obj.programId === programId; }) ) return;

		addComparisonStats({
			programId: programId,
		});

		e.currentTarget.value='';
		e.currentTarget.blur();

	};

	// helper functions
	const statsTitle = () => {

		if( program )

			return (
				<>
					<span className='font-weight-normal text-muted'>{program.groupName}</span><br/>
					<span>{program.programName}</span>
				</>
			);

		if( blend )

			return (
				<>
					<span>{blend.blendName}</span>
				</>
			);

		return '';

	};

	return (
		<div className='row'>

			<div className={'col-12 col-lg-8 col-xl-9 order-2 order-lg-1'}>

				<ButtonGroup
					className='mb-4 px-3 d-none d-lg-block'
					aria-label='Toggle monthly/annual statistics:'>
				{[
					{id:'monthly',label:text.monthly,className:'px-2'},
					{id:'annual',label:text.annual,className:'px-4'},
				].map((type) =>
					<Button
						key={type.id}
						className={'rounded-pill mx-n3 px-5'}
						variant={type.id===statsType?'primary':'outline-primary'}
						onClick={(e) => {setStatsType(type.id);e.currentTarget.blur();}}>
						<span className={type.className}>{type.label}</span>
					</Button>
				)}
				</ButtonGroup>

				<div className='table-responsive' hidden={'monthly'!==statsType}>
					<table className='table table-sm table-striped'>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>{`Reward`}</th>
								<th className='text-right' scope='col'>
									{statsTitle()}
								</th>
							{comparisonStats.map((stat,i) =>
								<th key={i} className='text-right' scope='col'>
									<button
										onClick={(e) => {removeComparisonStats(stat);}}
										className='btn btn-outline-dark btn-sm'>
										<span className='d-flex text-left align-items-center'>
											<i className='fa-solid fa-times mr-2' style={{fontSize:'90%'}}></i>
											<span className='py-1' style={{lineHeight:'normal'}}>
												<span className='font-weight-normal text-muted'>{stat.groupName}</span><br/>
												<span>{stat.programName}</span>
											</span>
										</span>
									</button>
								</th>
							)}
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'arithmeticMean',
								label: 'Average RoR',
							},
							{
								field: 'bestPeriod',
								label: 'Max Gain',
							},
							{
								field: 'gainFrequency',
								label: 'Gain Frequency',
							},
							{
								field: 'avgGain',
								label: 'Average Gain',
							},
							{
								field: 'gainDeviation',
								label: 'Gain Deviation',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{monthlyStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.monthlyStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>Risk</th>
								<th className='text-right' scope='col'></th>
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'standardDeviation',
								label: 'Standard Deviation',
							},
							{
								field: 'worstPeriod',
								label: 'Worst Loss',
							},
							{
								field: 'lossFrequency',
								label: 'Loss Frequency',
							},
							{
								field: 'avgLoss',
								label: 'Average Loss',
							},
							{
								field: 'lossDeviation',
								label: 'Loss Deviation',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{monthlyStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.monthlyStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>Reward/Risk</th>
								<th className='text-right' scope='col'></th>
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'sharpeRatio',
								label: text.sharpeRatio,
							},
							{
								field: 'skewness',
								label: 'Skewness',
							},
							{
								field: 'kurtosis',
								label: 'Kurtosis',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{monthlyStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.monthlyStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
					</table>
				</div>

				<div className='table-responsive' hidden={'annual'!==statsType}>
					<table className='table table-sm table-striped'>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>Reward</th>
								<th className='text-right' scope='col'>
									{statsTitle()}
								</th>
							{comparisonStats.map((stat,i) =>
								<th key={i} className='text-right' scope='col'>
									<button
										onClick={(e) => {removeComparisonStats(stat);}}
										className='btn btn-outline-dark btn-sm'>
										<span className='d-flex text-left align-items-center'>
											<i className='fa-solid fa-times mr-2' style={{fontSize:'90%'}}></i>
											<span className='py-1' style={{lineHeight:'normal'}}>
												<span className='font-weight-normal text-muted'>{stat.groupName}</span><br/>
												<span>{stat.programName}</span>
											</span>
										</span>
									</button>
								</th>
							)}
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'compoundReturn',
								label: 'Compound RoR',
							},
							{
								field: 'arithmeticMean',
								label: 'Average RoR',
							},
							{
								field: 'bestPeriod',
								label: 'Max Gain',
							},
							{
								field: 'gainFrequency',
								label: 'Gain Frequency',
							},
							{
								field: 'avgGain',
								label: 'Average Gain',
							},
							{
								field: 'gainDeviation',
								label: 'Gain Deviation',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{annualStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.annualStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>Risk</th>
								<th className='text-right' scope='col'></th>
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'standardDeviation',
								label: 'Standard Deviation',
							},
							{
								field: 'worstPeriod',
								label: 'Worst Loss',
							},
							{
								field: 'lossFrequency',
								label: 'Loss Frequency',
							},
							{
								field: 'avgLoss',
								label: 'Average Loss',
							},
							{
								field: 'lossDeviation',
								label: 'Loss Deviation',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{annualStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.annualStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
						<thead className='table-heading'>
							<tr>
								<th scope='col'>Reward/Risk</th>
								<th className='text-right' scope='col'></th>
							</tr>
						</thead>
						<tbody>
						{[
							{
								field: 'sharpeRatio',
								label: text.sharpeRatio,
							},
							{
								field: 'skewness',
								label: 'Skewness',
							},
							{
								field: 'kurtosis',
								label: 'Kurtosis',
							},
						].map((row,i) =>
							<tr key={i}>
								<td>{row.label}</td>
								<td className='text-right'>{annualStats[row.field]}</td>
							{comparisonStats.map((stat,i) =>
								<td className='text-right' key={i}>{stat.annualStats[row.field]}</td>
							)}
							</tr>
						)}
						</tbody>
					</table>
				</div>

			</div>

			<div className={'col-12 col-lg-4 col-xl-3 order-1 order-lg-2 d-print-none'}>

				<ButtonGroup
					className='mb-4 px-3 d-lg-none'
					aria-label='Toggle monthly/annual statistics:'>
				{[
					{id:'monthly',label:text.monthly,className:'px-2'},
					{id:'annual',label:text.annual,className:'px-4'},
				].map((type) =>
					<Button
						key={type.id}
						className={'rounded-pill mx-n3 px-5'}
						variant={type.id===statsType?'primary':'outline-primary'}
						onClick={(e) => {setStatsType(type.id);e.currentTarget.blur();}}>
						<span className={type.className}>{type.label}</span>
					</Button>
				)}
				</ButtonGroup>

				<div className='form-group'>

					<h6 className='table-heading'>
						<label className='m-0' htmlFor='compareStatsTo'>
							{text.compareTo}
							<span className={'text-primary pl-1 fade' + (comparisonStatsLoading ? ' show' : '')}>
								<i className='fa-solid fa-circle-notch fa-spin'></i>
							</span>
						</label>
					</h6>

					<AsyncTypeahead
						filterBy={filterBy}
						id='compareStatsTo'
						isLoading={isSearching}
						labelKey='programName'
						ref={compareStatsSearch}
						minLength={3}
						onChange={(selected) => {
							if( !selected.length ) return;
							let program = selected[0];
							if( comparisonStats.find(obj => { return obj.programId === program.programId; }) ) return;
							addComparisonStats({
								programId: program.programId,
								title: program.programName,
							});
							compareStatsSearch.current.clear();
						}}
						onSearch={handleProgramSearch}
						options={searchResults}
						placeholder={text.programSearchPlaceholder}
						renderMenuItemChildren={(option, props) => (
							<>
								<small className='groupName'>{option.groupName}</small>
								<div className='programName'>{option.programName}</div>
							</>
						)}
						/>

				</div>

				<div className='form-group'>

					<label htmlFor='statsIndex' className='form-text'>{text.or}</label>

					<select className='custom-select' id='statsIndex'
						onChange={(e) => handleStatComparison(e)}>
						<option value={''}>{text.selectAnIndex}</option>
					{programsCompareItems && programsCompareItems.items && programsCompareItems.items.filter((program) => program.programType === 1).map((program) =>
						<option
							key={program.programId}
							data-type={program.programType}
							disabled={comparisonStats.find(obj => { return obj.programId === program.programId; })}
							value={program.programId}>{program.title}</option>
					)}
					</select>

				</div>

			</div>

		</div>
	)

}

export default StatsAndRatios;
