import React, {
	useEffect,
} from 'react';


// forms
import {
	ForgotPasswordForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsForgotPassword } from '../i18n/ForgotPassword';


// ForgotPassword
function ForgotPassword({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsForgotPassword[lang]};

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( session.access_token ) {
			window.location = `/managed-futures/performance`;
		}
	}, [
		lang,
		session,
	]);

	if ( session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col' style={{maxWidth:'30rem'}}>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					{text.pageContent}

					<ForgotPasswordForm
						lang={lang}
						session={session}
						/>

				</div>

			</div>

		</div>
	)

}

export default ForgotPassword;
