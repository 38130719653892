import React, {
	useState,
	useEffect,
	useCallback,
} from 'react';


/*
 * React Query
 *
 * Use the QueryClientProvider component to connect and provide a QueryClient to our application
 *
 * @link https://react-query.tanstack.com/reference/QueryClient
 * @link https://react-query.tanstack.com/reference/QueryClientProvider
 *
 */
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useRoutes,
	useRedirect,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/modal/
 *
 */
import {
	Modal,
} from 'react-bootstrap';


/*
 * date-fns
 *
 * @link https://date-fns.org/
 *
 */
import {
	format as dateFormat,
	subMonths,
} from 'date-fns';


// components
import Admin from './components/Admin';
import Header from './components/Header';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Profile from './components/Profile';
import Register from './components/Register';
import AddCTA from './components/AddCTA';
import RegisterCTA from './components/RegisterCTA';
import Group from './components/Group';
import Programs from './components/Programs';
import Program from './components/Program';
import Indexes from './components/Indexes';
import Index from './components/Index';
import AwardList from './components/AwardList';
import Quotes from './components/Quotes';
import Dashboard from './components/Dashboard';
import Watchlist from './components/Watchlist';
import Portfolio from './components/Portfolio';
import SavedBlends from './components/SavedBlends';
import Blender from './components/Blender';


// constants
import {
	WP_AJAX_URL,
	WP_BASE_URL,
	PROGRAMS_COLUMNS,
	RETURNS_COLUMNS,
	ARCHIVE_COLUMNS,
	PROGRAM_ARCHIVE_STATUS_ID,
} from './helpers/Constants';


// API
import {
	UserContext,
} from './api/Api';


// partials
import {
	LoginForm,
} from './components/forms/Forms';


// i18n
import { labels } from './i18n/Global';
import { labels as labelsForms } from './i18n/Forms';
import { labels as labelsArchives } from './i18n/Archives';
import { labels as labelsNewListings } from './i18n/NewListings';
import { labels as labelsReturns } from './i18n/Returns';

let logoutTimer;

// App
function App() {

	// set up our QueryClient
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
			},
		},
		queryCache: new QueryCache({
			onError: (error, query) => {
				console.log(error);
			},
		}),
	});

	// i18n
	const [lang] = useState( 'en' );
	const text = {...labels[lang], ...labelsForms[lang]};

	// login/modal
	const [showLogin, setShowLogin] = useState(false);
	const toggleShowLogin = () => setShowLogin(!showLogin);

	// session handler
	const [session, setSession] = useState({});

	const login = useCallback((response) => {

		setSession(response);

		localStorage.setItem( 'session', JSON.stringify(response) );

	}, []);

	const logout = useCallback(() => {

		localStorage.setItem( 'session', JSON.stringify({}) );

		setSession({});

		document.cookie = `iasg_token1=null;path=/;max-age=0;`;
		document.cookie = `iasg_token2=null;path=/;max-age=0;`;
		document.cookie = `iasg_access=true;path=/;max-age=0;`;

		window.location = `/login`;

	}, []);


	// routes
	let routes = {};

	[
		'en',
	].forEach((lang) => {

		routes[`/admin`] = () =>
		<>
			<Admin
				lang={lang}
				session={session}
				/>
		</>

		routes[`/admin/:tab`] = ({tab}) =>
		<>
			<Admin
				lang={lang}
				session={session}
				tab={tab}
				/>
		</>

		routes[`/admin/:tab/:id`] = ({tab,id}) =>
		<>
			<Admin
				lang={lang}
				session={session}
				tab={tab}
				id={id}
				/>
		</>

		routes[`/login`] = () =>
		<>
			<Login
				lang={lang}
				session={session}
				setShowLogin={setShowLogin}
				/>
		</>

		routes[`/login/suspended`] = () =>
		<>
			<Login
				lang={lang}
				session={session}
				setShowLogin={setShowLogin}
				suspended={true}
				/>
		</>

		routes[`/forgot-password`] = () =>
		<>
			<ForgotPassword
				lang={lang}
				session={session}
				/>
		</>

		routes[`/reset-password`] = () =>
		<>
			<ResetPassword
				lang={lang}
				session={session}
				/>
		</>

		routes[`/profile`] = () =>
		<>
			<Profile
				lang={lang}
				session={session}
				/>
		</>

		routes[`/register`] = () =>
		<>
			<Register
				lang={lang}
				session={session}
				/>
		</>

		routes[`/join/cta`] = () =>
		<>
			<RegisterCTA
				lang={lang}
				session={session}
				/>
		</>

		routes[`/managed-futures/archived-programs`] = () =>
		<>
			<Programs
				lang={lang}
				session={session}
				context='archived-programs'
				filterByProgramStatusIds={PROGRAM_ARCHIVE_STATUS_ID}
				cols={ARCHIVE_COLUMNS}
				sort='dateInception'
				labels={labelsArchives}
				/>
		</>

		routes[`/managed-futures/market-quotes`] = () =>
		<>
			<Quotes
				lang={lang}
				session={session}
				toggleShowLogin={toggleShowLogin}
				/>
		</>

		routes[`/managed-futures/new-listings`] = () =>
		<>
			<Programs
				lang={lang}
				session={session}
				context='new-listings'
				filterByDateApprovedStart={dateFormat(subMonths(new Date(), 1), 'yyyy-MM-dd')}
				cols={PROGRAMS_COLUMNS}
				sort={'dateInception'}
				labels={labelsNewListings}
				/>
		</>

		routes[`/managed-futures/performance`] = () =>
		<>
			<Programs
				lang={lang}
				session={session}
				context='performance'
				cols={PROGRAMS_COLUMNS}
				sort={'monthReturn'}
				/>
		</>

		routes[`/managed-futures/returns`] = () =>
		<>
			<Programs
				lang={lang}
				session={session}
				context='returns'
				cols={RETURNS_COLUMNS}
				sort={'ytdReturn'}
				labels={labelsReturns}
				/>,
		</>

		routes[`/groups/:groupUri`] = ({groupUri}) =>
		<>
			<Group
				lang={lang}
				session={session}
				groupUri={groupUri}
				/>
		</>

		routes[`/groups/:groupUri/:mode`] = ({groupUri,mode}) =>
		<>
			<Group
				lang={lang}
				session={session}
				groupUri={groupUri}
				mode={mode}
				/>
		</>

		routes[`/groups/:groupUri/programs/:programUri`] = ({groupUri,programUri}) =>
		<>
			<Program
				lang={lang}
				session={session}
				groupUri={groupUri}
				programUri={programUri}
				toggleShowLogin={toggleShowLogin}
				/>
		</>

		routes[`/groups/:groupUri/programs/:programUri/:tab`] = ({groupUri,programUri,tab}) =>
		<>
			<Program
				lang={lang}
				session={session}
				groupUri={groupUri}
				programUri={programUri}
				tab={tab}
				toggleShowLogin={toggleShowLogin}
				/>
		</>

		routes[`/indexes`] = () =>
		<>
			<Indexes
				lang={lang}
				session={session}
				/>
		</>

		routes[`/indexes/:portfolioUri`] = ({portfolioUri}) =>
		<>
			<Index
				lang={lang}
				session={session}
				portfolioUri={portfolioUri}
				toggleShowLogin={toggleShowLogin}
				/>
		</>

		routes[`/indexes/:portfolioUri/rankings`] = ({portfolioUri}) =>
		<>
			<AwardList
				lang={lang}
				portfolioUri={portfolioUri}
				/>
		</>

		routes[`/indexes/:portfolioUri/:tab`] = ({portfolioUri,tab}) =>
		<>
			<Index
				lang={lang}
				session={session}
				portfolioUri={portfolioUri}
				tab={tab}
				toggleShowLogin={toggleShowLogin}
				/>
		</>

		routes[`/tools/add-cta`] = () =>
		<>
			<AddCTA
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/dashboard`] = () =>
		<>
			<Dashboard
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/dashboard/:tab`] = ({tab}) =>
		<>
			<Dashboard
				lang={lang}
				session={session}
				tab={tab}
				/>
		</>

		routes[`/tools/watchlist`] = () =>
		<>
			<Watchlist
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/portfolio`] = () =>
		<>
			<Portfolio
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/saved-blends`] = () =>
		<>
			<SavedBlends
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/blender`] = () =>
		<>
			<Blender
				lang={lang}
				session={session}
				/>
		</>

		routes[`/tools/blender/:tab`] = ({tab}) =>
		<>
			<Blender
				lang={lang}
				session={session}
				tab={tab}
				/>
		</>

	});

	// redirects
	useRedirect('/', `/en/managed-futures/performance`);

	const routeResults = useRoutes(routes);


	// here we go…
	useEffect(() => {

		// always scroll to top…
		window.scrollTo(0, 0);

		// load session from localStorage if possible
		const session = JSON.parse( localStorage.getItem('session') || JSON.stringify({
			expires_in: ( new Date() ).getTime() + (3599 * 1000)
		}) );

		// remainingTime
		const remainingTime = session.expires_in - ( new Date() ).getTime();
		const refreshMinute = 600 * 1000; // 10 minutes

		if( session.access_token && session.refresh_token ) {

			if( session.expires && remainingTime < 0 ) {

				// out of time, log out
				logout();

			}
			else if( remainingTime < refreshMinute ) {

				// setup FormData
				const body = new FormData();

				body.append( 'action', 'iasg_refresh' );
				body.append( 'refresh_token', session.refresh_token );

				fetch( WP_AJAX_URL, {
					method: 'POST',
					body: body,
				} )
					.then(response => response.json())
					.then((response) => {

						if( response && response.success ) {

							const max_age = (response.expires_in * 1000);

							login({
								...session,
								expires_in: ( new Date() ).getTime() + max_age,
								access_token: response.access_token,
								refresh_token: response.refresh_token,
							});

							const middle = Math.floor(response.access_token.length / 2);
							const token1 = response.access_token.substr(0, middle);
							const token2 = response.access_token.substr(middle);

							document.cookie = `iasg_token1=${token1};path=/;max-age=${max_age}`;
							document.cookie = `iasg_token2=${token2};path=/;max-age=${max_age}`;
							document.cookie = `iasg_access=true;path=/;max-age=${max_age}`;

						}
						else {

							logout();

						}
					}

				).catch((error) => {
					console.log( error.toString() );
				});

			}
			else {

				// login with stored session
				login(session);

			}

		}
		else {

			login({anonymous:true});

		}

	}, [
		login,
		logout,
	]);


	// expires handler
	useEffect(() => {

		if( session && session.expires ) {

			const remainingTime = session.expires_in - ( new Date() ).getTime();

			logoutTimer = setTimeout(logout, remainingTime);

		}
		else {

			clearTimeout(logoutTimer);

		}

	}, [
		session,
		logout,
	]);


	return (session.access_token || session.anonymous ?
		<UserContext.Provider value={{
			...session,
			isLoggedIn: !!session.access_token,
			login: login,
			logout: logout
		}}>
		<QueryClientProvider client={queryClient}>
			{'localhost'===window.location.hostname &&
				<Header
					lang={lang}
					session={session}
					logout={logout}
					toggleShowLogin={toggleShowLogin}
					WP_BASE_URL={WP_BASE_URL}
					/>
			}
			<aside>
				<form method='post' action={`${WP_BASE_URL}`} id='pdfcrowd'>
					<input type='hidden' name='action' value='iasg_print'/>
					<input type='hidden' name='html' id='html'/>
					<input type='hidden' name='filename' id='filename'/>
				</form>
			</aside>

			{routeResults||<div className='container py-5'><h1>{text.pageNotFound}</h1></div>}

			<Modal show={showLogin} onHide={toggleShowLogin} centered>

				<Modal.Header className='pb-0 border-0'>

					<button
						type='button'
						className='close'
						onClick={toggleShowLogin}>
						<i className='fa-solid fa-times' style={{fontSize:'80%'}}></i>
					</button>

				</Modal.Header>

				<Modal.Body className='px-sm-5'>

					<h5>{text.accountLogIn}</h5>

					<LoginForm
						lang={lang}
						session={session}
						setShowLogin={setShowLogin}
						/>

				</Modal.Body>

			</Modal>

		</QueryClientProvider>
		</UserContext.Provider> : <></>
	);
}

export default App;
