export const labels = {
	'en': {
		pageTitle: 'Forgot your password?',
		pageContent: 'Enter your email address and you’ll receive an email with instructions to reset your password.',
		emailNotFound: 'Email not found',
		emailFound: 'Check your email for instructions to reset your password.',
	},
	'zh-hans': {
		pageTitle: '忘记密码了吗？',
		pageContent: '输入您的电子邮件地址，您将收到一封电子邮件，其中包含重置密码的说明。',
		emailNotFound: '电子邮件没有找到',
		emailFound: '检查您的电子邮件以获取重置密码的说明。',
	}
};
