export const labels = {
	'en': {
		typeHeading: 'Type',
		registrationsHeading: 'Registrations',
		locationsHeading: 'Location',
		descriptionHeading: 'CTA Introduction',
		'Marketing Manager': 'Marketing Manager',
		'Portfolio Manager': 'Portfolio Manager',
		'Performance Manager': 'Performance Manager',
	},
	'zh-hans': {
		typeHeading: '类型',
		registrationsHeading: '注册',
		locationsHeading: '地点',
		descriptionHeading: 'CTA介绍',
		'Marketing Manager': '市场经理',
		'Portfolio Manager': '投资组合经理',
		'Performance Manager': '绩效经理',
	}
};
