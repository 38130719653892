export const labels = {
	'en': {
		pageTitle: 'My Saved Blends',

		blenderInstructions: 'Enter programs below to create your own custom blend and set the allocation.',

		addToYourBlend: 'Add to your blend:',
		compareBlendTo: 'Compare blend to…',
		createNewBlend: 'Create New Blend',
		nameThisBlend: 'Name this Blend',
		saveBlend: 'Save Blend',
		saveBlendAs: 'Save as…',
		currentBlend: 'Current Blend',
		findNonCorrelatedPrograms: 'Find non-correlated programs',

		creatingWillDelete: 'Creating a new blend will automatically delete your current worksheet.',
		loadingWillDelete: 'Loading this blend will automatically delete your current worksheet.',
		deleteAndCreate: 'Delete worksheet and create new blend',
		deleteAndLoad: 'Delete worksheet and load this blend',

		viewCurrentWorksheet: 'View my current worksheet',

		annualizedDisclaimer: '*Annualized',
		blenderDisclaimer: <>
			<p className='text-muted'>
				<small>{`This presentation assumes an investment with the assets being divided between the selected commodity trading advisors. The performance capsule is net of CTA and brokerage fees charged to the accounts under their respective management. It assumes that all profits, net of fees, were reinvested and that the returns were compounded monthly.`}</small>
			</p>

			<p className='text-muted'>
				<small>{`THIS COMPOSITE PERFORMANCE RECORD IS HYPOTHETICAL AND THESE TRADING ADVISORS HAVE NOT TRADED TOGETHER IN THE MANNER SHOWN IN THE COMPOSITE. HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE DESCRIBED BELOW. NO REPRESENTATION IS BEING MADE THAT ANY MULTI-ADVISOR MANAGED ACCOUNT OR POOL WILL OR IS LIKELY TO ACHIEVE A COMPOSITE PERFORMANCE RECORD SIMILAR TO THAT SHOWN. IN FACT, THERE ARE FREQUENTLY SHARP DIFFERENCES BETWEEN A HYPOTHETICAL COMPOSITE PERFORMANCE RECORD AND THE ACTUAL RECORD SUBSEQUENTLY ACHIEVED.`}</small>
			</p>

			<p className='text-muted'>
				<small>{`ONE OF THE LIMITATIONS OF A HYPOTHETICAL COMPOSITE PERFORMANCE RECORD IS THAT DECISIONS RELATING TO THE SELECTION OF TRADING ADVISORS AND THE ALLOCATION OF ASSETS AMONG THOSE TRADING ADVISORS WERE MADE WITH THE BENEFIT OF HINDSIGHT BASED UPON THE HISTORICAL RATES OF RETURN OF THE SELECTED TRADING ADVISORS. THEREFORE, COMPOSITE PERFORMANCE RECORDS INVARIABLY SHOW POSITIVE RATES OF RETURN. ANOTHER INHERENT LIMITATION ON THESE RESULTS IS THAT THE ALLOCATION DECISIONS REFLECTED IN THE PERFORMANCE RECORD WERE NOT MADE UNDER ACTUAL MARKET CONDITIONS AND, THEREFORE, CANNOT COMPLETELY ACCOUNT FOR THE IMPACT OF FINANCIAL RISK IN ACTUAL TRADING. FURTHERMORE, THE COMPOSITE PERFORMANCE RECORD MAY BE DISTORTED BECAUSE THE ALLOCATION OF ASSETS CHANGES FROM TIME TO TIME AND THESE ADJUSTMENTS ARE NOT REFLECTED IN THE COMPOSITE.`}</small>
			</p>
		</>
	},
	'zh-hans': {
		pageTitle: '我保存的混合物',

		blenderInstructions: '在下面输入程序以创建您自己的自定义混合并设置分配。',

		addToYourBlend: '添加到您的混合物中：',
		compareBlendTo: '比较混合…',
		createNewBlend: '创建新混合',
		nameThisBlend: '命名这个混合',
		saveBlend: '保存混合',
		saveBlendAs: '另存为',
		currentBlend: '当前混合',
		findNonCorrelatedPrograms: '查找不相关的程序',

		creatingWillDelete: '创建新的混合将自动删除您当前的工作表。',
		loadingWillDelete: '加载此混合将自动删除您当前的工作表。',
		deleteAndCreate: '删除工作表并创建新的混合',
		deleteAndLoad: '删除工作表并加载此混合',

		viewCurrentWorksheet: '查看我当前的工作表',

		annualizedDisclaimer: '*年化',
		blenderDisclaimer: <>
			<p className='text-muted'>
				<small>{`本演示文稿假设资产在选定的商品交易顾问之间进行分配。 业绩胶囊扣除向其各自管理的账户收取的 CTA 和经纪费。 它假设扣除费用后的所有利润都进行了再投资，并且每个月的回报都是复合的。`}</small>
			</p>

			<p className='text-muted'>
				<small>{`该综合业绩记录是假设性的，这些交易顾问并未按照综合业绩记录中所示的方式进行交易。 假设的性能结果有许多固有的局限性，其中一些描述如下。 不表示任何多顾问管理的账户或池将或可能实现与所示类似的综合性能记录。 事实上，假设的复合性能记录与随后实现的实际记录之间经常存在明显差异。`}</small>
			</p>

			<p className='text-muted'>
				<small>{`假设的综合业绩记录的局限性之一是，有关交易顾问的选择和这些交易顾问之间的资产分配的决定是基于对交易顾问的后见之明的选择而做出的。 因此，综合业绩记录总是显示出积极的回报率。 上述结果的另一个固有局限性是业绩记录所反映的分配决策并非在实际市场情况下作出的，因此不能完全说明实际交易中财务风险的影响。 此外，由于资产分配不时发生变化，并且这些调整不会在组合中反映出来，因此组合的业绩记录可能会被扭曲。`}</small>
			</p>
		</>
	}
};
