import React, {
	useContext,
	useState,
} from 'react';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 * @link https://react-bootstrap.github.io/components/overlays/
 *
 */
import {
	Form,
	Collapse,
/*
	OverlayTrigger,
	Popover,
*/
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
	FieldArray,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


/*
 * react-country-region-selector
 *
 * @link https://github.com/country-regions/react-country-region-selector
 *
 */
import {
	CountryDropdown,
	RegionDropdown,
} from 'react-country-region-selector';


// Constants
import {
	GROUP_PENDING_STATUS_ID,
	GROUP_TYPES,
	GROUP_MEMBERSHIPS,
	GROUP_CONTACT_TYPES,
} from '../../helpers/Constants';


// API
import {
	UserContext,
	authenticate,
	apiRequest,
	postGroup,
	mailchimpApiRequest,
	transactionalEmailRequest,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';
import { labels as labelsRegisterCTA } from '../../i18n/RegisterCTA';


// CTAForm
function CTAForm({
	lang,
	initialValues,
	callback=false,
}) {

	const User = useContext(UserContext);

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsRegisterCTA[lang]};

	// error handling
	const [registerError, setRegisterError] = useState(false);

	// section toggles
	const [groupInformationOpen,setGroupInformationOpen] = useState(true);
	const [groupContactsOpen,setGroupContactsOpen] = useState(false);
	const [groupMembershipsOpen,setGroupMembershipsOpen] = useState(false);


	// initial question
	const [userContactTypeId,setUserContactTypeId] = useState('');

	// profileComplete (bool)
	const [profileComplete,setProfileComplete] = useState(false);

	// always set the first contact = User :\
	const syncUserAndFirstContact = (values) => {

		values.contacts[0] = {
			contactTypeId: userContactTypeId,
			firstName: values.firstName,
			lastName: values.lastName,
			emailAddress: values.Email,
			phoneDirect: values.phone,
			address1: values.address1,
			address2: values.address2,
			city: values.cityTown,
			zip: values.postalCode,
			country: values.country,
			state: values.stateProvince,
		};

	};


	// user schema
	const userSchema = {
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		Email: yup.string().email().required(text.invalidEmail),
		phone: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).nullable().required(text.invalidPhone),
		address1: yup.string().required(),
		address2: yup.string().nullable(),
		cityTown: yup.string().required(),
		country: yup.string().required(),
		stateProvince: yup.string().required(),
		postalCode: yup.string().required(),
		Password: yup.string().min(6, text.PasswordMinLength.replace('{min', '${min')).matches( (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/)), text.PasswordStrength).required(text.PasswordRequired),
		ConfirmPassword: yup.string().oneOf([yup.ref('Password'), null], text.PasswordMismatch).required(text.PasswordRequired),
		getNewsletter: yup.boolean(),
	};

	// group schema
	const groupSchema = {
		groupName: yup.string().required(),
		webUrl: yup.string().nullable(),
		descriptionLong: yup.string().required(),

		groupsTypes: yup.array(),

		groupsMemberships: yup.array().of(
			yup.object().shape({
				membershipTypeId: yup.string().required(),
				title: yup.string().required(),
				info: yup.string().required(),
			})
		),

		contacts: yup.array().of(
			yup.object().shape({
				contactTypeId: yup.string().required(),
				firstName: yup.string().required(),
				lastName: yup.string().required(),
				emailAddress: yup.string().email().required(text.invalidEmail),
				phoneDirect: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).nullable().required(text.invalidPhone),
				address1: yup.string().required(),
				address2: yup.string().nullable(),
				city: yup.string().required(),
				country: yup.string().required(),
				state: yup.string().required(),
				zip: yup.string().required(),
			})
		).min(1),

		AcceptedAgreement: yup.boolean().required().oneOf([true], 'You must certify that all information is accurate'),
	};

	// form schema
	const schema = yup.object().shape({
		...userSchema,
		...groupSchema,
	});

	// submit handler
	const handleRegisterSubmit = (values, formikBag) => {

		// clear errors
		setRegisterError(false);

		// parse user data
		let userData = {
			RoleNames: [
				'Member',
				'CTA Manager',
			],
		};

		for(let key in userSchema) {
			userData[key] = values[key];
		}

		// parse group data
		let groupData = {
			groupStatusId: GROUP_PENDING_STATUS_ID,
			country: userData.country,
		};

		for(let key in groupSchema) {
			switch(key) {
				case 'groupsTypes':
					// massage groupsTypes
					let groupsTypes = [];

					values.groupsTypes.forEach((grouptypeId) => {
						groupsTypes.push({
							grouptypeId: grouptypeId
						});
					});

					groupData[key] = groupsTypes;
				break;
				case 'AcceptedAgreement':
				break;
				default:
					groupData[key] = values[key];
			}
		}

		// use object destructuring and the spread operator to omit some props from userData
		const {Email, Password, ConfirmPassword, RoleNames, ...UserProfile} = userData;

		// make registration call
		apiRequest({
			path: '/userAccounts/register',
			data:  JSON.stringify({
				UserName: Email,
				Email: Email,
				Password: Password,
				ConfirmPassword: ConfirmPassword,
				UserProfile: JSON.stringify(UserProfile),
				RoleNames: RoleNames,
			})
		}).then((response) => {

			if( response.error ) {

				// set error
				setRegisterError(response.error);
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
				// scroll to top
				window.scrollTo(0, 0);

			}
			else {

				const UserId = response.UserId;

				// add user to MailChimp list
				if( UserProfile.getNewsletter === true ) {

					mailchimpApiRequest({
						method: 'setListMember',
						email: Email,
						FNAME: UserProfile.firstName,
						LNAME: UserProfile.lastName,
					});

				}

				// send new user notification
				transactionalEmailRequest({
					template: 'cta-registration-confirmation',
					email: Email,
					username: Email,
					callback: 'iasg_zoho',
					UserId: UserId,
					firstName: UserProfile.firstName,
					lastName: UserProfile.lastName,
					phone: UserProfile.phone,
					company: groupData.groupName,
					Contact_Type: 'CTA',
				}).then((response) => {

					// authenticate new user, create group, and redirect…
					authenticate( Email, Password )

						.then((response) => {

							if( response && response.success ) {

								// postGroup
								postGroup(groupData, response.access_token).then((groupResponse) => {

									let groupId = groupResponse.groupId;
									let groupUri = groupResponse.groupUri;

									// authenticate new user and redirect…
									if( 'function' === typeof User.login ) {

										const max_age = (response.expires_in * 1000);

										User.login({
											expires: false,
											expires_in: ( new Date() ).getTime() + max_age,
											access_token: response.access_token,
											refresh_token: response.refresh_token,
											email: Email,
											UserId: UserId,
											Groups: groupId,
											Roles: userData.RoleNames.join(','),
											Admin: false,
										});

										const middle = Math.floor(response.access_token.length / 2);
										const token1 = response.access_token.substr(0, middle);
										const token2 = response.access_token.substr(middle);

										document.cookie = `iasg_token1=${token1};path=/;max-age=${max_age}`;
										document.cookie = `iasg_token2=${token2};path=/;max-age=${max_age}`;
										document.cookie = `iasg_access=true;path=/;max-age=${max_age}`;

										window.location = `/groups/${groupUri}?reg=cta`;

									}

									// callback
									if( 'function' === typeof callback ) {
										callback(groupResponse);
									}

								});

							}
							else {

								setRegisterError(response.error_description);

							}

						}

					).catch((error) => {

						setRegisterError(error.toString());

					});

				});

			}

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={handleRegisterSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onChange={() => {
					syncUserAndFirstContact(values);
				}}
				onSubmit={handleSubmit}>

				<div className='alert alert-danger' hidden={!registerError}>
					{registerError}
				</div>

				<Form.Group>
					<Form.Label htmlFor={`userContactTypeId`}>
						{`I am a…`}
					</Form.Label>
					<Form.Control
						as='select'
						custom
						name={`userContactTypeId`}
						id={`userContactTypeId`}
						value={userContactTypeId}
						onChange={(e) => {
							setUserContactTypeId(e.currentTarget.value);
						}}
					>
						<option value={''}>{`—`}</option>
					{GROUP_CONTACT_TYPES.map((contactType) =>
						<option
							key={contactType.contactTypeId}
							disabled={false}
							value={contactType.contactTypeId}>{contactType.contactTitle}</option>
					)}
					</Form.Control>
				</Form.Group>

			{!!userContactTypeId && <>
				<div className='form-row'>

					<div className='col'>

						<Form.Group controlId='firstName'>
							<Form.Label>{text.firstName}*</Form.Label>
							<Form.Control
								type='text'
								value={values.firstName}
								onChange={handleChange}
								isValid={touched.firstName && !errors.firstName}
								isInvalid={touched.firstName && errors.firstName}
							/>
						</Form.Group>
					</div>

					<div className='col'>

						<Form.Group controlId='lastName'>
							<Form.Label>{text.lastName}*</Form.Label>
							<Form.Control
								type='text'
								value={values.lastName}
								onChange={handleChange}
								isValid={touched.lastName && !errors.lastName}
								isInvalid={touched.lastName && errors.lastName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col'>

						<Form.Group controlId='Email'>
							<Form.Label>{text.Email}*</Form.Label>
							<Form.Control
								type='email'
								value={values.Email}
								onChange={handleChange}
								isValid={touched.Email && !errors.Email}
								isInvalid={touched.Email && errors.Email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Email}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col'>

						<Form.Group controlId='phone'>
							<Form.Label>{text.phone}*</Form.Label>
							<Form.Control
								type='tel'
								placeholder='312-555-1234'
								value={values.phone}
								onChange={handleChange}
								isValid={touched.phone && !errors.phone}
								isInvalid={touched.phone && errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phone}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address1'>{text.address1}*</Form.Label>
							<Form.Control
								type='text'
								name='address1'
								id='address1'
								value={values.address1}
								onChange={handleChange}
								isValid={touched.address1 && !errors.address1}
								isInvalid={touched.address1 && errors.address1}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address2'>{text.address2}</Form.Label>
							<Form.Control
								type='text'
								name='address2'
								id='address2'
								value={values.address2}
								onChange={handleChange}
								isValid={touched.address2 && !errors.address2}
								isInvalid={touched.address2 && errors.address2}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='cityTown'>{text.cityTown}*</Form.Label>
							<Form.Control
								type='text'
								name='cityTown'
								id='cityTown'
								value={values.cityTown}
								onChange={handleChange}
								isValid={touched.cityTown && !errors.cityTown}
								isInvalid={touched.cityTown && errors.cityTown}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='country'>{text.country}*</Form.Label>
							<CountryDropdown
								name='country'
								id='country'
								classes='custom-select'
								value={values.country||''}
								valueType='short'
								onChange={(val) => setFieldValue('country', val)}
								/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='stateProvince'>{text.stateProvince}*</Form.Label>
							<RegionDropdown
								name='stateProvince'
								id='stateProvince'
								classes='custom-select'
								value={values.stateProvince||''}
								valueType='short'
								country={values.country||''}
								countryValueType='short'
								onChange={(val) => setFieldValue('stateProvince', val)}
								/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='postalCode'>{text.postalCode}*</Form.Label>
							<Form.Control
								type='text'
								name='postalCode'
								id='postalCode'
								value={values.postalCode}
								onChange={handleChange}
								isValid={touched.postalCode && !errors.postalCode}
								isInvalid={touched.postalCode && errors.postalCode}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row mb-3'>

					<div className='col'>

						<Form.Group controlId='Password'>
							<Form.Label>{text.Password}*</Form.Label>
							<Form.Control
								type='password'
								value={values.Password}
								onChange={handleChange}
								isValid={touched.Password && !errors.Password}
								isInvalid={touched.Password && errors.Password}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Password}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col'>

						<Form.Group controlId='ConfirmPassword'>
							<Form.Label>{text.ConfirmPassword}*</Form.Label>
							<Form.Control
								type='password'
								value={values.ConfirmPassword}
								onChange={handleChange}
								isValid={touched.ConfirmPassword && !errors.ConfirmPassword}
								isInvalid={touched.ConfirmPassword && errors.ConfirmPassword}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.ConfirmPassword}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

			{!profileComplete &&
				<Form.Group>
					<button
						type='submit'
						className='btn btn-outline-primary btn-block'
						disabled={ ( !values.firstName || !values.lastName || !values.Email || !values.phone || !values.address1 || !values.cityTown || !values.stateProvince || !values.postalCode || !values.country || !values.Password || !values.ConfirmPassword ) }
						onClick={() => {
							setProfileComplete( ( errors.firstName || errors.lastName || errors.Email || errors.phone || errors.address1 || errors.cityTown || errors.stateProvince || errors.postalCode || errors.country || errors.Password || errors.ConfirmPassword ) ? false : true );
						}}>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{`Continue`}</span>
					</button>
				</Form.Group>
			}
			{profileComplete && <>
				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3'
					aria-controls="group-information"
					aria-expanded={groupInformationOpen}
					onClick={() => setGroupInformationOpen(!groupInformationOpen)}
				>
					<span className='h5'>{text.groupInformation}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupInformationOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupInformationOpen}>

					<div className='p-3' id='group-information'>

						<Form.Group>
							<Form.Label htmlFor='groupName'>{text.groupName}*</Form.Label>
							<Form.Control
								type='text'
								name='groupName'
								id='groupName'
								autoComplete='off'
								value={values.groupName}
								onChange={handleChange}
								isValid={touched.groupName && !errors.groupName}
								isInvalid={touched.groupName && errors.groupName}
							/>
						</Form.Group>

						<Form.Group controlId='webUrl'>
							<Form.Label>{text.website}</Form.Label>
							<Form.Control
								type='url'
								placeholder={'https://www.example.com/'}
								value={values.webUrl}
								onChange={handleChange}
								isValid={touched.webUrl && !errors.webUrl}
								isInvalid={touched.webUrl && errors.webUrl}
							/>
						</Form.Group>

						<Form.Group controlId='descriptionLong'>
							<Form.Label>{text.groupDescription}*</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								value={values.descriptionLong||''}
								onChange={handleChange}
								isValid={touched.descriptionLong && !errors.descriptionLong}
								isInvalid={touched.descriptionLong && errors.descriptionLong}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Label>{text.groupType}</Form.Label>
						{GROUP_TYPES.map((groupType) =>
							<Form.Check 
								key={groupType.grouptypeId}
								custom
								type='checkbox'
								name='groupsTypes'
								id={`groupsTypes-${groupType.grouptypeId}`}
								value={groupType.grouptypeId}
								checked={values.groupsTypes.indexOf(groupType.grouptypeId)>-1}
								onChange={(e) => {
									const isChecked = values.groupsTypes.indexOf(groupType.grouptypeId) > -1;
									setFieldValue('groupsTypes', isChecked ? values.groupsTypes.filter((e) => {return e !== groupType.grouptypeId;}) : [...values.groupsTypes,groupType.grouptypeId] );
								}}
								label={<>
									{groupType.title}
								</>}
							/>
						)}
						</Form.Group>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-contacts"
					aria-expanded={groupContactsOpen}
					onClick={() => setGroupContactsOpen(!groupContactsOpen)}
				>
					<span className='h5'>{text.groupContacts}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupContactsOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupContactsOpen}>

					<div className='pb-3' id='group-contacts'>

						<FieldArray
							name='contacts'
							render={arrayHelpers => {
								const contacts = values.contacts;
								const isValid = (index, key) => {
									return touched.contacts && touched.contacts[index] && touched.contacts[index][key] && errors.contacts && errors.contacts[index] && !errors.contacts[index][key];
								};
								const isInvalid = (index, key) => {
									return touched.contacts && touched.contacts[index] && touched.contacts[index][key] && errors.contacts && errors.contacts[index] && errors.contacts[index][key];
								};
								return (
									<div className='mb-4'>
									{contacts && contacts.length > 0 ? contacts.map((contact, index) => (
										<div className={'p-3' + ( index % 2 === 0 ? ' bg-light-blue' : '' )} key={index}>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-contactTypeId`}>
															{text.groupContactType}
															<span>*</span>
														</Form.Label>
														<Form.Control
															as='select'
															custom
															name={`contacts.${index}.contactTypeId`}
															id={`contacts-${index}-contactTypeId`}
															disabled={!index}
															value={values.contacts[index].contactTypeId}
															onChange={handleChange}
															isValid={isValid(index,'contactTypeId')}
															isInvalid={isInvalid(index,'contactTypeId')}
														>
															<option value={''}>{`—`}</option>
														{GROUP_CONTACT_TYPES.map((contactType) =>
															<option
																key={contactType.contactTypeId}
																disabled={false}
																value={contactType.contactTypeId}>{contactType.contactTitle}</option>
														)}
														</Form.Control>
													</Form.Group>

												</div>

												<div className='col-auto ml-auto'>
													<button
														className='btn btn-link py-0 mr-n2 text-danger'
														disabled={!index}
														type='button'
														onClick={() => arrayHelpers.remove(index)}
														>
														<i className='fa-solid fa-times-circle'></i>
													</button>
												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-firstName`}>
															{text.firstName}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.firstName`}
															id={`contacts-${index}-firstName`}
															readOnly={!index}
															value={values.contacts[index].firstName||''}
															onChange={handleChange}
															isValid={isValid(index,'firstName')}
															isInvalid={isInvalid(index,'firstName')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-lastName`}>
															{text.lastName}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.lastName`}
															id={`contacts-${index}-lastName`}
															readOnly={!index}
															value={values.contacts[index].lastName||''}
															onChange={handleChange}
															isValid={isValid(index,'lastName')}
															isInvalid={isInvalid(index,'lastName')}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-emailAddress`}>
															{text.Email}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='email'
															name={`contacts.${index}.emailAddress`}
															id={`contacts-${index}-emailAddress`}
															readOnly={!index}
															value={values.contacts[index].emailAddress||''}
															onChange={handleChange}
															isValid={isValid(index,'emailAddress')}
															isInvalid={isInvalid(index,'emailAddress')}
														/>
														<Form.Control.Feedback type='invalid'>
															{text.invalidEmail}
														</Form.Control.Feedback>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-phoneDirect`}>
															{text.Phone}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='tel'
															name={`contacts.${index}.phoneDirect`}
															id={`contacts-${index}-phoneDirect`}
															readOnly={!index}
															value={values.contacts[index].phoneDirect||''}
															onChange={handleChange}
															isValid={isValid(index,'phoneDirect')}
															isInvalid={isInvalid(index,'phoneDirect')}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-address1`}>
															{text.address1}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.address1`}
															id={`contacts-${index}-address1`}
															readOnly={!index}
															value={values.contacts[index].address1||''}
															onChange={handleChange}
															isValid={isValid(index,'address1')}
															isInvalid={isInvalid(index,'address1')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-address2`}>
															{text.address2}
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.address2`}
															id={`contacts-${index}-address2`}
															readOnly={!index}
															value={values.contacts[index].address2||''}
															onChange={handleChange}
														/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-city`}>
															{text.cityTown}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.city`}
															id={`contacts-${index}-city`}
															readOnly={!index}
															value={values.contacts[index].city||''}
															onChange={handleChange}
															isValid={isValid(index,'city')}
															isInvalid={isInvalid(index,'city')}
														/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-country`}>
															{text.country}
															<span>*</span>
														</Form.Label>
														<CountryDropdown
															classes='custom-select'
															name={`contacts.${index}.country`}
															id={`contacts-${index}-country`}
															disabled={!index}
															value={values.contacts[index].country}
															valueType='short'
															onChange={(val) => setFieldValue(`contacts[${index}].country`, val)}
															/>
													</Form.Group>

												</div>

											</div>

											<div className='form-row'>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-state`}>
															{text.stateProvince}
															<span>*</span>
														</Form.Label>
														<RegionDropdown
															classes='custom-select'
															name={`contacts.${index}.state`}
															id={`contacts-${index}-state`}
															disabled={!index}
															value={values.contacts[index].state}
															valueType='short'
															country={values.contacts[index].country}
															countryValueType='short'
															onChange={(val) => setFieldValue(`contacts[${index}].state`, val)}
															/>
													</Form.Group>

												</div>

												<div className='col-12 col-sm-6'>

													<Form.Group>
														<Form.Label htmlFor={`contacts-${index}-zip`}>
															{text.postalCode}
															<span>*</span>
														</Form.Label>
														<Form.Control
															type='text'
															name={`contacts.${index}.zip`}
															id={`contacts-${index}-zip`}
															readOnly={!index}
															value={values.contacts[index].zip||''}
															onChange={handleChange}
															isValid={isValid(index,'zip')}
															isInvalid={isInvalid(index,'zip')}
														/>
													</Form.Group>

												</div>

											</div>

										</div>
									)) : null}
										<div className='mt-3 ml-3'>
											<button
												className='btn btn-sm btn-outline-primary'
												hidden={contacts.length===3}
												disabled={contacts.length===3}
												type='button'
												onClick={() => {
													arrayHelpers.push({
														contactTypeId: '',
														firstName: '',
														lastName: '',
														emailAddress: '',
														phoneDirect: '',
														address1: '',
														address2: '',
														city: '',
														zip: '',
														country: 'US',
														state: '',
													})
												}}
												>
												{text.addAnother}
											</button>
										</div>
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-memberships"
					aria-expanded={groupMembershipsOpen}
					onClick={() => setGroupMembershipsOpen(!groupMembershipsOpen)}
				>
					<span className='h5'>{text.groupMemberships}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupMembershipsOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupMembershipsOpen}>

					<div className='pb-3' id='group-memberships'>

						<FieldArray
							name='groupsMemberships'
							render={arrayHelpers => {
								const groupsMemberships = values.groupsMemberships;
								const isValid = (index, key) => {
									return touched.groupsMemberships && touched.groupsMemberships[index] && touched.groupsMemberships[index][key] && errors.groupsMemberships && errors.groupsMemberships[index] && !errors.groupsMemberships[index][key];
								};
								const isInvalid = (index, key) => {
									return touched.groupsMemberships && touched.groupsMemberships[index] && touched.groupsMemberships[index][key] && errors.groupsMemberships && errors.groupsMemberships[index] && errors.groupsMemberships[index][key];
								};
								const membershipTitle = (membershipTypeId) => {
									return (GROUP_MEMBERSHIPS.find(obj => {
										return obj.membershipTypeId === membershipTypeId
									})).title;
								};
								return (
									<div>
									{groupsMemberships && groupsMemberships.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<tbody>
												{groupsMemberships.map((membership, index) =>
													<tr key={index}>
														<th scope='row' style={{width:'10rem'}}>
															<Form.Control
																hidden={'11a1138e-2635-4f4e-aea9-413f590ad1b9'===values.groupsMemberships[index].membershipTypeId}
																as='select'
																custom
																name={`groupsMemberships.${index}.membershipTypeId`}
																id={`groupsMemberships-${index}-membershipTypeId`}
																value={values.groupsMemberships[index].membershipTypeId}
																onChange={(e) => {
																	setFieldValue(`groupsMemberships[${index}].membershipTypeId`, e.currentTarget.value);
																	setFieldValue(`groupsMemberships[${index}].title`, membershipTitle(e.currentTarget.value));
																}}
																isValid={isValid(index,'membershipTypeId')}
																isInvalid={isInvalid(index,'membershipTypeId')}
															>
															{GROUP_MEMBERSHIPS.map((membershipType) =>
																<option
																	key={membershipType.membershipTypeId}
																	disabled={false}
																	value={membershipType.membershipTypeId}>{membershipType.title}</option>
															)}
															</Form.Control>
															<Form.Control
																hidden={'11a1138e-2635-4f4e-aea9-413f590ad1b9'!==values.groupsMemberships[index].membershipTypeId}
																disabled={'11a1138e-2635-4f4e-aea9-413f590ad1b9'!==values.groupsMemberships[index].membershipTypeId}
																placeholder={`Other`}
																type='text'
																name={`groupsMemberships.${index}.title`}
																id={`groupsMemberships-${index}-title`}
																value={values.groupsMemberships[index].title}
																onChange={handleChange}
																isValid={isValid(index,'title')}
																isInvalid={isInvalid(index,'title')}
															/>
														</th>
														<td>
															<Form.Control
																type='text'
																name={`groupsMemberships.${index}.info`}
																id={`groupsMemberships-${index}-info`}
																value={values.groupsMemberships[index].info}
																onChange={handleChange}
																isValid={isValid(index,'info')}
																isInvalid={isInvalid(index,'info')}
															/>
														</td>
														<td style={{width:'1%'}}>
															<button
																className='btn btn-link py-0 mr-n2 text-danger'
																type='button'
																onClick={() => arrayHelpers.remove(index)}
																>
																<i className='fa-solid fa-times-circle'></i>
															</button>
														</td>
													</tr>
												)}
												</tbody>
											</table>
										</div>
									) : null}
										<div className='mt-3 ml-3'>
											<button
												className='btn btn-sm btn-outline-primary'
												hidden={groupsMemberships.length===3}
												disabled={groupsMemberships.length===3}
												type='button'
												onClick={() => {
													arrayHelpers.push({
														membershipTypeId: GROUP_MEMBERSHIPS[0].membershipTypeId,
														membershipUrl: null,
														title: GROUP_MEMBERSHIPS[0].title,
														info: '',
													})
												}}
												>
												{text.addAnother}
											</button>
										</div>
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<div className='form-row mt-3'>

					<div className='col'>

						<Form.Group>

							<Form.Check 
								custom
								type='checkbox'
								name='getNewsletter'
								id='getNewsletter'
								value={values.getNewsletter}
								checked={values.getNewsletter}
								onChange={(e) => setFieldValue('getNewsletter', !values.getNewsletter)}
								label={<>
									{text.signup}
								</>}
							/>

							<Form.Check 
								custom
								type='checkbox'
								name='AcceptedAgreement'
								id='AcceptedAgreement'
								value={false}
								checked={values.AcceptedAgreement}
								onChange={(e) => setFieldValue('AcceptedAgreement', !values.AcceptedAgreement)}
								label={<>
									{`I certify that all information I have furnished to ${text.siteTitle} is accurate, to the best of my knowledge.`}
								</>}
							/>

							<small className='pl-4 text-danger' hidden={!errors.AcceptedAgreement}>{`You must certify that all information is accurate`}</small>

						</Form.Group>

					</div>

				</div>

				<p className='text-muted my-3'>{text.signupDisclaimer}</p>

				{ (errors.firstName || errors.lastName || errors.Email || errors.Phone || errors.address1 || errors.cityTown || errors.stateProvince || errors.postalCode || errors.country || errors.Password || errors.ConfirmPassword || errors.groupName || errors.descriptionLong || errors.groupsMemberships) &&
					<div className='alert alert-danger'>
					{(errors.firstName || errors.lastName || errors.Email || errors.Phone || errors.address1 || errors.cityTown || errors.stateProvince || errors.postalCode || errors.country || errors.Password || errors.ConfirmPassword) &&
						<div>{`Please complete your profile above.`}</div>
					}
					{(errors.groupName || errors.descriptionLong) &&
						<div>{`Please complete your company information above.`}</div>
					}
					{errors.groupsMemberships &&
						<div>{`There is an error with your registrations. Please see above.`}</div>
					}
					</div>
				}

				<Form.Group>
					<button
						type='submit'
						disabled={isSubmitting&&!registerError}
						className='btn btn-primary btn-block'>
					{isSubmitting && !registerError &&
						<span>{text.working}</span>
					}
					{!isSubmitting && <>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.createAccount}</span>
					</>}
					</button>
				</Form.Group>
			</>}

				<p className='text-muted'>{text.requiredFields}</p>
			</>}
			</Form>
		)}
		</Formik>
	)

};

export default CTAForm;
