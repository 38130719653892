export const labels = {
	'en': {
		pageTitle: 'Account Login',
		noAccount: 'No account found associated with this email.',
		invalidPw: 'The password you entered is invalid.',
		noAccountPrompt: 'Don’t have an account?',
		noAccountSignUp: 'Sign up',
	},
	'zh-hans': {
		pageTitle: '帐号登录',
		noAccount: '找不到与此电子邮件关联的帐户。',
		invalidPw: '您输入的密码无效。',
		noAccountPrompt: '没有帐户？',
		noAccountSignUp: '注册',
	}
};
