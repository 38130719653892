/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
	OverlayTrigger,
	Popover,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


/*
 * react-country-region-selector
 *
 * @link https://github.com/country-regions/react-country-region-selector
 *
 */
import {
	CountryDropdown,
	RegionDropdown,
} from 'react-country-region-selector';


// UserForm
function UserForm({
	text,
	initialValues,
	onSubmit,
}) {

	// form schema
	const schema = yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		Email: yup.string().email().required(text.invalidEmail),
		phone: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).nullable().required(text.invalidPhone),
		address1: yup.string(),
		address2: yup.string(),
		cityTown: yup.string(),
		country: yup.string().required(),
		stateProvince: yup.string(),
		postalCode: yup.string(),
		website: yup.string().nullable(),
		getNewsletter: yup.boolean(),
		getAlertNewProgram: yup.boolean(),
		isAccredited: yup.boolean(),
	});

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='firstName'>{text.firstName}*</Form.Label>
							<Form.Control
								type='text'
								name='firstName'
								id='firstName'
								value={values.firstName}
								onChange={handleChange}
								isValid={touched.firstName && !errors.firstName}
								isInvalid={touched.firstName && errors.firstName}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='lastName'>{text.lastName}*</Form.Label>
							<Form.Control
								type='text'
								name='lastName'
								id='lastName'
								value={values.lastName}
								onChange={handleChange}
								isValid={touched.lastName && !errors.lastName}
								isInvalid={touched.lastName && errors.lastName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='Email'>{text.Email}*</Form.Label>
							<Form.Control
								type='email'
								name='Email'
								id='Email'
								value={values.Email}
								onChange={handleChange}
								isValid={touched.Email && !errors.Email}
								isInvalid={touched.Email && errors.Email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Email}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='phone'>{text.phone}*</Form.Label>
							<Form.Control
								type='tel'
								name='phone'
								id='phone'
								value={values.phone}
								onChange={handleChange}
								isValid={touched.phone && !errors.phone}
								isInvalid={touched.phone && errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phone}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address1'>{text.address1}</Form.Label>
							<Form.Control
								type='text'
								name='address1'
								id='address1'
								value={values.address1}
								onChange={handleChange}
								isValid={touched.address1 && !errors.address1}
								isInvalid={touched.address1 && errors.address1}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='address2'>{text.address2}</Form.Label>
							<Form.Control
								type='text'
								name='address2'
								id='address2'
								value={values.address2}
								onChange={handleChange}
								isValid={touched.address2 && !errors.address2}
								isInvalid={touched.address2 && errors.address2}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='cityTown'>{text.cityTown}</Form.Label>
							<Form.Control
								type='text'
								name='cityTown'
								id='cityTown'
								value={values.cityTown}
								onChange={handleChange}
								isValid={touched.cityTown && !errors.cityTown}
								isInvalid={touched.cityTown && errors.cityTown}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='country'>{text.country}</Form.Label>
							<CountryDropdown
								name='country'
								id='country'
								classes='custom-select'
								value={values.country||''}
								valueType='short'
								onChange={(val) => setFieldValue('country', val)}
								/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='stateProvince'>{text.stateProvince}</Form.Label>
							<RegionDropdown
								name='stateProvince'
								id='stateProvince'
								classes='custom-select'
								value={values.stateProvince||''}
								valueType='short'
								country={values.country||''}
								countryValueType='short'
								onChange={(val) => setFieldValue('stateProvince', val)}
								/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='postalCode'>{text.postalCode}</Form.Label>
							<Form.Control
								type='text'
								name='postalCode'
								id='postalCode'
								value={values.postalCode}
								onChange={handleChange}
								isValid={touched.postalCode && !errors.postalCode}
								isInvalid={touched.postalCode && errors.postalCode}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='website'>{text.website}</Form.Label>
							<Form.Control
								type='url'
								name='website'
								id='website'
								value={values.website}
								onChange={handleChange}
								isValid={touched.website && !errors.website}
								isInvalid={touched.website && errors.website}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<div className='form-group'>

							<Form.Check 
								custom
								type='checkbox'
								name='getNewsletter'
								id='getNewsletter'
								value={values.getNewsletter}
								checked={values.getNewsletter}
								onChange={(e) => setFieldValue('getNewsletter', !values.getNewsletter)}
								label={<>
									{text.getNewsletter}
								</>}
							/>

							<Form.Check 
								custom
								type='checkbox'
								name='getAlertNewProgram'
								id='getAlertNewProgram'
								value={values.getAlertNewProgram}
								checked={values.getAlertNewProgram}
								onChange={(e) => setFieldValue('getAlertNewProgram', !values.getAlertNewProgram)}
								label={<>
									{text.getAlertNewProgram}
								</>}
							/>

							<Form.Check 
								custom
								type='checkbox'
								name='isAccredited'
								id='isAccredited'
								value={values.isAccredited}
								checked={values.isAccredited}
								onChange={(e) => setFieldValue('isAccredited', !values.isAccredited)}
								label={<>
									{text.isAccredited}
									<OverlayTrigger
										placement={'right'}
										overlay={
											<Popover>
												<Popover.Content>
													{text.isAccreditedTooltip}
												</Popover.Content>
											</Popover>
										}>
										<i className='fa-solid fa-question-circle text-muted ml-1' style={{fontSize:'75%'}}></i>
									</OverlayTrigger>
								</>}
							/>

						</div>

					</div>

				</div>

				<Form.Group>
					<button type='submit' className='btn btn-primary btn-block'>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default UserForm;
