export const labels = {
	'en': {
		pageTitle: 'Programs Returns',
		pageSubTitle: 'Managed Futures',
		seoTitle: 'Managed Futures Programs Returns',
	},
	'zh-hans': {
		pageTitle: '程序返回',
		pageSubTitle: '管理期货',
		seoTitle: '管理期货计划回报',
	}
};
