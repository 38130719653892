export const labels = {
	'en': {
		snapshot: 'Snapshot',
		strategy: 'Strategy',
		composition: 'Composition',
		charts: 'Charts',
		statsAndRatios: 'Statistics & Ratios',
		performanceTables: 'Performance Tables',
		badges: 'Badges',
		topPerformerBadges: 'Top Performer Badges',
		vamiHeading: 'Growth of $1,000 — VAMI',
		vamiTooltip: <>
			<b>{'Value-Added Monthly Index (VAMI)'}</b>
			<br/>
			<span>{'VAMI is defined as the growth in value of an average $1000 investment, calculated by multiplying (1 + current monthly ROR) × (previous monthly VAMI). VAMI assumes the reinvestment of all profits and interest income. Incentive and Management Fees have been deducted.'}</span>
		</>,
		monthlyPerformance: 'Monthly Performance',
		periodReturns: 'Period Returns',
		accountAndFees: 'Account & Fees',
		subscriptions: 'Subscriptions',
		trading: 'Trading',
		holdingPeriods: 'Holding Periods',
		decisionMaking: 'Decision-Making',
		cumulativeReturns: 'Cumulative returns',
		rolling: 'Rolling',
		aumCumulativeReturns: 'AUM & Cumulative Returns',
		distribution: 'Distribution',
		consecutiveGains: 'Consecutive Gains',
		consecutiveLosses: 'Consecutive Losses',
		timeWindowAnalysis: 'Time Window Analysis',
	},
	'zh-hans': {
		snapshot: '快照',
		strategy: '战略',
		composition: '组成',
		charts: '图表',
		statsAndRatios: '统计和比率',
		performanceTables: '性能表',
		badges: '徽章',
		topPerformerBadges: '最佳表演者徽章',
		vamiHeading: '1,000 美元的增长 — VAMI',
		vamiTooltip: <>
			<b>{'每月增加值指数 (VAMI)'}</b>
			<br/>
			<span>{'VAMI 被定义为平均 1000 美元投资的价值增长，通过乘以（1 + 当前每月 ROR）×（前一个月 VAMI）计算。 VAMI 承担所有利润和利息收入的再投资。 已扣除奖励和管理费。'}</span>
		</>,
		monthlyPerformance: '月度表现',
		periodReturns: '期间回报',
		accountAndFees: '账户和费用',
		subscriptions: '订阅',
		trading: '贸易',
		holdingPeriods: '持有期',
		decisionMaking: '做决定',
		cumulativeReturns: '累积回报',
		rolling: '轰响',
		aumCumulativeReturns: '管理资产和累计回报',
		distribution: '分发',
		consecutiveGains: '持续收入',
		consecutiveLosses: '连续亏损',
		timeWindowAnalysis: '时间窗分析',
	}
};
