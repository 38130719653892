import {
	MoneyFormat,
} from './Helpers';


// Constants
import {
	CHART_COLORS,
} from '../helpers/Constants';

/**
 * TO-DO: DOCUMENT!
 *
 */
export const chartDefaults = {
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	title: {
		text: null
	},
	colors: CHART_COLORS.default,
	legend: {
		align: 'left',
		backgroundColor: 'rgba(255,255,255,.75)',
		enabled: false,
		borderColor: 'rgba(0,0,0,.25)',
		borderWidth: 1,
		itemMarginTop: 2,
		itemMarginBottom: 2,
		itemStyle: {
			fontFamily: 'proxima-nova, sans-serif',
			fontSize: '.75rem',
			fontWeight: 'normal',
			lineHeight: '1rem',
		},
		padding: 8,
		layout: 'vertical',
		verticalAlign: 'top',
	},
	plotOptions: {
		series: {
			events: {
				legendItemClick: function () {
					if( !this.index )
						return false;
				}
			}
		}
	},
};


/**
 * TO-DO: DOCUMENT!
 *
 */
export const addWaterMark = (chart) => {
	chart.container.querySelectorAll('svg image').forEach((image) => {image.remove();});
	chart.renderer.image('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAABUBAMAAABen3zpAAAAHlBMVEUARs8AAAAAqPIARs8ARs8ARs8AeOEAqPIAqPIAqPKC+xxiAAAACnRSTlMaABoTDQcEEwkOUadxBwAABCtJREFUeNrV2ztv2zAQAOALrGQ2IUW2x2rIY5OQxLOEOp0VtNkjt4CzVkPbNR6artEQpP+2aBWHD5OspCMP0K2OaX/mUXeiGFjxYJ0j/vX75af55dtVryh0Yxx/6jcI8Jh0Zjwmf+P0pdK+/B56xoGG0XuQAZC4SV7j/EHzFVLAQ74AeIZwRzsplcaBh3wGCsg2EeJcVaaAh0RAAZknUjzJr+aAh8QpCYQnlmZKQnAAyYECskmUkNZ76gASAQmkViHv5AnBQ3ISyCJR40SaEDwkAhJIswc5k74DHlKSQOYcoFntpQsIkEDWVgg4gIQ0kHofcipmFh5SkkAWiW1G7lxAwC+EdyeWq1bqABKRQGKZoBZEcAAJSSAba2ZFLiB3JJDGmlmhC0hOAZknmqjEHxMPAQrI2ppZLHcAiT1C7Ev9q1vIMQVkps0sbce46heF7oIRrDzsohiLyBnjIQ40NEJxmvqFCBmQWSOErLWZNUJIrc2s8UEW2swaIWSrzazxQeJEm1njg8yMmRW+1ZFRQBpjZuX7ELjoFNdVZ0j0/9G6QebGzIoB0aJ86AxBtij2IvLUfjTmbjughtTmzHqDTAEh8QuxF5GT174bd2O1JIVsLZkFyBa88A+xF5HT3QpH3t1NCCEbQ2a1s4C9s8roII1xG2gqQqaIKSGBzK2ZBej71IwKsrZmFqAfjwREkDixZhYwbG5BRQOZmTMr5RBUbtFAtvbMAvzjv8A3RM2s50aTWeDg+R8JZC2m0329l1nA8FNSUEBqKZ3iR55ZEXAIbpUcEEAWSrfbTsqDsGvt4DjMIQFkK99HtZNyLpYNB+cvJv4hse74zP2zODhzIPEPmZmPZt3xcoY4QMfH8Atp5MqhO0Bz4+AY341vyFzuSbRj3+gy8rZDlML11wixj5d2hayNR+VYySEDYyo2KcP2tbpC4lrejIuv+GswJohaREKY7L52NCqIXETa971OSjkmiFpEovbP/q1MGBNkw4uIVDkuGQtHBVGLCP/LohwTRC0iISiBh6h1JBoMmUgQexHJDRBUD69CBoUdEitPdI7BH6RCH+EIzJAZh/BM8ATBH6o5EiH2pZ76g0wcnMIzQtSlHoE/yCH+bGYmQqxLvfQIOUDv8UFlhPB+8Rtfhp4gBXpDacJkiKVf9AnBH5c9lCDWftEjJMDvjGUSxNoveoRkDL0RXpkgM7Vf9Amp0Ft8AZMgtiLiERLgt5MyGWLrFz1CMvy/aTEZYukXfUH4x2KK+5ECMW86gA8InxDslFQKxFhEpj4g/FOxU7JkCsRcRDxCij1I1PunUCEDigj68fSSA4Z2joURom46lP4gAdNF3m+NGSG1uungAcIdSMmSGSFztV/0BrlipvjYdX28LTHh/OHlLrO+7+JHO+iFNfhF+6JfrCpmjnjVYYTLa/6GPyQrCrbk5mXCAAAAAElFTkSuQmCC', (chart.chartWidth / 2) - 75, (chart.chartHeight / 2) - 40, 150, 32).add();
};


/**
 * TO-DO: DOCUMENT!
 *
 */
export const getColumnChartOptions = ({data,name,width=1200,height=null}) => {

	const chartData = [];

	for( const [key, value] of Object.entries(data) ) {
		if( 'programId' !== key )
			chartData.push(value);
	}

	return {
		...chartDefaults,
		chart: {
			type: 'column',
			width: width,
			height: height,
		},
		tooltip: {
			enabled: false,
		},
		xAxis: {
			categories: [
				'< -20',
				'-20 to -18',
				'-18 to -16',
				'-16 to -14',
				'-14 to -12',
				'-12 to -10',
				'-10 to -8',
				'-8 to -6',
				'-6 to -4',
				'-2 to -4',
				' 0 to -2',
				'0 to 2',
				'2 to 4',
				'4 to 6',
				'8 to 6',
				'10 to 8',
				'12 to 10',
				'14 to 12',
				'16 to 14',
				'18 to 16',
				'20 to 18',
				'> 20',
			],
			labels: {
				rotation: -90
			}
		},
		yAxis: {
			title: {
				text: null
			}
		},
		series: [
			{
				name: name,
				color: CHART_COLORS.default[0],
				type: 'column',
				marker: {
					enabled: false
				},
				data: chartData
			}
		]
	};

};


/**
 * TO-DO: DOCUMENT!
 *
 */
export const getPieChartOptions = ({data,name}) => {

	// sort by value DESC
	data.sort((a,b) => {return b.value < a.value ? -1 : ( b.value > a.value ? 1 : (0) )});

	const chartData = data.map(function(i) {
		return {
			name: i.title,
			y: i.value
		}
	});

	return {
		...chartDefaults,
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			width: 200,
			height: 200,
			margin: [0,0,0,0],
			className: 'mx-auto'
		},
		tooltip: {
			enabled: true,
			pointFormat: '<b>{point.percentage:.1f}%</b>',
			formatter: function() {
				return '<b>' + this.point.name + ':</b> ' + this.percentage.toFixed(2) + '%'
			}
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %'
				}
			}
		},
		series: [{
			name: name,
			colorByPoint: true,
			data: chartData
		}]
	};

};


/**
 * TO-DO: DOCUMENT!
 *
 */
export const getAllocationsPieChartOptions = ({data,name}) => {

	const chartData = data.map(function(i) {
		return {
			name: i.programName,
			y: i.pctAllocated
		}
	});

	const chartOptions = {
		...chartDefaults,
		colors: [...CHART_COLORS.default],
	};

	return {
		...chartOptions,
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			width: 200,
			height: 200,
			margin: [0,0,0,0],
			className: 'mx-auto'
		},
		tooltip: {
			enabled: true,
			pointFormat: '<b>{point.percentage:.1f}%</b>',
			formatter: function() {
				return '<b>' + this.point.name + ':</b> ' + this.percentage.toFixed(2) + '%'
			}
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %'
				}
			}
		},
		series: [{
			name: name,
			colorByPoint: true,
			data: chartData
		}]
	};

};


/**
 * parsePerformanceChartData
 *
 */
export const parsePerformanceChartData = ({data,metric}) => {

	let chartData = data.map((point) => {
		return [(new Date(point.dateReturn)).getTime(),point[metric]];
	});

	return chartData;

};


/**
 * getPerformanceChartOptions
 *
 * @link https://api.highcharts.com/class-reference/Highcharts.Time
 *
 */
export const getPerformanceChartOptions = ({data,name,metric,width=1200,height=null}) => {

	const chartData = parsePerformanceChartData({data:data,metric:metric});

	return {
		...chartDefaults,
		chart: {
			defaultSeriesType: 'line',
			backgroundColor: 'transparent',
			margin: [15, 5, 20, 15],
			width: width,
			height: height
		},
		tooltip: {
			pointFormatter: function() {
				let value = 'AUM' === this.series.name ? MoneyFormat( this.y ) : this.y.toFixed(2);
				return [
					'<span style="color:' + this.color + ';">' + this.series.name + '</span>',
					'<span>' + value + '</span>',
				].join('<br>');
			},
			valueDecimals: 2,
			split: true,
			xDateFormat: '%b %Y',
			style: {
				fontSize: '.75rem',
			},
		},
		xAxis: {
			type: 'datetime',
			gridLineColor: '#f3f3f3',
			gridLineWidth: 1,
		},
		yAxis: {
			title: {
				text: null
			},
			gridLineColor: '#f3f3f3',
			gridLineWidth: 1,
			opposite: true,
			offset: -50,
			labels: {
				align: 'right',
				x: 40,
				y: -3,
				formatter: function() {
					if (this.value > 1000000) {
						return (this.value / 1000000).toFixed(2) + 'M'
					}
					else if (this.value > 1000) {
						return (this.value / 1000).toFixed(2) + 'k';
					}

					return this.value;
				}
			},
			tickPositioner: function() {
				var positions = [];
				var tick = Math.floor(this.dataMin);
				var increment = Math.ceil((this.dataMax - this.dataMin) / 5);

				for (; tick - increment <= this.dataMax; tick += increment) {
					positions.push(tick);
				}

				return positions;
			},
		},
		series: [
			{
				name: name,
				color: CHART_COLORS.default[0],
				type: 'area',
				fillOpacity: 0.10,
				marker: {
					enabled: false
				},
				data: chartData
			}
		]
	}

};
