import React, {
	useEffect,
	useState,
} from 'react';


// forms
import {
	RegisterForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsRegister } from '../i18n/Register';


// Register
function Register({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsRegister[lang]};

	// getNewsletter boolean
	const [getNewsletter, setGetNewsletter] = useState(null);

	// simple geolocation
	const getLocation = async () => {

		const failure = {success:false};

		const response = await fetch(`https://ipapi.co/json/`, {
			method: 'GET',
		}).catch((error) => {
			return failure;
		});

		if ( response.ok ) {
			return response.json();
		}

		return failure;

	};

	// default getNewsletter to true for US visitors
	useEffect(() => {

		getLocation().then((response) => {
			setGetNewsletter( !!(response && 'US' === response.country_code) );
		});

	});

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( session.access_token ) {
			setTimeout(() => {
				window.location = `/managed-futures/performance`;
			}, 3000);
		}
	}, [
		lang,
		session
	]);

	if ( session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					{text.pageContent}

				{getNewsletter !== null &&
					<RegisterForm
						lang={lang}
						initialValues={{
							firstName: '',
							lastName: '',
							Email: '',
							country: ('zh-hans' === lang ? 'CN' : 'US'),
							phonePrefix: ('zh-hans' === lang ? '+86' : '+1'),
							phone: '',
							Password: '',
							ConfirmPassword: '',
							getNewsletter: getNewsletter,
							isAccredited: false,
						}}
						setSession={true}
						/>
				}

				</div>

			</div>

		</div>
	)

}

export default Register;
