import {
	format as dateFormat,
	subMonths,
} from 'date-fns';

// platform
const platform = [
	'sandbox.iasg.com',
].indexOf(window.location.hostname) > -1 ? 'sandboxapi' : 'iasgapi';

// dev vs www
const domain = [
	'localhost',
	'iasg.test',
	'dev.iasg.com',
].indexOf(window.location.hostname) > -1 ? 'enki.us' : 'iasg.com';

// API
export const BASE_URL = `https://${platform}.${domain}`;
export const API_URL = BASE_URL + '/api';

// WordPress
export const WP_BASE_URL = ['localhost','iasg.test'].indexOf(window.location.hostname) > -1 ? 'http://iasg.test' : ( 'https://' + window.location.hostname );
export const WP_AJAX_URL = WP_BASE_URL + '/wp-admin/admin-ajax.php';

// Platforms
export const IASG_PLATFORM_ID = '9279b5f5-88cc-4dc8-ab01-4b75d69dc0ca';
export const INSIGHT_PLATFORM_ID = 'f2cfc35b-65db-461f-ae21-3b7a55ecc21a';
export const SANDBOX_PLATFORM_ID = '946886be-19fb-4bd1-a245-f69efb3227f1';

// Status IDs
export const GROUP_ACTIVE_STATUS_ID = '5017ae64-b74a-4a87-bf10-c721492870c7';
export const GROUP_PENDING_STATUS_ID = '85d9dd76-a967-4dfa-9402-335e6bc4f32d';

export const PROGRAM_STATIC_STATUS_ID = '00000000-0000-0000-0000-000000000001';
export const PROGRAM_ALLCTA_STATUS_ID = '00000000-0000-0000-0000-000000000002';
export const PROGRAM_ACTIVE_STATUS_ID = '8056b77e-56d4-430c-92d3-cd032b3fbdb1';
export const PROGRAM_ARCHIVE_STATUS_ID = '3002e84c-cc91-46f9-8017-b79983e2d282';
export const PROGRAM_PENDING_STATUS_ID = '9e14fd91-697f-4e81-be15-514c6741ec73';
export const PROGRAM_DELETED_STATUS_ID = '4cad1560-e0e5-4f01-b455-613a4de4ed10';
export const PROGRAM_CLOSED_STATUS_ID = 'c5a24bbb-32e5-42dc-adb1-614e2c7e354d';
export const PROGRAM_OPEN_STATUS_ID = 'da4c7f6e-cba2-42ce-b216-91f3e301bd9b';

// type IDs
export const INDEX_TYPE_ID = 'babac227-96ae-4e40-9deb-1d875617015f';
export const MANAGED_ACCOUNT_TYPE_ID = '97f42b3e-7383-4def-810b-e6eea42ffeee';

// Dates
export const NOW = new Date().getUTCFullYear();

export const YEARS = Array(NOW - 1956).fill('').map((v, idx) => NOW - idx);

export const MONTHS = Array.from({length: 12}, (e, i) => {
	return {
		month: (i < 9 ? '0' : '') + (i + 1),
		label: new Date(null, i + 1, null).toLocaleDateString('en', {month: 'long'})
	};
});

// Platforms
export const PLATFORMS = [
	{
		platformId: IASG_PLATFORM_ID,
		platformTitle: 'IASG'
	},
	{
		platformId: INSIGHT_PLATFORM_ID,
		platformTitle: 'Insight'
	},
	{
		platformId: SANDBOX_PLATFORM_ID,
		platformTitle: 'Sandbox'
	},
];

// Static Arrays
export const GROUP_TYPES = [
	{
		grouptypeId: '1ed029ea-6917-4237-83d2-2b54b4ff27b2',
		title: 'Managed Forex Manager',
	},
	{
		grouptypeId: 'cb579d9f-d039-48b8-bcad-48d9b4d91365',
		title: 'Commodity Trading Advisor (CTA)',
	},
	{
		grouptypeId: '407d116d-ea3a-4753-b931-9e00a0b8bafc',
		title: 'Commodity Pool Operator (CPO)',
	},
];

export const GROUP_MEMBERSHIPS = [
	{
		membershipTypeId: '35a5073e-b8df-4204-8c2e-1f88a64d8852',
		title: 'NFA',
	},
	{
		membershipTypeId: '1a2bb620-c594-4def-9c98-3c012da56f71',
		title: 'FCA',
	},
	{
		membershipTypeId: '11a1138e-2635-4f4e-aea9-413f590ad1b9',
		title: 'Other',
    },
];

export const GROUP_STATUS_IDS = [
	{
		groupStatusId: 'cf6915ea-3c9d-4670-8389-1a5a6fd87b1d',
		groupStatus: 'Portfolio Apt Only'
	},
	{
		groupStatusId: GROUP_PENDING_STATUS_ID,
		groupStatus: 'Pending'
	},
	{
		groupStatusId: 'b3a1b731-9840-4f42-8100-4b0bf3bbb9e3',
		groupStatus: 'Closed'
	},
	{
		groupStatusId: '214c11aa-f26d-448c-9200-a842a2b0419f',
		groupStatus: 'Deleted'
	},
	{
		groupStatusId: GROUP_ACTIVE_STATUS_ID,
		groupStatus: 'Active'
	},
];

export const GROUP_CONTACT_TYPES = [
	{
		contactTypeId: '9208dd81-3181-4dd0-a707-20ad1e20e754',
		contactTitle: 'Performance Manager'
	},
	{
		contactTypeId: '8a737a36-c515-4886-91a9-3c322d16407c',
		contactTitle: 'Marketing Manager'
	},
	{
		contactTypeId: 'b90bef99-7380-4377-b876-f3881fc86d0f',
		contactTitle: 'Portfolio Manager'
	},
];

export const PROGRAM_TYPES = [
	{
		programTypeId: INDEX_TYPE_ID,
		programType: 'Index'
	},
	{
		programTypeId: '517067ee-c27f-4e40-bf04-e22512ab2ede',
		programType: 'Fund'
	},
	{
		programTypeId: MANAGED_ACCOUNT_TYPE_ID,
		programType: 'Managed Account'
	},
];

export const PROGRAM_MEMBERSHIP_TYPES = [
	{
		membershipStatusId: PROGRAM_CLOSED_STATUS_ID,
		membershipStatus: 'Closed to New Investments'
	},
	{
		membershipStatusId: PROGRAM_OPEN_STATUS_ID,
		membershipStatus: 'Open to New Investments'
	},
];

export const PROGRAM_SECTOR_FOCUS_TYPES = [
	{
		sectorFocusTypeId: 'd39aa7c9-c47a-4f5a-b56a-b69c4a49557b',
		sectorFocus: 'Agricultural Traders'
	},
	{
		sectorFocusTypeId: '9eea5322-501a-41cb-8444-9ca22fcb3473',
		sectorFocus: 'Arbitrage & Spread Traders'
	},
	{
		sectorFocusTypeId: '3f781936-8cc1-4662-8254-78fce1e34a10',
		sectorFocus: 'Currency Traders'
	},
	{
		sectorFocusTypeId: 'ee890afe-1fc0-43ab-aa41-14405fab5b30',
		sectorFocus: 'Diversified Traders'
	},
	{
		sectorFocusTypeId: '2061444d-bc3c-474d-9c23-633c0f6f2fec',
		sectorFocus: 'Energy Traders'
	},
	{
		sectorFocusTypeId: '6f9253dd-1ded-448f-998c-e8c7015232e7',
		sectorFocus: 'Financial & Metals Traders'
	},
	{
		sectorFocusTypeId: 'c1a4f603-0b71-4f12-9b56-50f0fa7222b0',
		sectorFocus: 'Global Climate Traders'
	},
	{
		sectorFocusTypeId: '1cc4bc0c-029d-4dce-9fba-ee2bd8407086',
		sectorFocus: 'Stock Index Traders'
	},
];

export const PROGRAM_INVESTOR_REQUIREMENTS_TYPES = [
	{
		investorRequirementTypeId: '00ef8500-a257-45c1-bc48-29df6c2b072f',
		investorRequirementType: 'Accredited Investors'
	},
	{
		investorRequirementTypeId: '7ff83e2a-f0a6-48d0-9fb2-67298ca4ea1c',
		investorRequirementType: 'Any Investor'
	},
	{
		investorRequirementTypeId: '81f1ada9-b493-4ef3-a6cd-a62489a92c8c',
		investorRequirementType: 'Qualified Eligible Persons (QEP)'
	},
];

export const PROGRAM_ALLOCATION_STRATEGY_TYPES = [
	{
		allocationStrategyTypeId: '42c50e94-15d7-46d7-b61c-61ef62f68ed1',
		allocationStrategy: 'Arbitrage'
	},
	{
		allocationStrategyTypeId: '437f9a3c-144d-48b3-a225-1722830425bf',
		allocationStrategy: 'Counter-trend'
	},
	{
		allocationStrategyTypeId: 'bf95e917-c387-4783-968c-e078e8d0af34',
		allocationStrategy: 'Fundamental'
	},
	{
		allocationStrategyTypeId: 'ca383f54-df6a-4b20-b2a2-eb063e69d21d',
		allocationStrategy: 'Momentum'
	},
	{
		allocationStrategyTypeId: 'a1aa3778-1ec9-4509-a243-ae6bd7b3a302',
		allocationStrategy: 'Option-purchasing'
	},
	{
		allocationStrategyTypeId: 'a0e53003-934e-4bb9-9940-49f9de48328f',
		allocationStrategy: 'Option-spread'
	},
	{
		allocationStrategyTypeId: 'ebb13eff-a87f-4d22-b3f9-613dd5035cf3',
		allocationStrategy: 'Option-writing'
	},
	{
		allocationStrategyTypeId: '681b945a-e13c-4466-b79b-0787fa942132',
		allocationStrategy: 'Other'
	},
	{
		allocationStrategyTypeId: '4507efd2-b950-4a2c-94f1-e0d8641b13ee',
		allocationStrategy: 'Pattern Recognition'
	},
	{
		allocationStrategyTypeId: '98d50fd7-de04-4e70-8485-139ae6c433cc',
		allocationStrategy: 'Seasonal/Cyclical'
	},
	{
		allocationStrategyTypeId: 'b140fae9-5883-499b-96ce-cbcf5f75dffe',
		allocationStrategy: 'Spreading/Hedging'
	},
	{
		allocationStrategyTypeId: '28e90f87-e71f-4231-aeae-ff061dd03790',
		allocationStrategy: 'Technical'
	},
	{
		allocationStrategyTypeId: '59b9678b-07d7-4e65-9a55-eb9581c8eac4',
		allocationStrategy: 'Trend-following'
	},
];

export const PROGRAM_METHODOLOGY_TYPES = [
	{
		methodologyTypeId: '97b4310e-61df-4d52-aabe-fc3cfcf82099',
		methodology: 'Discretionary'
	},
	{
		methodologyTypeId: 'a84537c0-ec2a-4644-9ba1-acbba3fa33dd',
		methodology: 'Systematic'
	},
];

export const PROGRAM_COMPOSITION_TYPES = [
	{
		compositionTypeId: '19f36643-1c9c-4cc9-9d56-8b00a52910a1',
		composition: 'Currency Futures'
	},
	{
		compositionTypeId: '54a3dc88-a8a9-494a-aeb0-498b62572833',
		composition: 'Currency FX'
	},
	{
		compositionTypeId: '4c88ab17-c6a7-493c-9359-cb99f37a611d',
		composition: 'Energy'
	},
	{
		compositionTypeId: 'e7c80ed6-ce5f-4128-8b4d-7c6e94f93860',
		composition: 'Grains'
	},
	{
		compositionTypeId: 'd1a5dd21-e7ba-46c3-b2ef-e49a8bd6081d',
		composition: 'Industrial Metals'
	},
	{
		compositionTypeId: '8c38e1c7-2cbd-4d9a-9849-652fe3cc56e1',
		composition: 'Interest Rates'
	},
	{
		compositionTypeId: '85421af1-b569-4168-bc03-d167b53fb7d5',
		composition: 'Livestock'
	},
	{
		compositionTypeId: '40f1ef50-6a93-4618-a340-b9b7c51e9d71',
		composition: 'Other'
	},
	{
		compositionTypeId: '5dd33a90-0432-4e05-8d95-297274618868',
		composition: 'Precious Metals'
	},
	{
		compositionTypeId: '770f0911-c46e-4601-a18c-6bb4355637fe',
		composition: 'Softs'
	},
	{
		compositionTypeId: '99672cd4-e43c-4e0e-b698-705a093e8c44',
		composition: 'SSF'
	},
	{
		compositionTypeId: '1500955a-ba1a-4bfb-acad-145dc1e94ad5',
		composition: 'Stock Indicies'
	},
	{
		compositionTypeId: '09ae1926-5913-4c66-8852-5f1280a5a518',
		composition: 'VIX'
	},
];

export const PROGRAM_STATUS_TYPES = [
	{
		programStatusId: PROGRAM_PENDING_STATUS_ID,
		programStatus: 'Pending'
	},
	{
		programStatusId: PROGRAM_DELETED_STATUS_ID,
		programStatus: 'Deleted'
	},
	{
		programStatusId: PROGRAM_ARCHIVE_STATUS_ID,
		programStatus: 'Archived'
	},
	{
		programStatusId: PROGRAM_ACTIVE_STATUS_ID,
		programStatus: 'Active'
	},
];

export const PROGRAM_HOLDING_TYPES = [
	{
		holdingTypeId: '8e294228-872e-44b3-bbc5-0fc1a839c8ae',
		holding: 'Held Intraday'
	},
	{
		holdingTypeId: 'b9132a93-6ced-4335-9256-e148f06ae630',
		holding: 'Held 1-30 Days'
	},
	{
		holdingTypeId: '0b1408ef-b2f4-4f13-89ea-eb40cabc6475',
		holding: 'Held 1-3 Months'
	},
	{
		holdingTypeId: '83bf7737-c4d2-4b26-a3db-26aa384ed446',
		holding: 'Held 4-12 Months'
	},
	{
		holdingTypeId: '14d18f13-00c4-4242-8810-fcd30f9ea0f7',
		holding: 'Held Over 12 Months'
	}
];

export const AUM_VALUES = [
	{
		value: 1000000,
		label: '$1M'
	},
	{
		value: 10000000,
		label: '$10M'
	},
	{
		value: 25000000,
		label: '$25M'
	},
	{
		value: 50000000,
		label: '$50M'
	},
	{
		value: 100000000,
		label: '$100M'
	},
	{
		value: 250000000,
		label: '$250M'
	},
	{
		value: 500000000,
		label: '$500M'
	},
	{
		value: 1000000000,
		label: '$1B'
	},
];

export const INVESTMENT_VALUES = [
	{
		value: 10000,
		label: '$10K'
	},
	{
		value: 25000,
		label: '$25K'
	},
	{
		value: 50000,
		label: '$50K'
	},
	{
		value: 100000,
		label: '$100K'
	},
	{
		value: 250000,
		label: '$250K'
	},
	{
		value: 500000,
		label: '$500K'
	},
	{
		value: 1000000,
		label: '$1M'
	},
];

// ytdReturnHeaderName
export const ytdReturnHeaderName = (new Date()).getMonth() === 0 ? ( (new Date()).getFullYear() - 1 ) : 'YTD';

export const DEFAULT_PROGRAM_COLUMNS = [
	{
		field: 'groupProgram',
		headerName: 'CTA / Program',
		alwaysVisible: true,
		sortable: true,
		width: '304px',
	},
	{
		field: 'past12Months',
		headerName: 'Past 12 Months',
		visible: true,
		width: '105px',
	},
	{
		field: 'monthReturn',
		headerName: dateFormat(subMonths(new Date(), 1), 'MMM'),
		type: 'float',
		format: 'plusMinus',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'ytdReturn',
		headerName: ytdReturnHeaderName,
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'compoundReturn',
		headerName: 'CAROR',
		type: 'percent',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'maxDrawDown',
		headerName: 'WDD',
		type: 'percent',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'assets',
		headerName: 'AUM',
		type: 'currency',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'investmentMin',
		headerName: 'Min Inv',
		type: 'float',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'dateInception',
		headerName: 'Inception',
		type: 'date',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'dateLastReturn',
		headerName: 'Last Return',
		type: 'date',
		visible: false,
		sortable: true,
		width: '72px',
	},
	{
		field: 'fullYearReturn',
		headerName: '1yr',
		type: 'percent',
		format: 'plusMinus',
		visible: false,
		sortable: true,
		width: '62px',
	},
	{
		field: 'threeYearReturn',
		headerName: '3yr',
		type: 'percent',
		format: 'plusMinus',
		visible: false,
		sortable: true,
		width: '62px',
	},
	{
		field: 'fiveYearReturn',
		headerName: '5yr',
		type: 'percent',
		format: 'plusMinus',
		visible: false,
		sortable: true,
		width: '62px',
	},
	{
		field: 'marginEquityRatio',
		headerName: 'Margin-Equity Ratio',
		type: 'percent',
		visible: false,
		sortable: true,
		width: '125px',
	},
	{
		field: 'sharpeRatio',
		headerName: 'Sharpe',
		type: 'float',
		visible: false,
		sortable: true,
		width: '107px',
	},
	{
		field: 'volatility',
		headerName: 'Volatility',
		type: 'percent',
		show_sign: true,
		visible: false,
		sortable: true,
		width: '62px',
	},
	{
		field: 'correlation',
		headerName: 'Blend Correlation',
		type: 'float',
		visible: false,
		alwaysVisible: true,
		sortable: true,
		width: '130px',
	},
];

export const WATCHLIST_COLUMNS = [
	...DEFAULT_PROGRAM_COLUMNS,
	{
		field: 'alert',
		headerName: 'Email Alert',
		width: '100px',
	},
	{
		field: 'actions',
		type: 'actions',
		width: '1%',
	},
];

// Programs Table Columns
export const PROGRAMS_COLUMNS = [
	{
		field: 'programId',
		type: 'checkbox',
		selectAll: true,
		visible: true,
		alwaysVisible: true,
		width: '1%',
	},
	...DEFAULT_PROGRAM_COLUMNS
];

// Programs Table Columns
export const ARCHIVE_COLUMNS = [
	...DEFAULT_PROGRAM_COLUMNS.filter((k) => k.field !== 'past12Months'),
	{
		field: 'dateArchived',
		headerName: 'Date Archived',
		type: 'date',
		visible: true,
		sortable: true,
		width: '62px',
	},
];

// Returns Table Columns
export const RETURNS_COLUMNS = [
	{
		field: 'programId',
		type: 'checkbox',
		selectAll: true,
		visible: true,
		alwaysVisible: true,
	},
	{
		field: 'groupProgram',
		headerName: 'CTA / Program',
		alwaysVisible: true,
		sortable: true,
		width: '304px',
	},
	{
		field: 'dateLastReturn',
		headerName: 'Last Return',
		type: 'date',
		dateFormat: 'MMMM',
		visible: true,
		sortable: true,
	},
	{
		field: 'ytdReturn',
		headerName: ytdReturnHeaderName,
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
	},
	{
		field: 'fullYearReturn',
		headerName: '1yr',
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
	},
	{
		field: 'threeYearReturn',
		headerName: '3yr',
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
	},
	{
		field: 'fiveYearReturn',
		headerName: '5yr',
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
	},
	{
		field: 'sharpeRatio',
		headerName: 'Sharpe',
		type: 'float',
		visible: true,
		sortable: true,
	},
	{
		field: 'maxDrawDown',
		headerName: 'WDD',
		type: 'percent',
		visible: true,
		sortable: true,
	},
	{
		field: 'investmentMin',
		headerName: 'Min Inv',
		type: 'float',
		visible: true,
		sortable: true,
	},
	{
		field: 'marginEquityRatio',
		headerName: 'Avg M/E',
		type: 'percent',
		precision: 0,
		show_sign: true,
		visible: true,
		sortable: true,
	},
];

// Indexes Table Columns
export const INDEXES_COLUMNS = [
	{
		field: 'portfolioId',
		type: 'checkbox',
		selectAll: false,
		visible: false,
		alwaysVisible: false,
	},
	{
		field: 'portfolioName',
		headerName: 'CTA/Program Index',
		alwaysVisible: true,
		sortable: true,
	},
	{
		field: 'past12Months',
		headerName: 'Past 12 Months',
		width: '105px',
	},
	{
		field: 'compoundedAnnualROR',
		headerName: 'CAROR',
		type: 'percent',
		sortable: true,
	},
	{
		field: 'monthReturn',
		headerName: dateFormat(subMonths(new Date(), 1), 'MMM'),
		type: 'float',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'ytdReturn',
		headerName: ytdReturnHeaderName,
		type: 'percent',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'fullYearReturn',
		headerName: '1yr',
		type: 'percent',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'threeYearReturn',
		headerName: '3yr',
		type: 'percent',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'fiveYearReturn',
		headerName: '5yr',
		type: 'percent',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'asset',
		headerName: 'Programs',
		type: 'int',
	},
];

// Portfolio Table Columns
export const PORTFOLIO_COLUMNS = [
	{
		field: 'groupProgram',
		headerName: 'CTA / Program',
		alwaysVisible: true,
		sortable: false,
		width: '304px',
	},
	{
		field: 'past12Months',
		headerName: 'Past 12 Months',
		sortable: false,
		width: '105px',
	},
	{
		field: 'quantity',
		headerName: 'Amount',
		type: 'currency',
		editable: true,
		sortable: false,
	},
	{
		field: 'dateAllocation',
		headerName: 'Allocation Date',
		type: 'date',
		editable: true,
		sortable: false,
	},
	{
		field: 'currentValue',
		headerName: 'Current Value',
		type: 'currency',
		sortable: false,
	},
	{
		field: 'periodReturn',
		headerName: 'Return %',
		type: 'percent',
		show_sign: true,
		sortable: false,
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: '',
		sortable: false,
		width: '1%',
	},
];

// Market Quotes Columns
export const MARKET_QUOTES_COLUMNS = [
	{
		field: 'groupProgram',
		headerName: 'Group / Product',
		sortable: true,
	},
	{
		field: 'symbol',
		headerName: 'Symbol',
		sortable: true,
	},
	{
		field: 'monthReturn',
		headerName: dateFormat(subMonths(new Date(), 1), 'MMM'),
		type: 'float',
		format: 'plusMinus',
		visible: true,
		sortable: true,
		width: '62px',
	},
	{
		field: 'ytdReturn',
		headerName: ytdReturnHeaderName,
		type: 'percent',
		format: 'plusMinus',
		visible: true,
		sortable: true,
	},
	{
		field: 'compoundedAnnualROR',
		headerName: 'CAROR',
		type: 'percent',
		sortable: true,
	},
	{
		field: 'maxDrawDown',
		headerName: 'WDD',
		type: 'percent',
		visible: true,
		sortable: true,
		width: '62px',
	},
];

// Saved Blends Columns
export const SAVED_BLENDS_COLUMNS = [
	{
		field: 'blendName',
		headerName: 'Saved Blends',
		sortable: true,
		width: '304px',
	},
	{
		field: 'past12Months',
		headerName: 'Past 12 Months',
		sortable: true,
		width: '105px',
	},
	{
		field: 'ytdReturn',
		headerName: ytdReturnHeaderName,
		type: 'percent',
		format: 'plusMinus',
		sortable: true,
	},
	{
		field: 'compoundedAnnualROR',
		headerName: 'CAROR',
		type: 'percent',
		sortable: true,
	},
	{
		field: 'maxDrawDown',
		headerName: 'Max DD',
		type: 'percent',
		sortable: true,
	},
	{
		field: 'correlationsp500',
		headerName: 'S&P Corr',
		type: 'float',
		sortable: true,
	},
	{
		field: 'sharpe',
		headerName: 'Sharpe*',
		type: 'float',
		sortable: true,
	},
	{
		field: 'standardDeviation',
		headerName: 'Std Dev*',
		type: 'float',
		sortable: true,
	},
	{
		field: 'dateCalculated',
		headerName: 'Last Modified',
		type: 'date',
		sortable: true,
	},
	{
		field: 'actions',
		type: 'actions',
		width: '1%',
	}
];

// colors
export const CHART_COLORS = {
	default: [
		'#0066cc',
		'#008080',
		'#ff6600',
		'#9933cc',
		'#cc9900',
		'#006600',
		'#cc0000',
		'#ff3399',
		'#663300',
		'#339966',
		'#3366ff',
		'#ff9933',
		'#555555',
	],
	indexes: [
		'#0d233a',
		'#8bbc21',
		'#910000',
		'#1aadce',
		'#492970',
		'#f28f43',
		'#77a1e5',
		'#c42525',
		'#a6c96a',
		'#2f7ed8',
		'#000000',
	],
	iasgIndexes: [
		'#4572A7',
		'#AA4643',
		'#89A54E',
		'#80699B',
		'#3D96AE',
		'#DB843D',
		'#92A8CD',
		'#A47D7C',
		'#B5CA92',
		'#000000',
	],
};
