/*
 * Tables string translation
 *
 * @link https://translate.google.com/?sl=en&tl=zh-CN&text=Hello%20world!&op=translate
 *
 */
export const labels = {
	'en': {
		YTD: 'Year to Date',
		WDD: 'Worst Draw Down',
		AUM: 'Assets Under Management',
		CAROR: 'Compounded Annual Rate of Return',
	},
	'zh-hans': {
		YTD: '年初至今',
		'最糟糕的回撤': '最糟糕的回撤',
		'管理资产的价值': '管理资产的价值',
		'复合年回报率': '复合年回报率',
	},
};
