import React, {
	useEffect,
} from 'react';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';


// BlockerModal
function BlockerModal({
	lang='en',
	session,
	toggleShowLogin,
}) {

	const text = {...labelsGlobal[lang]};

	useEffect(() => {
		!!session.access_token ? document.body.classList.remove('overflow-hidden') : document.body.classList.add('overflow-hidden');
	}, [
		session,
	]);

	return (<>
		<div className={'fade modal-backdrop modal-blocker' + (!!session.access_token ? '' : ' show')}></div>

		<div className={'modal d-' + (!!session.access_token ? 'none' : 'block')} tabIndex='-1'>

			<div className='modal-dialog modal-lg modal-dialog-centered' style={{maxWidth:'600px'}}>

			    <div className='modal-content'>

					<div className='modal-body py-5'>
					
						<div className='text-center px-lg-5'>

							<h3 className='text-primary border-top border-bottom border-primary py-2 mx-3'>{text.blockerHeading}</h3>

							<p><b>{text.blockerContent}</b></p>

							<div className='d-flex justify-content-center'>

								<ul className='fa-ul text-left'>
								{text.blockerBullets.map((bullet,index) =>
									<li key={index}><span className="fa-li text-success"><i className="fa-solid fa-check"></i></span>{bullet}</li>
								)}
								</ul>

							</div>

							<div className='px-3'>

								<a className='btn btn-primary btn-block mb-3' href={`/register`}>
									<i className='fa fa-chevron-right pr-2'></i>
									<span>{text.blockerCTA}</span>
								</a>

								<p className='mb-0'>{text.blockerQuestion} <a href={`/login`} onClick={(e) => {e.preventDefault();toggleShowLogin();return false;}}>{text.blockerLogin}</a></p>

							</div>

						</div>

					</div>

			    </div>

			</div>

		</div>
	</>)

}

export default BlockerModal;
