import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
	InputGroup,
	Collapse,
	Toast,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
	FieldArray,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// Constants
import {
	GROUP_ACTIVE_STATUS_ID,
	GROUP_PENDING_STATUS_ID,
	PROGRAM_TYPES,
	PROGRAM_MEMBERSHIP_TYPES,
	PROGRAM_STATUS_TYPES,
	PROGRAM_SECTOR_FOCUS_TYPES,
	PROGRAM_INVESTOR_REQUIREMENTS_TYPES,
	PROGRAM_ALLOCATION_STRATEGY_TYPES,
	PROGRAM_COMPOSITION_TYPES,
	PROGRAM_HOLDING_TYPES,
} from '../../helpers/Constants';


// API
import {
	getRequest,
	addProgram,
	updateProgram,
	addProgramToPlatform,
	deleteProgramFromPlatform,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';


// ProgramForm
function ProgramForm({
	lang,
	session,
	initialValues,
	add=false,
	fromGroupUri=false,
	callback=false,
}) {

	const text = {...labelsGlobal[lang],...labelsForms[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');


	// section toggles
	const [programInformationOpen,setProgramInformationOpen] = useState(true);
	const [programInvestmentInformationOpen,setProgramInvestmentInformationOpen] = useState(true);
	const [programFeesOpen,setProgramFeesOpen] = useState(true);
	const [programDecisionMakingOpen,setProgramDecisionMakingOpen] = useState(true);
	const [programInvestmentStrategyOpen,setProgramInvestmentStrategyOpen] = useState(true);
	const [programCompositionOpen,setProgramCompositionOpen] = useState(true);
	const [programPositionInformationOpen,setProgramPositionInformationOpen] = useState(true);
	const [programDescriptionOpen,setProgramDescriptionOpen] = useState(true);
	const [groupPlatformOpen,setGroupPlatformOpen] = useState(true);


	// platforms
	const [platforms, setPlatforms] = useState([]);


	// run our queries
	const queryOptions = {
		cacheTime: 300000,
		refetchOnMount: false,
	};
	const [
		{
			data: groups
		},
		{
			data: programsSubscriptionFrequencyTypes
		},
		{
			data: programsRedemptionFrequencyTypes
		},
	] = useQueries([
		{
			queryKey: ['groups'],
			queryFn: () => getRequest('/groups', {
				filterByGroupStatusIds: [GROUP_ACTIVE_STATUS_ID, GROUP_PENDING_STATUS_ID].join('&filterByGroupStatusIds='),
				SortBy: 'groupName',
				pageSize: 2000,
			}, session.access_token),
			...queryOptions
		},
		{
			queryKey: ['programsSubscriptionFrequencyTypes'],
			queryFn: () => getRequest('/programsSubscriptionFrequencyTypes', {sortBy:'subscriptionFrequency'}),
			...queryOptions
		},
		{
			queryKey: ['programsRedemptionFrequencyTypes'],
			queryFn: () => getRequest('/programsRedemptionFrequencyTypes', {sortBy:'redemptionFrequency'}),
			...queryOptions
		},
		{
			queryKey: ['platforms'],
			queryFn: () => getRequest('/platforms', {
				filterByGroupId: initialValues.groupId,
			}, session.access_token),
			onSuccess: (response) => {
				setPlatforms(response.items);
			},
			enabled: !!(session.PlatformAdmin) && !add
		},
		{
			queryKey: ['programPlatforms'],
			queryFn: () => getRequest('/programsPlatform', {
				filterByProgramId: initialValues.programId
			}, session.access_token),
			onSuccess: (response) => {
				initialValues.platforms = response.items.map((el) => {return el.platformId;});
			},
			enabled: !!(session.PlatformAdmin) && !add
		},
	]);


	// form schema
	const schema = yup.object().shape({

		programId: yup.string(),
		groupId: yup.string(),
		programStatusId: yup.string().required(text.required),
		programName: yup.string().required(text.required),
		programTypeId: yup.string().required(text.required),
		membershipStatusId: yup.string().required(text.required),
		symbol: yup.string().nullable(),

		currency: yup.string().required(text.required),
		investmentMin: yup.number().nullable(),
		subscriptionFrequencyTypeId: yup.string().nullable(),
		redemptionFrequencyTypeId: yup.string().nullable(),
		investorRequirementsTypeId: yup.string().nullable(),
		wddExpected: yup.number().nullable(),
		worstPeak2Trough: yup.number().nullable(),
		sectorFocusTypeId: yup.string().nullable(),
		carorType: yup.string().required(text.required),
		usInvest: yup.number(),
		proprietary: yup.boolean(),
		hideProgram: yup.boolean(),

		feeManagement: yup.number(),
		feePerformance: yup.number(),
		highwaterMark: yup.string().nullable(),

		methodologies: yup.array().of(
			yup.object().shape({
				methodologyTypeId: yup.string().required(),
				title: yup.string().required(),
				value: yup.number(),
			})
		).test(
			'mustEqual100',
			'Total must equal 100%',
			function() {
				let sum = 0;
				this.parent.methodologies.forEach((methodology) => {
					sum += methodology.value ? parseFloat(methodology.value) : 0;
				});
				return sum === 0 || sum === 100;
			}
		),

		allocationStrategies: yup.array().of(
			yup.object().shape({
				allocationStrategyTypeId: yup.string().required(),
				title: yup.string().required(),
				value: yup.number(),
			})
		).test(
			'mustEqual100',
			'Total must equal 100%',
			function() {
				let sum = 0;
				this.parent.allocationStrategies.forEach((strategy) => {
					sum += strategy.value ? parseFloat(strategy.value) : 0;
				});
				return sum === 0 || sum === 100;
			}
		),

		compositions: yup.array().of(
			yup.object().shape({
				compositionTypeId: yup.string().required(),
				title: yup.string().required(),
				value: yup.number(),
			})
		).test(
			'mustEqual100',
			'Total must equal 100%',
			function() {
				let sum = 0;
				this.parent.compositions.forEach((type) => {
					sum += type.value ? parseFloat(type.value) : 0;
				});
				return sum === 0 || sum === 100;
			}
		),

		roundturnYearMillion: yup.number().nullable(),
		marginEquityRatio: yup.number(),

		holdings: yup.array().of(
			yup.object().shape({
				holdingTypeId: yup.string().required(),
				title: yup.string().required(),
				value: yup.number(),
			})
		).test(
			'mustEqual100',
			'Total must equal 100%',
			function() {
				let sum = 0;
				this.parent.holdings.forEach((type) => {
					sum += type.value ? parseFloat(type.value) : 0;
				});
				return sum === 0 || sum === 100;
			}
		),

		description: yup.string(),
		strategyInvestment: yup.string(),
		strategyRisk: yup.string(),
		accountingNotes: yup.string().nullable(),

		platforms: !!(session.PlatformAdmin) && !add ? yup.array().min(1) : yup.string(),

		AcceptedAgreement: yup.boolean().required().oneOf([true], 'You must certify that all information is accurate'),

	});


	// onSubmit handler
	const onSubmit = (values, formikBag) => {

		let data = {};

		// only submit values defined in our schema
		for(let key in schema.fields) {
			switch(key) {
				case 'methodologies':
					let methodologies = [];
					values.methodologies.forEach((methodology) => {
						if( methodology.value ) {
							methodologies.push({
								...methodology,
								value: (methodology.value / 100)
							});
						}
					})
					data[key] = methodologies;
				break;
				case 'allocationStrategies':
					let strategies = [];
					values.allocationStrategies.forEach((strategy) => {
						if( strategy.value ) {
							strategies.push({
								...strategy,
								value: (strategy.value / 100)
							});
						}
					})
					data[key] = strategies;
				break;
				case 'compositions':
					let compositions = [];
					values.compositions.forEach((type) => {
						if( type.value ) {
							compositions.push({
								...type,
								value: (type.value / 100)
							});
						}
					})
					data[key] = compositions;
				break;
				case 'holdings':
					let holdings = [];
					values.holdings.forEach((type) => {
						if( type.value ) {
							holdings.push({
								...type,
								value: (type.value / 100)
							});
						}
					})
					data[key] = holdings;
				break;
				case 'AcceptedAgreement':
				break;
				default:
					data[key] = values[key];
			}
		}

		// modify percentages for submit :\
		[
			'feeManagement',
			'feePerformance',
			'marginEquityRatio',
		].forEach((key) => {
			data[key] = data[key] ? ( parseFloat(data[key]) / 100 ) : 0;
		});

		// nullable values
		[
			'wddExpected',
			'worstPeak2Trough',
		].forEach((key) => {
			data[key] = data[key] ? ( parseFloat(data[key]) / 100 ) : null;
		});

		// set programId
		data.programId = values.programId;

		// store platforms
		let programPlatforms = data.platforms;

		delete data.platforms;

		// addProgram
		if( add === true ) {

			addProgram(data, session.access_token).then((response) => {
				let program = response;
				// callback
				if( 'function' === typeof callback ) {
					callback(program);
				}
				// redirect
				if( fromGroupUri ) {
					window.location = `/groups/${fromGroupUri}/programs/${program.programUri}`;
				}
			});

		}
		// updateProgram
		else {

			updateProgram(data, session.access_token).then((response) => {

				window.scrollTo(0, 0);

				setShowToast(true);
				setToastMessage(text.programSaved);

				// platform settings
				if( session.PlatformAdmin && programPlatforms ) {
					let toDelete = initialValues.platforms.filter(x => !programPlatforms.includes(x));
					let toAdd = programPlatforms.filter(x => !initialValues.platforms.includes(x));
					if( toAdd.length ) {
						[...new Set(toAdd)].forEach((platformId) => {
							addProgramToPlatform(platformId, values.programId, session.access_token);
						});
					}
					if( toDelete.length ) {
						[...new Set(toDelete)].forEach((platformId) => {
							deleteProgramFromPlatform(platformId, values.programId, session.access_token);
						});
					}
				}

				// reset 'AcceptedAgreement'
				formikBag.setFieldValue('AcceptedAgreement', false);
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);

			});

		}

	}


	// massage some arrays :\
	useEffect(() => {

		// PROGRAM_ALLOCATION_STRATEGY_TYPES
		let allocationStrategies = [];

		PROGRAM_ALLOCATION_STRATEGY_TYPES.forEach((type) => {

			let found = initialValues.allocationStrategies.find(obj => {
				return obj.allocationStrategyTypeId === type.allocationStrategyTypeId
			})

			allocationStrategies.push(found ? found : {
				allocationStrategyTypeId: type.allocationStrategyTypeId,
				title: type.allocationStrategy,
				value: '',
			});

		});

		initialValues.allocationStrategies = allocationStrategies;

		// PROGRAM_COMPOSITION_TYPES
		let compositions = [];

		PROGRAM_COMPOSITION_TYPES.forEach((type) => {

			let found = initialValues.compositions.find(obj => {
				return obj.compositionTypeId === type.compositionTypeId
			})

			compositions.push(found ? found : {
				compositionTypeId: type.compositionTypeId,
				title: type.composition,
				value: '',
			});

		});

		initialValues.compositions = compositions;

		// PROGRAM_HOLDING_TYPES
		let holdings = [];

		PROGRAM_HOLDING_TYPES.forEach((type) => {

			let found = initialValues.holdings.find(obj => {
				return obj.holdingTypeId === type.holdingTypeId
			})

			holdings.push(found ? found : {
				holdingTypeId: type.holdingTypeId,
				title: type.holding,
				value: '',
			});

		});

		initialValues.holdings = holdings;

	}, [
		initialValues,
	]);


	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-5'
				noValidate
				onSubmit={handleSubmit}>

				<div
					className='position-relative'
					aria-live='polite'
					aria-atomic='true'>

					<div
						className='position-absolute d-flex justify-content-center'
						style={{top:'-3.25rem',left:0,right:0}}>

						<Toast
							show={showToast}
							onClose={() => {setShowToast(false);}}
							delay={3000}
							autohide
							className='border-success position-absolute mt-n2'>

							<Toast.Body
								className='text-success text-center py-1'>
								<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
								{toastMessage}
							</Toast.Body>

						</Toast>

					</div>

				</div>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3'
					aria-controls='program-information'
					aria-expanded={programInformationOpen}
					onClick={() => setProgramInformationOpen(!programInformationOpen)}
				>
					<span className='h5'>{text.programInformation}</span>
					<small className='text-danger pl-2' hidden={!errors.programName && !errors.membershipStatusId}>{`Check errors below`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programInformationOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programInformationOpen}>

					<div className='p-3' id='program-information'>
						<Form.Group controlId='programName'>
							<Form.Label>{text.programName}*</Form.Label>
							<Form.Control
								type='text'
								name='programName'
								autoComplete='off'
								value={values.programName}
								onChange={handleChange}
								isValid={touched.programName && !errors.programName}
								isInvalid={touched.programName && errors.programName}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.programName}
							</Form.Control.Feedback>
						</Form.Group>

					{session.Admin && fromGroupUri === false &&
						<Form.Group controlId='groupId'>
							<Form.Label>{text.programGroup}</Form.Label>
						{groups && groups.items ? (
							<Form.Control
								as='select'
								custom
								name='groupId'
								value={values.groupId}
								onChange={handleChange}
								isValid={touched.groupId && !errors.groupId}
								isInvalid={touched.groupId && errors.groupId}
							>
							{groups.items.map((group) =>
								<option key={group.groupId} value={group.groupId}>{group.groupName}</option>
							)}
							</Form.Control>
						) : (
							<div>{text.loading}</div>
						)}
						</Form.Group>
					}
					{session.Admin &&
						<Form.Group controlId='programStatusId'>
							<Form.Label>{text.programStatus}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='programStatusId'
								value={values.programStatusId}
								onChange={handleChange}
								isValid={touched.programStatusId && !errors.programStatusId}
								isInvalid={touched.programStatusId && errors.programStatusId}
							>
							{PROGRAM_STATUS_TYPES.map((type) =>
								<option key={type.programStatusId} value={type.programStatusId}>{type.programStatus}</option>
							)}
							</Form.Control>
						</Form.Group>
					}
						<Form.Group controlId='programTypeId'>
							<Form.Label>{text.programType}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='programTypeId'
								value={values.programTypeId}
								onChange={handleChange}
								isValid={touched.programTypeId && !errors.programTypeId}
								isInvalid={touched.programTypeId && errors.programTypeId}
							>
							{PROGRAM_TYPES.map((type) =>
								<option key={type.programTypeId} value={type.programTypeId}>{type.programType}</option>
							)}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='membershipStatusId'>
							<Form.Label>{`Current Status`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='membershipStatusId'
								value={values.membershipStatusId}
								onChange={handleChange}
								isValid={touched.membershipStatusId && !errors.membershipStatusId}
								isInvalid={touched.membershipStatusId && errors.membershipStatusId}
							>
							{PROGRAM_MEMBERSHIP_TYPES.map((type) =>
								<option key={type.membershipStatusId} value={type.membershipStatusId}>{type.membershipStatus}</option>
							)}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='symbol'>
							<Form.Label>{`Yahoo Ticker Symbol`}</Form.Label>
							<Form.Control
								type='text'
								name='symbol'
								autoComplete='off'
								value={values.symbol||''}
								onChange={handleChange}
								isValid={touched.symbol && !errors.symbol}
								isInvalid={touched.symbol && errors.symbol}
							/>
						</Form.Group>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-investment-information'
					aria-expanded={programInvestmentInformationOpen}
					onClick={() => setProgramInvestmentInformationOpen(!programInvestmentInformationOpen)}
				>
					<span className='h5'>{text.programInvestmentInformation}</span>
					<small className='text-danger pl-2' hidden={!errors.usInvest}>{`Check errors below`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programInvestmentInformationOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programInvestmentInformationOpen}>

					<div className='p-3' id='program-investment-information'>

						<Form.Group controlId='currency'>
							<Form.Label>{`Currency`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='currency'
								value={values.currency}
								onChange={handleChange}
								isValid={touched.currency && !errors.currency}
								isInvalid={touched.currency && errors.currency}
							>
							{[
								{
									abbr: 'USD',
									label: 'US Dollar',
								},
								{
									abbr: 'GBP',
									label: 'United Kingdom Pounds',
								},
								{
									abbr: 'JPY',
									label: 'Japan Yen',
								},
								{
									abbr: 'AUD',
									label: 'Australia Dollar',
								},
								{
									abbr: 'EUR',
									label: 'Euro',
								},
								{
									abbr: 'CNY',
									label: 'China Yuan',
								},
								{
									abbr: 'CHF',
									label: 'Swiss Franc',
								},
								{
									abbr: 'CAD',
									label: 'Canadian Dollar',
								},
							].map((currency) => (
								<option key={currency.abbr} value={currency.abbr}>{currency.label}</option>
							))}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='investmentMin'>
							<Form.Label>{`Minimum Investment`}</Form.Label>
							<Form.Control
								type='number'
								min={0}
								name='investmentMin'
								value={values.investmentMin||''}
								onChange={handleChange}
								isValid={touched.investmentMin && !errors.investmentMin}
								isInvalid={touched.investmentMin && errors.investmentMin}
								/>
						</Form.Group>

						<Form.Group controlId='subscriptionFrequencyTypeId'>
							<Form.Label>{`Subscription Frequency`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='subscriptionFrequencyTypeId'
								value={values.subscriptionFrequencyTypeId||''}
								onChange={handleChange}
								isValid={touched.subscriptionFrequencyTypeId && !errors.subscriptionFrequencyTypeId}
								isInvalid={touched.subscriptionFrequencyTypeId && errors.subscriptionFrequencyTypeId}
							>
								<option value={''}>{`—`}</option>
							{programsSubscriptionFrequencyTypes && programsSubscriptionFrequencyTypes.items ? programsSubscriptionFrequencyTypes.items.map((type) => (
								<option key={type.subscriptionFrequencyTypeId} value={type.subscriptionFrequencyTypeId}>{type.subscriptionFrequency}</option>
							)) : null}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='redemptionFrequencyTypeId'>
							<Form.Label>{`Redemption Frequency`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='redemptionFrequencyTypeId'
								value={values.redemptionFrequencyTypeId||''}
								onChange={handleChange}
								isValid={touched.redemptionFrequencyTypeId && !errors.redemptionFrequencyTypeId}
								isInvalid={touched.redemptionFrequencyTypeId && errors.redemptionFrequencyTypeId}
							>
								<option value={''}>{`—`}</option>
							{programsRedemptionFrequencyTypes && programsRedemptionFrequencyTypes.items ? programsRedemptionFrequencyTypes.items.map((type) => (
								<option key={type.redemptionFrequencyTypeId} value={type.redemptionFrequencyTypeId}>{type.redemptionFrequency}</option>
							)) : null}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='investorRequirementsTypeId'>
							<Form.Label>{`Investor Requirements`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='investorRequirementsTypeId'
								value={values.investorRequirementsTypeId||''}
								onChange={handleChange}
								isValid={touched.investorRequirementsTypeId && !errors.investorRequirementsTypeId}
								isInvalid={touched.investorRequirementsTypeId && errors.investorRequirementsTypeId}
							>
								<option value={''}>{`—`}</option>
							{PROGRAM_INVESTOR_REQUIREMENTS_TYPES.map((type) =>
								<option key={type.investorRequirementTypeId} value={type.investorRequirementTypeId}>{type.investorRequirementType}</option>
							)}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='wddExpected' style={{maxWidth:'15rem'}}>
							<Form.Label>{`Targeted Worst Draw Down`}</Form.Label>
							<InputGroup>
								<Form.Control
									type='number'
									step={.01}
									name='wddExpected'
									value={values.wddExpected||''}
									onChange={handleChange}
									isValid={touched.wddExpected && !errors.wddExpected}
									isInvalid={touched.wddExpected && errors.wddExpected}
									aria-label={`Targeted Worst Draw Down`}
									aria-describedby='wddExpected-addon'
									/>
								<InputGroup.Text id='wddExpected-addon'>{`%`}</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Group controlId='worstPeak2Trough' style={{maxWidth:'15rem'}}>
							<Form.Label>{`Worst Peak-to-Trough`}</Form.Label>
							<InputGroup>
								<Form.Control
									type='number'
									step={.01}
									name='worstPeak2Trough'
									value={values.worstPeak2Trough||''}
									onChange={handleChange}
									isValid={touched.worstPeak2Trough && !errors.worstPeak2Trough}
									isInvalid={touched.worstPeak2Trough && errors.worstPeak2Trough}
									aria-label={`Worst Peak-to-Trough`}
									aria-describedby='worstPeak2Trough-addon'
									/>
								<InputGroup.Text id='worstPeak2Trough-addon'>{`%`}</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Group controlId='sectorFocusTypeId'>
							<Form.Label>{`Trading Program Sector Weighting`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='sectorFocusTypeId'
								value={values.sectorFocusTypeId||''}
								onChange={handleChange}
								isValid={touched.sectorFocusTypeId && !errors.sectorFocusTypeId}
								isInvalid={touched.sectorFocusTypeId && errors.sectorFocusTypeId}
							>
								<option value={''}>{`—`}</option>
							{PROGRAM_SECTOR_FOCUS_TYPES.map((type) =>
								<option key={type.sectorFocusTypeId} value={type.sectorFocusTypeId}>{type.sectorFocus}</option>
							)}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='carorType'>
							<Form.Label>{`Way to calculate AROR?`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='carorType'
								value={values.carorType}
								onChange={handleChange}
								isValid={touched.carorType && !errors.carorType}
								isInvalid={touched.carorType && errors.carorType}
							>
							{[
								'Cumulative',
								'Unique',
								'Sum',
							].map((type,index) => (
								<option key={index} value={index}>{type}</option>
							))}
							</Form.Control>
						</Form.Group>

						<Form.Label>{text.usInvest}</Form.Label>

						<Form.Group>

							<Form.Check
								custom
								inline
								type='radio'
								name='usInvest'
								id='usInvest-yes'
								value={1}
								checked={1===values.usInvest}
								onChange={(e) => setFieldValue('usInvest', 1)}
								label={`Yes`}
							/>

							<Form.Check
								custom
								inline
								type='radio'
								name='usInvest'
								id='usInvest-no'
								value={0}
								checked={0===values.usInvest}
								onChange={(e) => setFieldValue('usInvest', 0)}
								label={<>
									{`No`}
								</>}
							/>

						</Form.Group>

						<Form.Group>

							<Form.Label>{`Additional Settings`}</Form.Label>

							<Form.Check
								custom
								type='checkbox'
								name='proprietary'
								id='proprietary'
								value={values.proprietary}
								checked={values.proprietary}
								onChange={(e) => setFieldValue('proprietary', !values.proprietary)}
								label={<>
									{`Assets/performance for this program are considered proprietary`}
								</>}
							/>

							<Form.Check
								custom
								type='checkbox'
								name='hideProgram'
								id='hideProgram'
								value={values.hideProgram}
								checked={values.hideProgram}
								onChange={(e) => setFieldValue('hideProgram', !values.hideProgram)}
								label={<>
									{`Hide program from non-registered users`}
								</>}
							/>

						</Form.Group>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-fees-and-commissions'
					aria-expanded={programFeesOpen}
					onClick={() => setProgramFeesOpen(!programFeesOpen)}
				>
					<span className='h5'>{text.programFees}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (programFeesOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programFeesOpen}>

					<div className='p-3' id='program-fees-and-commissions'>

						<Form.Group controlId='feeManagement' style={{maxWidth:'15rem'}}>
							<Form.Label>{text.managementFee}</Form.Label>
							<InputGroup>
								<Form.Control
									type='number'
									min={0}
									step={.01}
									name='feeManagement'
									value={values.feeManagement}
									onChange={handleChange}
									isValid={touched.feeManagement && !errors.feeManagement}
									isInvalid={touched.feeManagement && errors.feeManagement}
									aria-label={`Management Fee`}
									aria-describedby='feeManagement-addon'
									/>
								<InputGroup.Text id='feeManagement-addon'>{`%`}</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Group controlId='feePerformance' style={{maxWidth:'15rem'}}>
							<Form.Label>{text.performanceFee}</Form.Label>
							<InputGroup>
								<Form.Control
									type='number'
									min={0}
									step={.01}
									name='feePerformance'
									value={values.feePerformance}
									onChange={handleChange}
									isValid={touched.feePerformance && !errors.feePerformance}
									isInvalid={touched.feePerformance && errors.feePerformance}
									aria-label={`Performance Fee`}
									aria-describedby='feePerformance-addon'
									/>
								<InputGroup.Text id='feePerformance-addon'>{`%`}</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Label>{text.highwaterMark}</Form.Label>

						<Form.Group>

							<Form.Check
								custom
								inline
								type='radio'
								name='highwaterMark'
								id='highwaterMark-yes'
								value={'yes'}
								checked={'yes'===values.highwaterMark}
								onChange={(e) => setFieldValue('highwaterMark', 'yes')}
								label={`Yes`}
							/>

							<Form.Check
								custom
								inline
								type='radio'
								name='highwaterMark'
								id='highwaterMark-no'
								value={'no'}
								checked={'no'===values.highwaterMark}
								onChange={(e) => setFieldValue('highwaterMark', 'no')}
								label={<>
									{`No`}
								</>}
							/>

						</Form.Group>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-decision-making'
					aria-expanded={programDecisionMakingOpen}
					onClick={() => setProgramDecisionMakingOpen(!programDecisionMakingOpen)}
				>
					<span className='h5'>{text.programDecisionMaking}</span>
					<small className='text-danger pl-2' hidden={!errors.methodologies}>{`Total must equal 100%`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programDecisionMakingOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programDecisionMakingOpen}>

					<div className='pb-3' id='program-decision-making'>

						<div className='table-responsive'>
							<table className='table table-striped mb-0'>
								<tbody>
									<tr>
										<th className='pl-3' scope='row'>
											<Form.Label
												className='mb-0'
												htmlFor={`methodologies-0-value`}>{values.methodologies[0].title}</Form.Label>
										</th>
										<td className='text-right' style={{width:'12rem'}}>
											<InputGroup>
												<Form.Control
													className='text-right'
													type='number'
													min={0}
													max={100}
													step={.01}
													name={`methodologies[0][value]`}
													id={`methodologies-0-value`}
													value={values.methodologies[0].value||''}
													onChange={handleChange}
													aria-label={values.methodologies[0].title}
													aria-describedby='methodologies-0-value-addon'
													isValid={touched.methodologies && !errors.methodologies}
													isInvalid={touched.methodologies && errors.methodologies}
													/>
												<InputGroup.Text id='methodologies-0-value-addon'>{`%`}</InputGroup.Text>
											</InputGroup>
										</td>
									</tr>
									<tr>
										<th className='pl-3' scope='row'>
											<Form.Label
												className='mb-0'
												htmlFor={`methodologies-1-value`}>{values.methodologies[1].title}</Form.Label>
										</th>
										<td className='text-right' style={{width:'12rem'}}>
											<InputGroup>
												<Form.Control
													className='text-right'
													type='number'
													min={0}
													max={100}
													step={.01}
													name={`methodologies[1][value]`}
													id={`methodologies-1-value`}
													value={values.methodologies[1].value||''}
													onChange={handleChange}
													aria-label={values.methodologies[1].title}
													aria-describedby='methodologies-1-value-addon'
													isValid={touched.methodologies && !errors.methodologies}
													isInvalid={touched.methodologies && errors.methodologies}
													/>
												<InputGroup.Text id='methodologies-1-value-addon'>{`%`}</InputGroup.Text>
											</InputGroup>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td className='pl-3 pt-0' colSpan={2}>
											<Form.Text className={'text-' + (errors.methodologies ? 'danger' : 'muted')}>
												{`Total must equal 100%`}
											</Form.Text>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-investment-strategy'
					aria-expanded={programInvestmentStrategyOpen}
					onClick={() => setProgramInvestmentStrategyOpen(!programInvestmentStrategyOpen)}
				>
					<span className='h5'>{text.programInvestmentStrategy}</span>
					<small className='text-danger pl-2' hidden={!errors.allocationStrategies}>{`Total must equal 100%`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programInvestmentStrategyOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programInvestmentStrategyOpen}>

					<div className='pb-3' id='program-investment-strategy'>

						<FieldArray
							name='allocationStrategies'
							render={arrayHelpers => {
								const allocationStrategies = values.allocationStrategies;
								const isValid = (index, key) => {
									return touched.allocationStrategies && !errors.allocationStrategies;
								};
								const isInvalid = (index, key) => {
									return touched.allocationStrategies && errors.allocationStrategies;
								};
								return (
									<div>
									{allocationStrategies && allocationStrategies.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<tbody>
												{allocationStrategies.map((type, index) =>
													<tr key={index}>
														<th className='pl-3' scope='row'>
															<Form.Label
																className='mb-0'
																htmlFor={`allocationStrategies-${index}-value`}>{type.title}</Form.Label>
														</th>
														<td className='text-right' style={{width:'12rem'}}>
															<InputGroup>
																<Form.Control
																	className='text-right'
																	type='number'
																	min={0}
																	max={100}
																	step={.01}
																	name={`allocationStrategies[${index}][value]`}
																	id={`allocationStrategies-${index}-value`}
																	value={values.allocationStrategies[index].value||''}
																	onChange={handleChange}
																	aria-label={type.title}
																	aria-describedby={`allocationStrategies-${index}-addon`}
																	isValid={isValid(index,'value')}
																	isInvalid={isInvalid(index,'value')}
																	/>
																<InputGroup.Text id={`allocationStrategies-${index}-addon`}>{`%`}</InputGroup.Text>
															</InputGroup>
														</td>
													</tr>
												)}
												</tbody>
												<tfoot>
													<tr>
														<td className='pl-3' colSpan={2}>
															<Form.Text className={'text-' + (errors.allocationStrategies ? 'danger' : 'muted')}>
																{`Total must equal 100%`}
															</Form.Text>
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									) : null}
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-composition'
					aria-expanded={programCompositionOpen}
					onClick={() => setProgramCompositionOpen(!programCompositionOpen)}
				>
					<span className='h5'>{text.programComposition}</span>
					<small className='text-danger pl-2' hidden={!errors.compositions}>{`Total must equal 100%`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programCompositionOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programCompositionOpen}>

					<div className='pb-3' id='program-composition'>

						<FieldArray
							name='compositions'
							render={arrayHelpers => {
								const compositions = values.compositions;
								const isValid = (index, key) => {
									return touched.compositions && !errors.compositions;
								};
								const isInvalid = (index, key) => {
									return touched.compositions && errors.compositions;
								};
								return (
									<div>
									{compositions && compositions.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<tbody>
												{compositions.map((type, index) =>
													<tr key={index}>
														<th className='pl-3' scope='row'>
															<Form.Label
																className='mb-0'
																htmlFor={`compositions-${index}-value`}>{type.title}</Form.Label>
														</th>
														<td className='text-right' style={{width:'12rem'}}>
															<InputGroup>
																<Form.Control
																	className='text-right'
																	type='number'
																	min={0}
																	max={100}
																	step={.01}
																	name={`compositions[${index}][value]`}
																	id={`compositions-${index}-value`}
																	value={values.compositions[index].value||''}
																	onChange={handleChange}
																	aria-label={type.title}
																	aria-describedby={`compositions-${index}-addon`}
																	isValid={isValid(index,'value')}
																	isInvalid={isInvalid(index,'value')}
																	/>
																<InputGroup.Text id={`compositions-${index}-addon`}>{`%`}</InputGroup.Text>
															</InputGroup>
														</td>
													</tr>
												)}
												</tbody>
												<tfoot>
													<tr>
														<td className='pl-3' colSpan={2}>
															<Form.Text className={'text-' + (errors.compositions ? 'danger' : 'muted')}>
																{`Total must equal 100%`}
															</Form.Text>
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									) : null}
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-position-information'
					aria-expanded={programPositionInformationOpen}
					onClick={() => setProgramPositionInformationOpen(!programPositionInformationOpen)}
				>
					<span className='h5'>{text.programPositionInformation}</span>
					<small className='text-danger pl-2' hidden={!errors.holdings}>{`Holdings must equal 100%`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (programPositionInformationOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programPositionInformationOpen}>

					<div className='pb-3' id='program-position-information'>

						<div className='table-responsive'>
							<table className='table table-striped mb-0'>
								<tbody>
									<tr>
										<th className='pl-3' scope='row'>
											<Form.Label className='mb-0' htmlFor={`roundturnYearMillion`}>{`Trade Frequency`}</Form.Label>
										</th>
										<td className='text-right' style={{width:'14rem'}}>
											<InputGroup>
												<Form.Control
													type='number'
													min={0}
													step={1}
													name={`roundturnYearMillion`}
													id={`roundturnYearMillion`}
													value={values.roundturnYearMillion||''}
													onChange={handleChange}
													isValid={touched.roundturnYearMillion && !errors.roundturnYearMillion}
													isInvalid={touched.roundturnYearMillion && errors.roundturnYearMillion}
													aria-label={`Trade Frequency`}
													aria-describedby='roundturnYearMillion-addon'
													/>
												<InputGroup.Text id='roundturnYearMillion-addon'>
													<small>{`RT/YR/$M`}</small>
												</InputGroup.Text>
											</InputGroup>
										</td>
									</tr>
									<tr>
										<th className='pl-3' scope='row'>
											<Form.Label
												className='mb-0'
												htmlFor={`marginEquityRatio`}>{`Average Margin-to-Equity Ratio`}</Form.Label>
										</th>
										<td className='text-right' style={{width:'14rem'}}>
											<InputGroup>
												<Form.Control
													type='number'
													min={0}
													max={100}
													step={.01}
													name={`marginEquityRatio`}
													id={`marginEquityRatio`}
													value={values.marginEquityRatio}
													onChange={handleChange}
													aria-label={`Average Margin-to-Equity Ratio`}
													aria-describedby={`marginEquityRatio-addon`}
													isValid={touched.marginEquityRatio && !errors.marginEquityRatio}
													isInvalid={touched.marginEquityRatio && errors.marginEquityRatio}
													/>
												<InputGroup.Text id={`marginEquityRatio-addon`}>{`%`}</InputGroup.Text>
											</InputGroup>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<h5 className='bg-light px-3 py-2 mb-0' style={{fontSize:'1rem'}}>
							<span className='h5'>Holdings</span>
							<small className='text-danger pl-2' hidden={!errors.holdings}>{`Total must equal 100%`}</small>
						</h5>

						<FieldArray
							name='holdings'
							render={arrayHelpers => {
								const holdings = values.holdings;
								const isValid = (index, key) => {
									return touched.holdings && !errors.holdings;
								};
								const isInvalid = (index, key) => {
									return touched.holdings && errors.holdings;
								};
								return (
									<div id='holdings-table'>
									{holdings && holdings.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<tbody>
												{holdings.map((type, index) =>
													<tr key={index}>
														<th className='pl-3' scope='row'>
															<Form.Label className='mb-0' htmlFor={`holdings-${index}-value`}>
																{type.title}
															</Form.Label>
														</th>
														<td className='text-right' style={{width:'12rem'}}>
															<InputGroup>
																<Form.Control
																	className='text-right'
																	type='number'
																	min={0}
																	max={100}
																	step={.01}
																	name={`holdings[${index}][value]`}
																	id={`holdings-${index}-value`}
																	value={values.holdings[index].value||''}
																	onChange={handleChange}
																	aria-label={type.title}
																	aria-describedby={`holdings-${index}-addon`}
																	isValid={isValid(index,'value')}
																	isInvalid={isInvalid(index,'value')}
																	/>
																<InputGroup.Text id={`holdings-${index}-addon`}>{`%`}</InputGroup.Text>
															</InputGroup>
														</td>
													</tr>
												)}
												</tbody>
												<tfoot>
													<tr>
														<td className='pl-3' colSpan={2}>
															<Form.Text className={'text-' + (errors.holdings ? 'danger' : 'muted')}>
																{`Total must equal 100%`}
															</Form.Text>
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									) : null}
									</div>
								);
							}}
						/>

					</div>

				</Collapse>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-description'
					aria-expanded={programDescriptionOpen}
					onClick={() => setProgramDescriptionOpen(!programDescriptionOpen)}
				>
					<span className='h5'>{text.programDescription}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (programDescriptionOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={programDescriptionOpen}>

					<div className='p-3' id='program-description'>

						<Form.Group controlId='description'>
							<Form.Label>{text.programSummary}</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								name='description'
								value={values.description||''}
								onChange={handleChange}
								isValid={touched.description && !errors.description}
								isInvalid={touched.description && errors.description}
							/>
						</Form.Group>

						<Form.Group controlId='strategyInvestment'>
							<Form.Label>{text.programStrategyInvestment}</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								name='strategyInvestment'
								value={values.strategyInvestment||''}
								onChange={handleChange}
								isValid={touched.strategyInvestment && !errors.strategyInvestment}
								isInvalid={touched.strategyInvestment && errors.strategyInvestment}
							/>
						</Form.Group>

						<Form.Group controlId='strategyRisk'>
							<Form.Label>{text.programStrategyRisk}</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								name='strategyRisk'
								value={values.strategyRisk||''}
								onChange={handleChange}
								isValid={touched.strategyRisk && !errors.strategyRisk}
								isInvalid={touched.strategyRisk && errors.strategyRisk}
							/>
						</Form.Group>

						<Form.Group controlId='accountingNotes'>
							<Form.Label>{text.accountingNotes}</Form.Label>
							<Form.Control
								as='textarea'
								rows={6}
								name='accountingNotes'
								value={values.accountingNotes||''}
								onChange={handleChange}
								isValid={touched.accountingNotes && !errors.accountingNotes}
								isInvalid={touched.accountingNotes && errors.accountingNotes}
							/>
						</Form.Group>



					</div>

				</Collapse>

			{session.PlatformAdmin && platforms && !add && <>
				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls="group-platform"
					aria-expanded={groupPlatformOpen}
					onClick={() => setGroupPlatformOpen(!groupPlatformOpen)}
				>
					<span className='h5'>{text.platform}</span>
					<small className='text-danger pl-2' hidden={!errors.platforms}>{`You must select at least one platform.`}</small>
					<i className={'fas float-right mt-1 fa-chevron-' + (groupPlatformOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={groupPlatformOpen}>

					<div className='p-3' id='group-platform'>
						<Form.Group>
						{platforms.map((platform) =>
							<Form.Check
								key={platform.platformId}
								custom
								type='checkbox'
								name='platforms'
								id={`platform-${platform.platformId}`}
								value={platform.platformId}
								checked={values.platforms.indexOf(platform.platformId)>-1}
								onChange={(e) => {
									const isChecked = values.platforms.indexOf(platform.platformId) > -1;
									setFieldValue('platforms', isChecked ? values.platforms.filter((e) => {return e !== platform.platformId;}) : [...values.platforms,platform.platformId] );
								}}
								label={<>
									{platform.platformTitle}
								</>}
							/>
						)}
						</Form.Group>
					</div>

				</Collapse>

			</>}
				<Form.Group className='mt-3 py-3 px-3'>
					<Form.Check
						custom
						type='checkbox'
						name='AcceptedAgreement'
						id='AcceptedAgreement-Program'
						value={false}
						checked={values.AcceptedAgreement}
						onChange={(e) => setFieldValue('AcceptedAgreement', !values.AcceptedAgreement)}
						label={<>
							{`I certify that all information I have furnished to ${text.siteTitle} is accurate, to the best of my knowledge.`}
						</>}
					/>
					<small className='pl-4 text-danger' hidden={!errors.AcceptedAgreement}>{`You must certify that all information is accurate`}</small>
				</Form.Group>

				{ (
					errors.programName || errors.membershipStatusId ||
					errors.usInvest ||
					errors.methodologies ||
					errors.allocationStrategies ||
					errors.compositions ||
					errors.holdings
				) &&
					<div className='alert alert-danger'>
					{(errors.programName || errors.membershipStatusI) &&
						<div><a className='alert-link font-weight-normal' href={'#program-information'}>{`Please complete basic information section above.`}</a></div>
					}
					{(errors.usInvest) &&
						<div><a className='alert-link font-weight-normal' href={'#program-investment-information'}>{`Please complete new investment information section above.`}</a></div>
					}
					{(errors.methodologies) &&
						<div><a className='alert-link font-weight-normal' href={'#program-decision-making'}>{`Decision-making total must equal 100%`}</a></div>
					}
					{(errors.allocationStrategies) &&
						<div><a className='alert-link font-weight-normal' href={'#program-investment-strategy'}>{`Investment Strategy total must equal 100%`}</a></div>
					}
					{(errors.compositions) &&
						<div><a className='alert-link font-weight-normal' href={'#program-composition'}>{`Composition total must equal 100%`}</a></div>
					}
					{(errors.holdings) &&
						<div><a className='alert-link font-weight-normal' href={'#holdings-table'}>{`Holdings total must equal 100%`}</a></div>
					}
					{(session.PlatformAdmin && errors.platforms) &&
						<div><a className='alert-link font-weight-normal' href={'#group-platform'}>{`You must select at least one platform.`}</a></div>
					}
					</div>
				}

				<Form.Group>
					<button
						type='submit'
						disabled={!values.AcceptedAgreement||isSubmitting}
						className='btn btn-primary btn-block'>
					{isSubmitting &&
						<span>{text.working}</span>
					}
					{!isSubmitting && <>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveChanges}</span>
					</>}
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default ProgramForm;
