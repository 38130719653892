import React, {
	useState,
} from 'react';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
	Toast,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// API
import {
	uploadGroupLogo,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';


// LogoForm
function LogoForm({
	lang,
	session,
	initialValues,
}) {

	const text = {...labelsGlobal[lang],...labelsForms[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');


	// Logo upload
	const [logoFileName, setLogoFileName] = useState('…');


	// form schema
	const schema = yup.object().shape({

		groupIdOrgroupUri: yup.string().required(text.required),

	});


	// onSubmit handler
	const onSubmit = (values, formikBag) => {

		const body = new FormData();

		body.append( 'logo', values.logo );

		uploadGroupLogo(values.groupIdOrgroupUri, body, session.access_token).then((response) => {
			setLogoFileName('…');
			setShowToast(true);
			setToastMessage('Logo updated');
			formikBag.setFieldValue('logo', false);
		});

	}

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-5'
				noValidate
				onSubmit={handleSubmit}>

				<div
					className='position-relative'
					aria-live='polite'
					aria-atomic='true'>

					<div
						className='position-absolute d-flex justify-content-center'
						style={{top:'-3.25rem',left:0,right:0}}>

						<Toast
							show={showToast}
							onClose={() => {setShowToast(false);}}
							delay={3000}
							autohide
							className='border-success position-absolute mt-n2'>

							<Toast.Body
								className='text-success text-center py-1'>
								<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
								{toastMessage}
							</Toast.Body>

						</Toast>

					</div>

				</div>

				<Form.Group>

					<div className='form-row'>
						<div className='col-auto'>
							<Form.File 
								type='file'
								name='logo'
								id='logo'
								label={logoFileName}
								onChange={(e) => {
									setLogoFileName(e.currentTarget.files[0].name);
									setFieldValue('logo', e.currentTarget.files[0]);
								}}
								custom
							/>
						</div>
						<div className='col-auto'>
							<button
								disabled={!values.logo}
								type='submit'
								className='btn btn-primary'
							>{`Upload`}</button>
						</div>
					</div>

				</Form.Group>

			</Form>
		)}
		</Formik>
	)

};

export default LogoForm;
