// Constants
import {
	WP_AJAX_URL,
} from '../helpers/Constants';

// apiRequest
export const apiRequest = async (props) => {

	const body = new FormData();

	body.append( 'action', 'iasg_api' );

	for (var prop in props) {
		body.append( prop, props[prop] );
	}

	const response = await fetch( WP_AJAX_URL, {
		method: 'POST',
		body: body,
	} );

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// wpAjax
export const wpAjax = async (props) => {

	const body = new FormData();

	for (var prop in props) {
		body.append( prop, props[prop] );
	}

	const response = await fetch( WP_AJAX_URL, {
		method: 'POST',
		body: body,
	} );

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
