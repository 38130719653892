import React, {
	useEffect,
	useRef,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


// Modal
import {
	Modal,
} from 'react-bootstrap';


// API
import {
	getRequest,
	addToBlend,
	addBlend,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsBlender } from '../../i18n/Blender';


// AddToBlenderModal
function AddToBlenderModal({
	lang='en',
	session,
	programs,
	showModal,
	setShowModal,
	setShowToast,
	setToastMessage,
}) {

	const text = {...labelsGlobal[lang],...labelsBlender[lang]};

	// blends
	const [blends, setBlends] = useState([]);
	const [blendId, setBlendId] = useState('');

	const selectBlend = useRef(null);

	// run our queries
	const [
		{
			data: loadedBlend,
		},
	] = useQueries([
		{
			queryKey: ['currentBlend', session.access_token],
			queryFn: () => getRequest('/blends/getcurrent/minimal', {}, session.access_token),
			enabled: !!session.access_token
		},
		{
			queryKey: ['blends', {}, session.access_token],
			queryFn: () => getRequest('/blends/minimal', {}, session.access_token),
			onSuccess: (response) => {
				setBlends(response.items);
			},
			enabled: !!session.access_token
		},
	]);

	useEffect(() => {
		// focus confirm button on render
		if( selectBlend.current )
			selectBlend.current.focus();
	});

	return (
		<Modal show={showModal} onHide={() => setShowModal(false)} centered>

			<Modal.Body className='p-5'>

				<button
					type='button'
					className='close mt-n4 mr-n4'
					style={{fontSize:'1rem'}}
					onClick={() => setShowModal(false)}>
					<i className='fa-solid fa-times'></i>
				</button>

				<h5 className='mb-4'>{`Add this program to…`}</h5>

				<div className='form-group'>
					<select className='custom-select'
						ref={selectBlend}
						value={blendId}
						onChange={(e) => setBlendId(e.currentTarget.value)}>
						<option value={''}>{`—`}</option>
						<optgroup label={text.selectBlend||'Select…'}>
						{loadedBlend &&
							<option value={loadedBlend.blendId}>{text.currentBlend}</option>
						}
							<option value={'new'}>{`New Blend`}</option>
						</optgroup>
						<optgroup label={'or ' + text.savedBlends + '…'}>
						{!!blends.length && blends.map((blend) =>
							<option key={blend.blendId} value={blend.blendId}>{blend.blendName}</option>
						)}
						</optgroup>
					</select>
				</div>

				{loadedBlend && loadedBlend.blendTypeId === 4 && 'new' === blendId && <>
					<div className='alert alert-warning'>
						<h6 className='d-flex align-items-center pt-2'>
							<i className='fa-solid fa-exclamation-triangle text-warning mr-1' style={{fontSize:'80%'}}></i>
							<b>{text.unsavedChanges}</b>
						</h6>
						<p className='m-0'>
							{text.creatingWillDelete}
						</p>
					</div>
					<a
						href={`/tools/blender`}
						className='btn btn-block btn-outline-primary'>
						{text.viewCurrentWorksheet}
						<i className='fa fa-chevron-right ml-2' style={{fontSize:'80%'}}></i>
					</a>
				</>}

				<button type='button' className='btn btn-primary btn-block' disabled={!blendId} onClick={(e) => {
					e.preventDefault();
					const allocations = programs.map((program) => {return {programId: program.programId}});
					if( 'new' === blendId ) {
						addBlend({
							allocations: allocations
						}, session.access_token).then((response) => {
							setShowModal(false);
							setShowToast(true);
							setToastMessage(text.addedToBlender);
						});
					}
					else {
						addToBlend(blendId, allocations, session.access_token).then((response) => {
							setShowModal(false);
							setShowToast(true);
							setToastMessage(text.addedToBlender);
							blendId === loadedBlend.blendId && localStorage.setItem( 'blendModified', true );
						});
					}
				}}>
					<i className='fa fa-chevron-right pr-2'></i>
					<span>{text.addToBlender}</span>
				</button>

			</Modal.Body>

		</Modal>
	)

}

export default AddToBlenderModal;
