/*
 *
 * @link https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
 *
 */
export default function debounce(fn, ms) {

	let timer;

	return _ => {
		clearTimeout(timer);
		timer = setTimeout(_ => {
			timer = null
			fn.apply(this, arguments)
		}, ms);
	};
};
