// TO-DO? find a better way :/
const platform = window.location.hostname.indexOf('sandbox') !== -1 ? {
	siteTitle: 'IASG',
} : {
	siteTitle: 'IASG',
};

/*
 * Global string translation
 *
 * @link https://translate.google.com/?sl=en&tl=zh-CN&text=Hello%20world!&op=translate
 *
 */
export const labels = {
	'en': {
		siteTitle: platform.siteTitle,
		titleSeparator: ' | ',

		accountLogIn: 'Account Login',
		platform: 'Platform',

		loading: <><i className='fa-solid fa-circle-notch fa-spin text-primary mr-2'></i>{`Loading…`}</>,
		working: <><i className='fa-solid fa-circle-notch fa-spin text-primary mr-2'></i>{`Working…`}</>,
		errorMessage: 'Something went wrong…',
		pageNotFound: 'Page Not Found',

		performanceHigh: 'All Time Performance High',
		performanceLow: 'All Time Performance Low',
		last30Days: 'Added in last 30 days',
		newMaxDD: 'New Max Drawdown',

		criteria: 'Criteria',
		startDate: 'Start Date',
		performanceDate: 'Performance Date',
		programType: 'Program Type',
		compoundedAROR: 'Compounded AROR',
		maxRisk: 'Max Risk',
		AUM: 'Assets Under Management',
		AUMAbbr: 'AUM',
		minimumInvestment: 'Minimum Investment',
		sectorFocus: 'Sector Focus',
		investorRequirements: 'Investor Requirements',
		blendCorrelation: 'Blend Correlation',
		investorStrategy: 'Investment Strategy',
		riskManagement: 'Risk Management',
		decisionMaking: 'Decision Making',
		composition: 'Composition',
		summary: 'Summary',

		compoundedARORTooltip: 'Rate of return which, if compounded over the years covered by the performance history, would yield the cumulative gain or loss actually achieved by the trading program during that period.',
		drawdownReportHeading: 'Drawdown Report',
		drawdownReportTooltip: 'The greatest cumulative percentage decline in net asset value due to losses sustained by the trading program during the year in which the initial net asset value is not equaled or exceeded by a subsequent asset value.',

		page: 'Page',
		of: 'of',
		or: 'or',
		to: 'to',
		rows: 'Rows',
		showAll: 'Show All',
		viewMore: 'View More',
		columns: 'Columns',
		columnOrder: 'Column Order',
		filters: 'Filters',
		relatedArticles: 'Related Articles',

		myPrograms: 'My Programs',
		addProgram: 'Add Program',
		showArchivedPrograms: 'Show Archived Programs',
		hideArchivedPrograms: 'Hide Archived Programs',
		showDeletedPrograms: 'Show Deleted Programs',
		hideDeletedPrograms: 'Hide Deleted Programs',

		addAlert: 'Add Alert',
		addedAlert: 'Alert added',

		myWatchlist: 'My Watchlist',
		addToWatchlist: 'Add to Watchlist',
		addToYourWatchlist: 'Add to your watchlist:',
		addedToWatchlist: 'added to Watchlist',

		myPortfolio: 'My Portfolio',
		addToPortfolio: 'Add to Portfolio',
		addToYourPortfolio: 'Add to your portfolio:',
		addedToPortfolio: 'added to Portfolio',

		savedBlends: 'Saved Blends',
		addToBlender: 'Add to Blender',
		addedToBlender: 'added to Blender',

		freeReport: 'Free Report',
		printPage: 'Print Page',

		savedSearches: 'Saved Searches',

		compareTo: 'Compare To',
		programSearchPlaceholder: 'Enter a CTA or Program Name…',
		selectAnIndex: 'Select an Index…',
		chartType: 'Chart Type',

		yearToDate: 'Year-to-Date',

		monthly: 'Monthly',
		annual: 'Annual',

		performanceDisclaimer: 'Past performance is not necessarily indicative of future results. The risk of loss in trading commodity futures, options, and foreign exchange (“forex”) is substantial.',
		indexesDisclaimer: `${platform.siteTitle} indexes are generated daily based on CTA manager supplied sector focus, investment strategy and performance. To participate in the ${platform.siteTitle} index, a program must have a minimum track record of 3 year’s performance. Other indexes require a minimum two year track record. Strategy based indexes require the program’s composition be more than 50% of the specific strategy. It is not possible for an investor to invest in any of the ${platform.siteTitle} indexes.`,
		statsDisclaimer: 'Note: Figures shown in the Monthly column are the greatest figures (or worst for losses/drawdowns) for any particular month. The Annual figures are the greatest for any calendar year.',

		confirmDelete: 'Are you sure you want to delete this?',
		yes: 'Yes',
		no: 'No',
		cancel: 'Cancel',

		logs: 'Logs',

		unsavedChanges: 'Notice: Unsaved Changes',

		blockerHeading: 'Get the Full Picture',
		blockerContent: 'Register now to access our complete CTA database:',
		blockerBullets: [
			'View Strategy & Composition Breakdowns',
			'Save Watchlists, Portfolios, and Custom Blends',
			'Download Profiles, Documents, and more!',
		],
		blockerCTA: 'Sign Up For Free',
		blockerQuestion: 'Already a member?',
		blockerLogin: 'Log in here.',

		exportData: 'Export Data',
		generatingPDF: 'Generating PDF…',
	},
	'zh-hans': {
		siteTitle: platform.siteTitle,
		titleSeparator: ' | ',

		accountLogIn: '帐号登录',
		platform: '平台',

		loading: <><i className='fa-solid fa-circle-notch fa-spin text-primary mr-2'></i>{`正在加载…`}</>,
		working: <><i className='fa-solid fa-circle-notch fa-spin text-primary mr-2'></i>{`加工…`}</>,
		errorMessage: '出问题了…',
		pageNotFound: '找不到网页',

		performanceHigh: '一直表现高',
		performanceLow: '所有时间性能低',
		last30Days: '在过去 30 天内添加',
		newMaxDD: '新的最大回撤',

		criteria: '标准',
		startDate: '开始日期',
		performanceDate: '演出日期',
		programType: '节目类型',
		compoundedAROR: '复合AROR',
		maxRisk: '最大风险',
		AUM: '管理资产',
		AUMAbbr: 'AUM',
		minimumInvestment: '最低投资',
		sectorFocus: '行业焦点',
		investorRequirements: '投资者要求',
		blendCorrelation: '混合相关',
		investorStrategy: '投资策略',
		riskManagement: '风险管理',
		decisionMaking: '做决定',
		composition: '作品',
		summary: '摘要',

		compoundedARORTooltip: '回报率，如果在业绩历史涵盖的年份复合，将产生该期间交易程序实际实现的累积收益或损失。',
		drawdownReportHeading: '缩编 报告',
		drawdownReportTooltip: '在初始资产净值不等于或不超过后续资产价值的年度内，由于交易程序遭受损失而导致资产净值的最大累计百分比下降。',

		page: '页',
		of: '的',
		or: '或',
		to: '至',
		rows: '行数',
		showAll: '显示所有',
		viewMore: '查看更多',
		columns: '列',
		columnOrder: '列顺序',
		filters: '过滤器',
		relatedArticles: '相关文章',

		myPrograms: '我的节目',
		addProgram: '添加程序',
		showArchivedPrograms: '显示已存档',
		hideArchivedPrograms: '隐藏已存档',
		showDeletedPrograms: '显示已删除的程序',
		hideDeletedPrograms: '隐藏已删除的程序',

		addAlert: '添加警报',
		addedAlert: '添加了警报',

		myWatchlist: '我的关注列表',
		addToWatchlist: '加入观看列表',
		addedToWatchlist: '添加到监视列表',

		myPortfolio: '我的投资组合',
		addToPortfolio: '添加到投资组合',
		addedToPortfolio: '添加到投资组合',

		savedBlends: '保存的混合',
		addToBlender: '添加到搅拌机',
		addedToBlender: '添加到搅拌机',

		freeReport: '免费报告',
		printPage: '打印页面',

		savedSearches: '保存的搜索',

		compareTo: '与之比较',
		programSearchPlaceholder: '输入 CTA 或程序名称…',
		selectAnIndex: '选择索引…',
		chartType: '图表类型',

		yearToDate: '年初至今',

		monthly: '每月',
		annual: '年度的',

		performanceDisclaimer: '过去的表现并不一定表示未来的结果。 商品期货，期权和外汇（“外汇”）交易损失的风险很大。',
		indexesDisclaimer: `${platform.siteTitle}指数是根据CTA经理提供的行业重点，投资策略和绩效每天生成的。 要参与${platform.siteTitle}指数，计划必须至少具有3年的业绩记录。 其他指数需要至少两年的跟踪记录。 基于策略的索引要求程序的组成必须超过特定策略的50％。 投资者无法投资任何${platform.siteTitle}指数。`,
		statsDisclaimer: '注意：每月一栏中显示的数字是任何特定月份的最大数字（或亏损/回撤最差）。 年度数字是任何日历年中最高的。',

		confirmDelete: '你确定要删除这个吗？',
		yes: '是的',
		no: '不',
		cancel: '取消',

		logs: '日志',

		unsavedChanges: '注意：未保存的更改',

		blockerHeading: '获取全貌',
		blockerContent: '立即注册以访问我们完整的 CTA 数据库：',
		blockerBullets: [
			'查看策略和组成细分',
			'保存监视列表、投资组合和自定义混合',
			'下载配置文件、文档等！',
		],
		blockerCTA: '免费注册',
		blockerQuestion: '已经是会员？',
		blockerLogin: '这里登录。',

		exportData: '导出数据',
		generatingPDF: '生成 PDF…',
	}
};
