import React, {
	useState,
} from 'react';


// API
import {
	deleteFromWatchlist,
	setAlert,
} from '../../api/Api';


// partials
import {
	ConfirmModal,
	ProgramsTable,
} from './Partials';


// WatchlistTable
function WatchlistTable({
	lang='en',
	session={},
	columns=[],
	rows=[],
	sortBy='',
	orderBy='',
	toggleSortBy=null,
	setRows=null,
}) {

	// confirmations
	const [program, setProgram] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// functions
	const handleCloseConfirm = () => {
		setProgram(false);
		setShowConfirm(false);
	};

	const handleShowConfirm = (data) => {
		setProgram(data);
		setShowConfirm(true);
	};

	const deleteProgram = () => {

		if( !program ) return;

		deleteFromWatchlist(program.portfolioOrderId, session.access_token).then(() => {

			let updatedRows = rows.filter((row) => {
				return row !== program;
			});

			setRows(updatedRows);
			setShowConfirm(false);

		});

	};

	const toggleEmailAlert = (program) => {

		setAlert(program.portfolioOrderId, !program.alert, session.access_token).then(() => {

			let updatedRows = rows.map((row) => {
				return row === program ? { ...row, alert: !row.alert } : { ...row};
			});

			setRows(updatedRows);

		});

	};

	return (<>
		<ConfirmModal
			lang={lang}
			showConfirm={showConfirm}
			handleCloseConfirm={handleCloseConfirm}
			confirmCallback={deleteProgram}
			/>

		<ProgramsTable
			lang={lang}
			columns={columns}
			rows={rows}
			sortBy={sortBy}
			orderBy={orderBy}
			toggleSortBy={toggleSortBy}
			removeRow={handleShowConfirm}
			setAlert={toggleEmailAlert}
			/>
	</>)


};

export default WatchlistTable;
