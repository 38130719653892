// Constants
import {
	API_URL,
} from '../helpers/Constants';

import {
	addBlend,
	saveBlend,
	renameBlend,
	copyBlend,
	deleteBlend,
	addToBlend,
	deleteFromBlend,
	patchOrder,
	setBlendNormalized,
} from './Blends';

import {
	postGroup,
	patchGroup,
	updateGroup,
	uploadGroupLogo,
	deleteGroup,
	putGroupAdministrators,
	deleteGroupAdministrators,
	addGroupToPlatform,
	deleteGroupFromPlatform,
} from './Groups';

import {
	addProgram,
	patchProgram,
	updateProgram,
	updateMonthlyReturns,
	uploadMonthlyReturns,

	deleteProgram,

	postSavedSearches,
	deleteSavedSearches,

	addProgramToPlatform,
	deleteProgramFromPlatform,
} from './Programs';

import {
	UserContext,
	authenticate,
	updateUser,
	deleteAccount,
	editPassword,
	mailchimpApiRequest,
	transactionalEmailRequest,
	putRoles,
	deleteRoles,
} from './UserAccounts';

import {
	setAlert,
	addToWatchlist,
	deleteFromWatchlist,
} from './Watchlists';

import {
	addToPortfolio,
	deleteFromPortfolio,
	patchPortfolio,
} from './Portfolios';

import {
	apiRequest,
	wpAjax,
} from './WordPress';

// export
export {
	addBlend,
	saveBlend,
	renameBlend,
	copyBlend,
	deleteBlend,
	addToBlend,
	deleteFromBlend,
	patchOrder,
	setBlendNormalized,

	postGroup,
	patchGroup,
	updateGroup,
	uploadGroupLogo,
	deleteGroup,
	putGroupAdministrators,
	deleteGroupAdministrators,
	addGroupToPlatform,
	deleteGroupFromPlatform,

	addProgram,
	patchProgram,
	updateProgram,
	updateMonthlyReturns,
	uploadMonthlyReturns,

	deleteProgram,

	postSavedSearches,
	deleteSavedSearches,

	addProgramToPlatform,
	deleteProgramFromPlatform,

	UserContext,
	authenticate,
	updateUser,
	deleteAccount,
	editPassword,
	mailchimpApiRequest,
	transactionalEmailRequest,
	putRoles,
	deleteRoles,

	setAlert,
	addToWatchlist,
	deleteFromWatchlist,

	addToPortfolio,
	deleteFromPortfolio,
	patchPortfolio,

	apiRequest,
	wpAjax,
};

// getRequest
export const getRequest = async (path='',queryParams={},access_token='',headers={}) => {

	let queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

	const response = await fetch(`${API_URL}${path}?${queryString}`, {
		headers: access_token ? {
			...headers,
			'Authorization': 'Bearer ' + access_token,
		} : headers
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error(response.status)
	}

	return response.json();

};
