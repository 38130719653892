import React, {
	useState,
} from 'react';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	A,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 */
import {
	DropdownButton,
	OverlayTrigger,
	Popover,
} from 'react-bootstrap';


/*
 * React-DatePicker
 *
 * @link https://reactdatepicker.com/#example-range-month-picker
 * @link https://github.com/Hacker0x01/react-datepicker
 *
 */
import DatePicker from 'react-datepicker';


// Constants
import {
	BASE_URL,
	WP_BASE_URL,
} from './Constants';


// helpers
import {
	getSymbolFromCurrency,
	isArchived,
	isDeleted,
	isPending,
	isClosed,
	isMaxDD,
	isNew,
	status,
	dateFormat,
	filterLabels,
	MoneyFormat,
	NumberFormat,
} from './Helpers';


// i18n
import { labels } from '../i18n/Tables';
import { labels as abbr } from '../i18n/Abbr';


// HeaderHelpText
const HeaderHelpText = (label = '', lang = 'en') => {

	const tooltips = {...abbr[lang]};

	const tooltip = tooltips[label] || false;

	if( tooltip ) {

		return <abbr title={tooltip}>
			<OverlayTrigger
				placement={'auto'}
				overlay={
					<Popover>
						<Popover.Content>
							{tooltip}
						</Popover.Content>
					</Popover>
				}>
				<span>{label}</span>
			</OverlayTrigger>
		</abbr>;

	}

	return label;

};


// HeaderClass
export const HeaderClass = (props) => {

	let classes = [];

	// default classes based on type
	switch(props.column.type) {

		case 'action':
		case 'actions':
		case 'activate':
		case 'status':

			classes.push('text-center');

		break;

		case 'currency':
		case 'float':
		case 'int':
		case 'percent':

			classes.push('text-right');

		break;

		case 'checkbox':

			classes.push('text-nowrap');

		break;

		default:
	}

	// classes based on field
	switch(props.column.field) {

		case 'alert':
		case 'chartToggle':
		case 'correlations':
		case 'programId':

			classes.push('text-center');

		break;

		case 'color':
		case 'past12Months':

			classes.push('text-nowrap');

		break;

		default:
	}

	if( props.column.className ) {

		classes.push( props.column.className );

	}

	return classes.join(' ');

};


// HeaderValue
export const HeaderValue = (props) => {

	const text = {...labels[props.lang]};

	let label = text[props.column.field] || props.column.headerName;

	label = HeaderHelpText(label, props.lang);

	switch(props.column.sortable) {

		case undefined:
		case false:

			return (
				<div>
					<span>{label}</span>
				</div>
			);

		default:

			let field = '';

			switch( props.column.field ) {
				case 'programEdit':
					field = 'programName';
				break;
				case 'groupProgram':
					field = 'groupName';
				break;
				default:
					field = props.column.field;
			}

			if( 'function' === typeof props.toggleSortBy ) {

				if( field === props.sortBy ) {

					return (
						<div style={{cursor:'pointer'}} onClick={(e) => {e.preventDefault();props.toggleSortBy(field);}}>
							<span>{label}</span>
							<i className={ 'DESC' === props.orderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
						</div>
					);

				}
				else {

					return (
						<div style={{cursor:'pointer'}} onClick={(e) => {e.preventDefault();props.toggleSortBy(field);}}>
							<span>{label}</span>
						</div>
					);

				}

			}
			else {
				return (
					<div>
						<span>{label}</span>
					</div>
				);
			}

	}

}


// CellClass
export const CellClass = (props) => {

	const value = props.row[props.column.field];

	let classes = [];

	// default classes based on type
	switch(props.column.type) {

		case 'action':
		case 'actions':
		case 'activate':
		case 'status':

			classes.push('text-center');

		break;

		case 'currency':
		case 'float':
		case 'int':
		case 'percent':

			classes.push('text-right');

		break;

		default:
	}

	// custom formatting
	switch(props.column.format) {

		case 'onlyMinus':

			classes.push('text-nowrap');
			classes.push( value === 0 ? '' : (value > 0 ? '' : 'text-danger') );

		break;

		case 'plusMinus':

			classes.push('text-nowrap');
			classes.push( value === 0 ? '' : (value > 0 ? 'text-success' : 'text-danger') );

		break;

		default:
	}

	switch(props.column.field) {

		case 'alert':
		case 'chartToggle':
		case 'programId':

			classes.push('text-center');

		break;

		case 'past12Months':

			classes.push('text-nowrap');

		break;

		default:
	}

	if( props.column.className ) {

		classes.push( props.column.className );

	}

	return classes.join(' ');

};


// CellValue
export const CellValue = (props) => {

	const text = {...labels[props.lang]};

	const value = props.row[props.column.field];
	let programId, portfolioId, groupUri, programUri, portfolioUri;

	const target = props.column.target || '_self';

	const toCurrency = (number) => {

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'decimal',
			currency: 'USD'
		});

		return formatter.format(number);

	};

	switch(props.column.field) {

		case 'action':

			programId = props.row.programId.toLowerCase();
			groupUri = props.row.groupUri.toLowerCase();
			programUri = props.row.programUri.toLowerCase();

			return (
				<div className='d-flex justify-content-end'>
					<DropdownButton
						onToggle={(isOpen,event) => {
							if( event.currentTarget.closest ) {
								isOpen ? event.currentTarget.closest('.table-responsive').classList.add('overflow-visible') : event.currentTarget.closest('.table-responsive').classList.remove('overflow-visible');
							}
						}}
						hidden={true}
						size='sm'
						id={`dropdown-button-drop-left`}
						drop={'left'}
						variant="outline-dark"
						title={`Badge Code`}
					>
						<div className='px-3 py-1 d-flex' style={{width:'480px',whiteSpace:'normal'}}>
							<img src={`${BASE_URL}/assets/images/badge/programId/${programId}`} alt="IASG Top Performer" width="160" height="305"/>
							<div className='ml-2' style={{maxWidth:'280px'}}>
								<code
									className='d-block p-3'
									style={{background:'#efefef',fontSize:'.75rem',color:'#333333'}}>{`<script src="${WP_BASE_URL}/badges/p/${programId}"></script>`}
								</code>
							</div>
						</div>
					</DropdownButton>
					<a className='btn btn-sm btn-link ml-1' href={`/groups/${groupUri}/programs/${programUri}/edit`}>
						<i className='fa-solid fa-edit mr-1' style={{fontSize:'80%'}}></i>
						{`Edit Program`}
					</a>
					<a className='btn btn-sm btn-link ml-1' href={`/groups/${groupUri}/programs/${programUri}/performance`}>
						<i className='fa-solid fa-edit mr-1' style={{fontSize:'80%'}}></i>
						{`Edit Performance`}
					</a>
				</div>
			);

		case 'actions':

			return (
				<div className='d-flex justify-content-center'>
				{'function' === typeof props.copyBlend &&
					<button className='btn btn-link btn-sm' title={text.copyBlend}
						data-blend={props.row.blendId}
						onClick={(e) => {props.copyBlend(props.row);e.currentTarget.blur();}}>
						<i className='fa-solid fa-fw fa-copy'></i>
						<i className='fa-solid fa-fw fa-circle-notch fa-spin' hidden></i>
					</button>
				}
					<button className='btn btn-link py-0 text-danger'
						onClick={(e) => {props.removeRow(props.row);}}>
						<i className='fa-solid fa-times-circle'></i>
					</button>
				</div>
			);

		case 'activate':

			return (
				<div className='d-flex justify-content-center'>
					<button className='btn btn-light btn-sm py-0 px-2'
						onClick={(e) => {props.activate(props.row);}}>
						{`activate`}
					</button>
				</div>
			);

		case 'alert':

			return (
				<div className='custom-control custom-checkbox'>
					<input type='checkbox' className='custom-control-input' id={`program-${props.row.programId}`}
						checked={props.row.alert}
						onChange={(e) => {props.setAlert(props.row);}}/>
					<label className='custom-control-label' htmlFor={`program-${props.row.programId}`}></label>
				</div>
			);

		case 'badge':

			programId = props.row.programId.toLowerCase();
			portfolioId = props.row.portfolioId.toLowerCase();

			return (
				<div className='d-flex justify-content-end'>
					<DropdownButton
						onToggle={(isOpen,event) => {
							if( event.currentTarget.closest ) {
								isOpen ? event.currentTarget.closest('.table-responsive').classList.add('overflow-visible') : event.currentTarget.closest('.table-responsive').classList.remove('overflow-visible');
							}
						}}
						size='sm'
						id={`dropdown-button-drop-left`}
						drop={'left'}
						variant="outline-dark"
						title={`Badge Code`}
					>
						<div className='px-3 py-1 d-flex' style={{width:'480px',whiteSpace:'normal'}}>
							<img src={`${BASE_URL}/assets/images/badge/programId/${programId}/portfolioId/${portfolioId}/awardTypeId/${props.row.awardTypeId}/dateend/${props.row.dateEnd}`} alt="IASG Top Performer" width="160" height="305"/>
							<div className='ml-2' style={{maxWidth:'280px'}}>
								<code
									className='d-block p-3'
									style={{background:'#efefef',fontSize:'.75rem',color:'#333333'}}>{`<script src="${WP_BASE_URL}/badges/p/${programId}/p/${portfolioId}/a/${props.row.awardTypeId}/d/${props.row.dateEnd}"></script>`}
								</code>
							</div>
						</div>
					</DropdownButton>
				</div>
			);

		case 'blendName':

			return (
				<div>
					<button type='button' className='btn btn-link btn-sm btn-block text-left' onClick={(e) => {props.setCurrentBlend(props.row);e.currentTarget.blur();}}>
						{props.row.blendName.trim()}
					</button>
				</div>
			);

		case 'chartToggle':

			return (
				<div className='custom-control custom-checkbox'>
					<input type='checkbox' className='custom-control-input' id={`program-${props.row.programId}`}
						checked={props.row.inComparisonCharts}
						onChange={(e) => {props.toggleComparisonChart(props.row);}}
						/>
					<label className='custom-control-label' htmlFor={`program-${props.row.programId}`}></label>
				</div>
			);

		case 'color':

			return (
				<div className='shadow shadow-sm' style={{backgroundColor:value,width:'2rem',height:'2rem'}}></div>
			);

		case 'filtersObject':

			let filters = JSON.parse(value);
			let queryStringFilters = JSON.parse(value);
			Object.keys(queryStringFilters).forEach(function(key, index) {
				let values = queryStringFilters[key].split(`&${key}=`);
				queryStringFilters[key] = values.join(',');
			});
			let queryString = (new URLSearchParams(queryStringFilters)).toString();

			return (
				<div>
					<a className='d-block' href={`/managed-futures/performance?${queryString}`}>
						{filterLabels(filters, props.blends, props.lang)}
					</a>
				</div>
			)

		case 'programId':

			if( props.headerOnly ) return (<></>);

			return (
				<div className='custom-control custom-checkbox'>
					<input type='checkbox' className='custom-control-input' id={`program-${value}`}
						checked={props.row.selected}
						onChange={(e) => {props.selectRow(props.row);}}/>
					<label className='custom-control-label' htmlFor={`program-${value}`}></label>
				</div>
			);


		case 'groupName':

			groupUri = props.row.groupUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block' href={`/groups/${groupUri}`}>
						{props.row.groupName}
					</A>
				</div>
			);

		case 'groupProgram':

			groupUri = props.row.groupUri.toLowerCase();
			programUri = props.row.programUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block font-weight-bold' href={`/groups/${groupUri}`}>
						{props.row.groupName}
					</A>
					<A target={target} className='d-block' href={`/groups/${groupUri}/programs/${programUri}`}>
						{props.row.programName.trim()}
						{isArchived(props.row.programStatusId, text.isArchived)}
						{isDeleted(props.row.programStatusId, text.isDeleted)}
						{isMaxDD(props.row.isMaxDD)}
						{!isPending(props.row.programStatusId) && isNew(props.row.isNew)}
						{isClosed(props.row.membershipStatusId, text.isClosed)}
					</A>
				</div>
			);

		case 'portfolioName':

			portfolioUri = props.row.portfolioUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block font-weight-bold' href={`/indexes/${portfolioUri}`}>
						{props.row.portfolioName.trim()}
					</A>
				</div>
			);

		case 'portfolioNameRanked':

			let dateEnd = dateFormat(new Date(props.row.dateEnd), 'yyyy-MM-dd');
			portfolioUri = props.row.portfolioUri.toLowerCase();

			return (
				<div>
					<a target={target} className='d-block font-weight-bold' href={`/indexes/${portfolioUri}/rankings?filterByDateEnd=${dateEnd}`}>
						{props.row.portfolioName.trim()}
					</a>
				</div>
			);

		case 'programEdit':

			groupUri = props.row.groupUri.toLowerCase();
			programUri = props.row.programUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block' href={`/groups/${groupUri}/programs/${programUri}/edit`}>
						{props.row.programName.trim()}
						{isArchived(props.row.programStatusId, text.isArchived)}
						{isDeleted(props.row.programStatusId, text.isDeleted)}
						{isMaxDD(props.row.isMaxDD)}
						{!isPending(props.row.programStatusId) && isNew(props.row.isNew)}
						{isClosed(props.row.membershipStatusId, text.isClosed)}
						<i className='fa-solid fa-edit text-success ml-1' style={{fontSize:'90%'}}></i>
					</A>
				</div>
			);

		case 'programName':

			groupUri = props.row.groupUri.toLowerCase();
			programUri = props.row.programUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block' href={`/groups/${groupUri}/programs/${programUri}`}>
						{props.row.programName.trim()}
						{isArchived(props.row.programStatusId, text.isArchived)}
						{isDeleted(props.row.programStatusId, text.isDeleted)}
						{isMaxDD(props.row.isMaxDD)}
						{!isPending(props.row.programStatusId) && isNew(props.row.isNew)}
						{isClosed(props.row.membershipStatusId, text.isClosed)}
					</A>
				</div>
			);

		case 'past12Months':

			switch( props.chartKey ){

				case 'blendId':

					return (
						<img src={`${BASE_URL}/assets/images/graphs/${props.row.blendId}_mg.png`} alt='chart' width='96' height='42' style={{width:'96px',height:'42px',mixBlendMode:'multiply'}}/>
					);

				case 'portfolioId':

					return (
						<img src={`${BASE_URL}/assets/images/graphs/${props.row.portfolioId}_mg.png`} alt='chart' width='96' height='42' style={{width:'96px',height:'42px',mixBlendMode:'multiply'}}/>
					);

				default:

					if( isArchived(props.row.programStatusId) ) return '';

					return (
						<img src={`${BASE_URL}/assets/images/graphs/${props.row.programId}_mg.png`} alt='chart' width='96' height='42' style={{width:'96px',height:'42px',mixBlendMode:'multiply'}}/>
					);
			}

		case 'monthReturn':

			if( value === null || ( !props.row.dateReturn && !props.alwaysDisplayReturns ) ) {

				return '';

			}
			else if( !props.row.isEquityMax && !props.row.isEquityLow ) {

				return (
					<div>
						{value.toFixed(2)}
					</div>
				);

			}
			else {

				return (
					<div>
						<i className={props.row.isEquityMax ? 'fas fa-caret-up fa-lg pr-1' : 'fas fa-caret-down fa-lg pr-1'} hidden={!value}></i>
						{value.toFixed(2)}
					</div>
				);

			}

		case 'maxDrawDown':

			return (
				<div>-{(value * 100).toFixed(2)}</div>
			);

		case 'asset':

			portfolioUri = props.row.portfolioUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block' href={`/indexes/${portfolioUri}/programs`}>
						{value}
					</A>
				</div>
			);

		case 'assets':

			if( value === null ) return '—';

			return (
				<div>{getSymbolFromCurrency(props.row.currency)}{MoneyFormat(value || 0)}</div>
			);

		case 'investmentMin':

			let investmentMin = (value / 1000);

			return (
				<NumberFormat value={(investmentMin > 1 ? investmentMin : 0)}
					displayType={'text'}
					thousandSeparator={true}
					renderText={(value, props) => <div>{value}k</div>}/>
			);

		case 'period':

			switch( props.row.awardName.toLowerCase() ) {

				case 'annual':

					return dateFormat(new Date(props.row.dateEnd), 'yyyy');

				case 'month':

					return dateFormat(new Date(props.row.dateStart), 'M/yyyy');

				default:

					return (
						<>
							<span>{dateFormat(new Date(props.row.dateStart), 'yyyy')}</span>
							<span>–</span>
							<span>{dateFormat(new Date(props.row.dateEnd), 'yyyy')}</span>
						</>
					);

			}

		case 'status':

			return (
				<div>
					{status(props.row.programStatusId)}
				</div>
			);

		case 'currentValue':

			let currentValue = props.row.quantity * (1 + props.row.periodReturn);
			let currencySymbol = getSymbolFromCurrency(props.row.currency);

			return (
				<NumberFormat value={currentValue}
					displayType={'text'}
					thousandSeparator={true}
					decimalScale={0}
					renderText={(value, props) => <div>{currencySymbol}{value}</div>}/>
			);

		case 'visits':

			groupUri = props.row.groupUri.toLowerCase();
			programUri = props.row.programUri.toLowerCase();

			return (
				<div>
					<A target={target} className='d-block' href={`/groups/${groupUri}/programs/${programUri}/users`}>
						{value}
					</A>
				</div>
			);

		default:

			switch(props.column.type) {

				case 'currency':

					if( props.column.editable && !props.readOnly ) {

						switch(props.column.field) {

							case 'quantity':

								return props.isEditing ? (
									<div className='d-flex align-items-center justify-content-end'>
										<div className='input-group input-group-sm' style={{width:'130px'}}>
											<div className='input-group-prepend'>
												<span className='input-group-text'>$</span>
											</div>
											<input
												type='number'
												min={props.row.investmentMin}
												className='form-control text-right'
												value={value}
												onChange={(e) => {
													props.updateProperty(props.row, 'quantity', e.currentTarget.value);
												}}
												onBlur={(e) => {
													props.setIsEditing(false);
													props.updatePropertyCallback(props.row, 'quantity', e.currentTarget.value);
												}}
												aria-label={props.column.headerName}/>
										</div>
									</div>
								) : (
									<div className='d-flex align-items-center justify-content-end'>
										<div className='input-group input-group-sm' style={{width:'130px'}}>
											<div className='input-group-prepend'>
												<span className='input-group-text'>$</span>
											</div>
											<input
												readOnly
												type='text'
												className='form-control text-right bg-white'
												value={toCurrency(value)}
												onFocus={(e) => {props.setIsEditing(true);}}
												aria-label={props.column.headerName}/>
										</div>
									</div>
								);

							default:

								return value;

						}

					}
					else {

						let currencySymbol = getSymbolFromCurrency(props.row.currency);

						return (
							<NumberFormat value={value}
								displayType={'text'}
								thousandSeparator={true}
								decimalScale={0}
								renderText={(value, props) => <div>{currencySymbol}{value}</div>}/>
						);

					}

				case 'date':

					if( props.column.editable ) {

						return (
							<div className='d-flex align-items-center justify-content-start' style={{width:'80px'}}>
								<DatePicker
									selected={new Date(value)}
									dateFormat='M/d/yyyy'
									onChange={(date) => {
										props.updateProperty(props.row, 'dateAllocation', date);
										props.updatePropertyCallback(props.row, 'dateAllocation', date);
									}}
									showMonthDropdown
									showYearDropdown
									minDate={new Date(props.row.dateInception)}
									maxDate={new Date()}
									dropdownMode='select'
									className='form-control form-control-sm'
								/>
							</div>
						);

					}
					else if( value.indexOf( '0001-01-01' ) === 0 ) {

						return '—';

					}
					else {

						let format = props.column.dateFormat ? props.column.dateFormat : 'M/d/yyyy';

						return value ? dateFormat(new Date(value), format) : '';

					}

				case 'percent':

					let precision = ( 'number' === typeof props.column.precision ) ? props.column.precision : 2;
					let show_sign = props.column.show_sign === true ? '%' : '';

					return value ? (value * 100).toFixed(precision) + show_sign : '—';

				case 'float':

					if( props.column.editable && !props.readOnly ) {

						switch(props.column.field) {

							case 'leverage':

								return (<>
									<select className='custom-select custom-select-sm d-print-none'
										value={value}
										onChange={(e) => {
											props.updateProperty(props.row, 'leverage', e.currentTarget.value);
											props.updatePropertyCallback(props.row, 'leverage', e.currentTarget.value);
										}}
										style={{width:'auto'}}>
									{[1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10].map(v =>
										<option key={v} value={v}>{v}</option>
									)}
									</select>
									<div className='d-none d-print-block'>{value}</div>
								</>);

							default:

								return value;

						}

					}
					else {

						return (
							<NumberFormat value={value}
								displayType={'text'}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						);

					}

				case 'int':

					return (value ? value : '—');

				default:

					return value;

			}

	}

};


// TableHeader
export const TableHeader = (props) => {

	switch(props.column.type) {

		case 'checkbox':

			return (
				<th data-field={props.column.field} className={HeaderClass(props)}>
				{!!props.column.selectAll &&
					<div className='custom-control custom-checkbox'>
						<input type='checkbox' className='custom-control-input' id='toggleAllPrograms' defaultChecked={false} onChange={(e) => {props.selectAll(e.currentTarget.checked);}}/>
						<label className='custom-control-label' htmlFor='toggleAllPrograms'></label>
					</div>
				}
				{!props.column.selectAll && HeaderValue(props)}
				</th>
			);

		default:

			return (
				<th data-field={props.column.field} className={HeaderClass(props)} colSpan={props.column.colSpan} style={{width: props.column.width ? props.column.width : 'auto' }}>
					{HeaderValue(props)}
				</th>
			);
	}

};


// TableCell
export const TableCell = (props) => {

	const [isEditing,setIsEditing] = useState(false);

	if( props.column.colSpan && props.column.colSpan > 0 ) {
		return (<>
			{props.row[props.column.field].map((column,i) => 
				<TableCell
					key={i}
					column={{
						field: props.column.subField,
						type: props.column.subType,
					}}
					row={column}
					/>
			)}
		</>)
	}
	else {
		return (
			<td data-field={props.column.field} className={CellClass(props)}>
				{CellValue({...props, isEditing: isEditing, setIsEditing: setIsEditing})}
			</td>
		)
	}

};
