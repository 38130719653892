import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
//	navigate,
} from 'hookrouter';


// Constants
import {
	YEARS,
	MONTHS,
} from '../helpers/Constants';


// API
import {
	getRequest,
} from '../api/Api';


// partials
import {
	Loader,
	ProgramsTable,
} from './partials/Partials';


// helpers
import {
	dateFormat,
	subMonths,
} from '../helpers/Helpers';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';


// AwardList
function AwardList({
	lang,
	portfolioUri,
}) {

	// text labels
	const text = {...labelsGlobal[lang]};

	// queryParams
	const [queryParams, setQueryParams] = useQueryParams();

	// Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		filterByDateEnd = '',
		sortBy = 'performance',
		orderBy = 'DESC',
	} = queryParams;

	// data
	const [columns] = useState([
		{
			field: 'rank',
			headerName: 'Rank',
			type: 'int',
			sortable: true,
		},
		{
			field: 'groupProgram',
			headerName: 'CTA / Program',
			sortable: true,
		},
		{
			field: 'performance',
			headerName: 'Performance',
			type: 'float',
			format: 'plusMinus',
			sortable: true,
		},
		{
			field: 'investmentMin',
			headerName: 'Min Inv',
			type: 'float',
			sortable: true,
		},
		{
			field: 'dateInception',
			headerName: 'Inception',
			type: 'date',
			sortable: true,
		},
	]);

	const [rows, setRows] = useState([]);

	const [years] = useState(YEARS.slice());
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);

	// run our queries
	const [
		{
			isLoading: indexLoading,
			data: index,
		},
		{
			isLoading: awardsLoading,
		},
	] = useQueries([
		{
			queryKey: ['index', {portfolioUri: portfolioUri}],
			queryFn: () => getRequest(`/indexes/${portfolioUri}`),
			onSuccess: (response) => {
			},
		},
		{
			queryKey: ['awards', portfolioUri, queryParams],
			queryFn: () => getRequest(`/awardList`, {
				...queryParams,
				filterByPortfolioUri: portfolioUri,
				filterByAwardTypeId: 1,
				PageSize: 500,
			}),
			onSuccess: (response) => {

				setRows(response.items);

			}
		}
	]);


	// functions
	const toggleSortBy = (field) => {

		let params = {};

		if( field === sortBy ) {
			params.orderBy = 'DESC' === orderBy ? 'ASC' : 'DESC';
		}

		setQueryParams({sortBy:field,...params});

	};


	// handleFilterByDateEndChange
	const handleFilterByDateEndChange = ({m,y}) => {
		if( m ) {
			setMonth(m);
			setQueryParams({
				...queryParams,
				filterByDateEnd: year + '-' + m + '-01',
			});
		}
		if( y ) {
			setYear(y);
			setQueryParams({
				...queryParams,
				filterByDateEnd: y + '-' + month + '-01',
			});
		}
		
	};


	// set an initial filterByDateEnd
	useEffect(() => {

		if(!filterByDateEnd) {

			setQueryParams({
				...queryParams,
				filterByDateEnd: dateFormat(subMonths(new Date(), 3), 'yyyy-MM-01'),
			});

		}

	});


	// set an initial month/year
	useEffect(() => {
		if(filterByDateEnd) {
			setMonth(filterByDateEnd.substr(5, 2));
			setYear(filterByDateEnd.substr(0, 4));
		}
	}, [
		filterByDateEnd,
		setMonth,
		setYear,
	]);


	// set page title and body class
	useEffect(() => {
		document.title = [ (index && year && month) ? (`${index.portfolioName} for ` + dateFormat(new Date(year + '-' + month + '-15'), 'MMMM yyyy')) : 'loading…', text.siteTitle].join(text.titleSeparator);
		document.body.classList.remove('overflow-hidden');
	});


	const isLoading = indexLoading || awardsLoading;

	if (isLoading) {
		return (
			<Loader/>
		)
	}

	return (
		<div className={'container'}>

			<div className='row'>

				<div className='col-12'>

					<h6 className='mb-0'>
						{`Monthly Rankings`}
					</h6>

					<h1 className='mb-4'>
						{index.portfolioName}
					{year && month &&
						<> {`for`} {dateFormat(new Date(year + '-' + month + '-15'), 'MMMM yyyy')}</>
					}
					</h1>

					<div className='d-flex align-items-center justify-content-end mb-4'>
						<div className='mr-2'>
							<>{`Show ranking for:`}</>
						</div>
						<div className='mr-2'>
							<select className='custom-select'
								value={month}
								onChange={(e) => handleFilterByDateEndChange({m:e.currentTarget.value})}>
							{MONTHS.map((month) =>
								<option key={month.label} value={month.month}>{month.label}</option>
							)}
							</select>
						</div>
						<div>
							<select className='custom-select'
								value={year}
								onChange={(e) => handleFilterByDateEndChange({y:e.currentTarget.value})}>
							{years.map((year) =>
								<option key={year} value={year}>{year}</option>
							)}
							</select>
						</div>
					</div>

					<ProgramsTable
						lang={lang}
						columns={columns}
						rows={rows}
						sortBy={sortBy}
						orderBy={orderBy}
						toggleSortBy={toggleSortBy}
						/>

				</div>

			</div>

		</div>
	)

}

export default AwardList;
