import React, {
	useEffect,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useMutation,
	useQueries,
	useQueryClient,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	navigate,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/tabs/
 *
 */
import {
	Modal,
	Tabs,
	Tab,
	Toast,
} from 'react-bootstrap';


// Constants
import {
	GROUP_ACTIVE_STATUS_ID,
	GROUP_PENDING_STATUS_ID,
	PROGRAM_ALLCTA_STATUS_ID,
	PROGRAM_PENDING_STATUS_ID,
	PROGRAM_METHODOLOGY_TYPES,
	PROGRAM_COMPOSITION_TYPES,
	PROGRAM_HOLDING_TYPES,
	MANAGED_ACCOUNT_TYPE_ID,
	PROGRAM_OPEN_STATUS_ID,
	WP_BASE_URL,
	WATCHLIST_COLUMNS,
	PORTFOLIO_COLUMNS,
	SAVED_BLENDS_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
	deleteProgram,
	patchPortfolio,
	wpAjax,
	deleteSavedSearches,
} from '../api/Api';


// helpers
import {
	addWaterMark,
	parseDate,
	dateFormat,
	subMonths,
	getColumnChartOptions,
	Highcharts,
	HighchartsReact,
} from '../helpers/Helpers';


// forms
import {
	ProgramForm,
} from './forms/Forms';


// partials
import {
	Loader,
	ConfirmModal,
	ProgramsTable,
	WatchlistTable,
	PortfolioTable,
	BlendsTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsBlender } from '../i18n/Blender';
import { labels as labelsDashboard } from '../i18n/Dashboard';
import { labels as labelsForms } from '../i18n/Forms';


// Dashboard
function Dashboard({
	lang,
	session,
	tab,
}) {

	const text = {...labelsGlobal[lang],...labelsBlender[lang],...labelsDashboard[lang],...labelsForms[lang]};

	// data
	const [programs,setPrograms] = useState([]);
	const [watchlistsColumns] = useState(WATCHLIST_COLUMNS);
	const [watchlistsRows,setWatchlistsRows] = useState([]);
	const [watchlistsOrderBy,setWatchlistsOrderBy] = useState('ASC');
	const [watchlistsSortBy,setWatchlistsSortBy] = useState('groupName');

	const [portfoliosColumns] = useState(PORTFOLIO_COLUMNS);
	const [portfoliosRows,setPortfoliosRows] = useState([]);

	const [blendsColumns] = useState(SAVED_BLENDS_COLUMNS);
	const [blendsRows,setBlendsRows] = useState([]);
	const [blendsOrderBy,setBlendsOrderBy] = useState('DESC');
	const [blendsSortBy,setBlendsSortBy] = useState('dateCreated');
	const [currentBlend, setCurrentBlend] = useState(false);
	
	// mode
	const [mode, setMode] = useState(null);

	// programs
	const [programsQueryKey, setProgramsQueryKey] = useState( (new Date()).getTime() );
	const [program, setProgram] = useState(false);
	const [showDeleteProgramConfirm, setShowDeleteProgramConfirm] = useState(false);
	const handleCloseConfirm = () => {
		setProgram(false);
		setShowDeleteProgramConfirm(false);
	};
	const handleShowConfirm = (data) => {
		setProgram(data);
		setShowDeleteProgramConfirm(true);
	};
	const _delete = () => {

		if( !program ) return;

		deleteProgram(program.programId, session.access_token).then(() => {

			setPrograms(programs.filter((p) => {
				return p !== program;
			}));

			setShowDeleteProgramConfirm(false);

		});

	};

	// notifications
	const [showToast, setShowToast] = useState(false);
	const [toastDelay, setToastDelay] = useState(3000);
	const [toastMessage, setToastMessage] = useState('');

	// blendToLoad
	const [blendtoLoad, setBlendToLoad] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// posts
	const [recentPosts,setRecentPosts] = useState([]);

	// savedSearches
	const [savedSearches,setSavedSearches] = useState([]);

	// stats
	const [returnStatistics,setReturnStatistics] = useState(null);
	const [lastMonth,setLastMonth] = useState(null);
	const [portfolioStatistics,setPortfolioStatistics] = useState(null);
	const [miscStatistics,setMiscStatistics] = useState(null);

	// charts
	const [distributionChartOptions,setDistributionChartOptions] = useState({});

	// functions
	const watchlistsToggleSortBy = (field) => {

		let updatedRows = ('DESC' === watchlistsOrderBy) ? watchlistsRows.sort((a,b) => {
			return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
		}) : watchlistsRows.sort((a,b) => {
			return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
		});

		setWatchlistsRows(updatedRows);
		setWatchlistsSortBy(field);
		setWatchlistsOrderBy('DESC' === watchlistsOrderBy ? 'ASC' : 'DESC');

	};


	// run our queries
	const queryClient = useQueryClient();

	const [
		{
			isLoading: programsLoading,
		},
		{
			isLoading: watchlistsLoading,
			isError,
		},
		{
			isLoading: portfoliosLoading,
		},
		{
			isLoading: savedSearchesLoading,
		},
		{
			isLoading: blendsLoading,
		},
		{
			data: loadedBlend,
		},
		{
			isLoading: postsLoading,
		},
	] = useQueries([
		{
			queryKey: ['programs', {}, programsQueryKey],
			queryFn: () => getRequest('/programsListings', {
				filterByGroupIds: session.Groups.split(',').join('&filterByGroupIds='),
				filterByGroupStatusIds: [GROUP_ACTIVE_STATUS_ID, GROUP_PENDING_STATUS_ID].join('&filterByGroupStatusIds='),
				filterByProgramStatusIds: [PROGRAM_ALLCTA_STATUS_ID].join('&filterByProgramStatusIds='),
			}, session.access_token),
			onSuccess: (response) => {
				setPrograms(response.items);
			},
			enabled: session.Groups !== ''
		},
		{
			queryKey: ['watchlists', {
				pageSize: 100,
			}, session.access_token],
			queryFn: () => getRequest('/watchlists', {
				pageSize: 100,
			}, session.access_token),
			onSuccess: (response) => {
				// setRows
				let updatedRows = response.items.map(row => {
					return { ...row };
				});
				('ASC' === watchlistsOrderBy) ? updatedRows.sort((a,b) => {
					return a[watchlistsSortBy] < b[watchlistsSortBy] ? -1 : ( a[watchlistsSortBy] > b[watchlistsSortBy] ? 1 : (0) )
				}) : updatedRows.sort((a,b) => {
					return b[watchlistsSortBy] < a[watchlistsSortBy] ? -1 : ( b[watchlistsSortBy] > a[watchlistsSortBy] ? 1 : (0) )
				});
				setWatchlistsRows(updatedRows);
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['portfolios', {
				pageSize: 100,
			}, session.access_token],
			queryFn: () => getRequest('/portfolios', {
				pageSize: 100,
			}, session.access_token),
			onSuccess: (response) => {
				setPortfoliosRows(response.items);
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['savedSearches'],
			queryFn: () => getRequest('/programsSavedSearches', {}, session.access_token),
			onSuccess: (response) => {
				setSavedSearches(response.items);
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['blends', {
				pageSize: 1000,
			}, session.access_token],
			queryFn: () => getRequest('/blends', {
				pageSize: 1000,
			}, session.access_token),
			onSuccess: (response) => {
				setBlendsRows(response.items);
			},
			enabled: !!session.access_token && (['saved-blends','saved-searches'].indexOf(tab) > -1)
		},
		{
			queryKey: ['currentBlend', session.access_token],
			queryFn: () => getRequest('/blends/getcurrent/minimal', {}, session.access_token),
			enabled: !!session.access_token && ('saved-blends' === tab)
		},
		{
			queryKey: ['wpAjax'],
			queryFn: () => wpAjax({
				action: 'wp_query',
				lang: lang,
				posts_per_page: 4
			}),
			onSuccess: (response) => {
				setRecentPosts(response.items);
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['blend', currentBlend, session.access_token],
			queryFn: () => getRequest(`/blends/${currentBlend.blendId}/Load`, {}, session.access_token),
			onSuccess: (response) => {
				navigate(`/tools/blender`);
			},
			enabled: !!currentBlend && ('saved-blends' === tab)
		},
		{
			queryKey: ['returnsDistribution'],
			queryFn: () => getRequest('/dataReturnsStatistics/returnsdistribution', {}, session.access_token),
			onSuccess: (response) => {
				setDistributionChartOptions(getColumnChartOptions({
					data: response,
					width: 650
				}));
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['returnStatistics'],
			queryFn: () => getRequest('/dataReturnsStatistics/returnstatistics', {}, session.access_token),
			onSuccess: (response) => {
				setReturnStatistics(response.returnStatistics);
				setLastMonth( subMonths(parseDate(response.dateCalculated), 1) );
				setPortfolioStatistics(response.portfolioStatistics);
				setMiscStatistics(response.miscStatistics);
			},
			enabled: !!session.access_token
		},
	]);


	const updatePortfolio = useMutation(params => patchPortfolio(params.portfolioOrderId, params.patch, session.access_token), {
		onSuccess: () => {
			queryClient.invalidateQueries('portfolios');
		},
	});

	const _cancelSetCurrentBlend = () => {
		setBlendToLoad(false);
		setShowConfirm(false);
	};

	const _setCurrentBlend = (blend) => {

		if( blend.blendId === loadedBlend.blendId ) {
			setCurrentBlend(blend);
		}
		else if( loadedBlend.blendTypeId === 4 ) {
			setBlendToLoad(blend);
			setShowConfirm(true);
		}

	};


	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/tools/dashboard`;
		}
		if( !session.Admin && 'pending-programs' === tab ) {
			window.location = `/tools/dashboard`;
		}
	}, [
		lang,
		session,
		tab,
	]);

	const isLoading = programsLoading || watchlistsLoading;

	if ( isLoading || !session.access_token ) {
		return (
			<Loader/>
		)
	}

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	return (
		<div className={'container'}>

			<ConfirmModal
				lang={lang}
				showConfirm={showDeleteProgramConfirm}
				handleCloseConfirm={handleCloseConfirm}
				confirmCallback={_delete}
				/>

			<Modal show={showConfirm} onHide={_cancelSetCurrentBlend} centered>

				<Modal.Body className='p-5'>

					<button
						type='button'
						className='close mt-n4 mr-n4'
						style={{fontSize:'1rem'}}
						onClick={_cancelSetCurrentBlend}>
						<i className='fa-solid fa-times'></i>
					</button>
	
					<div className='alert alert-warning'>
						<h6 className='d-flex align-items-center pt-2'>
							<i className='fa-solid fa-exclamation-triangle text-warning mr-1' style={{fontSize:'80%'}}></i>
							<b>{text.unsavedChanges}</b>
						</h6>

					{!!blendtoLoad.blendId &&
						<p className='m-0'>
							{text.loadingWillDelete}
						</p>
					}
					</div>

					<a
						href={`/tools/blender`}
						className='btn btn-block btn-outline-primary'
						onClick={_cancelSetCurrentBlend}>
						{text.viewCurrentWorksheet}
						<i className='fa fa-chevron-right ml-2' style={{fontSize:'80%'}}></i>
					</a>

					<button
						type='button'
						className='btn btn-block btn-danger mt-3'
						onClick={() => setCurrentBlend(blendtoLoad)}>
					{!!blendtoLoad.blendId && <>
						{text.deleteAndLoad}
					</>}
					</button>

				</Modal.Body>

			</Modal>

			<div className='row'>

				<div className='col-12'>

					<div className='row align-items-center mb-4'>

						<div className='col-12 col-md-6'>

							<h1 className='mb-0'>
								{text.pageTitle}
							</h1>

						</div>

						<div className='col-12 col-md-6 text-md-right'>
						{session.Roles.split(',').indexOf('CTA Manager') > -1 && !session.Groups &&
							<a className='btn btn-sm btn-outline-primary mr-2' href={`/tools/add-cta`}>
								<i className='fa-solid fa-plus-circle mr-1'></i>
								{`Add Your CTA`}
							</a>
						}
							<a className='btn btn-sm btn-outline-primary mr-2' href={`/profile`}>
								<i className='fa-solid fa-user-edit mr-1' style={{fontSize:'90%'}}></i>
								{`Edit My Profile`}
							</a>

							<a className='btn btn-sm btn-success' href={`/contact-us`}>
								<i className='fa fa-chevron-right mr-1' style={{fontSize:'90%'}}></i>
								{`Contact Us`}
							</a>

						</div>

					</div>
				{window.location.hostname.indexOf('sandbox') === -1 &&

					<div className='row'>

						<div className='col-12 col-md-6'>

							<hr/>

							<h2 className='h3 mb-4'>{`Next Steps`}</h2>

							<div className='position-relative'>
								<h5><a className='stretched-link' href={`/free-alternative-investment-plan`}>{`Get a Free Customized Alternative Investment Plan`}</a></h5>
								<p>{`Let IASG find the right managed futures for your portfolio. No cost. No obligation.`}</p>
								<img className='border border-light' src={WP_BASE_URL + '/wp-content/themes/iasg/img/saved-blend.png'} alt='screenshot' width='275' height='175'/>
							</div>

						</div>

						<div className='col-12 col-md-6' hidden={!recentPosts.length}>

							<hr/>

							<h2 className='h3 mb-4'>{`Recent Posts`}</h2>

						{postsLoading ? (
							<>{text.loading}</>
						) : (
							<div className='row row-cols-1 row-cols-md-2'>
							{recentPosts.map((post,i) =>
								<article className='col mb-3' key={`post-` + post.ID}>
									<small>
										<time dateTime={post.post_date}>{dateFormat(parseDate(post.post_date), 'MM/d/yyyy')}</time>
									</small>
									<a className='d-block' href={post.guid}>{post.post_title}</a>
								</article>
							)}
							</div>
						)}
						</div>

					</div>

				}
					<hr className='my-5'/>

					<h2 className='h3 my-5'>{`My Activities`}</h2>

					<div
						className='position-relative'
						aria-live='polite'
						aria-atomic='true'>

						<div
							className='position-absolute d-flex justify-content-center'
							style={{left:0,right:0}}>

							<Toast
								show={showToast}
								onClose={() => {setShowToast(false);setToastDelay(3000);}}
								delay={toastDelay}
								autohide
								className='border-success position-absolute mt-3'>

								<Toast.Body
									className='text-success text-center py-1'>
									<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
									{toastMessage}
								</Toast.Body>

							</Toast>

						</div>

					</div>

					<Tabs
						className={'iasg-tabs show-' + tab}
						defaultActiveKey={tab ? tab : 'watchlist'}
						onSelect={(k) => {navigate(`/tools/dashboard/${k}`);}}>

					{session.Groups &&
						<Tab className='py-5' eventKey="programs" title={text.myPrograms}>
							<div className='mt-n3 mb-3 my-lg-n4 text-left text-lg-right' hidden={mode}>
								<button type='button' className='btn btn-outline-primary btn-sm ml-1' onClick={(e) => {setMode('add-program');}}>
									<i className='fa-solid fa-plus-circle mr-1'></i>
									{text.addProgram}
								</button>
							</div>

						{!mode && programs.length > 0 && <>
							<ProgramsTable
								sticky={false}
								columns={[
									{
										field: 'groupProgram',
										headerName: 'CTA / Program',
									},
									{
										field: 'status',
										headerName: 'Status',
										type: 'status',
									},
									{
										field: 'action',
										headerName: '',
										type: 'action',
									},
									{
										field: 'actions',
										type: 'actions',
										headerName: '',
										visible: !!session.Admin,
										width: '1%',
									},
								]}
								rows={programs}
								removeRow={handleShowConfirm}
								/>
						</>}
						{'add-program' === mode && <>
							<h2 className='d-flex align-items-center'>
								<span>{text.addProgram}</span>
								<button type='button' className='btn btn-outline-dark btn-sm ml-2 py-1 px-2' onClick={(e) => {setMode(null);}}>
									{text.cancel}
								</button>
							</h2>

							<ProgramForm
								lang={lang}
								session={session}
								add={true}
								callback={(program) => {
									setProgramsQueryKey( (new Date()).getTime() );
									setToastMessage(text.programSaved);
									setShowToast(true);
									setMode(null);
									window.scrollTo(0, 0);
								}}
								initialValues={{
									programId: '00000000-0000-0000-0000-000000000000',
									groupId: session.Groups.split(',')[0],
									programStatusId: PROGRAM_PENDING_STATUS_ID,
									programName: '',
									programTypeId: MANAGED_ACCOUNT_TYPE_ID,
									membershipStatusId: PROGRAM_OPEN_STATUS_ID,
									symbol: '',
									currency: 'USD',
									investmentMin: '',
									tradingLevelIncrease: '',
									subscriptionFrequencyTypeId: '',
									redemptionFrequencyTypeId: '',
									investorRequirementsTypeId: '',
									lockupPeriod: 0,
									wddExpected: '',
									worstPeak2Trough: '',
									sectorFocusTypeId: '',
									carorType: 0,
									fundingNotional: false,
									fundingMaxNotional: 0,
									usInvest: 1,
									proprietary: false,
									hideProgram: false,
									feeManagement: '',
									feePerformance: '',
									averageCommission: '',
									highwaterMark: 'yes',
									methodologies: [
										...PROGRAM_METHODOLOGY_TYPES.map((type) => {
											return {
												methodologyTypeId: type.methodologyTypeId,
												title: type.methodology,
												value: 0,
											}
										})
									],
									allocationStrategies: [],
									compositions: [
										...PROGRAM_COMPOSITION_TYPES.map((type) => {
											return {
												compositionTypeId: type.compositionTypeId,
												title: type.composition,
												value: 0,
											}
										})
									],
									roundturnYearMillion: '',
									marginEquityRatio: '',
									holdings: [
										...PROGRAM_HOLDING_TYPES.map((type) => {
											return {
												holdingTypeId: type.holdingTypeId,
												title: type.holding,
												value: 0,
											}
										})
									],
									description: '',
									strategyInvestment: '',
									strategyRisk: '',
									platforms: [],
									AcceptedAgreement: false,
								}}
								/>
						</>}
						</Tab>
					}
						<Tab className='py-5' eventKey="watchlist" title={text.myWatchlist}>

							<WatchlistTable
								lang={lang}
								session={session}
								columns={watchlistsColumns}
								rows={watchlistsRows}
								sortBy={watchlistsSortBy}
								orderBy={watchlistsOrderBy}
								toggleSortBy={watchlistsToggleSortBy}
								setRows={setWatchlistsRows}
								/>

						</Tab>

						<Tab className='py-5' eventKey="portfolio" title={text.myPortfolio}>
						{portfoliosLoading ? (
							<>{text.loading}</>
						) : (
							<PortfolioTable
								lang={lang}
								session={session}
								columns={portfoliosColumns}
								rows={portfoliosRows}
								setRows={setPortfoliosRows}
								updatePortfolio={updatePortfolio}
								/>
						)}
						</Tab>

						<Tab className='py-5' eventKey="saved-blends" title={text.savedBlends}>
						{blendsLoading ? (
							<>{text.loading}</>
						) : (
							<BlendsTable
								lang={lang}
								session={session}
								columns={blendsColumns}
								rows={blendsRows}
								sortBy={blendsSortBy}
								orderBy={blendsOrderBy}
								toggleSortBy={(field) => {
									setBlendsRows(('DESC' === blendsOrderBy) ? blendsRows.sort((a,b) => {
										return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
									}) : blendsRows.sort((a,b) => {
										return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
									}));
									setBlendsSortBy(field);
									setBlendsOrderBy('DESC' === blendsOrderBy ? 'ASC' : 'DESC');
								}}
								setCurrentBlend={_setCurrentBlend}
								setRows={setBlendsRows}
								/>
						)}
						</Tab>

						<Tab className='py-5' eventKey="saved-searches" title={text.savedSearches}>
						{savedSearchesLoading &&
							<>{text.loading}</>
						}
						{!savedSearchesLoading && !savedSearches &&
							<p>{`You don’t have any saved searches yet.`} <a href={`/managed-futures/performance`}>{`Let’s go create some!`}</a></p>
						}
						{savedSearches && savedSearches.length > 0 &&
							<>
								<ProgramsTable
									columns={[
										{
											field: 'filtersObject',
											headerName: 'Filters',
										},
										{
											field: 'actions',
											width: '1%',
										},
									]}
									rows={savedSearches}
									removeRow={(row) => {
										deleteSavedSearches(row.programSavedSearchId, session.access_token).then((response) => {
											setSavedSearches(savedSearches.filter((search) => {
												return search !== row;
											}));
										});
									}}
									blends={blendsRows}
									/>
							</>
						}
						</Tab>

					</Tabs>

					<div className='row'>

						<div className='col-12 col-md-6'>

							<hr/>

							<h2 className='h3 mb-3'>{`Top Rankings`}</h2>

							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{`Browse By`}
											</th>
										</tr>
									</thead>
									<tbody>
									{[
										{
											label: 'Systematic Traders',
											section: 'performance',
											filters: '?filterByMethodologyTypesId=a84537c0-ec2a-4644-9ba1-acbba3fa33dd&sortBy=ytdReturn',
										},
										{
											label: 'Discretionary Traders',
											section: 'performance',
											filters: '?filterByMethodologyTypesId=97b4310e-61df-4d52-aabe-fc3cfcf82099&sortBy=ytdReturn',
										},
										{
											label: 'Energy Traders',
											section: 'performance',
											filters: '?filterByCompositionTypesId=4c88ab17-c6a7-493c-9359-cb99f37a611d&sortBy=ytdReturn',
										},
										{
											label: 'Sharpe Ratio Ranking',
											section: 'returns',
											filters: '?sortBy=sharpeRatio',
										},
										{
											label: 'YTD Performance',
											section: 'performance',
											filters: '?sortBy=ytdReturn',
										},
										{
											label: '1 Year Performance',
											section: 'returns',
											filters: '?sortBy=fullYearReturn',
										},
										{
											label: '3 Year Performance',
											section: 'returns',
											filters: '?sortBy=threeYearReturn',
										},
										{
											label: '5 Year Performance',
											section: 'returns',
											filters: '?sortBy=fiveYearReturn',
										},
										{
											label: 'AUM < $10M',
											section: 'performance',
											filters: '?filterByAUMMax=10000000&sortBy=assets',
										},
										{
											label: 'AUM > $10M',
											section: 'performance',
											filters: '?filterByAUMMin=10000000&sortBy=assets',
										},
									].map((row,i) =>
										<tr key={i}>
											<td>
												{row.label}
											</td>
											<td className='text-right'>
												<a className='d-block' href={`/managed-futures/${row.section}${row.filters}`}>
													{`View Rankings`}
												</a>
											</td>
										</tr>
									)}
									</tbody>
								</table>

							</div>

						</div>

						<div className='col-12 col-md-6'>
						{returnStatistics && <>
							<hr/>

							<h2 className='h3 mb-3'>{`Database Stats`}</h2>

							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{`Active CTAs/Programs`}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{`As of ` + dateFormat((new Date()), 'MMMM d, yyyy')}
											</td>
											<td className='text-right'>
												{returnStatistics.returnCount}
											</td>
										</tr>
										<tr>
											<td>
												{`Through ` + dateFormat( lastMonth || (new Date()), 'MMMM yyyy' ) }
											</td>
											<td className='text-right'>
												{returnStatistics.returnCountLastMonth} ({ ((returnStatistics.returnCountLastMonth/returnStatistics.activeProgramsTotal)*100).toFixed(1) + '%'})
											</td>
										</tr>
										<tr>
											<td>
												{`Last 60 days, excluding ` + dateFormat( lastMonth || (new Date()), 'MMMM' )}
											</td>
											<td className='text-right'>
												{returnStatistics.returnCountTwoMonth} ({ ((returnStatistics.returnCountTwoMonth/returnStatistics.activeProgramsTotal)*100).toFixed(1) + '%'})
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{dateFormat( lastMonth || (new Date()), 'MMMM yyyy' ) + ` ROR`}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{`Managers with (+)`}
											</td>
											<td className='text-right'>
												{returnStatistics.positiveData.returnCount}
											</td>
										</tr>
										<tr>
											<td>
												{`Managers with (-)`}
											</td>
											<td className='text-right'>
												{returnStatistics.negativeData.returnCount}
											</td>
										</tr>
										<tr>
											<td>
												{`Average (+)`}
											</td>
											<td className='text-right'>
												{returnStatistics.positiveData.returnAverage.toFixed(2) + '%'}
											</td>
										</tr>
										<tr>
											<td>
												{`Average (-)`}
											</td>
											<td className='text-right'>
												{returnStatistics.negativeData.returnAverage.toFixed(2) + '%'}
											</td>
										</tr>
										<tr>
											<td>
												{`Highest`}
											</td>
											<td className='text-right'>
												{returnStatistics.activeData.returnMax.toFixed(2) + '%'}
											</td>
										</tr>
										<tr>
											<td>
												{`Average`}
											</td>
											<td className='text-right'>
												{returnStatistics.activeData.returnAverage.toFixed(2) + '%'}
											</td>
										</tr>
										<tr>
											<td>
												{`Lowest`}
											</td>
											<td className='text-right'>
												{returnStatistics.activeData.returnMin.toFixed(2) + '%'}
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{`Distribution of Returns`}
											</th>
										</tr>
									</thead>
								</table>
							</div>
						</>}
							<figure className='mb-5 d-flex justify-content-center'>

								<HighchartsReact
									highcharts={Highcharts}
									options={distributionChartOptions}
									containerProps={{id:'distribution'}}
									callback={(chart) => {addWaterMark(chart);}}
								/>

							</figure>

						{session.Admin && portfolioStatistics &&
							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{`Blender Stats`}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{`Blends`}
											</td>
											<td className='text-right'>
												{portfolioStatistics.blendsTotal||0}
											</td>
										</tr>
										<tr>
											<td>
												{`Portfolios`}
											</td>
											<td className='text-right'>
												{portfolioStatistics.portfoliosTotal||0}
											</td>
										</tr>
										<tr>
											<td>
												{`Watchlists`}
											</td>
											<td className='text-right'>
												{portfolioStatistics.watchListsTotal||0}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						}
						{session.Admin && miscStatistics &&
							<div className='table-responsive'>
								<table className='table table-sm table-striped'>
									<thead className='table-heading'>
										<tr>
											<th scope='col' colSpan='2'>
												{`Misc Stats`}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{`New Program Alerts`}
											</td>
											<td className='text-right'>
												{miscStatistics.newProgramAlertsTotal||0}
											</td>
										</tr>
										<tr>
											<td>
												{`Saved Searches`}
											</td>
											<td className='text-right'>
												{miscStatistics.savedSearchesTotal||0}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						}
						</div>

					</div>

				</div>

			</div>

		</div>
	)

};

export default Dashboard;
