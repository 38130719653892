// i18n
import { labels as labelsGlobal } from '../../i18n/Global';

function Pagination(props) {

	const text = {...labelsGlobal[props.lang ? props.lang : 'en']};

	const resultsFrom = props.pageNumber <= 1 ? 1 : (props.pageNumber - 1) * props.pageSize + 1;
	const resultsTo = (props.pageSize * props.pageNumber) > props.totalCount ? props.totalCount : (props.pageSize * props.pageNumber);

	return (
		<div className='form-inline pr-3'>
			<span className='pr-3'>
				{resultsFrom} – {resultsTo} {text.of} {props.totalCount}
			</span>
			<div className='w-100 py-1 d-sm-none'></div>
			<span className='pr-2'>
				<b>{text.page}</b>
			</span>
			<span className='pr-2'>
				<input className='form-control form-control-sm'
					type='number'
					step='1'
					min='1'
					max={props.totalPages}
					value={props.pageNumber}
					onChange={(e) => {props.setQueryParams({pageNumber:e.currentTarget.value})}}/>
			</span>
			<span className='pr-2'>
				{text.of} {props.totalPages}
			</span>
			<button className='btn btn-sm btn-primary mr-1'
				disabled={parseInt(props.pageNumber) === 1}
				onClick={(e) => {e.preventDefault();props.setQueryParams({pageNumber:(parseInt(props.pageNumber)-1)})}}>
				<i className='fa-solid fa fa-chevron-left mx-1'></i>
			</button>
			<button className='btn btn-sm btn-primary'
				disabled={parseInt(props.pageNumber) === props.totalPages}
				onClick={(e) => {e.preventDefault();props.setQueryParams({pageNumber:(parseInt(props.pageNumber)+1)})}}>
				<i className='fa-solid fa fa-chevron-right mx-1'></i>
			</button>
		</div>
	)

}

export default Pagination;
