// helpers
import {
	NumberFormat,
} from '../../helpers/Helpers';


// TO-DO? why aren't these in ../../helpers/Helpers ?
import {
	TableHeader,
	TableCell,
} from '../../helpers/Tables';


// i18n
import { labels } from '../../i18n/Tables';


// ProgramsTable
function ProgramsTable({
	lang='en',
	columns=[],
	rows=[],
	blends=[],
	footer=null,
	sortBy='',
	orderBy='',
	toggleSortBy=null,
	selectAll=null,
	chartKey='',
	setAlert=null,
	copyBlend=null,
	setCurrentBlend=null,
	toggleComparisonChart=null,
	removeRow=null,
	activate=null,
	updateProperty=null,
	updatePropertyCallback=null,
	alwaysDisplayReturns=true,
	selectRow=null,
	sticky=true,
	readOnly=false,
	headerOnly=false,
	noResultsMessage=null,
	containerClass='',
}) {

	const text = { ...labels[lang] };

	// only use visible columns…
	const visibleColumns = columns.filter((column) => {
		return column.visible !== false;
	}).sort((a,b) => {if((a?.order||0)<(b?.order||0)) return -1;if((a?.order||0)>(b?.order||0)) return 1;return 0;});

	// totalQuantity
	const totalQuantity = () => {

		let totalQuantity = 0;

		rows.forEach((row) => {
			totalQuantity += parseFloat(row.quantity);
		});

		return totalQuantity;

	};

	// totalValue
	const totalValue = () => {

		let totalValue = 0;

		rows.forEach((row) => {
			let value = row.quantity * (1 + row.periodReturn);
			totalValue += value;
		});

		return totalValue;

	};

	// totalCashLevel
	const totalCashLevel = () => {

		let totalCashLevel = 0;

		rows.forEach((row) => {
			totalCashLevel += parseFloat(row.cashLevel);
		});

		return totalCashLevel;

	};

	// tableFooter
	const tableFooter = (columns) => {

		if( !footer ) return;

		switch(footer) {

			case 'portfolio':

				let footerColumns = [
					{
						field: 'groupProgram',
						value: text.totalValue,
					},
					{
						field: 'past12Months',
					},
					{
						field: 'quantity',
						className: 'text-right',
						value: (
							<NumberFormat value={totalQuantity()}
								displayType={'text'}
								thousandSeparator={true}
								decimalScale={0}
								renderText={(value, props) => <div style={{paddingRight:'9px'}}>{'$'}{value}</div>}/>
						),
					},
					{
						field: 'dateAllocation',
					},
					{
						field: 'currentValue',
						className: 'text-right',
						value: (
							<NumberFormat value={totalValue()}
								displayType={'text'}
								thousandSeparator={true}
								decimalScale={0}
								renderText={(value, props) => <div>{'$'}{value}</div>}/>
						),
					},
					{
						field: 'periodReturn',
						className: 'text-right',
						value: (((totalValue() / totalQuantity()) - 1) * 100).toFixed(2) + '%',
					},
					{
						field: 'actions',
					}
				];

				return (
					<tfoot>
						<tr className='border-top font-weight-bold'>
						{footerColumns.filter((column) => {
							return columns.find((col) => {return column.field === col.field;});
						}).map((cell,i) =>
							<td data-field={cell.field} className={cell.className} key={i}>
								{cell.value}
							</td>
						)}
						</tr>
					</tfoot>
				);

			case 'allocations':

				let allocated = totalQuantity();
				let cashLevel = totalCashLevel();

				return (
					<tfoot>
						<tr className='border-top font-weight-bold'>
						{[
							{
								field: 'chartToggle',
							},
							{
								field: 'color',
							},
							{
								value: text.totalValue
							},
							{},
							{},
							{
								className: 'text-right',
								value: (
									<NumberFormat value={allocated}
										displayType={'text'}
										thousandSeparator={true}
										decimalScale={0}
										renderText={(value, props) => <div style={{paddingRight:'9px'}}>{'$'}{value}</div>}/>
								)
							},
							{
								className: 'text-right',
								value: (
									<NumberFormat value={cashLevel}
										displayType={'text'}
										thousandSeparator={true}
										decimalScale={0}
										renderText={(value, props) => <div>{'$'}{value}</div>}/>
								)
							},
							{
								className: 'text-right',
								value: ( allocated / cashLevel ).toFixed(2)
							},
							{},
							{},
							{
								field: 'actions',
								hidden: !!readOnly
							},
						].map((cell,i) =>
							<td data-field={cell.field} className={cell.className} key={i} hidden={cell.hidden}>
								{cell.value}
							</td>
						)}
						</tr>
					
					</tfoot>
				);

			default:

				return (<></>);

		}

	};

	if( !rows.length ) {

		return typeof noResultsMessage === 'function' ? noResultsMessage() : (
			<div className='alert alert-warning'>
				{`No data available in table`}
			</div>
		)

	}
	else {

		return (
			<div className={'table-responsive mb-3' + (headerOnly === false ? '' : ' header-only') + (containerClass ? ` ${containerClass}` : '')}
				onScroll={(e) => {
					const sib = e.target.previousSibling;
					if( sib.classList.contains('sticky-top') ) {
						sib.querySelectorAll('.table-responsive')[0].scrollLeft = e.target.scrollLeft;
					}
				}}>
				<table className='table table-sm table-striped'>
					<colgroup>
						{visibleColumns.map((column) =>
							<col
								key={column.field}
								style={{width: column.width ? column.width : 'auto' }}
								/>
						)}
					</colgroup>
					<thead>
						<tr>
						{visibleColumns.map((column) =>
							<TableHeader
								lang={lang}
								key={column.field}
								column={column}
								sortBy={sortBy}
								orderBy={orderBy}
								toggleSortBy={toggleSortBy}
								selectAll={selectAll}
								/>
						)}
						</tr>
					</thead>
					<tbody>
					{rows.map((row,i) =>
						<tr key={i}>
						{visibleColumns.map((column) =>
							<TableCell
								lang={lang}
								key={`${row.programId}-${column.field}`}
								column={column}
								row={row}
								blends={blends}
								chartKey={chartKey}
								setAlert={setAlert}
								copyBlend={copyBlend}
								setCurrentBlend={setCurrentBlend}
								toggleComparisonChart={toggleComparisonChart}
								removeRow={removeRow}
								activate={activate}
								updateProperty={updateProperty}
								updatePropertyCallback={updatePropertyCallback}
								alwaysDisplayReturns={alwaysDisplayReturns}
								selectRow={selectRow}
								readOnly={readOnly}
								headerOnly={headerOnly}
								/>
						)}
						</tr>
					)}
					</tbody>
					{tableFooter(visibleColumns)}
				</table>
			</div>
		)

	}

};

export default ProgramsTable;
