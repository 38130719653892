export const labels = {
	'en': {
		firstName: 'First Name',
		lastName: 'Last Name',
		Email: 'Email',
		country: 'Country',
		phonePrefix: 'Prefix',
		phonePrefixTooltip: 'An international call prefix or dial out code is a trunk prefix used to select an international telephone circuit for placing an international call.',
		phone: 'Phone',
		address1: 'Address',
		address2: 'Suite/Apt/Unit',
		cityTown: 'City',
		stateProvince: 'State/Province',
		postalCode: 'ZIP/Postal Code',
		website: 'Website',

		profile: 'Profile',
		profileSaved: 'Profile Saved',
		passwordSaved: 'Password Updated',
		passwordReset: <>{`You can now`} <a className='alert-link' href='/en/login'>{`log in`}</a> {`with your new password (redirecting…)`}</>,
		username: 'Username',
		Password: 'Password',
		ConfirmPassword: 'Retype Password',
		NewPassword: 'New Password',
		PasswordConfirmation: 'Confirm New Password',
		createAccount: 'Create Account',
		saveChanges: 'Save Changes',
		register: 'Register',
		rememberMe: 'Keep me logged in',
		forgotPassword: 'Forgot password?',

		accountingNotes: 'Accounting Notes',

		groupInformation: 'Company Information',
		groupName: 'Company Name',
		groupDescription: 'Company Description',
		groupStatus: 'Status',
		groupLogo: 'Company Logo',
		groupType: 'Company Type',

		groupMemberships: 'Registrations',
		groupContacts: 'Contacts',
		groupContactType: 'Contact Type',

		programInformation: 'Basic Information',
		programGroup: 'CTA',
		programName: 'Program Name',
		programStatus: 'Program Status',
		programSaved: 'Program Saved',
		performanceDataSaved: 'Performance Data Saved',
		managementFee: 'Management Fee',
		performanceFee: 'Performance Fee',
		highwaterMark: 'High Water Mark',

		programInvestmentInformation: 'New Investment Information',
		programFees: 'Fees',
		programDecisionMaking: 'Decision-Making',
		programInvestmentStrategy: 'Investment Strategy',
		programComposition: 'Composition',
		programPositionInformation: 'Position Information',

		usInvest: 'Available to US Investors',

		programDescription: 'Program Description',
		programSummary: 'Summary',
		programStrategyInvestment: 'Investment Strategy',
		programStrategyRisk: 'Risk Management Strategy',

		invalidEmail: 'Please enter a valid email address',
		invalidPhone: 'Please enter a valid phone number',
		invalidURL: 'Please enter a valid URL',
		PasswordMismatch: 'Passwords do not match',
		PasswordRequired: 'Please enter a password',
		PasswordMinLength: 'Password must be at least {min} characters',
		PasswordStrength: 'Must include a mixture of uppercase and lowercase letters',

		addAnother: 'add another',

		required: 'Required',
		requiredFields: '* Required fields',

		login: 'Log In',
		emailMe: 'Email me',

		getAlertNewProgram: 'Alert me when there are new programs',
		getNewsletter: 'Subscribe to IASG Newsletters',

		getResearchReportHeading: 'Get Free Research Report on this CTA',
		getResearchReportContent: 'Get additional information on {groupName}, including performance, charts, statistics, ratios, and more.',
		getResearchReport: 'Get Research Report',

		isAccredited: 'I am an Accredited Investor',
		isAccreditedTooltip: 'Investors who have a net worth exceeding $1 million, or individual income of $200,000 per year, or joint income of $300,000, in each of the last two years.',

		signup: 'Sign up for latest CTA news & rankings',
		signupDisclaimer: <>{`By signing up you agree to the`} <a href='/en/terms-of-service'>{`Terms of Service`}</a> {`and`} <a href='/en/privacy-policy'>{`Privacy Policy`}</a>.</>,

		requestReceived: <>
			<p>{`We have received your request and will be contacting you shortly.`}</p>
			<p className='mb-0'>{`For further assistance, please email us at`} <a href={`mailto:info@iasg.com`}>{`info@iasg.com`}</a> {`or call 312-561-3145.`}</p>
		</>,
		thankYou: 'Thank you!',
	},
	'zh-hans': {
		firstName: '名',
		lastName: '姓',
		Email: '电子邮件',
		country: '国家',
		phonePrefix: '字首',
		phonePrefixTooltip: '国际电话前缀或拨出代码是用于选择国际电话线路以拨打国际电话的中继前缀。',
		phone: '电话号码',
		address1: '地址',
		address2: '套房/公寓/单元',
		cityTown: '城市',
		stateProvince: '州/省',
		postalCode: '邮编/邮政编码',
		website: '网站',

		profile: '轮廓',
		profileSaved: '已保存配置文件',
		passwordSaved: '密码更新',
		passwordReset: <>{`您现在可以使用新密码`} <a className='alert-link' href='/zh-hans/login'>{`登录`}</a>{`。(重定向…)`}</>,
		username: '用户名',
		Password: '密码',
		ConfirmPassword: '重新输入密码',
		NewPassword: '新密码',
		PasswordConfirmation: '确认新密码',
		createAccount: '创建帐号',
		saveChanges: '保存更改',
		register: '登记',
		rememberMe: '保持登录状态',
		forgotPassword: '忘记密码？',

		accountingNotes: '会计笔记',

		groupInformation: '公司信息',
		groupName: '公司名称',
		groupDescription: '公司介绍',
		groupStatus: '地位',
		groupLogo: '公司标志',
		groupType: '公司类型',

		groupMemberships: '注册',
		groupContacts: '联系人',
		groupContactType: '联系方式',

		programInformation: '基本信息',
		programGroup: '商品交易顾问',
		programName: '程序名称',
		programSaved: '程序已保存',
		performanceDataSaved: '性能数据已保存',
		managementFee: '管理费',
		performanceFee: '绩效费',
		highwaterMark: '高水位线',


		programInvestmentInformation: '新投资资讯',
		programFees: '费用',
		programDecisionMaking: '做决定',
		programInvestmentStrategy: '投资策略',
		programComposition: '作品',
		programPositionInformation: '职位信息',

		usInvest: '可供美国投资者使用',

		programDescription: '程序说明',
		programSummary: '概括',
		programStrategyInvestment: '投资策略',
		programStrategyRisk: '风险管理策略',

		invalidEmail: '请输入有效的电子邮件地址',
		invalidPhone: '请输入一个有效的电话号码',
		invalidURL: '请输入有效网址',
		PasswordMismatch: '密码不匹配',
		PasswordRequired: '请输入密码',
		PasswordMinLength: '密码必须至少为 {min} 个字符',
		PasswordStrength: '必须包含大写和小写字母的混合',

		addAnother: '加上另一个',

		required: '必需的',
		requiredFields: '* 必填字段',

		login: '登录',
		emailMe: '给我发邮件',

		getAlertNewProgram: '有新程序时提醒我',
		getNewsletter: '订阅 IASG 通讯',

		getResearchReportHeading: '获取有关此 CTA 的免费研究报告',
		getResearchReportContent: '获取有关 {groupName} 的其他信息，包括性能、图表、统计数据、比率等。',
		getResearchReport: '获取研究报告',

		isAccredited: '我是合格投资者',
		isAccreditedTooltip: '在过去两年中每年净资产超过 100 万美元，或个人年收入超过 20 万美元，或共同收入超过 30 万美元的投资者。',

		signup: '注册以获取最新的 CTA 新闻和排名',
		signupDisclaimer: <>{`注册即表示您同意`} <a href='/zh-hans/terms-of-service'>{`服务条款`}</a> {`和`} <a href='/zh-hans/privacy-policy'>{`隐私政策`}</a>.</>,

		requestReceived: <>
			<p>{`我们已收到您的请求，将尽快与您联系。`}</p>
			<p className='mb-0'>{`如需进一步帮助，请发送电子邮件至`} <a href={`mailto:info@iasg.com`}>{`info@iasg.com`}</a> {`或致电 312-561-3145。`}</p>
		</>,
		thankYou: '谢谢！',
	}
};
