import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
} from 'hookrouter';


// Constants
import {
	INDEXES_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
} from '../api/Api';


// partials
import {
	Loader,
	ProgramsTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsIndexes } from '../i18n/Indexes';


// Indexes
function Indexes({
	lang,
}) {

	const text = {...labelsGlobal[lang],...labelsIndexes[lang]};

	// error handling
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// queryParams
	const [queryParams,setQueryParams] = useQueryParams();

    // Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		sortBy = 'monthReturn',
		orderBy = 'DESC',
		pageNumber = 1,
		pageSize = 25,
	} = queryParams;

	// data
	const [columns] = useState(INDEXES_COLUMNS);
	const [rows,setRows] = useState([]);

	const toggleSortBy = (field) => {

		let params = {};

		if( field === sortBy ) {
			params.orderBy = 'DESC' === orderBy ? 'ASC' : 'DESC';
		}

		setQueryParams({sortBy:field,...params});

	};

	useEffect(() => {

		setIsLoading(true);

		let stateParams = {
		};

		// don't allow anyone to change these params!
		let staticParams = {
		};

		stateParams = {
			...stateParams,
			...staticParams,
		};

		stateParams = {
			...stateParams,
			sortBy: sortBy,
			orderBy: orderBy,
			pageSize: pageSize,
			pageNumber: pageNumber,
		};

		// remove any blank stateParams before we fire off our get request…
		let getQueryParams = Object.fromEntries(Object.entries(stateParams).filter(([_, v]) => v !== ''));

		getRequest(`/indexes`, getQueryParams)
			.then((response) => {
				setIsLoading(false);
				setIsLoaded(true);
				setRows(response.items.map(row => {
					return { ...row, selected: false };
				}));
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				setIsLoading(false);
				setIsLoaded(true);
				setError(error);
			}
		);

	}, [
		sortBy,
		orderBy,
		pageNumber,
		pageSize
	]);

	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	if (!isLoaded) {
		return (
			<Loader/>
		)
	}

	if( error )

		return (
			<div className={'container'}>
				<div className='alert alert-danger'>
					{text.errorMessage}
				</div>
			</div>
		);

	else

	return (
		<div className={'container'} style={{display:(isLoaded ? 'block' : 'none')}}>

			<h1 className='h2 mb-4'>{text.pageTitle}</h1>

			<div className={'row'} style={{ opacity: ( !isLoading ? 1 : .5) }}>

				<div className='col-12'>

					<ProgramsTable
						lang={lang}
						columns={columns}
						rows={rows}
						chartKey={'portfolioId'}
						sortBy={sortBy}
						orderBy={orderBy}
						toggleSortBy={toggleSortBy}
						/>

					<p className='text-muted'>
						<small>{text.performanceDisclaimer}</small>
					</p>

					<p className='text-muted'>
						<small>{text.indexesDisclaimer}</small>
					</p>

				</div>

			</div>

		</div>
	)

}

export default Indexes;
