// Constants
import {
	WP_BASE_URL,
} from '../../helpers/Constants';

// helpers
import {
	he,
} from '../../helpers/Helpers';

// i18n
import { labels as labelsGlobal } from '../../i18n/Global';

// RelatedArticles
function RelatedArticles({
	lang='en',
	data={},
	tag=null,
}) {

	const text = {...labelsGlobal[lang ? lang : 'en']};

	return (
		<aside className='iasg-archive py-4 border-top'>

			<div className='row mb-4 align-items-center'>

				<div className='col-12 col-lg-8'>

					<h5 className='mb-0'>{text.relatedArticles}</h5>

				</div>

				<div className='col-12 col-lg-4 text-lg-right'>
				{tag &&
					<a className='btn btn-link p-0' href={`${WP_BASE_URL}/blog/tag/${tag}`} hidden={data.found_posts<4}>{text.viewMore}<i className='far fa-arrow-alt-circle-right pl-2'></i></a>
				}
				</div>

			</div>

			<div className='row row-cols-1 row-cols-lg-3' dangerouslySetInnerHTML={{__html: data.html ? he.decode(data.html) : ''}}></div>

		</aside>
	)

}

export default RelatedArticles;
