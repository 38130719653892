import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
	navigate,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/overlays/
 * @link https://react-bootstrap.github.io/components/tabs/
 *
 */
import {
	OverlayTrigger,
	Popover,
	Tabs,
	Tab,
} from 'react-bootstrap';


/*
 * react-csv
 *
 * @link https://www.npmjs.com/package/react-csv
 *
 */
import {
	CSVLink,
} from 'react-csv';


// Constants
import {
	DEFAULT_PROGRAM_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
	wpAjax,
} from '../api/Api';


// helpers
import {
	addWaterMark,
	dateFormat,
	parseDate,
	debounce,
	getPerformanceChartOptions,
	HighchartsReact,
	Highstock,
	parseInvestmentReturns,
} from '../helpers/Helpers';


// partials
import {
	Loader,
	StatsTable,
	Pagination,
	PerformanceTable,
	ProgramsTable,
	RelatedArticles,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsIndexes } from '../i18n/Indexes';
import { labels as labelsIndex } from '../i18n/Index';
import { labels as labelsTables } from '../i18n/Tables';


// Index
function Index({
	lang,
	session,
	portfolioUri,
	tab,
	toggleShowLogin,
}) {

	const text = {...labelsGlobal[lang], ...labelsIndexes[lang], ...labelsIndex[lang], ...labelsTables[lang]};

	// queryParams
	const [queryParams, setQueryParams] = useQueryParams();

    // Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		sortBy = 'monthReturn',
		orderBy = 'DESC',
		pageNumber = 1,
		pageSize = 50,
	} = queryParams;

	// data
	const [index, setIndex] = useState(false);
	const [indexReturns, setIndexReturns] = useState(null);
	const [monthlyReturns, setMonthlyReturns] = useState([]);
	const [monthlyReturnsCSVData, setMonthlyReturnsCSVData] = useState(false);
	const [columns] = useState(DEFAULT_PROGRAM_COLUMNS);
	const [programs, setPrograms] = useState({});
	const [rows, setRows] = useState([]);

	// sort and paging
	const [totalPages, setTotalPages] = useState(1);

	// charts
	const [performanceChart, setPerformanceChart] = useState(false);
	const [performanceChartOptions, setPerformanceChartOptions] = useState(false);

	// functions
	const toggleSortBy = (field) => {

		let params = {};

		if( field === sortBy ) {
			params.orderBy = 'DESC' === orderBy ? 'ASC' : 'DESC';
		}

		setQueryParams({sortBy:field,...params});

	};

	// posts
	const [recentPosts, setRecentPosts] = useState(null);

	let stateParams = {
		sortBy: sortBy,
		orderBy: orderBy,
		pageSize: pageSize,
		pageNumber: pageNumber,
	};

	// remove any blank stateParams before we fire off our get request…
	let getQueryParams = Object.fromEntries(Object.entries(stateParams).filter(([_, v]) => v !== ''));

	// run our queries
	const [
		{
			isLoading: indexLoading,
		},
		{
			isLoading: programsLoading,
		},
		{
			isLoading: postsLoading,
		},
	] = useQueries([
		{
			queryKey: ['index', {portfolioUri: portfolioUri}],
			queryFn: () => getRequest(`/indexes/${portfolioUri}`, {}, session.access_token),
			onSuccess: (response) => {

				let width = 990;

				width = window.innerWidth < 1400 ? 825 : width;
				width = window.innerWidth < 1200 ? 690 : width;
				width = window.innerWidth < 768 ? 510 : width;
				width = window.innerWidth < 576 ? 320 : width;

				// charts
				if( response.investmentReturns.length ) {
					let performanceChartOptions = getPerformanceChartOptions({
						data: response.investmentReturns,
						name: response.portfolioName,
						metric: 'cumulativeROR',
						width: width,
					});
					setPerformanceChartOptions(performanceChartOptions);
				}

				// parseInvestmentReturns
				if( response.investmentReturns.length ) {

					let investmentReturns = parseInvestmentReturns(response.investmentReturns);

					setMonthlyReturns(investmentReturns.monthlyReturns);

					// data for CSV
					setMonthlyReturnsCSVData(response.investmentReturns.map(function(i) {
						return {
							Year: dateFormat(parseDate(i.dateReturn), 'yyyy'),
							Month: dateFormat(parseDate(i.dateReturn), 'M'),
							Return: i.monthReturn,
							VAMI: i.vami
						}
					}));

				}

				// indexReturns
				setIndexReturns(response.periodReturns[0]);

				// setIndex
				setIndex(response);

			},
		},
		{
			queryKey: ['programs', portfolioUri, getQueryParams, session.access_token],
			queryFn: () => getRequest(`/programsListings/portfolio/${portfolioUri}`, getQueryParams, session.access_token),
			onSuccess: (response) => {

				setPrograms( response );

				// rows
				setRows(response.items.map(row => {
					return { ...row, selected: false };
				}));

				setTotalPages( Math.ceil( response.totalCount / pageSize ) );

			}
		},
		{
			queryKey: ['wpAjax', {index:index}],
			queryFn: () => wpAjax({
				action: 'wp_query',
				lang: lang,
				posts_per_page: 3,
				tag: index.portfolioUri.toLowerCase(),
				orderby: 'DATE',
				post_type: 'post',
			}),
			onSuccess: (response) => {
				setRecentPosts(response);
			},
			enabled: !!(index)
		},
	]);


	// refreshCharts
	const refreshCharts = () => {

		if( !performanceChart.container ) return;

		setPerformanceChartOptions({
			...performanceChartOptions,
			chart: {
				...performanceChartOptions.chart,
				width: performanceChart.container.closest('figure').offsetWidth
			}
		});

		addWaterMark(performanceChart);

	};
	// TO-DO? find a better way :/


	// resize events
	useEffect(() => {

	    const debouncedHandleResize = debounce(() => {

			refreshCharts();

		}, 500);

	    window.addEventListener('resize', debouncedHandleResize);

		return _ => {
			window.removeEventListener('resize', debouncedHandleResize)
		}
	});


	// set page title and body class
	useEffect(() => {
		document.title = index ? [index.portfolioName, text.pageTitle, text.siteTitle].join(text.titleSeparator) : '…';
		document.body.classList.remove('overflow-hidden');
	});


	const isLoading = indexLoading;

	if (isLoading) {
		return (
			<Loader/>
		)
	}

	return (
		<div className={'container'}>
		{index &&
			<div className='row'>

				<div className='col-12'>

					<h1 className='mb-4'>
						{index.portfolioName}
					</h1>
				{text[portfolioUri] &&

					<div className='row'>
						<div className='col-12 col-lg-8'>
							<p>{text[portfolioUri]}</p>
						</div>
					</div>
				}
					<Tabs
						className={'iasg-tabs'}
						defaultActiveKey={tab ? tab : 'snapshot'}
						onSelect={(k) => {navigate(`/indexes/${portfolioUri}/${k}`);setTimeout(refreshCharts, 150);}}>

						<Tab className='py-5' eventKey="snapshot" title={text.snapshot}>

							<div className='row'>

								<div className='col-12 col-lg-3 mb-5'>

									<div className='card shadow'>
									{indexReturns &&
										<div className='card-body'>

											<h6>{text.yearToDate}</h6>

											<div className={'h1 pb-2 mb-0 border-bottom text-' + (indexReturns.ytdReturn > 0 ? 'primary' : 'danger')}>
												<span className='pr-2'>{indexReturns.ytdReturn ? (indexReturns.ytdReturn * 100).toFixed(2) + '%' : ''}</span>
												<i className={'fas fa-long-arrow-alt-' + (indexReturns.ytdReturn > 0 ? 'up' : 'down')} style={{fontSize:'90%'}}></i>
											</div>

											<StatsTable stats={[
												{
													label: <span className='d-flex align-items-center'>
														<span>{text.compoundedAnnualROR}</span>
														<OverlayTrigger
															placement={'right'}
															overlay={
																<Popover>
																	<Popover.Content>
																		{text.compoundedARORTooltip}
																	</Popover.Content>
																</Popover>
															}>
															<i className='fa-solid fa-question-circle text-muted ml-1'></i>
														</OverlayTrigger>
													</span>,
													value: <span>{indexReturns.compoundedAnnualROR ? (indexReturns.compoundedAnnualROR * 100).toFixed(2) + '%' : ''}</span>
												},
												{
													label: text.maxDrawDown,
													value: <span>{indexReturns.maxDrawDown ? (indexReturns.maxDrawDown * 100).toFixed(2) : ''}</span>
												},
												{
													label: text.correlationsp500,
													value: <span>{indexReturns.correlationsp500 ? indexReturns.correlationsp500.toFixed(2) : ''}</span>
												},
												{
													label: text.sharpeAnnualized,
													value: <span>{indexReturns.sharpe ? indexReturns.sharpe.toFixed(2) : ''}</span>
												},
												{
													label: text.standardDeviation,
													value: <span>{indexReturns.standardDeviation ? (indexReturns.standardDeviation * 100).toFixed(2) : ''}</span>
												},
											]}/>

										</div>
									}
									</div>

								</div>

								<div className='col-12 col-lg-9'>

									<h6>{text.performance}</h6>

									<figure className='mb-5 d-flex justify-content-center'>
									{performanceChartOptions &&
										<HighchartsReact
											options={performanceChartOptions}
											highcharts={Highstock}
											constructorType='stockChart'
											containerProps={{id:'performanceChart'}}
											callback={(chart) => {addWaterMark(chart);setPerformanceChart(chart);}}
										/>
									}
									</figure>

								</div>

							</div>
						{postsLoading && text.loading}
						{!!recentPosts && recentPosts.found_posts > 0 &&
							<RelatedArticles
								lang={lang}
								data={recentPosts}
								tag={index.portfolioUri.toLowerCase()}
								/>
						}
						</Tab>

						<Tab className='py-5' eventKey="historical-data" title={text.historicalData}>

							<div className='row'>
							{monthlyReturns.length > 0 &&
								<div className='col-12'>

									<div className='d-flex mb-2'>

										<h3 className='mb-0'>{text.averagePerformance}</h3>

									{!!session.access_token && monthlyReturnsCSVData &&
										<CSVLink
											className='btn btn-link btn-sm ml-auto d-print-none'
											filename={`average-performance.csv`}
											data={monthlyReturnsCSVData}>
											<i className='fa-solid fa-file-spreadsheet mr-1'></i>
											{text.exportData}
										</CSVLink>
									}

									</div>

									<PerformanceTable
										lang={lang}
										returns={monthlyReturns}
										awards={true}
										portfolioUri={portfolioUri}
										/>

									<p className='text-muted'>
										<small>{text.performanceDisclaimer}</small>
									</p>

									<p className='text-muted'>
										<small>{text.indexesDisclaimer}</small>
									</p>

								</div>
							}
							</div>

						</Tab>

						<Tab className='py-5' eventKey="programs" title={text.programs}>

							<div className={'row'} style={{ opacity: ( programsLoading ? .5 : 1 ) }}>

								<div className='col-12'>

									<div className='sticky-top sticky-top-screener'>

										<div className={'d-flex align-items-center justify-content-between py-3 bg-white border-bottom'}>

											<div className={'text-primary fade' + (programsLoading ? ' show' : '')}>
												<i className='fa-solid fa-circle-notch fa-spin'></i>
											</div>

											<Pagination
												totalCount={programs.totalCount}
												totalPages={totalPages}
												pageSize={pageSize}
												pageNumber={pageNumber}
												setQueryParams={setQueryParams}
												lang={lang}
												/>

										</div>

										<ProgramsTable
											lang={lang}
											columns={columns}
											rows={rows}
											sortBy={sortBy}
											orderBy={orderBy}
											toggleSortBy={toggleSortBy}
											headerOnly={true}
											/>

									</div>

								{!!programs.totalCount &&
									<ProgramsTable
										lang={lang}
										columns={columns}
										rows={rows}
										sortBy={sortBy}
										orderBy={orderBy}
										toggleSortBy={toggleSortBy}
										/>
								}
								</div>

							</div>

						</Tab>

					</Tabs>

				</div>

			</div>
		}
		</div>
	)

};

export default Index;
