// helpers
import {
	dateFormat,
	MoneyFormat,
} from './Helpers';


// constants
import {
	PROGRAM_TYPES,
	PROGRAM_SECTOR_FOCUS_TYPES,
	PROGRAM_INVESTOR_REQUIREMENTS_TYPES,
	PROGRAM_ALLOCATION_STRATEGY_TYPES,
	PROGRAM_METHODOLOGY_TYPES,
	PROGRAM_COMPOSITION_TYPES,
} from './Constants';


// filterLabels
export const filterLabels = (filters, blends = [], lang = 'en') => {

	let labels = [];
	let parsed = [];
	let values = [];

	Object.keys(filters).forEach((key) => {

		if( parsed.indexOf(key) > -1 ) return;

		let value = filters[key];

		switch(key) {

			case 'filterByYearStartMin':

				if( 'filterByYearStartMax' in filters ) {

					value += '–' + filters.filterByYearStartMax;

					parsed.push('filterByYearStartMax');

				}

				labels.push( 'Start Date: ' + value );

			break;

			case 'filterByYearStartMax':

				if( !('filterByYearStartMin' in filters) ) {

					labels.push( 'Start Date: –' + value );

				}

			break;

			case 'filterByDatePerformanceMin':

				value = dateFormat(new Date(value), 'M/yyyy');

				if( 'filterByDatePerformanceMax' in filters ) {

					value += '–' + dateFormat(new Date(filters.filterByDatePerformanceMax), 'M/yyyy');

					parsed.push('filterByDatePerformanceMax');

				}

				labels.push( 'Performance Date: ' + value );

			break;

			case 'filterByProgramTypesId':

				value = PROGRAM_TYPES.find((type) => {return type.programTypeId === value}).programType;

				labels.push( 'Program Type: ' + value );

			break;

			case 'filterByCARORMin':

				value = value === '-2' ? '<0%' : ('>'+ value + '%');

				labels.push( 'CAROR: ' + value );

			break;

			case 'filterByMaxRisk':

				labels.push( 'Max Risk: ' + value.substr(1) + '% Drawdown' );

			break;

			case 'filterByAUMMin':

				value = '$' + MoneyFormat(value);

				if( 'filterByAUMMax' in filters ) {

					value += '–$' + MoneyFormat(filters.filterByAUMMax);

					parsed.push('filterByAUMMax');

				}
				else {
					value = '>=' + value;
				}

				labels.push( 'AUM: ' + value );

			break;

			case 'filterByAUMMax':

				if( !('filterByAUMMin' in filters) ) {

					value = '<=$' + MoneyFormat(value);

					labels.push( 'AUM: ' + value );

				}

			break;

			case 'filterByInvestmentMin':

				value = '$' + MoneyFormat(value);

				if( 'filterByInvestmentMax' in filters ) {

					value += '–$' + MoneyFormat(filters.filterByInvestmentMax);

					parsed.push('filterByInvestmentMax');

				}

				labels.push( 'Minimum Investment: ' + value );

			break;

			case 'filterByInvestmentMax':

				if( !('filterByInvestmentMin' in filters) ) {

					value = '<=$' + MoneyFormat(value);

					labels.push( 'Minimum Investment: ' + value );

				}

			break;

			case 'filterByProgramsSectorTypesId':

				value = PROGRAM_SECTOR_FOCUS_TYPES.find((type) => {return type.sectorFocusTypeId === value}).sectorFocus;

				labels.push( 'Sector Focus: ' + value );

			break;

			case 'filterByInvestorRequirementsTypesId':

				value = PROGRAM_INVESTOR_REQUIREMENTS_TYPES.find((type) => {return type.investorRequirementTypeId === value}).investorRequirementType;

				labels.push( 'Requirements: ' + value );

			break;

			case 'filterByBlendCorrelationId':

				const blend = blends.find(blend => blend.blendId === value);

				if( blend ) {
					labels.push( 'Blend Correlation: ' + blend.blendName );
				}

			break;

			case 'filterByAllocationStrategyTypesId':

				values = value.split('&filterByAllocationStrategyTypesId=');

				values.forEach((id) => {
					value = PROGRAM_ALLOCATION_STRATEGY_TYPES.find((type) => {return type.allocationStrategyTypeId === id}).allocationStrategy;
					labels.push( 'Strategy: ' + value );
				});

			break;

			case 'filterByMethodologyTypesId':

				values = value.split('&filterByMethodologyTypesId=');

				values.forEach((id) => {
					value = PROGRAM_METHODOLOGY_TYPES.find((type) => {return type.methodologyTypeId === id}).methodology;
					labels.push( 'Methodology: ' + value );
				});

			break;

			case 'filterByCompositionTypesId':

				values = value.split('&filterByCompositionTypesId=');

				values.forEach((id) => {
					value = PROGRAM_COMPOSITION_TYPES.find((type) => {return type.compositionTypeId === id}).composition;
					labels.push( 'Composition: ' + value );
				});

			break;

			default:

				labels.push( key + ': ' + value );

		}

		parsed.push(key);

	});

	return labels.join(' // ');

};
