import React, {
	useContext,
	useState,
} from 'react';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 * @link https://react-bootstrap.github.io/components/overlays/
 *
 */
import {
	Form,
	OverlayTrigger,
	Popover,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


/*
 * react-country-region-selector
 *
 * @link https://github.com/country-regions/react-country-region-selector
 *
 */
import {
	CountryDropdown,
} from 'react-country-region-selector';


// API
import {
	UserContext,
	authenticate,
	apiRequest,
	mailchimpApiRequest,
	transactionalEmailRequest,
} from '../../api/Api';


// helpers
import {
	countries,
} from '../../helpers/Helpers';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';
import { labels as labelsRegister } from '../../i18n/Register';


// RegisterForm
function RegisterForm({
	lang,
	initialValues,
	setSession=false,
	callback=false,
}) {

	const User = useContext(UserContext);

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsRegister[lang]};

	// error handling
	const [registerError, setRegisterError] = useState(false);

	// form schema
	const schema = yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		Email: yup.string().email().required(text.invalidEmail),
		country: yup.string().required(),
		phonePrefix: yup.string().required(text.required),
		phone: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).nullable().required(text.invalidPhone),
		Password: yup.string().min(6, text.PasswordMinLength.replace('{min', '${min')).matches( (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/)), text.PasswordStrength).required(text.PasswordRequired),
		ConfirmPassword: yup.string().oneOf([yup.ref('Password'), null], text.PasswordMismatch).required(text.PasswordRequired),
		getNewsletter: yup.boolean(),
		isAccredited: yup.boolean(),
	});

	// submit handler
	const handleRegisterSubmit = (values, formikBag) => {

		setRegisterError(false);

		// use object destructuring and the spread operator to omit some props from values
		const {Email, phonePrefix, phone, Password, ConfirmPassword, ...UserProfile} = values;

		// make registration call
		apiRequest({
			path: '/userAccounts/register',
			data:  JSON.stringify({
				UserName: Email,
				Email: Email,
				Password: Password,
				ConfirmPassword: ConfirmPassword,
				UserProfile: JSON.stringify({
					phone: (phonePrefix + ' ' + phone),
					...UserProfile
				}),
				RoleNames: [
					'Member',
				],
			})
		}).then((response) => {

			if( response.error ) {

				// set error
				setRegisterError(response.error);
				// reset 'isSubmitting'
				formikBag.setSubmitting(false);
				// scroll to top
				window.scrollTo(0, 0);

			}
			else {

				const UserId = response.UserId;

				// add user to MailChimp list
				if( UserProfile.getNewsletter === true ) {

					mailchimpApiRequest({
						method: 'setListMember',
						email: Email,
						FNAME: UserProfile.firstName,
						LNAME: UserProfile.lastName,
					});

				}

				// authenticate new user and redirect…
				if( 'function' === typeof User.login && !!setSession ) {

					// send new user notification
					transactionalEmailRequest({
						template: 'user-registration-confirmation',
						email: Email,
						username: Email,
						callback: 'iasg_zoho',
						UserId: UserId,
						firstName: UserProfile.firstName,
						lastName: UserProfile.lastName,
						phone: (phonePrefix + ' ' + phone),
					}).then((response) => {

						authenticate( Email, Password )

							.then((response) => {

								if( response && response.success ) {

									const max_age = (response.expires_in * 1000);

									User.login({
										expires: false,
										expires_in: ( new Date() ).getTime() + max_age,
										access_token: response.access_token,
										refresh_token: response.refresh_token,
										email: Email,
										UserId: UserId,
										Groups: '',
										Roles: 'Member',
										Admin: false,
									});

									const middle = Math.floor(response.access_token.length / 2);
									const token1 = response.access_token.substr(0, middle);
									const token2 = response.access_token.substr(middle);

									document.cookie = `iasg_token1=${token1};path=/;max-age=${max_age}`;
									document.cookie = `iasg_token2=${token2};path=/;max-age=${max_age}`;
									document.cookie = `iasg_access=true;path=/;max-age=${max_age}`;

									window.location = `/managed-futures/performance?reg=inv`;

								}
								else {

									setRegisterError(response.error_description);

								}

							}

						).catch((error) => {

							setRegisterError(error.toString());

						});

					});

				}

				// callback
				if( 'function' === typeof callback ) {
					callback(response);
				}

			}

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={handleRegisterSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='alert alert-danger' hidden={!registerError}>
					{registerError}
				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='firstName'>{text.firstName}*</Form.Label>
							<Form.Control
								type='text'
								name='firstName'
								id='firstName'
								value={values.firstName}
								onChange={handleChange}
								isValid={touched.firstName && !errors.firstName}
								isInvalid={touched.firstName && errors.firstName}
							/>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='lastName'>{text.lastName}*</Form.Label>
							<Form.Control
								type='text'
								name='lastName'
								id='lastName'
								value={values.lastName}
								onChange={handleChange}
								isValid={touched.lastName && !errors.lastName}
								isInvalid={touched.lastName && errors.lastName}
							/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='Email'>{text.Email}*</Form.Label>
							<Form.Control
								type='email'
								name='Email'
								id='Email'
								value={values.Email}
								onChange={handleChange}
								isValid={touched.Email && !errors.Email}
								isInvalid={touched.Email && errors.Email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Email}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12'>

						<Form.Group>
							<Form.Label htmlFor='country'>{text.country}</Form.Label>
							<CountryDropdown
								name='country'
								id='country'
								classes='custom-select'
								value={values.country}
								valueType='short'
								onChange={(val) => {
									setFieldValue('country', val);
									if( countries[val].countryCallingCodes ) {
										setFieldValue('phonePrefix', countries[val].countryCallingCodes[0]);
									}
								}}
								/>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-3 col-sm-2'>

						<Form.Group>
							<Form.Label htmlFor='phonePrefix'>
								<OverlayTrigger
									placement={'right'}
									overlay={
										<Popover>
											<Popover.Content>
												{text.phonePrefixTooltip}
											</Popover.Content>
										</Popover>
									}>
									<i className='fa-solid fa-question-circle text-muted mr-1' style={{fontSize:'87.5%'}}></i>
								</OverlayTrigger>
								{text.phonePrefix}*
							</Form.Label>
							<Form.Control
								type='text'
								name='phonePrefix'
								id='phonePrefix'
								value={values.phonePrefix}
								onChange={handleChange}
								isValid={touched.phonePrefix && !errors.phonePrefix}
								isInvalid={touched.phonePrefix && errors.phonePrefix}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phonePrefix}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-9 col-sm-10'>

						<Form.Group>
							<Form.Label htmlFor='phone'>{text.phone}*</Form.Label>
							<Form.Control
								type='tel'
								name='phone'
								id='phone'
								value={values.phone}
								onChange={handleChange}
								isValid={touched.phone && !errors.phone}
								isInvalid={touched.phone && errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phone}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='Password'>{text.Password}*</Form.Label>
							<Form.Control
								type='password'
								name='Password'
								id='Password'
								autoComplete='off'
								value={values.Password}
								onChange={handleChange}
								isValid={touched.Password && !errors.Password}
								isInvalid={touched.Password && errors.Password}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.Password}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='ConfirmPassword'>{text.ConfirmPassword}*</Form.Label>
							<Form.Control
								type='password'
								name='ConfirmPassword'
								id='ConfirmPassword'
								value={values.ConfirmPassword}
								onChange={handleChange}
								isValid={touched.ConfirmPassword && !errors.ConfirmPassword}
								isInvalid={touched.ConfirmPassword && errors.ConfirmPassword}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.ConfirmPassword}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<div className='form-group'>

							<Form.Check 
								custom
								type='checkbox'
								name='getNewsletter'
								id='getNewsletter'
								value={values.getNewsletter}
								checked={values.getNewsletter}
								onChange={(e) => setFieldValue('getNewsletter', !values.getNewsletter)}
								label={<>
									{text.getNewsletter}
								</>}
							/>

							<Form.Check 
								custom
								type='checkbox'
								name='isAccredited'
								id='isAccredited'
								value={values.isAccredited}
								checked={values.isAccredited}
								onChange={(e) => setFieldValue('isAccredited', !values.isAccredited)}
								label={<>
									{text.isAccredited}
									<OverlayTrigger
										placement={'right'}
										overlay={
											<Popover>
												<Popover.Content>
													{text.isAccreditedTooltip}
												</Popover.Content>
											</Popover>
										}>
										<i className='fa-solid fa-question-circle text-muted ml-1' style={{fontSize:'75%'}}></i>
									</OverlayTrigger>
								</>}
							/>

						</div>

					</div>

				</div>

				<p className='text-muted my-3'>{text.signupDisclaimer}</p>

				<Form.Group>
					<button type='submit' className='btn btn-primary btn-block' disabled={isSubmitting}>
					{isSubmitting &&
						<span>{text.working}</span>
					}
					{!isSubmitting && <>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.createAccount}</span>
					</>}
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default RegisterForm;
