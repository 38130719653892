export const labels = {
	'en': {
		snapshot: 'Snapshot',
		historicalData: 'Historical Data',
		programs: 'Programs',
		performance: 'Performance',
		averagePerformance: 'Average Performance',
		'agricultural-trader-index': 'Agricultural traders often use fundamental inputs to drive discretionary trading. Markets include corn, wheat, soybeans, cattle, and lean hogs but can also include milk futures and others. Supply and demand is the ultimate driver of price in these commodities which requires knowledge of growing conditions, weather, trade reports, storage numbers, and the supply chain amongst other factors.',
		'option-strategy-index': 'A typical option program earns money by selling calls and puts outside of the current range of the market. If these contracts expire worthless, they get to keep the premium value of that option. Traders in this space pay special attention to the VIX index which helps determine option prices and the volatility of the S&P 500.',
		'discretionary-trader-index': 'Discretionary traders use their best judgement to determine the best positions at any given time. Unlike systems that have a defined set of criteria to look at these managers can use any inputs to choose their trades. Often they are focused on a narrow set of markets where they are subject matter experts.',
		'trend-following-index': 'Trend traders typically use a systematic methodology to discover patterns and momentum across multiple sectors of the commodity and financial futures space. Well-known managers in this space include Winton, Transtrend, Fort, and Quantica Capital.',
		'iasg-cta-index': 'An index of all of the traders in the IASG database. Comparable indices in the futures space include the CISDM Index which is an equal weighted view of larger managers in the industry.',
		'diversified-trader-index': 'Futures contracts fall into main categories of financial and commodity futures. Under financial futures we see interest rates, foreign exchange, and stock indices. Commodities include energy, metals, agricultural, and softs. A diversified trading strategy can trade just a few sectors or all of them.',
		'systematic-trader-index': 'As markets have moved to electronic execution, the use of computers and algorithms to drive trading decisions continues to grow. Systems take human emotion out of trading with the goal of providing a consistent approach that can be backtested prior to launch and refined as trading continues. Diversified programs often use this approach due to the wide range of markets traded.',
		'stock-index-trader-index': 'CTAs in this segment use the efficiency of futures contracts to enter and exit long and short positions across indices including the S&P 500, Dow, Nasdaq, and Russell 2000 as well as foreign indices including the Nikkei and the FTSE. Holding periods range from intraday to months depending on the purpose of the strategy.',
	},
	'zh-hans': {
		snapshot: '快照',
		historicalData: '历史数据',
		programs: '程式',
		performance: '表现',
		averagePerformance: '平均表现',
		'agricultural-trader-index': '农产品交易者经常使用基本面投入来推动自由裁量交易。 市场包括玉米、小麦、大豆、牛和瘦肉猪，但也可以包括牛奶期货等。 供需是这些商品价格的最终驱动因素，这需要了解生长条件、天气、贸易报告、存储数量和供应链等因素。',
		'option-strategy-index': '典型的期权计划通过在当前市场范围之外卖出看涨期权和看跌期权来赚钱。 如果这些合同到期毫无价值，他们就可以保留该期权的溢价。 该领域的交易者特别关注 VIX 指数，该指数有助于确定期权价格和标准普尔 500 指数的波动性。',
		'discretionary-trader-index': '全权委托交易者使用他们的最佳判断来确定任何给定时间的最佳头寸。 与具有一组定义标准的系统不同，这些经理可以使用任何输入来选择他们的交易。 通常，他们专注于他们是主题专家的狭窄市场。',
		'trend-following-index': '趋势交易者通常使用系统方法来发现商品和金融期货领域多个领域的模式和动量。 该领域的知名经理人包括 Winton、Transtrend、Fort 和 Quantica Capital。',
		'iasg-cta-index': 'IASG 数据库中所有交易者的索引。 期货领域的可比指数包括 CISDM 指数，该指数是该行业较大经理的同等权重视图。',
		'diversified-trader-index': '期货合约主要分为金融期货和商品期货。 在金融期货下，我们看到利率、外汇和股票指数。 商品包括能源、金属、农业和软商品。 多元化的交易策略可以只交易几个部门或所有部门。',
		'systematic-trader-index': '随着市场转向电子执行，使用计算机和算法来推动交易决策的情况继续增长。 系统将人类情绪排除在交易之外，目的是提供一种一致的方法，可以在启动之前进行回测，并随着交易的继续进行改进。 由于交易的市场范围广泛，多样化的程序经常使用这种方法。',
		'stock-index-trader-index': '该领域的 CTA 使用期货合约的效率来进入和退出包括标准普尔 500 指数、道琼斯指数、纳斯达克指数和罗素 2000 在内的指数以及包括日经指数和富时指数在内的外国指数的多头和空头头寸。 持有期从日内到数月不等，具体取决于策略的目的。',
	}
};
