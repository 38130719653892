import React, {
	useEffect,
	useRef,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	A,
	navigate,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/tabs/
 *
 */
import {
	Form,
	Tabs,
	Tab,
	Toast,
} from 'react-bootstrap';


/*
 * React Bootstrap Typeahead
 *
 * @link https://github.com/ericgio/react-bootstrap-typeahead
 * @link https://ericgio.github.io/react-bootstrap-typeahead
 *
 */
import {
	AsyncTypeahead,
} from 'react-bootstrap-typeahead';


/*
 * react-csv
 *
 * @link https://www.npmjs.com/package/react-csv
 *
 */
import {
	CSVLink,
} from 'react-csv';


// Constants
import {
	GROUP_PENDING_STATUS_ID,
	GROUP_ACTIVE_STATUS_ID,
	GROUP_STATUS_IDS,
	PLATFORMS,
	INSIGHT_PLATFORM_ID,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_STATIC_STATUS_ID,
	PROGRAM_STATUS_TYPES,
	WATCHLIST_COLUMNS,
	PORTFOLIO_COLUMNS,
	SAVED_BLENDS_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
	deleteAccount,
	updateUser,
	editPassword,
	mailchimpApiRequest,
	putRoles,
	deleteRoles,
	patchGroup,
	deleteGroup,
} from '../api/Api';


// helpers
import {
	parseDate,
	dateFormat,
} from '../helpers/Helpers';


// forms
import {
	RegisterForm,
	GroupForm,
	UserForm,
	PasswordForm,
} from './forms/Forms';


// partials
import {
	ConfirmModal,
	Loader,
	WatchlistTable,
	PortfolioTable,
	BlendsTable,
	PendingTable,
	ProgramsTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsAdmin } from '../i18n/Admin';
import { labels as labelsForms } from '../i18n/Forms';


// Admin
function Admin({
	lang,
	session,
	tab,
	id,
}) {

	const text = {...labelsGlobal[lang],...labelsAdmin[lang],...labelsForms[lang]};

	// notifications
	const [isLoading, setIsLoading] = useState(true);
	const [showToast, setShowToast] = useState(false);
	const [toastDelay, setToastDelay] = useState(3000);
	const [toastMessage, setToastMessage] = useState('');

	// users
	const [usersQueryKey, setUsersQueryKey] = useState( (new Date()).getTime() );
	const [usersSortBy, setUsersSortBy] = useState('createdUtc');
	const [usersOrderBy, setUsersOrderBy] = useState('DESC');
	const [usersPageNumber, setUsersPageNumber] = useState(1);
	const [usersPageSize, setUsersPageSize] = useState(10);
	const [usersTotalPages, setUsersTotalPages] = useState(1);
	const [usersTotalCount, setUsersTotalCount] = useState(0);
	const [usersExport, setUsersExport] = useState(false);
	const [users, setUsers] = useState([]);
	const [userColumns] = useState([
		{
			field: 'name',
			headerName: 'Name',
			sortable: true,
		},
		{
			field: 'email',
			headerName: 'Email',
			sortable: true,
		},
		{
			field: 'phone',
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'createdUtc',
			headerName: 'Joined',
			sortable: true,
		},
		{
			field: 'actions',
			headerName: '',
			sortable: false,
		},
	]);
	const [user, setUser] = useState(false);
	const [editingUser, setEditingUser] = useState(false);
	const [showUserConfirm, setShowUserConfirm] = useState(false);
	const confirmDeleteUser = (user) => {
		setUser(user);
		setShowUserConfirm(true);
	};
	const closeUserConfirm = () => {
		setUser(false);
		setShowUserConfirm(false);
	};
	const deleteUser = () => {

		if( !user ) return;

		deleteAccount(user.userId, session.access_token).then((response) => {

			setUsers(users.filter((u) => {
				return u !== user;
			}));
			setUsersTotalCount(usersTotalCount - 1);
			setShowUserConfirm(false);
			setShowToast(true);
			setToastMessage(response.message);

		});

	};
	const handleUpdateUser = (values) => {

		// did email change?
		const emailChanged = editingUser.Email !== values.Email;

		// use object destructuring and the spread operator to omit Email from values
		const {Email, ...UserProfile} = values;

		// updateUser
		updateUser(id, {
			Email: Email,
			UserProfile: JSON.stringify(UserProfile)
		}, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(text.profileSaved);
		});

		// add user to MailChimp list
		if( UserProfile.getNewsletter === true ) {

			mailchimpApiRequest({
				method: 'setListMember',
				email: editingUser.getNewsletter === true ? editingUser.Email : Email,
				FNAME: UserProfile.firstName,
				LNAME: UserProfile.lastName,
				new_email: emailChanged ? Email : '',
			});

		}
		// remove from MailChimp list
		else if( editingUser.getNewsletter === true && UserProfile.getNewsletter !== true ) {

			mailchimpApiRequest({
				method: 'deleteListMember',
				email: Email,
			});

		}

		// update editingUser to prevent weirdness on re-saves…
		setEditingUser({
			...editingUser,
			Email: Email,
			getNewsletter: UserProfile.getNewsletter,
		});

	};
	const handleUpdatePassword = (values) => {

		editPassword(values, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(text.passwordSaved);
		});

	};

	// groups
	const [groupsQueryKey] = useState( (new Date()).getTime() );
	const [groupsSortBy, setGroupsSortBy] = useState('dateCreated');
	const [groupsOrderBy, setGroupsOrderBy] = useState('ASC');
	const [filterByGroupStatusId, setFilterByGroupStatusId] = useState(GROUP_PENDING_STATUS_ID);
	const [groupsPageNumber, setGroupsPageNumber] = useState(1);
	const [groupsPageSize, setGroupsPageSize] = useState(10);
	const [groupsTotalPages, setGroupsTotalPages] = useState(1);
	const [groupsTotalCount, setGroupsTotalCount] = useState(0);
	const [groups, setGroups] = useState([]);
	const [groupsColumns] = useState([
		{
			field: 'groupName',
			headerName: 'Name',
			sortable: true,
		},
		{
			field: 'groupStatusId',
			headerName: 'Status',
			sortable: true,
		},
		{
			field: 'dateCreated',
			headerName: 'Created',
			sortable: true,
		},
		{
			field: 'activate',
			headerName: '',
			sortable: false,
		},
		{
			field: 'actions',
			headerName: '',
			sortable: false,
		},
	]);
	const [group, setGroup] = useState(false);
	const [showGroupConfirm, setShowGroupConfirm] = useState(false);
	const confirmDeleteGroup = (group) => {
		setGroup(group);
		setShowGroupConfirm(true);
	};
	const closeGroupConfirm = () => {
		setGroup(false);
		setShowGroupConfirm(false);
	};
	const handleDeleteGroup = () => {

		if( !group ) return;

		deleteGroup(group.groupId, session.access_token).then((response) => {
			setGroups(groups.filter((g) => {
				return g !== group;
			}));
			setShowGroupConfirm(false);
			setShowToast(true);
			setToastMessage('CTA Deleted');
		});

	};

	// status
	const groupStatus = (groupStatusId) => {
		let status = GROUP_STATUS_IDS.find((s) => {return s.groupStatusId === groupStatusId});
		return status ? status.groupStatus : '';
	};


	// handleUsersSearch
	const [isSearchingUsers, setIsSearchingUsers] = useState(false);
	const filterByUsers = () => true;
	const usersSearch = useRef(null);
	const handleUsersSearch = (query) => {

	    setIsSearchingUsers(true);

		getRequest('/userAccounts', {
			search: query,
			pageSize: 10,
		}, session.access_token).then((response) => {
			setUsers(response.items);
			setIsSearchingUsers(false);
		});

	};

	// roles
	const [roles, setRoles] = useState([]);
	const handleUpdateRoles = () => {
		putRoles(editingUser.UserId, editingUser.RoleNames, session.access_token).then((response) => {
			deleteRoles(editingUser.UserId, roles.filter((role) => {
				return editingUser.RoleNames.indexOf(role) === -1;
			}), session.access_token).then((response) => {
				setShowToast(true);
				setToastMessage('Roles updated');
			});
		});
	};

	// watchlists
	const [watchlistsColumns] = useState([
		...WATCHLIST_COLUMNS.map((column) => {
			return {...column,sortable:false};
		}),
	]);
	const [watchlistsRows, setWatchlistsRows] = useState([]);
	const [watchlistsOrderBy] = useState('ASC');
	const [watchlistsSortBy] = useState('groupName');

	// portfolios
	const [portfoliosColumns] = useState([
		...PORTFOLIO_COLUMNS.filter((column) => {
			return ['quantity','dateAllocation'].indexOf(column.field) === -1;
		}).map((column) => {
			return {...column,sortable:false};
		}),
	]);
	const [portfoliosRows, setPortfoliosRows] = useState([]);

	// blends
	const [blendsColumns] = useState(SAVED_BLENDS_COLUMNS);
	const [blendsRows, setBlendsRows] = useState([]);
	const [blendsOrderBy,setBlendsOrderBy] = useState('DESC');
	const [blendsSortBy,setBlendsSortBy] = useState('dateCreated');

	// insightPrograms
	const [filterByPlatformId, setFilterByPlatformId] = useState( INSIGHT_PLATFORM_ID );
	const [filterByProgramStatusId, setFilterByProgramStatusId] = useState( PROGRAM_ACTIVE_STATUS_ID );
	const [insightProgramsQueryKey, setInsightProgramsQueryKey] = useState( (new Date()).getTime() );
	const [insightProgramsSortBy, setInsightProgramsSortBy] = useState('dateCreated');
	const [insightProgramsOrderBy, setInsightProgramsOrderBy] = useState('DESC');
	const [insightProgramsPageNumber, setInsightProgramsPageNumber] = useState(1);
	const [insightProgramsPageSize, setInsightProgramsPageSize] = useState(25);
	const [insightProgramsTotalPages, setInsightProgramsTotalPages] = useState(1);
	const [insightProgramsTotalCount, setInsightProgramsTotalCount] = useState(0);
	const [insightPrograms, setInsightPrograms] = useState([]);

	// view logs
	const [logsSortBy, setLogsSortBy] = useState('dateCreated');
	const [logsOrderBy, setLogsOrderBy] = useState('DESC');
	const [logsPageNumber, setLogsPageNumber] = useState(1);
	const [logsPageSize, setLogsPageSize] = useState(25);
	const [logsTotalPages, setLogsTotalPages] = useState(1);
	const [logsTotalCount, setLogsTotalCount] = useState(0);
	const [logs, setLogs] = useState(false);
	const [logsColumns] = useState([
		{
			field: 'groupName',
			headerName: 'CTA',
			sortable: true,
		},
		{
			field: 'programName',
			headerName: 'Program',
			sortable: true,
		},
		{
			field: 'dateCreated',
			headerName: 'Viewed',
			sortable: true,
		},
	]);

	// pagination
	const Pagination = (props) => {

		const resultsFrom = props.pageNumber <= 1 ? 1 : (props.pageNumber - 1) * props.pageSize + 1;
		const resultsTo = (props.pageSize * props.pageNumber) > props.totalCount ? props.totalCount : (props.pageSize * props.pageNumber);

		return (
			<div className='form-inline'>
				<div className='form-group pr-2'>
					<i className='fa-solid fa-circle-notch fa-spin text-primary mr-2' hidden={!usersLoading && !groupsLoading && !logsLoading}></i>
					<label className='pr-2'>
						<b>{text.rows}:</b>
					</label>
					<select className='custom-select custom-select-sm'
						value={props.pageSize}
						onChange={(e) => {props.setPageSize(e.currentTarget.value);props.setPageNumber(1);}}>
					{[10,25,50,100].map((size) =>
						<option key={size} value={size}>{size}</option>
					)}
					</select>
				</div>
				<span className='pr-3'>
					{resultsFrom} – {resultsTo} {text.of} {props.totalCount}
				</span>
				<span className='pr-2'>
					<b>{text.page}</b>
				</span>
				<span className='pr-2'>
					<input className='form-control form-control-sm'
						type='number'
						step='1'
						min='1'
						max={props.totalPages}
						value={props.pageNumber}
						onChange={(e) => {props.setPageNumber(e.currentTarget.value)}}/>
				</span>
				<span className='pr-2'>
					{text.of} {props.totalPages}
				</span>
				<button className='btn btn-sm btn-primary mr-1'
					disabled={parseInt(props.pageNumber) === 1}
					onClick={(e) => {e.preventDefault();props.setPageNumber((parseInt(props.pageNumber)-1))}}>
					<i className='fa-solid fa fa-chevron-left mx-1'></i>
				</button>
				<button className='btn btn-sm btn-primary'
					disabled={parseInt(props.pageNumber) === props.totalPages}
					onClick={(e) => {e.preventDefault();props.setPageNumber((parseInt(props.pageNumber)+1))}}>
					<i className='fa-solid fa fa-chevron-right mx-1'></i>
				</button>
			</div>
		)

	};

	// run our queries
	const [
		{
			isLoading: usersLoading,
			isError: isUsersError,
		},
		{
			data: CSVUsers,
		},
		{
			isLoading: groupsLoading,
			isError: isGroupsError,
		},
		{
			isLoading: CSVGroupsLoading,
			data: CSVGroups,
		},
		{
			isLoading: logsLoading,
		},
	] = useQueries([
		{
			queryKey: ['users', {
				SortBy: usersSortBy,
				OrderBy: usersOrderBy,
				pageSize: usersPageSize,
				pageNumber: usersPageNumber,
			}, usersQueryKey],
			queryFn: () => getRequest('/userAccounts', {
				SortBy: usersSortBy,
				OrderBy: usersOrderBy,
				pageSize: usersPageSize,
				pageNumber: usersPageNumber,
			}, session.access_token),
			onSuccess: (response) => {
				// setUsers
				setUsers(response.items);
				setUsersTotalCount( response.totalCount );
				setUsersTotalPages( Math.ceil(response.totalCount / usersPageSize) );
			},
			enabled: !!(session.Admin)
		},
		{
			queryKey: ['CSVUsers', {
				pageSize: usersTotalCount,
			}],
			queryFn: () => getRequest('/userAccounts', {
				pageSize: usersTotalCount,
			}, session.access_token),
			enabled: !!(session.Admin) && !!(usersExport)
		},
		{
			queryKey: ['groups', {
				filterByGroupStatusIds: filterByGroupStatusId,
				SortBy: groupsSortBy,
				OrderBy: groupsOrderBy,
				pageSize: groupsPageSize,
				pageNumber: groupsPageNumber,
			}, groupsQueryKey],
			queryFn: () => getRequest('/groups', {
				filterByGroupStatusIds: filterByGroupStatusId,
				SortBy: groupsSortBy,
				OrderBy: groupsOrderBy,
				pageSize: groupsPageSize,
				pageNumber: groupsPageNumber,
			}, session.access_token),
			onSuccess: (response) => {
				// setGroups
				setGroups(response.items.map((group) => {
					return {
						...group,
						groupUri: group.groupUri.toLowerCase(),
					}
				}));
				setGroupsTotalCount( response.totalCount );
				setGroupsTotalPages( Math.ceil(response.totalCount / groupsPageSize) );
			},
			enabled: !!(session.Admin)
		},
		{
			queryKey: ['CSVGroups', {
				filterByGroupStatusIds: filterByGroupStatusId,
				pageSize: groupsTotalCount,
			}, groupsQueryKey],
			queryFn: () => getRequest('/groups', {
				filterByGroupStatusIds: filterByGroupStatusId,
				pageSize: groupsTotalCount,
			}, session.access_token),
			enabled: !!(session.Admin) && (groupsTotalCount > 0)
		},
		{
			queryKey: ['viewLogs', {
				filterByUserId: id,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsPageSize,
				pageNumber: logsPageNumber,
			}, session.access_token],
			queryFn: () => getRequest('/viewLog', {
				filterByUserId: id,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsPageSize,
				pageNumber: logsPageNumber,
			}, session.access_token),
			onSuccess: (response) => {
				setLogs(response.items);
				setLogsTotalCount( response.totalCount );
				setLogsTotalPages( Math.ceil(response.totalCount / logsPageSize) );
			},
			enabled: !!(session.Admin) && ('users' === tab) && !!(id && 'add' !== id)
		},
		{
			queryKey: ['roles', session.access_token],
			queryFn: () => getRequest('/userAccounts/roles', {}, session.access_token),
			onSuccess: (response) => {
				setRoles(session.Admin ? [
					'Administrator',
					...response.roles.sort(),
				] : response.roles);
			},
			enabled: !!(session.Admin)
		},
		{
			queryKey: ['user', {
				filterByUserId: id,
			}],
			queryFn: () => getRequest('/userAccounts', {
				filterByUserId: id,
			}, session.access_token),
			onSuccess: (response) => {

				getRequest( `/userAccounts`, {
					email: encodeURIComponent(response.items[0].email),
				}, session.access_token ).then((response) => {

					let User = response.UserProfile;

					for (var prop in User) {
						switch(prop) {
							case 'country':
								User.country = 'USA' === User.country ? 'US' : User.country;
							break;
							case 'website':
								User.website = User.website ? (User.website.indexOf('http') === 0 ? User.website : 'http://' + User.website) : '';
							break;
							default:
								User[prop] = User[prop] ? User[prop] : '';
						}
					}

					setEditingUser({
						...User,
						UserId: response.UserId,
						Email: response.Email,
						RoleNames: response.RoleNames,
						lockedOut: response.LockoutEndUtc && dateFormat(parseDate(response.LockoutEndUtc), 'yyyyMMddHms') > dateFormat(new Date(), 'yyyyMMddHms'),
					});

					// get watchlists
					getRequest('/watchlists', {
						filterByUserId: response.UserId,
						pageSize: 100,
					}, session.access_token).then((response) => {
						setWatchlistsRows(response.items);
					});

					// get portfolios
					getRequest('/portfolios', {
						filterByUserId: response.UserId,
						pageSize: 100,
					}, session.access_token).then((response) => {
						setPortfoliosRows(response.items);
					});

					// get blends
					getRequest('/blends', {
						filterByUserId: response.UserId,
						pageSize: 1000,
					}, session.access_token).then((response) => {
						setBlendsRows(response.items);
					});

				});
			},
			enabled: !!(session.Admin) && ('users' === tab) && !!(id && 'add' !== id)
		},
		{
			queryKey: ['insightPrograms', {
				filterByPlatformId: filterByPlatformId,
				filterByProgramStatusId: filterByProgramStatusId,
				SortBy: insightProgramsSortBy,
				OrderBy: insightProgramsOrderBy,
				pageSize: insightProgramsPageSize,
				pageNumber: insightProgramsPageNumber,
			}, insightProgramsQueryKey],
			queryFn: () => getRequest('/programsListings', {
				filterByPlatformId: filterByPlatformId,
				filterByGroupStatusIds: PROGRAM_STATIC_STATUS_ID,
				filterByProgramStatusIds: filterByProgramStatusId,
				filterByProgramTypesId: [
					'3DE844D6-1370-4B51-ACF0-670516FC00B5',
					'517067EE-C27F-4E40-BF04-E22512AB2EDE',
					'97F42B3E-7383-4DEF-810B-E6EEA42FFEEE',
				].join('&filterByProgramTypesId='),
				SortBy: insightProgramsSortBy,
				OrderBy: insightProgramsOrderBy,
				pageSize: insightProgramsPageSize,
				pageNumber: insightProgramsPageNumber,
			}, session.access_token),
			onSuccess: (response) => {
				setInsightPrograms(response?.items||[]);
				setInsightProgramsTotalCount( response.totalCount );
				setInsightProgramsTotalPages( Math.ceil(response.totalCount / insightProgramsPageSize) );
			},
			enabled: !!(session.PlatformAdmin)
		},
	]);


	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	// access + redirects
	useEffect(() => {
		if( !session.Admin ) {
			window.location = tab ? `/login?redirect=/admin/${tab}` : `/login?redirect=/admin`;
		}
		setIsLoading(false);
	}, [
		lang,
		session,
		tab,
	]);

	const isError = isUsersError || isGroupsError;

	if ( isLoading || !session.access_token ) {
		return (
			<Loader/>
		)
	}

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	return (
		<div className={'container'}>

			<div className='row'>

				<div className='col-12'>

					<div className='row align-items-center mb-4'>

						<div className='col-12 col-md-6'>

							<h1 className='mb-0'>
								{text.pageTitle}
							</h1>

						</div>

					</div>

					<div
						className='position-relative'
						aria-live='polite'
						aria-atomic='true'>

						<div
							className='position-absolute d-flex justify-content-center'
							style={{left:0,right:0}}>

							<Toast
								show={showToast}
								onClose={() => {setShowToast(false);setToastDelay(3000);}}
								delay={toastDelay}
								autohide
								className='border-success position-absolute mt-3'>

								<Toast.Body
									className='text-success text-center py-1'>
									<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
									{toastMessage}
								</Toast.Body>

							</Toast>

						</div>

					</div>

					<Tabs
						className={'iasg-tabs'}
						defaultActiveKey={tab ? tab : 'users'}
						onSelect={(k) => {
							navigate(`/admin/${k}`);
							if( editingUser && 'users' === k ) {
								setEditingUser(false);
								setUsersQueryKey( (new Date()).getTime() );
							}
						}}>

						<Tab className='py-5' eventKey="users" title={`Users`}>

							<ConfirmModal
								lang={lang}
								showConfirm={showUserConfirm}
								handleCloseConfirm={closeUserConfirm}
								confirmCallback={deleteUser}
								message={`Are you sure you want to delete this user?`}
								/>

						{ 'users' === tab && 'add' === id &&
							<div className='row justify-content-center'>
				
								<div className='col-12 col-md-10 col-lg-8 col-xl-7'>
				
									<h2 className='mb-4'>
										<i className='fa-solid fa-user-plus mr-2' style={{fontSize:'75%'}}></i>
										{`Add User`}
									</h2>

									<RegisterForm
										lang={lang}
										initialValues={{
											firstName: '',
											lastName: '',
											Email: '',
											country: ('zh-hans' === lang ? 'CN' : 'US'),
											phonePrefix: ('zh-hans' === lang ? '+86' : '+1'),
											phone: '',
											Password: '',
											ConfirmPassword: '',
											getNewsletter: false,
										}}
										callback={(data) => {
											navigate(`/admin/users/${data.UserId}`);
										}}
										/>
				
								</div>
				
							</div>
						}
						{ (id && 'add' !== id && !editingUser) &&
							<div style={{padding:'.75rem .5rem'}}>{text.loading}</div>
						}
						{editingUser && <>
							<h2 className='mb-4 text-center'>
								<i className='fa-solid fa-edit mr-2' style={{fontSize:'75%'}}></i>
								<span className='pr-2'>{`Editing`}</span>
								<span>{editingUser.firstName} {editingUser.lastName}</span>
							{editingUser.lockedOut &&
								<span className='badge badge-danger ml-2' style={{fontSize:'1rem'}}>
									<span>{`Locked`}</span>
									<i className='fa-solid fa-lock ml-1'
										title={`Unlock this user`}
										style={{fontSize:'87.5%',cursor:'pointer'}}
										onMouseOver={(e) => {e.currentTarget.classList.replace('fa-lock','fa-unlock-alt');}}
										onMouseOut={(e) => {e.currentTarget.classList.replace('fa-unlock-alt','fa-lock');}}
										onClick={(e) => {
											getRequest(`/userAccounts/unlock`, {
												email: encodeURIComponent(editingUser.Email),
											}, session.access_token).then((response) => {
												setShowToast(true);
												setToastMessage(response.message);
												setEditingUser({
													...editingUser,
													lockedOut: null,
												});
											});
										}}></i>
								</span>
							}
							</h2>

							<Tabs
								className={'iasg-tabs justify-content-center'}
								defaultActiveKey={'profile'}>

								<Tab
									className='pb-4'
									eventKey='profile'
									title={text.profile}>

									<div className='row justify-content-center'>

										<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

											<UserForm
												text={text}
												initialValues={editingUser}
												onSubmit={handleUpdateUser}
												/>

										</div>

									</div>

								</Tab>

								<Tab
									className='pb-4'
									eventKey='password'
									title={text.Password}>

									<div className='row justify-content-center'>

										<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

											<PasswordForm
												text={text}
												initialValues={{
													NewPassword: '',
													PasswordConfirmation: '',
												}}
												onSubmit={(values) => {
													handleUpdatePassword({
														Email: editingUser.Email,
														...values,
													})
												}}
												/>

										</div>

									</div>

								</Tab>

								<Tab
									className='py-5'
									eventKey='roles'
									title={`Roles`}>

									<div className='row justify-content-center'>

										<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

											<form noValidate className='py-4'>
												<div className='form-group'>
												{roles.map(role =>
													<Form.Check 
														key={role}
														custom
														type='checkbox'
														name='roles[]'
														id={`role-${role}`}
														value={role}
														checked={editingUser.RoleNames.indexOf(role) > -1}
														onChange={(e) => {
															setEditingUser({
																...editingUser,
																RoleNames: e.currentTarget.checked ? [
																	...editingUser.RoleNames,
																	role,
																] : editingUser.RoleNames.filter((r) => {return r !== role;}),
															});
														}}
														label={<>
															{role}
														</>}
													/>
												)}
												</div>

												<Form.Group>
													<button type='submit' className='btn btn-primary btn-block' onClick={(e) => {
														e.preventDefault();
														handleUpdateRoles();
													}}>
														<i className='fa fa-chevron-right pr-2'></i>
														<span>{text.saveChanges}</span>
													</button>
												</Form.Group>
											</form>

										</div>

									</div>

								</Tab>

								<Tab className='py-5' eventKey="watchlist" title={`Watchlist`}>
								{watchlistsRows.length > 0 ? (
									<WatchlistTable
										lang={lang}
										session={session}
										columns={watchlistsColumns}
										rows={watchlistsRows}
										sortBy={watchlistsSortBy}
										orderBy={watchlistsOrderBy}
										setRows={setWatchlistsRows}
										/>
								) : (
									<div className='alert alert-warning'>
										{`No programs in watchlist.`}
									</div>
								)}
								</Tab>

								<Tab className='py-5' eventKey="portfolio" title={`Portfolio`}>
								{portfoliosRows.length > 0 ? (
									<PortfolioTable
										lang={lang}
										session={session}
										columns={portfoliosColumns}
										rows={portfoliosRows}
										setRows={setPortfoliosRows}
										/>
								) : (
									<div className='alert alert-warning'>
										{`No programs in portfolio.`}
									</div>
								)}
								</Tab>

								<Tab className='py-5' eventKey="saved-blends" title={text.savedBlends}>
								{blendsRows.length > 0 ? (
									<BlendsTable
										lang={lang}
										session={session}
										columns={blendsColumns}
										rows={blendsRows}
										sortBy={blendsSortBy}
										orderBy={blendsOrderBy}
										toggleSortBy={(field) => {
											setBlendsRows(('DESC' === blendsOrderBy) ? blendsRows.sort((a,b) => {
												return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
											}) : blendsRows.sort((a,b) => {
												return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
											}));
											setBlendsSortBy(field);
											setBlendsOrderBy('DESC' === blendsOrderBy ? 'ASC' : 'DESC');
										}}
										setCurrentBlend={(blend) => {window.location = `/tools/blender?blendId=${blend.blendId}`;}}
										setRows={setBlendsRows}
										/>
								) : (
									<div className='alert alert-warning'>
										{`No saved blends found.`}
									</div>
								)}
								</Tab>

								<Tab
									className='py-5'
									eventKey='logs'
									title={text.logs}>
								{ !logs &&
									<div className='py-3' style={{padding:'.75rem .5rem'}}>{text.loading}</div>
								}
								{ logs && !logs.length &&
									<div className='alert alert-warning'>{`No logs found…`}</div>
								}
								{!!logs.length && <>
									<div className='row no-gutters align-items-center py-3 bg-white border-bottom'>
										<div className='col-auto ml-xl-auto pt-3 pt-xl-0' hidden={logsTotalCount <= logsPageSize}>
											<Pagination
												totalCount={logsTotalCount}
												totalPages={logsTotalPages}
												pageSize={logsPageSize}
												pageNumber={logsPageNumber}
												setPageNumber={setLogsPageNumber}
												setPageSize={setLogsPageSize}
												/>
										</div>
									</div>
									<div className={'table-responsive sticky-thead mb-3'}>
										<table className='table table-sm table-striped'>
											<thead>
												<tr>
												{logsColumns.map(column =>
													<th key={column.field} scope='col' style={{
														cursor: (column.sortable ? 'pointer' : 'default'),
														width: 'actions' === column.field ? '1%' : 'auto',
													}}
														onClick={(e) => {
															if( !column.sortable ) return;
															switch(column.field) {
																case 'name':
																	setLogsSortBy('lastName');
																break;
																default:
																	setLogsSortBy(column.field);
															}
															setLogsOrderBy('DESC' === logsOrderBy ? 'ASC' : 'DESC');
														}}>
														<span>{column.headerName}</span>
													{column.field === logsSortBy &&
														<i className={ 'DESC' === logsOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
													}
													{'name' === column.field && 'lastName' === logsSortBy &&
														<i className={ 'DESC' === logsOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
													}
													</th>
												)}
												</tr>
											</thead>
											<tbody>
											{logs.map(log =>
												<tr key={log.dateCreated}>
												{logsColumns.map(column =>
													<td key={`${log.dateCreated}-${column.field}`} style={{width:'40%'}}>
													{'groupName' === column.field &&
														<div>
															<A className='d-block' href={`/groups/${log.groupUri}`}>
																{log.groupName}
															</A>
														</div>
													}
													{'programName' === column.field &&
														<div>
															<A className='d-block' href={`/groups/${log.groupUri}/programs/${log.programUri}`}>
																{log.programName}
															</A>
														</div>
													}
													{'dateCreated' === column.field &&
														<div style={{whiteSpace:'nowrap'}}>
															{dateFormat(parseDate(log.dateCreated), 'MM/dd/yyyy, H:mm')}
														</div>
													}
													</td>
												)}
												</tr>
											)}
											</tbody>
										</table>
									</div>
								</>}
								</Tab>

							</Tabs>
						</>}
						{!editingUser && !id &&
							<div className='row no-gutters align-items-center py-3 bg-white border-bottom'>
								<div className='col-auto pr-2'>
									<div className='position-relative' style={{width:'320px'}}>
										<AsyncTypeahead
											size='small'
											filterBy={filterByUsers}
											id='usersSearch'
											isLoading={isSearchingUsers}
											labelKey={(option) => `${option.firstName} ${option.lastName}`}
											ref={usersSearch}
											delay={500}
											minLength={3}
											onChange={(selected) => {
												if( !selected.length ) return;
												let user = selected[0];
												navigate(`/admin/users/${user.userId}`);
												usersSearch.current.clear();
											}}
											onSearch={handleUsersSearch}
											options={users}
											placeholder={`Search…`}
											renderMenuItemChildren={(option, props) => (
												<>
													<div className='userFullName'>{option.firstName} {option.lastName}</div>
													<small className='userEmail'>{option.email}</small>
												</>
											)}
											/>
									</div>
									<button className='btn btn-sm'
										style={{position:'absolute',top:'1px',right:'.5rem'}}
										hidden={usersSearch && usersSearch.current && !usersSearch.current.state.text}
										disabled={false}
										onClick={(e) => {
											e.preventDefault();
											usersSearch.current.clear();
											setUsersQueryKey( (new Date()).getTime() );
											e.currentTarget.blur();
										}}>
										<i className='fa-solid fa-times-circle'></i>
									</button>
								</div>
								<div className='col-auto'>
									<A className='btn btn-outline-primary btn-sm ml-1' href={`/admin/users/add`}>
										<i className='fa-solid fa-user-plus mr-1'></i>
										{`Add User`}
									</A>
								</div>
							{!CSVUsers &&
								<div className='col-auto'>
									<button
										type='button'
										disabled={usersExport}
										className='btn btn-link btn-sm ml-1'
										onClick={(e) => {
											setUsersExport(true);
											e.currentTarget.blur();
										}}>
									{usersExport && <>
										<i className='fa-solid fa-fw fa-circle-notch fa-spin mr-1'></i>
										{`Preparing Export…`}
									</>}
									{!usersExport && <>
										<i className='fa-solid fa-fw fa-file-download mr-1'></i>
										{`Export Users`}
									</>}
									</button>
								</div>
							}
							{CSVUsers &&
								<div className='col-auto'>
									<CSVLink
										className='btn btn-link btn-sm ml-1'
										filename={`users.csv`}
										data={CSVUsers.items}>
										<i className='fa-solid fa-fw fa-file-download mr-1'></i>
										{`Download CSV`}
									</CSVLink>
								</div>
							}
								<div className='col-auto ml-xl-auto pt-3 pt-xl-0'>
									<Pagination
										totalCount={usersTotalCount}
										totalPages={usersTotalPages}
										pageSize={usersPageSize}
										pageNumber={usersPageNumber}
										setPageNumber={setUsersPageNumber}
										setPageSize={setUsersPageSize}
										/>
								</div>
							</div>
						}
						{!editingUser && !id && users && users.length > 0 &&
							<div className={'table-responsive sticky-thead mb-3'}>
								<table className='table table-sm table-striped'>
									<thead>
										<tr>
										{userColumns.map(column =>
											<th key={column.field} scope='col' style={{
												cursor: (column.sortable ? 'pointer' : 'default'),
												width: 'actions' === column.field ? '1%' : 'auto',
											}}
												onClick={(e) => {
													if( !column.sortable ) return;
													switch(column.field) {
														case 'name':
															setUsersSortBy('lastName');
														break;
														default:
															setUsersSortBy(column.field);
													}
													setUsersOrderBy('DESC' === usersOrderBy ? 'ASC' : 'DESC');
												}}>
												<span>{column.headerName}</span>
											{column.field === usersSortBy &&
												<i className={ 'DESC' === usersOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
											}
											{'name' === column.field && 'lastName' === usersSortBy &&
												<i className={ 'DESC' === usersOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
											}
											</th>
										)}
										</tr>
									</thead>
									<tbody>
									{users.map(user =>
										<tr key={user.userId}>
										{userColumns.map(column =>
											<td key={`${user.userId}-${column.field}`} style={{width:'40%'}}>
											{'name' === column.field &&
												<div>
													<A className='d-block' href={`/admin/users/${user.userId}`}>
														{user.firstName} {user.lastName}<i className='fa-solid fa-edit text-success ml-1' style={{fontSize:'90%'}}></i>
													</A>
													<span>{user.email}</span>
												</div>
											}
											{'email' === column.field &&
												<div>
													<a className='d-block' href={`mailto:${user.email}`}>{user.email}</a>
												</div>
											}
											{'phone' === column.field &&
												<div>
													<a className='d-block' href={`tel:${user.phone}`}>{user.phone}</a>
												</div>
											}
											{'createdUtc' === column.field &&
												<div>
													{dateFormat(parseDate(user.createdUtc), 'MM/dd/yyyy')}
												</div>
											}
											{'actions' === column.field &&
												<div className='d-flex justify-content-center'>
													<A className='btn btn-link py-0 text-success' href={`/admin/users/${user.userId}`}>
														<i className='fa-solid fa-edit'></i>
													</A>
													<button className='btn btn-link py-0 text-danger'
														onClick={(e) => {confirmDeleteUser(user);}}>
														<i className='fa-solid fa-times-circle'></i>
													</button>
												</div>
											}
											</td>
										)}
										</tr>
									)}
									</tbody>
								</table>
							</div>
						}
						</Tab>

						<Tab className='py-5' eventKey="groups" title={`CTAs`}>

							<ConfirmModal
								lang={lang}
								showConfirm={showGroupConfirm}
								handleCloseConfirm={closeGroupConfirm}
								confirmCallback={handleDeleteGroup}
								message={`Are you sure you want to delete this CTA?`}
								/>

						{ 'groups' === tab && 'add' === id &&
							<div className='row justify-content-center'>
				
								<div className='col-12 col-md-10 col-lg-8 col-xl-7'>
				
									<h2 className='mb-4'>
										{`Add CTA`}
									</h2>

									<GroupForm
										lang={lang}
										session={session}
										initialValues={{
											groupName: '',
											webUrl: '',
											descriptionLong: '',
											groupStatusId: GROUP_PENDING_STATUS_ID,
											groupsTypes: [],
											groupsMemberships: [
												{
													membershipTypeId: '35a5073e-b8df-4204-8c2e-1f88a64d8852',
													membershipUrl: null,
													title: 'NFA',
													info: '',
												},
											],
											platforms: [
												'9279b5f5-88cc-4dc8-ab01-4b75d69dc0ca',
												'946886be-19fb-4bd1-a245-f69efb3227f1',
											],
											contacts: [],
											AcceptedAgreement: false,
										}}
										callback={(data) => {
											window.location = `/groups/${data.groupUri}`;
										}}
										/>
				
								</div>
				
							</div>
						}
						{ 'add' !== id &&
							<div className='row no-gutters align-items-center py-3 bg-white border-bottom'>
								<div className='col-auto pr-2'>
									<div style={{width:'240px'}}>
										<select className='custom-select custom-select-sm'
											value={filterByGroupStatusId}
											onChange={(e) => {
												setFilterByGroupStatusId(e.currentTarget.value);
												setGroupsPageNumber(1);
											}}>
											<option value={''}>{`All CTAs`}</option>
										{GROUP_STATUS_IDS.map((status) =>
											<option key={status.groupStatusId} value={status.groupStatusId}>{status.groupStatus}</option>
										)}
										</select>
									</div>
								</div>
								<div className='col-auto'>
									<A className='btn btn-outline-primary btn-sm ml-1' href={`/admin/groups/add`}>
										<i className='fa-solid fa-plus-circle mr-1'></i>
										{`Add CTA`}
									</A>
								</div>
							{CSVGroups &&
								<div className='col-auto'>
									<CSVLink
										className='btn btn-link btn-sm ml-1'
										filename={`groups.csv`}
										data={CSVGroups.items}>
										<i className='fa-solid fa-fw fa-file-download mr-1'></i>
										{`Export CTAs`}
									</CSVLink>
								</div>
							}
							{(CSVGroupsLoading || groupsLoading) &&
								<div className='col-auto pl-1'>
									<div className='btn btn-sm disabled'>
										<i className='fa-solid fa-fw fa-circle-notch fa-spin mr-1'></i>
										{`Preparing Export…`}
									</div>
								</div>
							}
								<div className='col-auto ml-lg-auto pt-3 pt-lg-0'>
									<Pagination
										totalCount={groupsTotalCount}
										totalPages={groupsTotalPages}
										pageSize={groupsPageSize}
										pageNumber={groupsPageNumber}
										setPageNumber={setGroupsPageNumber}
										setPageSize={setGroupsPageSize}
										/>
								</div>
							</div>
						}
						{'add' !== id && groups && groups.length > 0 &&
							<div className={'table-responsive sticky-thead mb-3'}>
								<table className='table table-sm table-striped'>
									<thead>
										<tr>
										{groupsColumns.map(column =>
											<th key={column.field} scope='col' style={{
												cursor: (column.sortable ? 'pointer' : 'default'),
												width: 'actions' === column.field ? '1%' : 'auto',
											}}
												onClick={(e) => {
													if( !column.sortable ) return;
													switch(column.field) {
														default:
															setGroupsSortBy(column.field);
													}
													setGroupsOrderBy('DESC' === groupsOrderBy ? 'ASC' : 'DESC');
												}}>
												<span>{column.headerName}</span>
											{column.field === groupsSortBy &&
												<i className={ 'DESC' === groupsOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
											}
											</th>
										)}
										</tr>
									</thead>
									<tbody>
									{groups.map(group =>
										<tr key={group.groupId}>
										{groupsColumns.map(column =>
											<td key={`${group.groupId}-${column.field}`}>
											{'groupName' === column.field &&
												<div>
													<A className='d-block' href={`/groups/${group.groupUri}`}>
														{group.groupName}
													</A>
												</div>
											}
											{'groupStatusId' === column.field &&
												<div>
													{groupStatus(group.groupStatusId)}
												</div>
											}
											{'dateCreated' === column.field &&
												<div>
													{dateFormat(parseDate(group.dateCreated), 'MM/dd/yyyy')}
												</div>
											}
											{'activate' === column.field &&
												<div className='d-flex justify-content-center'>
												{GROUP_PENDING_STATUS_ID === group.groupStatusId &&
													<button className='btn btn-light btn-sm py-0 px-2'
														onClick={(e) => {

															patchGroup(group.groupId, [
																{
																	op: 'replace',
																	path: '/groupStatusId',
																	value: GROUP_ACTIVE_STATUS_ID
																}
															], session.access_token).then((response) => {

																setGroups(groups.filter((g) => {
																	return g !== group;
																}));
																setGroupsTotalCount(groupsTotalCount - 1);

																setToastDelay(5000);
																setShowToast(true);
																setToastMessage(<>
																	<b>{`Group activated!`}</b>
																	<a className='text-primary d-block mt-1' href={`/groups/${group.groupUri}`} target='_blank' rel='noreferrer'>{`View ${group.groupName}’s profile`}</a>
																</>);

															});

														}}>
														{`activate`}
													</button>
												}
												</div>
											}
											{'actions' === column.field &&
												<div className='d-flex justify-content-center'>
													<A className='btn btn-link py-0 text-success' href={`/groups/${group.groupUri}/edit`}>
														<i className='fa-solid fa-edit'></i>
													</A>
													<A className='btn btn-link py-0' href={`/groups/${group.groupUri}/users`}>
														<i className='fa-solid fa-users'></i>
													</A>
													<button className='btn btn-link py-0 text-danger'
														onClick={(e) => {confirmDeleteGroup(group);}}>
														<i className='fa-solid fa-times-circle'></i>
													</button>
												</div>
											}
											</td>
										)}
										</tr>
									)}
									</tbody>
								</table>
							</div>
						}
						</Tab>

						<Tab className='py-5' eventKey="pending-programs" title={`Pending Programs`}>

							<PendingTable
								lang={lang}
								session={session}
								archive={false}
								/>

						</Tab>

						<Tab className='py-5' eventKey="recently-archived" title={`Recently Archived`}>

							<PendingTable
								lang={lang}
								session={session}
								archive={true}
								/>

						</Tab>

					{!!(session.PlatformAdmin) && !!(insightPrograms.length) &&

						<Tab className='py-5' eventKey="all-programs" title={`All Programs`}>

							<div className='row no-gutters align-items-center py-3 bg-white border-bottom'>
								<div className='col-auto pr-2'>
									<div style={{width:'240px'}}>
										<select className='custom-select custom-select-sm'
											value={filterByPlatformId}
											onChange={(e) => {
												setFilterByPlatformId(e.currentTarget.value);
												setInsightProgramsPageNumber(1);
												e.currentTarget.blur();
											}}>
										{PLATFORMS.map((platform) =>
											<option key={platform.platformTitle} value={platform.platformId}>{platform.platformTitle}</option>
										)}
										</select>
									</div>
								</div>
								<div className='col-auto pr-2'>
									<div style={{width:'240px'}}>
										<select className='custom-select custom-select-sm'
											value={filterByProgramStatusId}
											onChange={(e) => {
												setFilterByProgramStatusId(e.currentTarget.value);
												setInsightProgramsPageNumber(1);
												e.currentTarget.blur();
											}}>
										{PROGRAM_STATUS_TYPES.map((type) =>
											<option key={type.programStatusId} value={type.programStatusId}>{type.programStatus}</option>
										)}
										</select>
									</div>
								</div>
								<div className='col-auto ml-xl-auto pt-3 pt-xl-0'>
									<Pagination
										totalCount={insightProgramsTotalCount}
										totalPages={insightProgramsTotalPages}
										pageSize={insightProgramsPageSize}
										pageNumber={insightProgramsPageNumber}
										setPageNumber={setInsightProgramsPageNumber}
										setPageSize={setInsightProgramsPageSize}
										/>
								</div>
							</div>

							<ProgramsTable
								lang={lang}
								containerClass='sticky-thead'
								columns={[
									{
										field: 'programEdit',
										headerName: 'Program Name',
										sortable: true,
										target: '_blank',
									},
									{
										field: 'groupName',
										headerName: 'Group Owner',
										sortable: true,
									},
									{
										field: 'dateCreated',
										headerName: 'Created',	
										type: 'date',
										sortable: true,
									},
								]}
								rows={insightPrograms}
								sortBy={insightProgramsSortBy}
								orderBy={insightProgramsOrderBy}
								toggleSortBy={(field) => {
									setInsightProgramsSortBy(field);
									setInsightProgramsOrderBy('DESC' === insightProgramsOrderBy ? 'ASC' : 'DESC');
									setInsightProgramsQueryKey( (new Date()).getTime() );
								}}
								/>

						</Tab>

					}
					</Tabs>

				</div>

			</div>

		</div>
	)

};

export default Admin;
