import React, {
	useEffect,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


// Constants
import {
	GROUP_ACTIVE_STATUS_ID,
	PROGRAM_ACTIVE_STATUS_ID,
	INDEX_TYPE_ID,
	MARKET_QUOTES_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
} from '../api/Api';


// partials
import {
	BlockerModal,
	Loader,
	Table,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsQuotes } from '../i18n/Quotes';


// Quotes
function Quotes({
	lang,
	session,
	toggleShowLogin,
}) {

	const text = {...labelsGlobal[lang],...labelsQuotes[lang]};

	// queryParams
	const queryParams = {
		filterByGroupStatusIds: GROUP_ACTIVE_STATUS_ID,
		filterByProgramStatusIds: PROGRAM_ACTIVE_STATUS_ID,
		filterByProgramTypesId: INDEX_TYPE_ID,
	};

	// run our queries
	const [
		{
			isLoading,
			data: quotes,
		},
	] = useQueries([
		{
			queryKey: ['quotes', queryParams],
			queryFn: () => getRequest('/programsListings', queryParams, session.access_token),
		}
	]);

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	// access + redirects
	useEffect(() => {
		!!session.access_token ? document.body.classList.remove('overflow-hidden') : document.body.classList.add('overflow-hidden');
	}, [
		session,
	]);


	if (isLoading) {
		return (
			<Loader/>
		)
	}

	return (
		<div className={'container'}>
		{!session.access_token &&
			<BlockerModal
				lang={lang}
				session={session}
				toggleShowLogin={toggleShowLogin}
				/>
		}
			<div className='row'>

				<div className='col-12'>

					<h1 className='mb-4'>{text.pageTitle}</h1>

					<Table
						lang={lang}
						columns={MARKET_QUOTES_COLUMNS}
						rows={quotes.items}
						/>

				</div>

			</div>

		</div>
	)

}

export default Quotes;
