export const labels = {
	'en': {
		pageTitle: 'New Listings',
		pageSubTitle: 'Managed Futures',
		seoTitle: 'Managed Futures New Listings',
	},
	'zh-hans': {
		pageTitle: '新房源',
		pageSubTitle: '管理期货',
		seoTitle: '管理期货新上市',
	}
};
