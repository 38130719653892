export const MoneyFormat = (value) => {

	// nine zeroes for billions
	return Math.abs(Number(value)) >= 1.0e+9

	? (Math.abs(Number(value)) / 1.0e+9).toFixed(1) + "B"

	// six zeroes for millions 
	: Math.abs(Number(value)) >= 1.0e+6

	? (Math.abs(Number(value)) / 1.0e+6).toFixed(1) + "M"

	// three zeroes for thousands
	: Math.abs(Number(value)) >= 1.0e+3

	? (Math.abs(Number(value)) / 1.0e+3).toFixed(0) + "K"

	: Math.abs(Number(value));

};
