import React, {
	useEffect,
	useRef,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	navigate,
	useQueryParams,
} from 'hookrouter';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/overlays/
 * @link https://react-bootstrap.github.io/components/tabs/
 *
 */
import {
	Modal,
	OverlayTrigger,
	Popover,
	Tabs,
	Tab,
} from 'react-bootstrap';


/*
 * React Bootstrap Typeahead
 *
 * @link https://github.com/ericgio/react-bootstrap-typeahead
 * @link https://ericgio.github.io/react-bootstrap-typeahead
 *
 */
import {
	AsyncTypeahead,
} from 'react-bootstrap-typeahead';


// Constants
import {
	WP_BASE_URL,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_ARCHIVE_STATUS_ID,
	CHART_COLORS,
} from '../helpers/Constants';


// API
import {
	getRequest,
	addBlend,
	addToBlend,
	deleteBlend,
	deleteFromBlend,
	patchOrder,
	saveBlend,
	setBlendNormalized,
} from '../api/Api';


// helpers
import {
	addWaterMark,
	debounce,
	getPerformanceChartOptions,
	getAllocationsPieChartOptions,
	Highcharts,
	HighchartsReact,
	Highstock,
	subMonths,
	parseInvestmentReturns,
	parseStatisticsRatios,
	parsePerformanceChartData,
} from '../helpers/Helpers';


// partials
import {
	ConfirmModal,
	Loader,
	PerformanceTable,
	ProgramsTable,
	StatsAndRatios,
	StatsTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsBlender } from '../i18n/Blender';
import { labels as labelsTables } from '../i18n/Tables';


// Blender
function Blender({
	lang,
	session,
	tab,
}) {

	const text = {...labelsGlobal[lang], ...labelsBlender[lang], ...labelsTables[lang]};

	// queryParams
	const [queryParams] = useQueryParams();

	// Highstock options
	Highstock.setOptions({
		lang: {
			decimalPoint: '.',
			thousandsSep: ',',
		},
	});

	// modals
	const [showModal, setShowModal] = useState(false);
	const toggleShowModal = () => setShowModal(!showModal);
	const [showPrinting, setShowPrinting] = useState(false);
	const [disablePrint, setDisablePrint] = useState(false);

	// search
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const filterBy = () => true;

	// data
	const [blend, setBlend] = useState( false );
	const [blendModified, setBlendModified] = useState(
		localStorage.getItem('blendModified') === 'true'
	);
	const [blendName, setBlendName] = useState('');
	const [blendQueryKey, setBlendQueryKey] = useState( false );
	const [allocations, setAllocations] = useState([]);
	const [correlations, setCorrelations] = useState([]);
	const [monthlyReturns,setMonthlyReturns] = useState([]);
	const [monthlyStats,setMonthlyStats] = useState({});
	const [annualStats,setAnnualStats] = useState({});

	// canViewCorrelations
	const canViewCorrelations = () => {

		const roles = ( session.Roles || '' ).split(',');

		return [
			'Administrator',
			'Broker',
			'CTA Manager',
			'Member Plus',
		].filter(e => roles.indexOf(e) !== -1).length > 0;

	};

	// charts
	const [allocationsPieChartOptions, setAllocationsPieChartOptions] = useState(false);

	const [comparisonCharts, setComparisonCharts] = useState([]);
	const [comparisonChartLoading, setComparisonChartLoading] = useState(false);
	const [performanceChart, setPerformanceChart] = useState(false);
	const [performanceChartOptions, setPerformanceChartOptions] = useState(false);

	// stats
	const [statsType,setStatsType] = useState('monthly');
	const [comparisonStats,setComparisonStats] = useState([]);
	const [comparisonStatsLoading,setComparisonStatsLoading] = useState(false);

	const compareStatsSearch = useRef(null);

	// run our queries
	const [
		{
			isError: isBlendError,
			isLoading: blendLoading,
		},
		{
			isError: isProgramsCompareError,
			data: programsCompareItems,
		},
	] = useQueries([
		{
			queryKey: ['currentBlend', session.access_token, blendQueryKey],
			queryFn: () => queryParams.blendId ? getRequest(`/blends/${queryParams.blendId}`, {}, session.access_token) : getRequest('/blends/getcurrent', {}, session.access_token),
			onSuccess: (response) => {

				// investmentReturns
				if( response.investmentReturns.length ) {
					let investmentReturns = parseInvestmentReturns(response.investmentReturns);
					setMonthlyReturns(investmentReturns.monthlyReturns);
				}

				// statisticsRatios
				if( response.statisticsRatios.length ) {
					let statisticsRatios = parseStatisticsRatios(response.statisticsRatios);
					setMonthlyStats(statisticsRatios.monthlyStats);
					setAnnualStats(statisticsRatios.annualStats);
				}

				// performanceChartOptions
				let width = 763;

				width = window.innerWidth < 1400 ? 635 : width;
				width = window.innerWidth < 1200 ? 530 : width;
				width = window.innerWidth < 768 ? 690 : width;
				width = window.innerWidth < 576 ? 320 : width;

				if( response.investmentReturns.length ) {
					let initialReturn = subMonths(new Date(response.investmentReturns[0].dateReturn), 1);

					setPerformanceChartOptions(getPerformanceChartOptions({
						data: [
							{
								dateReturn: initialReturn,
								cumulativeROR: 0
							},
							...response.investmentReturns
						],
						name: response.blendName,
						metric: 'cumulativeROR',
						width: width,
						height: 450,
					}));
				}

				if( comparisonCharts.length ) {
					setComparisonCharts([]);
				}

				// allocations
				if( response.allocations.length ) {

					let totalQuantity = 0;

					response.allocations.forEach((row) => {
						totalQuantity += parseFloat(row.quantity);
					});

					let parsedAllocations = response.allocations.map((allocation,i) => {

						let cashLevel = allocation.quantity / allocation.leverage;
						let pctAllocated = allocation.quantity / totalQuantity;

						return {
							...allocation,
							color: CHART_COLORS.default[i] || CHART_COLORS.default[(i - CHART_COLORS.default.length)],
							inComparisonCharts: false,
							cashLevel: cashLevel,
							pctAllocated: pctAllocated
						}

					});

					setAllocations(parsedAllocations);

					setCorrelations(response.allocations.map((allocation,i) => {

						return {
							...allocation,
							color: CHART_COLORS.default[i] || CHART_COLORS.default[ (i - CHART_COLORS.default.length) ],
							inComparisonCharts: false,
							correlations: response.correlationsMatrix[i].correlations
						}

					}));

					// allocations pie chart
					let pieChartOptions = getAllocationsPieChartOptions({
						data: [...parsedAllocations],
						name: 'Allocation'
					});

					setAllocationsPieChartOptions(pieChartOptions);

				}

				setBlend(response);

			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['programsCompareItems'],
			queryFn: () => getRequest('/programsCompareItems', {}, session.access_token),
			enabled: !!session.access_token
		},
	]);

	// handleProgramSearch
	const programSearchDefault = useRef(null);
	const programSearchAllocations = useRef(null);
	const programSearchCorrelations = useRef(null);
	const handleProgramSearch = (query) => {
		// get roles
		const roles = session.Roles ? session.Roles.split(',') : [];
		// set filterByProgramStatusIds based on session
		const filterByProgramStatusIds = ( session.Admin || roles.indexOf('CTA Manager') > -1 ) ? [
			PROGRAM_ACTIVE_STATUS_ID,
			PROGRAM_ARCHIVE_STATUS_ID,
		] : [
			PROGRAM_ACTIVE_STATUS_ID,
		];
		// isSearching=true
		setIsSearching(true);
		// run our search query
		getRequest(`/programs/search/${query}`, {
			filterByProgramStatusIds: filterByProgramStatusIds.join('&filterByProgramStatusIds=')
		}, session.access_token).then((response) => {
			setSearchResults(response);
			setIsSearching(false);
		});
	};


	// chart comparisons
	const removeComparisonChart = (index) => {

		let indexes = [...comparisonCharts];

		if( !indexes.find(obj => { return obj.programId === index.programId; }) ) return;

		// remove index
		indexes.splice( indexes.indexOf(index), 1 );

		// update comparisonCharts
		setComparisonCharts(indexes);

		let series = [...performanceChartOptions.series];

		let indexSeries = performanceChartOptions.series.find(obj => { return obj.name === (index.portfolioName ? index.portfolioName : index.title); });

		// remove data
		series.splice( series.indexOf(indexSeries), 1 );

		setPerformanceChartOptions({
			...performanceChartOptions,
			legend: {
				...performanceChartOptions.legend,
				enabled: (series.length > 1)
			},
			series: series
		});

		setAllocations(allocations.map(allocation => {
			return allocation.programId === index.programId ? { ...allocation, inComparisonCharts: false } : { ...allocation};
		}));
		setCorrelations(correlations.map(allocation => {
			return allocation.programId === index.programId ? { ...allocation, inComparisonCharts: false } : { ...allocation};
		}));
	};

	const parseComparisonChart = ({selectedIndex,response,color=false}) => {

		let initialReturn = subMonths(new Date(response[0].dateReturn), 1);

		let chartData = parsePerformanceChartData({
			data: [
				{
					dateReturn: initialReturn,
					cumulativeROR: 0
				},
				...response
			],
			metric: 'cumulativeROR',
		});

		setComparisonCharts([
			...comparisonCharts,
			{
				...selectedIndex,
				color: color,
			},
		]);

		setPerformanceChartOptions({
			...performanceChartOptions,
			legend: {
				...performanceChartOptions.legend,
				enabled: true
			},
			series: [
				...performanceChartOptions.series,
				{
					name: selectedIndex.title,
					color: !!color ? color : CHART_COLORS.default[(comparisonCharts.length+1)],
					type: 'line',
					marker: {
						enabled: false
					},
					data: chartData
				}
			]
		});

	};

	const addComparisonChart = ({programId,comparisonType,title='',color=false}) => {

		let selectedIndex = programsCompareItems.items.find(obj => { return obj.programId === programId; });

		selectedIndex = ( 'undefined' === typeof selectedIndex ) ? {
			programId: programId,
			title: title.trim(),
		} : selectedIndex;

		let dateStart = blend.investmentReturns[0].dateReturn;
		let dateEnd = blend.investmentReturns[blend.investmentReturns.length-1].dateReturn;

		switch( comparisonType ) {
			case 1:
				setComparisonChartLoading(true);
				getRequest('/graphs/monthly/programs', {
					programId: programId,
					dateStart: dateStart,
					dateEnd: dateEnd,
				}, session.access_token).then((response) => {
					parseComparisonChart({selectedIndex:selectedIndex,response:response,color:color});
					setComparisonChartLoading(false);
				});
			break;
			case 2:
				setComparisonChartLoading(true);
				getRequest('/graphs/monthly/portfolios', {
					portfolioId: programId,
					dateStart: dateStart,
					dateEnd: dateEnd,
				}, session.access_token).then((response) => {
					parseComparisonChart({selectedIndex:selectedIndex,response:response,color:color});
					setComparisonChartLoading(false);
				});
			break;
			default:
				console.log(`Invalid comparisonType: ${comparisonType}`);
		}

	};

	const handleChartComparison = (e) => {

		let select = e.currentTarget;
		let comparisonType = parseInt( select.options[select.selectedIndex].dataset.type );
		let programId = select.value;

		if( !programId ) return;

		if( comparisonCharts.find(obj => { return obj.programId === programId; }) ) return;

		addComparisonChart({
			programId: programId,
			comparisonType: comparisonType,
			color: select.options[select.selectedIndex].dataset.color,
		});

		e.currentTarget.value='';
		e.currentTarget.blur();

	};

	const toggleComparisonChart = (program) => {

		if( !program.inComparisonCharts ) {
			addComparisonChart({
				programId: program.programId,
				comparisonType: 1,
				title: program.programName,
				color: program.color,
			});
			setAllocations(allocations.map(allocation => {
				return allocation.blendOrderId === program.blendOrderId ? { ...allocation, inComparisonCharts: true } : { ...allocation};
			}));
			setCorrelations(correlations.map(allocation => {
				return allocation.blendOrderId === program.blendOrderId ? { ...allocation, inComparisonCharts: true } : { ...allocation};
			}));
		}
		else {
			removeComparisonChart(program);
		}
	};


	// modified
	const _blendModified = (modified) => {
		localStorage.setItem( 'blendModified', modified );
		setBlendModified( modified );
	};


	// normalization
	const toggleNormalization = (isNormalized) => {
		setBlend({
			...blend,
			isNormalized: isNormalized,
		});
		setBlendNormalized(blend.blendId, isNormalized.toString(), session.access_token).then((response) => {
			setBlendQueryKey( (new Date()).getTime() );
			_blendModified( true );
		});
	};


	// refreshCharts
	const refreshCharts = (width=null,callback=null) => {

		if( !performanceChart.container ) return;

		setPerformanceChartOptions({
			...performanceChartOptions,
			chart: {
				...performanceChartOptions.chart,
				width: width ? width : performanceChart.container.closest('figure').offsetWidth
			}
		});

		addWaterMark(performanceChart);

		if( callback ) {
			callback();
		}

	};
	// TO-DO? find a better way :/


	// print page
	const printPage = () => {

		navigate(`/tools/blender/all`, false, queryParams.blendId ? {blendId:queryParams.blendId} : {});

		const form = document.getElementById('pdfcrowd');
		const root = document.getElementById('content');
		const html = document.getElementById('html');
		const name = document.getElementById('filename');

		setDisablePrint(true);
		setShowPrinting(true);

		refreshCharts(732, () => {

			setTimeout(() => {

				html.value = root.outerHTML;
				name.value = blend.blendName;

				form.setAttribute('action', WP_BASE_URL + window.location.pathname);
				form.submit();

				setTimeout(() => {

					refreshCharts();

					setTimeout(() => {

						setShowPrinting(false);

						setTimeout(() => {

							setDisablePrint(false);

						}, 2000);

					}, 1000);

				}, 3000);

			}, 1000);

		});

	};


	// delete blend
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const cancelDelete = () => {
		setShowConfirmDelete(false);
	};

	const confirmDelete = (data) => {
		setShowConfirmDelete(true);
	};

	const _deleteBlend = () => {

		deleteBlend(blend.blendId, session.access_token).then(() => {

			// reload
			window.location.reload();

		});

	};


	// create blend
	const [showConfirmCreate, setShowConfirmCreate] = useState(false);

	const _createNewBlend = () => {

		// trying to create a blend when the loaded blend isn't saved…
		if( blendModified ) {
			setShowConfirmCreate(true);
		}
		// create new blend…
		else {
			createNewBlend();
		}

	};

	const createNewBlend = () => {

		addBlend({}, session.access_token).then((response) => {

			getRequest(`/blends/${response.blendId}/Load`, {}, session.access_token).then((response) => {
				localStorage.setItem( 'blendModified', false );
				window.location = `/tools/blender`;
			});

		});

	};


	// resize events
	useEffect(() => {

	    const debouncedHandleResize = debounce(() => {

			refreshCharts();

		}, 500);

	    window.addEventListener('resize', debouncedHandleResize);

		return _ => {
			window.removeEventListener('resize', debouncedHandleResize)
		}

	});


	// set page title
	useEffect(() => {
		document.title = blend ? [blend.blendName, text.siteTitle].join(text.titleSeparator) : '…';
	});


	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/tools/blender`;
		}
	}, [
		lang,
		session
	]);


	const isError = isBlendError || isProgramsCompareError;

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	if ( !blend || !session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className={'container'} id='content'>

			<ConfirmModal
				lang={lang}
				showConfirm={showConfirmDelete}
				handleCloseConfirm={cancelDelete}
				confirmCallback={_deleteBlend}
				/>

			<Modal show={showConfirmCreate} onHide={() => {setShowConfirmCreate(false);}} centered>

				<Modal.Body className='p-5'>

					<button
						type='button'
						className='close mt-n4 mr-n4'
						style={{fontSize:'1rem'}}
						onClick={() => {setShowConfirmCreate(false);}}>
						<i className='fa-solid fa-times'></i>
					</button>

					<div className='alert alert-warning'>
						<h6 className='d-flex align-items-center pt-2'>
							<i className='fa-solid fa-exclamation-triangle text-warning mr-1' style={{fontSize:'80%'}}></i>
							<b>{text.unsavedChanges}</b>
						</h6>
						<p className='m-0'>
							{text.creatingWillDelete}
						</p>
					</div>

					<button
						type='button'
						className='btn btn-block btn-danger mt-3'
						onClick={() => {
							createNewBlend();
						}}>
						{text.deleteAndCreate}
					</button>

				</Modal.Body>

			</Modal>

			<Modal show={showModal} onHide={toggleShowModal} centered>

				<Modal.Body className='p-5'>

					<button
						type='button'
						className='close mt-n4 mr-n4'
						style={{fontSize:'1rem'}}
						onClick={toggleShowModal}>
						<i className='fa-solid fa-times'></i>
					</button>

					<h5>{text.nameThisBlend}</h5>

					<div className='form-group'>
						<input type='text' className='form-control' placeholder={blend.blendName} value={blendName||('New Blend'===blend.blendName?'':blend.blendName)}
							onFocus={(e) => {
								setBlendName(e.currentTarget.value);
							}}
							onChange={(e) => {
								setBlendName(e.currentTarget.value);
							}}/>
					</div>

					<button type='button' className='btn btn-primary btn-block' disabled={!blendName} onClick={(e) => {
						e.preventDefault();
						saveBlend(blend.blendId, blendName, session.access_token).then((response) => {
							toggleShowModal();
							setBlendQueryKey( (new Date()).getTime() );
							_blendModified( false );
						});
					}}>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveBlend}</span>
					</button>

				</Modal.Body>

			</Modal>

			<Modal show={showPrinting} onHide={() => {setShowPrinting(false)}} centered>

				<Modal.Body className='py-5 text-center'>

					<div className='d-flex justify-content-center'>
						<div>
							<h4 className='mb-3'>{text.generatingPDF}</h4>
						</div>
					</div>
					<div className='d-flex justify-content-center align-items-center'>
						<i className='fa-solid fa-circle-notch fa-2x fa-spin text-primary mr-2'></i>
					</div>

				</Modal.Body>

			</Modal>

			<div className='row'>

				<div className='col-12'>

					<div className='row align-items-center mb-4'>
						<div className='col-12 col-md-6' style={{minHeight:'60px'}}>
							<h1 className='d-flex align-items-center mb-md-0'>
								<span>{blend.blendName + (blendModified ? ' (Unsaved)' : '')}</span>
							</h1>
						</div>
						<div className='col-12 col-md-auto ml-md-auto'>
						{blendModified && <>
						{'New Blend'!==blend.blendName &&
							<button type='button' className='btn btn-success ml-2' onClick={(e) => {
								e.preventDefault();

								saveBlend(blend.blendId, blend.blendName, session.access_token).then((response) => {
									setBlendQueryKey( (new Date()).getTime() );
									_blendModified( false );
								});
							}}>
								<i className='fa fa-chevron-right pr-2'></i>
								{text.saveBlend}
							</button>
						}
							<button type='button' className='btn btn-outline-success ml-2' onClick={(e) => {
								e.preventDefault();
								toggleShowModal();
							}}>
								{'New Blend'===blend.blendName?text.saveBlend:text.saveBlendAs}
							</button>
						</>}
							<button type='button' className='btn btn-success ml-2' onClick={(e) => {
								e.preventDefault();
								_createNewBlend();
							}}>
								<i className='fa fa-chevron-right pr-2'></i>
								{text.createNewBlend}
							</button>
						</div>
					</div>

					<div className='row d-print-none'>
						<div className='col'>
							<p>
								{text.blenderInstructions}
							</p>
						</div>
						<div className='col-3 ml-auto text-right'>
						{!!blend.allocations.length &&
							<button className='btn btn-sm btn-link'
								disabled={!session.access_token||disablePrint}
								onClick={printPage}>
								<i className='fa-solid fa-fw fa-print mr-1'></i>
								{text.printPage}
							</button>
						}
						{blend.blendTypeId !== 4 &&
							<button className='btn btn-sm btn-link text-danger'
								onClick={confirmDelete}>
								<i className='fa-solid fa-fw fa-times-circle'></i>
							</button>
						}
						</div>
					</div>

				{!blend.allocations.length &&
					<div className='row mb-5 align-items-center d-print-none' hidden={queryParams.blendId}>
						<div className='col-12 col-md-auto pr-0'>
							{text.addToYourBlend}
						</div>
						<div className='col-12 col-md-6 col-lg-3'>
							<AsyncTypeahead
								filterBy={filterBy}
								id='programSearchDefault'
								isLoading={isSearching}
								labelKey='programName'
								ref={programSearchDefault}
								minLength={3}
								onChange={(selected) => {
									if( !selected.length ) return;
									let program = selected[0];
									// addToBlend
									addToBlend(blend.blendId, [{
										programId: program.programId
									}], session.access_token).then((response) => {
										setBlendQueryKey( (new Date()).getTime() );
										_blendModified( true );
										programSearchDefault.current.clear();
									});
								}}
								onSearch={handleProgramSearch}
								options={searchResults}
								placeholder={text.programSearchPlaceholder}
								renderMenuItemChildren={(option, props) => (
									<>
										<small className='groupName'>{option.groupName}</small>
										<div className='programName'>{option.programName}</div>
									</>
								)}
								/>
						</div>
					</div>
				}
				{!!blend.allocations.length && <>
					<div className='row my-5' style={{minHeight:'490px'}}>

						<div className='col-12 col-lg-5 mb-5 mb-lg-0'>

							<div className='card shadow h-100'>

								<div className='card-body'>

									<div className='row iasg-print-cols-2'>

										<div className='col-12 col-xl-6 order-xl-2 mb-3'>
											<h6>{text.yearToDate}</h6>

											<div className={'h1 pb-2 mb-0 border-bottom text-' + (blend.ytdReturn > 0 ? 'primary' : 'danger')}>
												<span className='pr-2'>{blend.ytdReturn ? (blend.ytdReturn * 100).toFixed(2) + '%' : ''}</span>
												<i className={'fas fa-long-arrow-alt-' + (blend.ytdReturn > 0 ? 'up' : 'down')} style={{fontSize:'90%'}}></i>
											</div>

											<StatsTable stats={[
												{
													label: <span className='d-flex align-items-center'>
														<span>{text.compoundedAnnualROR}</span>
														<OverlayTrigger
															placement={'right'}
															overlay={
																<Popover>
																	<Popover.Content>
																		{text.compoundedARORTooltip}
																	</Popover.Content>
																</Popover>
															}>
															<i className='fa-solid fa-question-circle text-muted ml-1 d-print-none'></i>
														</OverlayTrigger>
													</span>,
													value: blend.compoundedAnnualROR ? (blend.compoundedAnnualROR * 100).toFixed(2) : ''
												},
												{
													label: 'Max Drawdown',
													value: blend.maxDrawDown ? (blend.maxDrawDown * 100).toFixed(2) : ''
												},
												{
													label: 'S&P Correlation',
													value: blend.correlationsp500 ? (blend.correlationsp500).toFixed(2) : ''
												},
												{
													label: 'Annualized Sharpe',
													value: blend.sharpe ? blend.sharpe.toFixed(2) : ''
												},
												{
													label: 'Annualized Std Dev',
													value: blend.standardDeviation ? (blend.standardDeviation * 100).toFixed(2) : ''
												},
											]}/>
										</div>

										<div className='col-12 col-xl-6 order-xl-1'>
											<h6>{'Allocation Chart'}</h6>
										{allocationsPieChartOptions &&
											<figure className='mb-0 mx-n2'>
												<HighchartsReact
													options={allocationsPieChartOptions}
													highcharts={Highcharts}
													containerProps={{id:'allocationsPieChart'}}
												/>
											</figure>
										}
										</div>

									</div>

								</div>

							</div>

						</div>
					{performanceChartOptions &&
						<div className='col-12 col-lg-7'>

							<div className='form-row align-items-center mb-2'>
								<div className='col-auto'>
									<h6 className='mb-0'>{`Performance`}</h6>
								</div>
								<div className={'col-auto fade' + (comparisonChartLoading ? ' show' : '')}>
									<span className={'text-primary'}>
										<i className='fa-solid fa-circle-notch fa-spin'></i>
									</span>
								</div>
								<div className='col-auto col-lg-3 ml-lg-auto d-print-none'>
								{!!programsCompareItems &&
									<select className='custom-select custom-select-sm' id='chartIndex'
										disabled={blendLoading}
										onChange={(e) => handleChartComparison(e)}>
										<option value={''}>{text.compareBlendTo}</option>
									{programsCompareItems.items.filter((program) => program.programType === 1).map((program,i) =>
										<option
											key={program.programId}
											data-color={CHART_COLORS.indexes[i]}
											data-type={program.programType}
											disabled={comparisonCharts.find(obj => { return obj.programId === program.programId; })}
											value={program.programId}>{program.title}</option>
									)}
										<option disabled>{`——— ${text.siteTitle} Indexes ———`}</option>
									{programsCompareItems.items.filter((program) => program.programType === 2).map((program,i) =>
										<option
											key={program.programId}
											data-color={CHART_COLORS.iasgIndexes[i]}
											data-type={program.programType}
											disabled={comparisonCharts.find(obj => { return obj.programId === program.programId; })}
											value={program.programId}>{program.title}</option>
									)}
									</select>
								}
								</div>
								<div className='col-auto d-print-none'>
									<div className='custom-control custom-checkbox'>
										<input type='checkbox' className='custom-control-input'
											id='isNormalized'
											checked={blend.isNormalized}
											disabled={blendLoading}
											onChange={(e) => {
												toggleNormalization(e.currentTarget.checked);
											}}
										/>
										<label className='custom-control-label' htmlFor='isNormalized'>
											<span style={{fontSize:'.875rem'}}>{`Normalized Graph`}</span>
										</label>
									</div>
								</div>
							</div>

							<figure className='d-flex justify-content-center mb-0'>
							{!blendLoading &&
								<HighchartsReact
									options={performanceChartOptions}
									highcharts={Highstock}
									constructorType='stockChart'
									containerProps={{id:'performanceChart'}}
									callback={(chart) => {addWaterMark(chart);setPerformanceChart(chart);}}
								/>
							}
							{blendLoading &&
								<div className='d-flex align-items-center' style={{height:'450px'}}><div>{text.loading}</div></div>
							}
							</figure>

							<aside className='d-print-none'>
							{comparisonCharts.map((index,i) =>
								<button
									key={index.programId}
									onClick={(e) => {removeComparisonChart(index);}}
									className='btn btn-outline-dark btn-sm btn-tag mr-2 mb-2 p-0'>
										<span className='tag-border' style={{background:!!index.color?index.color:CHART_COLORS.default[i] || CHART_COLORS.default[(i - CHART_COLORS.default.length)]}}></span>
										<span className='ml-2'>{index.title}</span>
										<i className='fa-solid fa-times mx-2'></i>
								</button>
							)}
							</aside>

						</div>
					}
					</div>

					<hr className='page-break' hidden={true}/>

					<h3>{`Performance (Hypothetical Blended)`}</h3>

					<PerformanceTable
						lang={lang}
						returns={monthlyReturns}
						showRows={4}
						/>

					<Tabs
						className={'iasg-tabs d-none d-lg-flex show-' + tab}
						defaultActiveKey={tab ? tab : 'allocations'}
						onSelect={(k) => {navigate(`/tools/blender/${k}`, false, queryParams.blendId ? {blendId:queryParams.blendId} : {});}}>

						<Tab className='py-5' eventKey="allocations" title={`Allocations`}>

							<h3 className='d-none d-print-block'>{`Allocations`}</h3>

							<div className='row mb-5 align-items-center d-print-none' hidden={queryParams.blendId}>
								<div className='col-12 col-md-auto pr-0'>
									{text.addToYourBlend}
								</div>
								<div className='col-12 col-md-6 col-lg-3'>
									<AsyncTypeahead
										filterBy={filterBy}
										id='programSearchAllocations'
										isLoading={isSearching}
										labelKey='programName'
										ref={programSearchAllocations}
										minLength={3}
										onChange={(selected) => {
											if( !selected.length ) return;
											let program = selected[0];
											// addToBlend
											addToBlend(blend.blendId, [{
												programId: program.programId
											}], session.access_token).then((response) => {
												setBlendQueryKey( (new Date()).getTime() );
												_blendModified( true );
												programSearchAllocations.current.clear();
											});
										}}
										onSearch={handleProgramSearch}
										options={searchResults}
										placeholder={text.programSearchPlaceholder}
										renderMenuItemChildren={(option, props) => (
											<>
												<small className='groupName'>{option.groupName}</small>
												<div className='programName'>{option.programName}</div>
											</>
										)}
										/>
								</div>
							{canViewCorrelations() &&
								<div className='col'>
									<a href={`/managed-futures/performance?filterByBlendCorrelationId=${blend.blendId}&sortBy=correlation&orderBy=ASC`}>{text.findNonCorrelatedPrograms}</a>
								</div>
							}
							</div>

							<ProgramsTable
								lang={lang}
								columns={[
									{
										field: 'chartToggle',
										headerName: 'Chart',
										type: 'checkbox',
										selectAll: false,
										alwaysVisible: true,
									},
									{
										field: 'color',
										headerName: '',
										type: 'color',
										sortable: false,
									},
									{
										field: 'groupProgram',
										headerName: 'CTA / Program',
										alwaysVisible: true,
										sortable: false,
										target: '_blank',
									},
									{
										field: 'feeManagement',
										headerName: 'Mgmt Fee',
										type: 'percent',
										show_sign: true,
										sortable: false,
									},
									{
										field: 'feePerformance',
										headerName: 'Perf Fee',
										type: 'percent',
										show_sign: true,
										sortable: false,
									},
									{
										field: 'quantity',
										headerName: '$ Allocated',
										type: 'currency',
										editable: true,
										sortable: false,
									},
									{
										field: 'cashLevel',
										headerName: 'Cash Level',
										type: 'currency',
										sortable: false,
									},
									{
										field: 'leverage',
										headerName: 'Leverage',
										type: 'float',
										editable: true,
										sortable: false,
									},
									{
										field: 'pctAllocated',
										headerName: '% Allocated',
										type: 'percent',
										show_sign: true,
										sortable: false,
									},
									{
										field: 'dateInception',
										headerName: 'Inception',
										type: 'date',
										sortable: false,
									},
									{
										field: 'actions',
										type: 'actions',
										headerName: '',
										sortable: false,
										visible: !queryParams.blendId
									},
								]}
								rows={allocations}
								toggleComparisonChart={toggleComparisonChart}
								updateProperty={(program, key, value) => {
									setAllocations(allocations.map(allocation => {
										if( allocation.blendOrderId === program.blendOrderId ) {
											allocation[key] = value;
											return allocation;
										}
										else {
											return allocation;
										}
									}));
								}}
								updatePropertyCallback={(program, key, value) => {
									patchOrder(blend.blendId, program.blendOrderId, [
										{
											op: 'add',
											path: '/' + key,
											value: value
										}
									], session.access_token).then((response) => {
										setBlendQueryKey( (new Date()).getTime() );
										_blendModified( true );
									});
								}}
								removeRow={(data) => {
									deleteFromBlend(blend.blendId, data.blendOrderId, session.access_token).then((response) => {
										setBlendQueryKey( (new Date()).getTime() );
										_blendModified( true );
									})
								}}
								footer={'allocations'}
								readOnly={!!queryParams.blendId}
								/>

						</Tab>

						<Tab
							className='py-5 correlations'
							eventKey="correlation"
							title={`Correlation`}>

							<h3 className='d-none d-print-block'>{`Correlation`}</h3>

							<div className='row mb-5 align-items-center d-print-none' hidden={queryParams.blendId}>
								<div className='col-12 col-md-auto pr-0'>
									{text.addToYourBlend}
								</div>
								<div className='col-12 col-md-6 col-lg-3'>
									<AsyncTypeahead
										filterBy={filterBy}
										id='programSearchCorrelations'
										isLoading={isSearching}
										labelKey='programName'
										ref={programSearchCorrelations}
										minLength={3}
										onChange={(selected) => {
											if( !selected.length ) return;
											let program = selected[0];
											// addToBlend
											addToBlend(blend.blendId, [{
												programId: program.programId
											}], session.access_token).then((response) => {
												setBlendQueryKey( (new Date()).getTime() );
												_blendModified( true );
												programSearchCorrelations.current.clear();
											});
										}}
										onSearch={handleProgramSearch}
										options={searchResults}
										placeholder={text.programSearchPlaceholder}
										renderMenuItemChildren={(option, props) => (
											<>
												<small className='groupName'>{option.groupName}</small>
												<div className='programName'>{option.programName}</div>
											</>
										)}
										/>
								</div>
							</div>

							<ProgramsTable
								lang={lang}
								columns={[
									{
										field: 'chartToggle',
										headerName: 'Chart',
										type: 'checkbox',
										selectAll: false,
										alwaysVisible: true,
									},
									{
										field: 'color',
										headerName: '',
										type: 'color',
										sortable: false,
									},
									{
										field: 'groupProgram',
										headerName: 'CTA / Program',
										sortable: false,
										target: '_blank',
									},
									{
										field: 'correlations',
										headerName: 'Correlation Analysis',
										colSpan: correlations.length,
										sortable: false,
										subField: 'correlation',
										subType: 'float',
									},
								]}
								rows={correlations}
								/>

						</Tab>

						<Tab
							className='py-5'
							eventKey='statistics-ratios'
							title={`Statistics & Ratios`}>

							<hr className='page-break' hidden={true}/>

							<h3 className='d-none d-print-block'>{`Statistics & Ratios`}</h3>

						{programsCompareItems &&
							<StatsAndRatios
								text={text}
								session={session}
								blend={blend}
								statsType={statsType}
								setStatsType={setStatsType}
								comparisonStats={comparisonStats}
								setComparisonStats={setComparisonStats}
								setComparisonStatsLoading={setComparisonStatsLoading}
								parseStatisticsRatios={parseStatisticsRatios}
								monthlyStats={monthlyStats}
								annualStats={annualStats}
								comparisonStatsLoading={comparisonStatsLoading}
								AsyncTypeahead={AsyncTypeahead}
								filterBy={filterBy}
								isSearching={isSearching}
								compareStatsSearch={compareStatsSearch}
								handleProgramSearch={handleProgramSearch}
								searchResults={searchResults}
								programsCompareItems={programsCompareItems}
							/>
						}
							<p className='text-muted'>
								<small className='d-print-none'>{text.performanceDisclaimer}</small>
								<br className='d-print-none'/>
								<small>{text.statsDisclaimer}</small>
							</p>

						</Tab>

						<Tab
							className='py-5 d-print-none'
							eventKey='all'
							title={text.showAll}>
						</Tab>

					</Tabs>

					{text.blenderDisclaimer}
				</>}
				</div>

			</div>

		</div>
	)

};

export default Blender;
