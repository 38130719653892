import React, {
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


// Helpers
import {
	dateFormat,
	subMonths,
} from '../../helpers/Helpers';


// Constants
import {
	GROUP_ACTIVE_STATUS_ID,
	GROUP_PENDING_STATUS_ID,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_ARCHIVE_STATUS_ID,
	PROGRAM_PENDING_STATUS_ID,
} from '../../helpers/Constants';


// API
import {
	getRequest,
	patchProgram,
	deleteProgram,
} from '../../api/Api';


// partials
import {
	ConfirmModal,
	ProgramsTable,
} from './Partials';


// i18n
import { labels } from '../../i18n/Global';


// PendingTable
function PendingTable({
	lang='en',
	session={},
	archive=false,
}) {

	const text = {...labels[lang]};

	// confirmations
	const [program, setProgram] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// data
	const [columns] = useState([
		{
			field: 'programName',
			headerName: 'Program Name',
			sortable: true,
		},
		{
			field: 'groupName',
			headerName: 'Group Owner',
			sortable: true,
		},
		{
			field: 'dateCreated',
			headerName: 'Created',	
			type: 'date',
			sortable: true,
		},
		{
			field: 'dateArchived',
			headerName: 'Date Archived',
			type: 'date',
			visible: !!archive,
			sortable: true,
		},
		{
			field: 'activate',
			type: 'activate',
			headerName: '',
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
		},
	]);

	const [rows,setRows] = useState(false);
	const [sortBy,setSortBy] = useState('dateCreated');
	const [orderBy,setOrderBy] = useState('ASC');

	const [queryParams,setQueryParams] = useState(!!archive ? {
		filterByDateArchivedStart: dateFormat( subMonths((new Date()), 4), 'yyyy-MM-01'),
		filterByGroupStatusIds: [GROUP_ACTIVE_STATUS_ID, GROUP_PENDING_STATUS_ID].join('&filterByGroupStatusIds='),
		filterByProgramStatusIds: PROGRAM_ARCHIVE_STATUS_ID,
		sortBy: sortBy,
		orderBy: orderBy,
		pageSize: 100,
	} : {
		filterByGroupStatusIds: [GROUP_ACTIVE_STATUS_ID, GROUP_PENDING_STATUS_ID].join('&filterByGroupStatusIds='),
		filterByProgramStatusIds: PROGRAM_PENDING_STATUS_ID,
		sortBy: sortBy,
		orderBy: orderBy,
		pageSize: 100,
	});

	// run our queries
	const [
		{
			isLoading,
			error
		}
	] = useQueries([
		{
			queryKey: ['programs', queryParams],
			queryFn: () => getRequest('/programsListings', queryParams, session.access_token),
			onSuccess: (response) => {
				// setRows
				setRows(response.items);
			},
			enabled: !!session.access_token
		},
	]);

	// functions
	const handleCloseConfirm = () => {
		setProgram(false);
		setShowConfirm(false);
	};

	const handleShowConfirm = (data) => {
		setProgram(data);
		setShowConfirm(true);
	};

	const _delete = () => {

		if( !program ) return;

		deleteProgram(program.programId, session.access_token).then(() => {

			setRows(rows.filter((row) => {
				return row !== program;
			}));

			setShowConfirm(false);

		});

	};

	const activateProgram = (program) => {

		patchProgram(program.programId, [
			{
				op: 'replace',
				path: '/programStatusId',
				value: PROGRAM_ACTIVE_STATUS_ID
			}
		], session.access_token).then((response) => {

			setRows(rows.filter((row) => {
				return row !== program;
			}));

		});

	};

	const toggleSortBy = (field) => {

		let order = field === sortBy ? ( 'DESC' === orderBy ? 'ASC' : 'DESC' ) : queryParams.orderBy;

		setQueryParams({
			...queryParams,
			sortBy: field,
			orderBy: order,
		});

		setSortBy(field);
		setOrderBy(order);

	};

	if ( isLoading && !rows ) {
		return (
			<div>{text.loading}</div>
		)
	}

	return error ? (<>
		<div className='alert alert-danger'>{`Something went wrong`}</div>
	</>) : (<div style={{opacity:isLoading?.5:1}}>
		<ConfirmModal
			lang={lang}
			showConfirm={showConfirm}
			handleCloseConfirm={handleCloseConfirm}
			confirmCallback={_delete}
			/>

		<ProgramsTable
			lang={lang}
			columns={columns}
			rows={rows}
			removeRow={handleShowConfirm}
			activate={activateProgram}
			sortBy={sortBy}
			orderBy={orderBy}
			toggleSortBy={toggleSortBy}
			/>
	</div>)


};

export default PendingTable;
