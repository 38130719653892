import React, {
	useEffect,
	useRef,
	useState,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	A,
	navigate,
} from 'hookrouter';


/*
 * react-meta-tags
 *
 * @link https://www.npmjs.com/package/react-meta-tags
 *
 */
import MetaTags from 'react-meta-tags';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/buttons/
 * @link https://react-bootstrap.github.io/components/button-group/
 * @link https://react-bootstrap.github.io/components/dropdowns/
 * @link https://react-bootstrap.github.io/components/overlays/
 * @link https://react-bootstrap.github.io/components/tabs/
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Button,
	ButtonGroup,
	Dropdown,
	Modal,
	OverlayTrigger,
	Popover,
	Tabs,
	Tab,
	Toast,
} from 'react-bootstrap';


/*
 * React Bootstrap Typeahead
 *
 * @link https://github.com/ericgio/react-bootstrap-typeahead
 * @link https://ericgio.github.io/react-bootstrap-typeahead
 *
 */
import {
	AsyncTypeahead,
} from 'react-bootstrap-typeahead';


/*
 * react-csv
 *
 * @link https://www.npmjs.com/package/react-csv
 *
 */
import {
	CSVLink,
} from 'react-csv';


// Constants
import {
	BASE_URL,
	WP_BASE_URL,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_ARCHIVE_STATUS_ID,
	PROGRAM_PENDING_STATUS_ID,
	PROGRAM_CLOSED_STATUS_ID,
	INDEX_TYPE_ID,
	CHART_COLORS,
} from '../helpers/Constants';


// API
import {
	getRequest,

	addToPortfolio,
	addToWatchlist,
} from '../api/Api';


// helpers
import {
	addWaterMark,
	debounce,
	parsePerformanceChartData,
	getColumnChartOptions,
	getPerformanceChartOptions,
	getPieChartOptions,
	getSymbolFromCurrency,
	he,
	Highcharts,
	HighchartsReact,
	Highstock,
	parseDate,
	dateFormat,
	subMonths,
	MoneyFormat,
	NumberFormat,
	prepInvestmentReturns,
	parseInvestmentReturns,
	parseStatisticsRatios,
} from '../helpers/Helpers';


// forms
import {
	ProgramForm,
	PerformanceForm,
	RequestForm,
} from './forms/Forms';


// partials
import {
	AddToBlenderModal,
	BlockerModal,
	Loader,
	NotFound,
	StatsAndRatios,
	StatsTable,
	PerformanceTable,
	Table,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsProgram } from '../i18n/Program';
import { labels as labelsTables } from '../i18n/Tables';


// Program
function Program({
	lang,
	session,
	groupUri,
	programUri,
	tab,
	toggleShowLogin,
}) {

	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsProgram[lang], ...labelsTables[lang]};

	// Highstock options
	Highstock.setOptions({
		lang: {
			decimalPoint: '.',
			thousandsSep: ',',
		},
	});

	// error handling
	const [isIndex, setIsIndex] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const [tabs] = useState([
		{
			key: 'snapshot',
			title: text.snapshot
		},
		{
			key: 'strategy',
			title: text.strategy
		},
		{
			key: 'charts',
			title: text.charts
		},
		{
			key: 'statistics-ratios',
			title: text.statsAndRatios
		},
		{
			key: 'performance-tables',
			title: text.performanceTables
		},
		{
			key: 'badges',
			title: text.badges
		},
		{
			key: 'users',
			title: text.logs
		},
		{
			key: 'all',
			title: text.showAll
		},
	]);

	// modals
	const [showPrinting, setShowPrinting] = useState(false);
	const [disablePrint, setDisablePrint] = useState(false);

	const [showRequestForm, setShowRequestForm] = useState(false);
	const toggleShowRequestForm = () => setShowRequestForm(!showRequestForm);

	// data
	const [program, setProgram] = useState(false);
	const [programsCompareItems, setProgramsCompareItems] = useState(false);
	const [assets,setAssets] = useState(0);
	const [monthlyReturns,setMonthlyReturns] = useState([]);
	const [monthlyReturnsCSVData,setMonthlyReturnsCSVData] = useState(false);
	const [periodReturns,setPeriodReturns] = useState([]);
	const [programReturns,setProgramReturns] = useState({});
	const [drawDownReport,setDrawDownReport] = useState({});
	const [worstDrawDown,setWorstDrawDown] = useState(false);
	const [monthlyStats,setMonthlyStats] = useState({});
	const [annualStats,setAnnualStats] = useState({});
	const [dateLastReturn, setDateLastReturn] = useState(false);

	const [comparisonCharts,setComparisonCharts] = useState([]);
	const [comparisonStats,setComparisonStats] = useState([]);

	const [twaProps] = useState([
		{
			field: 'avgPeriodReturn',
			headerName: 'Average',
		},
		{
			field: 'worstPeriod',
			headerName: 'Min',
		},
		{
			field: 'bestPeriod',
			headerName: 'Max',
		},
		{
			field: 'percentProfitable',
			headerName: '% Positive',
		},
		{
			field: 'avgGain',
			headerName: 'Avg. Pos. Period',
		},
		{
			field: 'avgLoss',
			headerName: 'Avg. Neg. Period',
		},
		{
			field: 'periods',
			headerName: '# of Periods',
		},
	]);

	const [twaCols] = useState([
		{
			months: 1,
			headerName: '1 Month',
		},
		{
			months: 3,
			headerName: '3 Months',
		},
		{
			months: 6,
			headerName: '6 Months',
		},
		{
			months: 12,
			headerName: '1 Year',
		},
		{
			months: 24,
			headerName: '2 Years',
		},
		{
			months: 36,
			headerName: '3 Years',
		},
	]);

	const [twaData, setTwaData] = useState([]);

	// blender
	const [showAddToBlender, setShowAddToBlender] = useState(false);

	// charts
	const [performanceChart, setPerformanceChart] = useState(false);
	const [performanceChartOptions, setPerformanceChartOptions] = useState(false);

	const [chartType, setChartType] = useState('cumulativeReturns');

	const [aumCumulativeReturnsChart, setAumCumulativeReturnsChart] = useState(false);
	const [aumCumulativeReturnsChartOptions, setAumCumulativeReturnsChartOptions] = useState(false);

	const [cumulativeReturnsChart, setCumulativeReturnsChart] = useState(false);
	const [cumulativeReturnsChartOptions, setCumulativeReturnsChartOptions] = useState(false);

	const [distributionChart, setDistributionChart] = useState(false);
	const [distributionChartOptions, setDistributionChartOptions] = useState(false);

	const [rollingChart, setRollingChart] = useState(false);
	const [rollingChartOptions, setRollingChartOptions] = useState(false);

	const [strategyPieChartOptions, setStrategyPieChartOptions] = useState(false);
	const [compositionPieChartOptions, setCompositionPieChartOptions] = useState(false);

	const parsePeriodReturns = (program) => {

		let periodReturnsList = [], periodReturns = program.periodReturns, programData = periodReturns[0] || {}, spData = periodReturns[1] || {};

		periodReturnsList.push({
			...programData,
			programIndex: program.programName
		});

		periodReturnsList.push({
			...spData,
			programIndex: 'S&P 500',
			fullYearReturn:  programData.fullYearReturn ? spData.fullYearReturn : 0,
			threeYearReturn: programData.threeYearReturn ? spData.threeYearReturn : 0,
			fiveYearReturn:  programData.fiveYearReturn ? spData.fiveYearReturn : 0,
			tenYearReturn:   programData.tenYearReturn ? spData.tenYearReturn : 0,
			cumulativeROR:   programData.sp500Inception
		});

		periodReturnsList.push({
			programId:       '00000000-0000-0000-0000-000000000000',
			programIndex:     '+/- S&P 500',
			lastReturn:      programData.lastReturn - spData.lastReturn,
			qtrYearReturn:   programData.qtrYearReturn - spData.qtrYearReturn,
			ytdReturn:       programData.ytdReturn - spData.ytdReturn,
			fullYearReturn:  programData.fullYearReturn ? (programData.fullYearReturn - spData.fullYearReturn) : 0,
			threeYearReturn: programData.threeYearReturn ? (programData.threeYearReturn - spData.threeYearReturn) : 0,
			fiveYearReturn:  programData.fiveYearReturn ? (programData.fiveYearReturn - spData.fiveYearReturn) : 0,
			tenYearReturn:   programData.tenYearReturn ? (programData.tenYearReturn - spData.tenYearReturn) : 0,
			cumulativeROR:   programData.cumulativeROR - programData.sp500Inception
		});

		setProgramReturns(programData);
		setPeriodReturns(periodReturnsList);

	};

	const parseDrawDownReport = (drawDownReport) => {
		setDrawDownReport(drawDownReport);
		setWorstDrawDown(drawDownReport.length ? drawDownReport[0].magnitude : false);
	};

	// stats
	const [statsType,setStatsType] = useState('monthly');


	// canEdit
	const canEdit = (groupId) => {
		const managedGroups = session.Groups ? session.Groups.split(',') : [];
		const roles = session.Roles ? session.Roles.split(',') : [];
		return session.Admin || ( managedGroups.indexOf(groupId) > -1 && roles.indexOf('CTA Manager') > -1 );
	};

	// badges
	const isArchived = (programStatusId) => {

		if( programStatusId && PROGRAM_ARCHIVE_STATUS_ID === programStatusId )

			return (
				<span className='badge badge-warning ml-2' style={{fontSize:'1rem'}}>{`Archived`}</span>
			);

		return false;

	};

	const isPending = (programStatusId) => {

		if( programStatusId && PROGRAM_PENDING_STATUS_ID === programStatusId )

			return (
				<span className='badge badge-warning ml-2' style={{fontSize:'1rem',backgroundColor:'#ffee58'}}>{`Pending`}</span>
			);

		return false;

	};

	const isClosed = (membershipStatusId) => {

		if( membershipStatusId && PROGRAM_CLOSED_STATUS_ID === membershipStatusId )

			return (
				<span className='badge badge-danger ml-2' style={{fontSize:'1rem'}}>{`Closed`}</span>
			);

		return false;

	};


	// view logs
	const [logsSortBy, setLogsSortBy] = useState('dateCreated');
	const [logsOrderBy, setLogsOrderBy] = useState('DESC');
	const [logsPageNumber, setLogsPageNumber] = useState(1);
	const [logsPageSize, setLogsPageSize] = useState(25);
	const [logsTotalPages, setLogsTotalPages] = useState(1);
	const [logsTotalCount, setLogsTotalCount] = useState(0);
	const [logsExport, setLogsExport] = useState(false);
	const [logs, setLogs] = useState(false);
	const [logsColumns] = useState([
		{
			field: 'name',
			headerName: 'Name',
			sortable: true,
		},
		{
			field: 'phone',
			headerName: 'Phone',
			sortable: true,
		},
		{
			field: 'email',
			headerName: 'Email',
			sortable: true,
		},
		{
			field: 'dateCreated',
			headerName: 'Viewed',
			sortable: true,
		},
	]);


	// run our queries
	const [
		{
			isLoading: programLoading,
			isError: isProgramError,
		},
		{
			data: group,
		},
		{
			isLoading: programsCompareLoading,
			isError: isProgramsCompareError,
		},
		{
			isLoading: logsLoading,
		},
		{
			data: CSVLogs,
		},
	] = useQueries([
		{
			queryKey: ['program', {
				programUri: programUri,
				groupUri: groupUri,
			}],
			queryFn: () => getRequest(`/programs/${programUri}/group/${groupUri}`, {}, session.access_token),
			onSuccess: (p) => {

				// parseInvestmentReturns
				if( p.investmentReturns.length ) {

					let investmentReturns = parseInvestmentReturns(p.investmentReturns, p.carorType);
					setAssets(investmentReturns.assets);
					setMonthlyReturns(investmentReturns.monthlyReturns);

					setDateLastReturn(p.investmentReturns[p.investmentReturns.length-1].dateReturn);

				}
				else {

					setDateLastReturn( dateFormat(new Date(), 'yyyy-MM-01') );

				}

				// Time Window Analysis
				getRequest(`/programsTimeWindow`, {
					filterByProgramId: p.programId,
				}, session.access_token).then((response) => {
					if( response && response.totalCount ) {
						let rows = [];
						twaCols.forEach((col, index) => {
							var data = response.items.find(function (r) {
								return r.months === col.months;
							});
							let row = {};
							if( data ) {
								twaProps.forEach((prop, index) => {
									row[prop.field] = prop.field in data ? data[prop.field] : '';
								});
							}
							rows.push(row);
						});
						setTwaData(rows);
					}
				});

				if( 'edit' === tab ) {

					// currency?
					p.currency = p.currency || 'USD';
					// modify percentages for edit :\
					[
						'wddExpected',
						'worstPeak2Trough',
						'feeManagement',
						'feePerformance',
					].forEach((key) => {
						p[key] = p[key] !== null ? (p[key] * 100).toFixed(2) : null;
					});
					// modify methodologies for edit :\
					p.methodologies.forEach((methodology,index) => {
						p.methodologies[index].methodologyTypeId = 'Discretionary' === methodology.title ? '97b4310e-61df-4d52-aabe-fc3cfcf82099' : 'a84537c0-ec2a-4644-9ba1-acbba3fa33dd';
						p.methodologies[index].value = (methodology.value * 100).toFixed(2);
					});
					// modify strategies for edit :\
					p.allocationStrategies.forEach((strategy,index) => {
						p.allocationStrategies[index].value = (strategy.value * 100).toFixed(2);
					});
					// modify compositions for edit :\
					p.compositions.forEach((type,index) => {
						p.compositions[index].value = (type.value * 100).toFixed(2);
					});
					// modify holdings for edit :\
					p.holdings.forEach((type,index) => {
						p.holdings[index].value = (type.value * 100).toFixed(2);
					});
					// modify marginEquityRatio for edit :\
					p.marginEquityRatio = (p.marginEquityRatio * 100).toFixed(2);
					// AcceptedAgreement
					p.AcceptedAgreement = false;

				}
				else if( 'performance' !== tab ) {

					// is this an index?
					setIsIndex(p.programTypeId===INDEX_TYPE_ID);

					let width = 1330;

					width = window.innerWidth < 1400 ? 1110 : width;
					width = window.innerWidth < 1200 ? 930 : width;
					width = window.innerWidth < 992 ? 690 : width;
					width = window.innerWidth < 768 ? 510 : width;
					width = window.innerWidth < 576 ? 320 : width;

					if( p.investmentReturns.length ) {

						let initialReturn = subMonths(new Date(p.investmentReturns[0].dateReturn), 1);

						// are all 'vami' values the same?
						if( !p.investmentReturns.every( (r, i, investmentReturns) => r.vami === investmentReturns[0].vami ) ) {

							// #performanceChart
							setPerformanceChartOptions(getPerformanceChartOptions({
								data: [
									{
										dateReturn: initialReturn,
										vami: 1000
									},
									...p.investmentReturns
								],
								name: p.programName,
								metric: 'vami',
								width: width,
								height: 500,
							}));

						}

						// are all 'cumulativeROR' values the same?
						if( !p.investmentReturns.every( (r, i, investmentReturns) => r.cumulativeROR === investmentReturns[0].cumulativeROR ) ) {

							// #aumCumulativeReturns
							let aumCumulativeReturnsChartOptions = getPerformanceChartOptions({
								data: [
									{
										dateReturn: initialReturn,
										cumulativeROR: 0
									},
									...p.investmentReturns
								],
								name: 'Performance',
								metric: 'cumulativeROR',
								width: width,
								height: 500,
							});

							setAumCumulativeReturnsChartOptions({
								...aumCumulativeReturnsChartOptions,
								chart: {
									...aumCumulativeReturnsChartOptions.chart,
									margin: [15, 40, 20, 60],
								},
								legend: {
									...aumCumulativeReturnsChartOptions.legend,
									enabled: true,
									x: 50,
								},
								series: [
									{
										...aumCumulativeReturnsChartOptions.series[0],
										yAxis: 1,
									},
									{
										name: 'AUM',
										color: CHART_COLORS.default[1],
										type: 'line',
										marker: {
											enabled: false
										},
										data: parsePerformanceChartData({
											data: [
												{
													dateReturn: initialReturn,
													assets: 0
												},
												...p.investmentReturns
											],
											metric: 'assets',
										})
									}
								],
								yAxis: [
									{
										title: {
											text: null
										},
										gridLineColor: '#f3f3f3',
										gridLineWidth: 1,
										offset: 0,
										opposite: false,
										labels: {
											style: {
												color: CHART_COLORS.default[1],
											},
											formatter: function() {
												if (this.value > 1000000) {
													return (this.value / 1000000).toFixed(1) + 'M'
												}
												else if (this.value > 1000) {
													return (this.value / 1000).toFixed(0) + 'K';
												}

												return this.value;
											}
										},
									},
									{
										title: {
											text: null
										},
										gridLineColor: '#f3f3f3',
										gridLineWidth: 1,
										offset: 0,
										opposite: true,
										labels: {
											style: {
												color: CHART_COLORS.default[0]
											}
										},
										tickPositioner: function() {
											var positions = [];
											var tick = Math.floor(this.dataMin);
											var increment = Math.ceil((this.dataMax - this.dataMin) / 5);

											for (; tick - increment <= this.dataMax; tick += increment) {
												positions.push(tick);
											}

											return positions;
										},
									},
								]
							});

							// #cumulativeReturns
							setCumulativeReturnsChartOptions(getPerformanceChartOptions({
								data: [
									{
										dateReturn: initialReturn,
										cumulativeROR: 0
									},
									...p.investmentReturns
								],
								name: p.programName,
								metric: 'cumulativeROR',
								width: width,
								height: 500,
							}));

						}

						// #distribution
						getRequest(`/graphs/distribution/programs/${p.programId}`, {}, session.access_token).then((response) => {
							setDistributionChartOptions(getColumnChartOptions({
								data: response[0],
								width: width,
								height: 500,
							}));
						});

						// #rolling
						getRequest(`/programsRollingData/${p.programId}`, {}, session.access_token).then((response) => {

							let rolling6 = parsePerformanceChartData({
								data: response,
								metric: 'rolling6',
							});
							let rolling12 = parsePerformanceChartData({
								data: response,
								metric: 'rolling12',
							});
							let rolling2yr = parsePerformanceChartData({
								data: response,
								metric: 'rolling2yr',
							});

							// are all 'monthReturn' values the same?
							if( !p.investmentReturns.every( (r, i, investmentReturns) => r.monthReturn === investmentReturns[0].monthReturn ) ) {

								// #rolling
								let RollingChartOptions = {
									...getPerformanceChartOptions({
										data: p.investmentReturns,
										name: 'Monthly ROR',
										metric: 'monthReturn',
										width: width,
										height: 500,
									}),
								};

								setRollingChartOptions({
									...RollingChartOptions,
									legend: {
										...RollingChartOptions.legend,
										enabled: true,
									},
									series: [
										...RollingChartOptions.series,
										{
											name: '6-Month Rolling',
											color: CHART_COLORS.default[1],
											type: 'line',
											marker: {
												enabled: false
											},
											data: rolling6
										},
										{
											name: '12-Month Rolling',
											color: CHART_COLORS.default[2],
											type: 'line',
											marker: {
												enabled: false
											},
											data: rolling12
										},
										{
											name: '2-Year Rolling',
											color: CHART_COLORS.default[3],
											type: 'line',
											marker: {
												enabled: false
											},
											data: rolling2yr
										}
									]
								});

							}

						});

					}

					// pie charts
					let strategyPieChartOptions = getPieChartOptions({
						data: p.allocationStrategies,
						name: 'Allocation'
					});
					setStrategyPieChartOptions(strategyPieChartOptions);

					let compositionPieChartOptions = getPieChartOptions({
						data: p.compositions,
						name: 'Composition'
					});
					setCompositionPieChartOptions(compositionPieChartOptions);

					if( p.investmentReturns.length ) {

						// data for CSV
						setMonthlyReturnsCSVData(p.investmentReturns.map(function(i) {
							return {
								Year: dateFormat(parseDate(i.dateReturn), 'yyyy'),
								Month: dateFormat(parseDate(i.dateReturn), 'M'),
								Return: i.monthReturn,
								Assets: i.assets
							}
						}));

					}

					// periodReturns
					parsePeriodReturns(p);

					// statisticsRatios
					if( p.statisticsRatios.length ) {
						let statisticsRatios = parseStatisticsRatios(p.statisticsRatios);
						setMonthlyStats(statisticsRatios.monthlyStats);
						setAnnualStats(statisticsRatios.annualStats);
					}

					// drawDownReport
					parseDrawDownReport(p.drawDownReport);

				}

				// platforms
				p.platforms = [];

				// done!
				setProgram(p);

			},
			initialData: {
				investmentReturns: [],
				periodReturns: [{},{}],
				statisticsRatios: [],
				drawDownReport: [],
				holdings: [],
				methodologies: [],
				compositions: [],
				allocationStrategies: [],
			},
		},
		{
			queryKey: ['group', groupUri, session.access_token],
			queryFn: () => getRequest(`/groups/${groupUri}`, {}, session.access_token),
		},
		{
			queryKey: ['programsCompareItems'],
			queryFn: () => getRequest('/programsCompareItems', {}, session.access_token),
			onSuccess: (response) => {
				setProgramsCompareItems(response);
			},
			enabled: ['edit','performance'].indexOf(tab) === -1 || !session.access_token
		},
		{
			queryKey: ['viewLogs', {
				filterByAuthenticated: true,
				filterByProgramId: program.programId,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsPageSize,
				pageNumber: logsPageNumber,
			}, session.access_token],
			queryFn: () => getRequest('/viewLog', {
				filterByAuthenticated: true,
				filterByProgramId: program.programId,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsPageSize,
				pageNumber: logsPageNumber,
			}, session.access_token),
			onSuccess: (response) => {
				setLogs(response.items);
				setLogsTotalCount( response.totalCount );
				setLogsTotalPages( Math.ceil(response.totalCount / logsPageSize) );
			},
			enabled: !!program && session.Admin
		},
		{
			queryKey: ['CSVLogs', {
				filterByAuthenticated: true,
				filterByProgramId: program.programId,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsTotalCount,
			}],
			queryFn: () => getRequest('/viewLog', {
				filterByAuthenticated: true,
				filterByProgramId: program.programId,
				SortBy: logsSortBy,
				OrderBy: logsOrderBy,
				pageSize: logsTotalCount,
			}, session.access_token),
			enabled: !!(session.Admin) && !!(logsExport)
		},
		{
			queryKey: ['sp500'],
			queryFn: () => {return true},
			onSuccess: () => {
				addComparisonChart({
					programId: '3816f560-3a8d-4373-9078-cb304bfd3eb7',
					comparisonType: 1,
					color: '#492970',
				});
			},
			enabled: !!program && !!cumulativeReturnsChartOptions && !!programsCompareItems && !!cumulativeReturnsChart
		}
	]);


	// comparisons
	const compareChartSearch = useRef(null);
	const compareStatsSearch = useRef(null);

	const [comparisonChartLoading,setComparisonChartLoading] = useState(false);
	const [comparisonStatsLoading,setComparisonStatsLoading] = useState(false);

	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	const filterBy = () => true;


	// handleProgramSearch
	const handleProgramSearch = (query) => {
		// get roles
		const roles = session.Roles ? session.Roles.split(',') : [];
		// set filterByProgramStatusIds based on session
		const filterByProgramStatusIds = ( session.Admin || roles.indexOf('CTA Manager') > -1 ) ? [
			PROGRAM_ACTIVE_STATUS_ID,
			PROGRAM_ARCHIVE_STATUS_ID,
		] : [
			PROGRAM_ACTIVE_STATUS_ID,
		];
		// isSearching=true
		setIsSearching(true);
		// run our search query
		getRequest(`/programs/search/${query}`, {
			filterByProgramStatusIds: filterByProgramStatusIds.join('&filterByProgramStatusIds=')
		}, session.access_token).then((response) => {
			setSearchResults(response);
			setIsSearching(false);
		});
	};


	// chart comparisons
	const removeComparisonChart = (index) => {

		let indexes = [...comparisonCharts];

		if ( indexes.indexOf(index) === -1 ) return;

		// remove index
		indexes.splice( indexes.indexOf(index), 1 );

		// update comparisonCharts
		setComparisonCharts(indexes);

		let series = [...cumulativeReturnsChartOptions.series];

		let indexSeries = cumulativeReturnsChartOptions.series.find(obj => { return obj.name === (index.portfolioName ? index.portfolioName : index.title); });

		// remove data
		series.splice( series.indexOf(indexSeries), 1 );

		setCumulativeReturnsChartOptions({
			...cumulativeReturnsChartOptions,
			legend: {
				...cumulativeReturnsChartOptions.legend,
				enabled: (series.length > 1)
			},
			series: series
		});

	};

	const parseComparisonChart = ({selectedIndex,response,color=false}) => {

		if( ! response.length ) return;

		let initialReturn = subMonths(new Date(response[0].dateReturn), 1);

		let chartData = parsePerformanceChartData({
			data: [
				{
					dateReturn: initialReturn,
					cumulativeROR: 0
				},
				...response
			],
			metric: 'cumulativeROR',
		});

		setComparisonCharts([
			...comparisonCharts,
			{
				...selectedIndex,
				color: color,
			},
		]);

		setCumulativeReturnsChartOptions({
			...cumulativeReturnsChartOptions,
			legend: {
				...cumulativeReturnsChartOptions.legend,
				enabled: true
			},
			series: [
				...cumulativeReturnsChartOptions.series,
				{
					name: selectedIndex.title,
					color: !!color ? color : CHART_COLORS.default[(comparisonCharts.length+1)],
					type: 'line',
					marker: {
						enabled: false
					},
					data: chartData
				}
			]
		});

	};

	const addComparisonChart = ({programId,comparisonType,title='',color=false}) => {

		let selectedIndex = programsCompareItems.items.find(obj => { return obj.programId === programId; });

		selectedIndex = ( 'undefined' === typeof selectedIndex ) ? {
			programId: programId,
			title: title.trim(),
		} : selectedIndex;

		let dateStart = program.investmentReturns[0].dateReturn;
		let dateEnd = program.investmentReturns[program.investmentReturns.length-1].dateReturn;

		switch( comparisonType ) {
			case 1:
				setComparisonChartLoading(true);
				getRequest('/graphs/monthly/programs', {
					programId: programId,
					dateStart: dateStart,
					dateEnd: dateEnd,
				}, session.access_token).then((response) => {
					parseComparisonChart({selectedIndex:selectedIndex,response:response,color:color});
					setComparisonChartLoading(false);
				});
			break;
			case 2:
				setComparisonChartLoading(true);
				getRequest('/graphs/monthly/portfolios', {
					portfolioId: programId,
					dateStart: dateStart,
					dateEnd: dateEnd,
				}, session.access_token).then((response) => {
					parseComparisonChart({selectedIndex:selectedIndex,response:response,color:color});
					setComparisonChartLoading(false);
				});
			break;
			default:
				console.log(`Invalid comparisonType: ${comparisonType}`);
		}

	};

	const handleChartComparison = (e) => {

		let select = e.currentTarget;
		let comparisonType = parseInt( select.options[select.selectedIndex].dataset.type );
		let programId = select.value;

		if( !programId ) return;

		if( comparisonCharts.find(obj => { return obj.programId === programId; }) ) return;

		addComparisonChart({
			programId: programId,
			comparisonType: comparisonType,
			color: select.options[select.selectedIndex].dataset.color,
		});

		e.currentTarget.value='';
		e.currentTarget.blur();

	};


	// refreshCharts
	const refreshCharts = (width=null,callback=null) => {

		if( ['edit','performance'].indexOf(tab) > -1 ) return;

		if( performanceChart.container ) {
			setPerformanceChartOptions({
				...performanceChartOptions,
				chart: {
					...performanceChartOptions.chart,
					width: width ? width : performanceChart.container.closest('figure').offsetWidth
				}
			});
			addWaterMark(performanceChart);
		}

		if( aumCumulativeReturnsChart.container ) {
			setAumCumulativeReturnsChartOptions({
				...aumCumulativeReturnsChartOptions,
				chart: {
					...aumCumulativeReturnsChartOptions.chart,
					width: width ? width : aumCumulativeReturnsChart.container.closest('figure').offsetWidth
				}
			});
			addWaterMark(aumCumulativeReturnsChart);
		}

		if( cumulativeReturnsChart.container ) {
			setCumulativeReturnsChartOptions({
				...cumulativeReturnsChartOptions,
				chart: {
					...cumulativeReturnsChartOptions.chart,
					width: width ? width : cumulativeReturnsChart.container.closest('figure').offsetWidth
				}
			});
			addWaterMark(cumulativeReturnsChart);
		}

		if( distributionChart.container ) {
			setDistributionChartOptions({
				...distributionChartOptions,
				chart: {
					...distributionChartOptions.chart,
					width: width ? width : distributionChart.container.closest('figure').offsetWidth
				}
			});
			addWaterMark(distributionChart);
		}

		if( rollingChart.container ) {
			setRollingChartOptions({
				...rollingChartOptions,
				chart: {
					...rollingChartOptions.chart,
					width: width ? width : rollingChart.container.closest('figure').offsetWidth
				}
			});
			addWaterMark(rollingChart);
		}

		if( width === 732 ) {
			setStrategyPieChartOptions({
				...strategyPieChartOptions,
				chart: {
					...strategyPieChartOptions.chart,
					width: 160,
					heigh: 160,
				}
			});
			setCompositionPieChartOptions({
				...compositionPieChartOptions,
				chart: {
					...compositionPieChartOptions.chart,
					width: 160,
					heigh: 160,
				}
			});
		}
		else {
			setStrategyPieChartOptions({
				...strategyPieChartOptions,
				chart: {
					...strategyPieChartOptions.chart,
					width: 200,
					heigh: 200,
				}
			});
			setCompositionPieChartOptions({
				...compositionPieChartOptions,
				chart: {
					...compositionPieChartOptions.chart,
					width: 200,
					heigh: 200,
				}
			});
		}

		if( callback ) {
			callback();
		}

	};
	// TO-DO? find a better way :/
	// @link https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/


	// print page
	const printPage = () => {

		const form = document.getElementById('pdfcrowd');
		const root = document.getElementById('content');
		const html = document.getElementById('html');
		const name = document.getElementById('filename');

		setDisablePrint(true);
		setShowPrinting(true);

		refreshCharts(732, () => {

			setTimeout(() => {

				html.value = root.outerHTML;
				name.value = programUri;

				form.setAttribute('action', WP_BASE_URL + window.location.pathname);
				form.submit();

				setTimeout(() => {

					refreshCharts();

					setTimeout(() => {

						setShowPrinting(false);

						setTimeout(() => {

							setDisablePrint(false);

						}, 2000);

					}, 1000);

				}, 3000);

			}, 1000);

		});

	};


	// resize events
	useEffect(() => {

	    const debouncedHandleResize = debounce(() => {

			refreshCharts();

		}, 500);

	    window.addEventListener('resize', debouncedHandleResize);

		return _ => {
			window.removeEventListener('resize', debouncedHandleResize)
		}
	});


	// access + redirects
	useEffect(() => {
		if( !session.access_token && ['edit','performance'].indexOf(tab) > -1 ) {
			window.location = `/groups/${groupUri}/programs/${programUri}`;
		}
		!!session.access_token ? document.body.classList.remove('overflow-hidden') : document.body.classList.add('overflow-hidden');
	}, [
		lang,
		session,
		groupUri,
		programUri,
		tab,
	]);


	const isLoading = programLoading || programsCompareLoading;
	const isError = isProgramError || isProgramsCompareError;

	if (isError) {
		return (
			<NotFound
				lang={lang}
			/>
		)
	}

	if (isLoading || !program) {
		return (
			<Loader/>
		)
	}

	if ( ['edit','performance'].indexOf(tab) > -1 && canEdit(program.groupId) ) return (
		<div className='container py-5'>

			<MetaTags>
				<title>{[program.programName, program.groupName, text.siteTitle].join(text.titleSeparator)}</title>
			</MetaTags>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10'>

					<h6 className='mb-0'>
						<A className='d-block text-dark' href={`/groups/${groupUri}`}>{program.groupName}</A>
					</h6>

					<h1 className='mb-4 d-flex'>
						<i className='fa-solid fa-edit mr-2 mt-2' style={{fontSize:'75%'}}></i>
						<span><a href={`/groups/${groupUri}/programs/${programUri}`}>{program.programName}</a></span>
					</h1>

					<Tabs
						className={'iasg-tabs'}
						defaultActiveKey={tab ? tab : 'edit'}
						onSelect={(k) => {
							switch( k ) {
								case 'performance':
									window.location = `/groups/${groupUri}/programs/${programUri}/${k}`;
								break;
								default:
									navigate(`/groups/${groupUri}/programs/${programUri}/${k}`);
							}
						}}>

						<Tab
							className='pb-4'
							eventKey='edit'
							title={`Program Profile`}>
						{'edit' === tab &&
							<ProgramForm
								lang={lang}
								session={session}
								initialValues={program}
								/>
						}
						</Tab>

						<Tab
							className='pb-4'
							eventKey='performance'
							title={`Performance Data`}>
						{'performance' === tab &&
							<PerformanceForm
								lang={lang}
								session={session}
								initialValues={{
									programId: program.programId,
									dateInception: program.dateInception,
									investmentReturns: program.investmentReturns.length ? prepInvestmentReturns(program.investmentReturns) : [],
									csv: false,
									AcceptedAgreement: false,
								}}
								/>
						}
						</Tab>

					</Tabs>

				</div>

			</div>

		</div>
	)

	return (
		<div className={'container'} id='content'>

			<MetaTags>
				<title>{[(tabs.find((item) => {return item.key === tab})?.title || text.snapshot), program.programName, program.groupName, text.siteTitle].join(text.titleSeparator)}</title>
				<link rel='canonical' href={`${WP_BASE_URL}/groups/${groupUri}/programs/${programUri}`} />
				<meta name='description' content={program.description} />
				<meta name='robots' content='INDEX, FOLLOW' />
				<meta property='og:title' content={program.programName} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content={`${WP_BASE_URL}/groups/${groupUri}/programs/${programUri}`} />
			</MetaTags>

			<Modal show={showPrinting} onHide={() => {setShowPrinting(false)}} centered>

				<Modal.Body className='py-5 text-center'>

					<div className='d-flex justify-content-center'>
						<div>
							<h4 className='mb-3'>{text.generatingPDF}</h4>
						</div>
					</div>
					<div className='d-flex justify-content-center align-items-center'>
						<i className='fa-solid fa-circle-notch fa-2x fa-spin text-primary mr-2'></i>
					</div>

				</Modal.Body>

			</Modal>

			<Modal show={showRequestForm} onHide={toggleShowRequestForm} centered>

				<Modal.Header className='pb-0 border-0'>

					<button
						type='button'
						className='close'
						onClick={toggleShowRequestForm}>
						<i className='fa-solid fa-times' style={{fontSize:'80%'}}></i>
					</button>

				</Modal.Header>

				<Modal.Body className='px-sm-5 pb-5'>
				{!!group && !!program &&
					<RequestForm
						lang={lang}
						session={session}
						group={group}
						program={program}
						confirmEmail={'program-info-request'}
					/>
				}
				</Modal.Body>

			</Modal>

		{!!session.access_token &&
			<AddToBlenderModal
				lang={lang}
				session={session}
				programs={[{programId:program.programId}]}
				showModal={showAddToBlender}
				setShowModal={setShowAddToBlender}
				setShowToast={setShowToast}
				setToastMessage={setToastMessage}
				/>
		}
		{!session.access_token &&
			<BlockerModal
				lang={lang}
				session={session}
				toggleShowLogin={toggleShowLogin}
				/>
		}
			<div className='row'>

				<div className='col-12'>

					<div className='row mb-4 align-items-center'>

						<div className={'col-auto col-lg-8 col-xl-10 mr-auto order-2 order-lg-1 iasg-program-title'}>

							<h6 className='mb-0'>
								<A className='d-block text-dark' href={`/groups/${groupUri}`}>{program.groupName}</A>
							</h6>

							<h1 className='mb-0 d-flex align-items-center'>
								<span>{program.programName}</span>
							{canEdit(program.groupId) &&
								<a href={`/groups/${groupUri}/programs/${programUri}/edit`}
									style={{fontSize:'75%'}}
									className='d-print-none'>
									<i className='fa-solid fa-edit ml-2 mt-1'></i>
								</a>
							}
								{session.Admin && isArchived(program.programStatusId)}
								{session.Admin && isPending(program.programStatusId)}
								{isClosed(program.membershipStatusId)}
							</h1>
						</div>
						<div className='col-12 col-lg-auto order-1 order-lg-3 mb-4 mb-lg-0 iasg-program-logo'>
						{group && group.logo &&
							<img className='img-fluid d-block mx-auto' src={`${BASE_URL}/assets/images/logos/${group.logo}`} alt={group.groupName} width={160}/>
						}
						</div>
					</div>

					<div className='d-lg-none mb-4 d-print-none'>
						<Dropdown>
							<Dropdown.Toggle variant='outline-dark btn-block text-left' id='program-tabs'>
								{tabs.find((item) => {return item.key === tab})?.title || text.snapshot}
							</Dropdown.Toggle>
							<Dropdown.Menu>
							{tabs.filter((item) => {
								if( isIndex && 'badges' === item.key) return false;
								if( !canEdit(program.groupId) && 'users' === item.key) return false;
								return item;
							}).map((item) =>
								<Dropdown.Item key={item.key} href={`/groups/${groupUri}/programs/${programUri}/${item.key}`}>{item.title}</Dropdown.Item>
							)}
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<Tabs
						className={'iasg-tabs d-none d-lg-flex show-' + (tab ? tab : 'snapshot')}
						defaultActiveKey={tab ? tab : 'snapshot'}
						onSelect={(k) => {navigate(`/groups/${groupUri}/programs/${programUri}/${k}`);setTimeout(refreshCharts, 150);}}>

						<Tab
							className='pb-4'
							eventKey='snapshot'
							title={text.snapshot}>

							<div className='break-out bg-light-blue py-5'>

								<div className='container'>

									<div className='row'>

										<div className='col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0'>

											<div className='card shadow h-100'>

												<div className='card-body'>

													<h6 className='mb-0'>{text.yearToDate}</h6>

													<div className={'h1 pb-2 mb-0 border-bottom text-' + (programReturns.ytdReturn ? (programReturns.ytdReturn > 0 ? 'primary' : 'danger') : 'dark')}>
														<span className='pr-2'>{programReturns.ytdReturn ? (programReturns.ytdReturn * 100).toFixed(2) + '%' : 'N/A'}</span>
													{!!programReturns.ytdReturn &&
														<i className={'fas fa-long-arrow-alt-' + (programReturns.ytdReturn > 0 ? 'up' : 'down')} style={{fontSize:'90%'}}></i>
													}
													</div>

													<StatsTable stats={[
														{
															label: dateLastReturn ? (text[dateFormat(parseDate(dateLastReturn), 'MMM')] || dateFormat(parseDate(dateLastReturn), 'MMM')) : '',
															value: <span className={'text-' + (programReturns.lastReturn ? (programReturns.lastReturn > 0 ? 'primary' : 'danger') : 'dark')}>
																<span className={'pr-2'}>
																	{programReturns.lastReturn.toFixed(2) + '%'}
																</span>
																<i className={'fas fa-long-arrow-alt-' + (programReturns.lastReturn > 0 ? 'up' : 'down')} style={{fontSize:'90%'}} hidden={!programReturns.lastReturn}></i>
															</span>
														},
													]}/>

												</div>

											</div>

										</div>

										<div className={'col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0' + (isIndex ? ' d-none' : '')}>

											<div className='card shadow h-100'>

												<div className='card-body'>

													<StatsTable stats={[
														{
															label: text.minimumInvestment,
															value: <NumberFormat value={(program.investmentMin > 1 ? (program.investmentMin / 1000) : 0)}
																		displayType={'text'}
																		thousandSeparator={true}
																		prefix={getSymbolFromCurrency(program.currency)}
																		renderText={(value, props) => <div>{value}k</div>}/>
														},
														{
															label: text.managementFee,
															value: ((program.feeManagement ? program.feeManagement : 0) * 100).toFixed(2) + '%'
														},
														{
															label: text.performanceFee,
															value: ((program.feePerformance ? program.feePerformance : 0) * 100).toFixed(2) + '%'
														},
													]}/>

												</div>

											</div>

										</div>

										<div className={'col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0' + (isIndex ? ' d-none' : '')}>

											<div className='card shadow h-100'>

												<div className='card-body'>

													<StatsTable stats={[
														{
															label: text.volatilityAnnualized,
															value: annualStats.standardDeviation
														},
														{
															label: text.sharpeRatio,
															value: annualStats.sharpeRatio
														},
														{
															label: <span className='d-flex align-items-center'>
																<span>{text.compoundedAnnualROR}</span>
																<OverlayTrigger
																	placement={'right'}
																	overlay={
																		<Popover>
																			<Popover.Content>
																				{text.compoundedARORTooltip}
																			</Popover.Content>
																		</Popover>
																	}>
																	<i className='fa-solid fa-question-circle text-muted ml-1 d-print-none'></i>
																</OverlayTrigger>
															</span>,
															value: program.investmentReturns.length < 24 ? '—' : annualStats.compoundReturn
														},
													]}/>

												</div>

											</div>

										</div>

										<div className={'col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0' + (isIndex ? ' d-none' : '')}>

											<div className='card shadow h-100'>

												<div className='card-body'>

													<StatsTable stats={[
														{
															label: text.assets,
															value: assets ? <>{getSymbolFromCurrency(program.currency)}{MoneyFormat(assets)}</> : ''
														},
														{
															label: text.worstDrawdown,
															value: worstDrawDown ? worstDrawDown.toFixed(2) : ''
														},
														{
															label: text.correlationsp500,
															value: programReturns.correlationsp500 ? (programReturns.correlationsp500).toFixed(2) : ''
														},
													]}/>

												</div>

											</div>

										</div>

										<div className={'col-12 col-sm-6 col-md-9' + (isIndex ? '' : ' d-none')}>

											<div className='card shadow h-100'>

												<div className='card-body'>

													<div className='row text-center align-items-center h-100'>

														<div className='col'>

															<h6>{'Annualized Vol'}</h6>

															<div className={'h1 pb-2 mb-0 text-' + (parseFloat(annualStats.standardDeviation) > 0 ? 'primary' : 'danger')}>
																<span className='pr-2'>{annualStats.standardDeviation}</span>
															</div>

														</div>

														<div className='col'>

															<h6>{'Sharpe (RFR=1%)'}</h6>

															<div className={'h1 pb-2 mb-0 text-' + (parseFloat(annualStats.sharpeRatio) > 0 ? 'primary' : 'danger')}>
																<span className='pr-2'>{annualStats.sharpeRatio}</span>
															</div>

														</div>

														<div className='col'>

															<h6>
																<span className='d-flex align-items-center justify-content-center'>
																	<span>{text.compoundedAnnualROR}</span>
																	<OverlayTrigger
																		placement={'right'}
																		overlay={
																			<Popover>
																				<Popover.Content>
																					{text.compoundedARORTooltip}
																				</Popover.Content>
																			</Popover>
																		}>
																		<i className='fa-solid fa-question-circle text-muted ml-1'></i>
																	</OverlayTrigger>
																</span>
															</h6>

															<div className={'h1 pb-2 mb-0 text-' + (parseFloat(annualStats.compoundReturn) > 0 ? 'primary' : 'danger')}>
																<span className='pr-2'>{annualStats.compoundReturn}</span>
															</div>

														</div>

														<div className='col'>

															<h6>{'Worst DD'}</h6>

															<div className={'h1 pb-2 mb-0 text-' + (parseFloat(worstDrawDown) > 0 ? 'primary' : 'danger')}>
																<span className='pr-2'>{worstDrawDown ? worstDrawDown.toFixed(2) : ''}</span>
															</div>

														</div>

														<div className='col'>

															<h6>{'S&P Correlation'}</h6>

															<div className={'h1 pb-2 mb-0 text-' + (parseFloat(programReturns.correlationsp500 ) > 0 ? 'primary' : 'danger')}>
																<span className='pr-2'>{programReturns.correlationsp500 ? (programReturns.correlationsp500).toFixed(2) : ''}</span>
															</div>

														</div>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

							<div
								className='position-relative'
								aria-live='polite'
								aria-atomic='true'>

								<div
									className='position-absolute d-flex justify-content-center'
									style={{left:0,right:0}}>

									<Toast
										show={showToast}
										onClose={() => {setShowToast(false);}}
										delay={3000}
										autohide
										className='border-success position-absolute mt-n3'>

										<Toast.Body
											className='text-success text-center py-1'>
											<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
											{toastMessage}
										</Toast.Body>

									</Toast>

								</div>

							</div>

							<div className={'row d-flex flex-column flex-lg-row align-items-center py-4'}>
							{performanceChartOptions &&
								<div className='col-12 col-xl-4 my-3 my-lg-0'>

									<h6 className='m-0'>
										<span className='d-flex align-items-center'>
											<span>{text.vamiHeading}</span>
											<OverlayTrigger
												placement={'auto'}
												overlay={
													<Popover>
														<Popover.Content>
															{text.vamiTooltip}
														</Popover.Content>
													</Popover>
												}>
												<i className='fa-solid fa-question-circle text-muted ml-1 d-print-none' style={{fontSize:'80%'}}></i>
											</OverlayTrigger>
										</span>

									</h6>

								</div>
							}
								<div className='col-12 col-xl-auto ml-lg-auto pt-3 pt-xl-0 d-print-none'>

									<button className='btn btn-sm btn-link mr-1 pl-0 pl-xl-2'
										disabled={!session.access_token}
										onClick={(e) => {
											addToWatchlist([{programId:program.programId,alert:true}], session.access_token).then((response) => {
												setShowToast(true);
												setToastMessage(text.addedAlert);
												e.target.blur();
											});
										}}>
										<i className='fa-solid fa-bell mr-1'></i>
										{text.addAlert}
									</button>

									<button className='btn btn-sm btn-link mr-1'
										disabled={!session.access_token}
										onClick={(e) => {
											setShowAddToBlender(true);
											e.target.blur();
										}}>
										<i className='fa-solid fa-plus-circle mr-1'></i>
										{text.addToBlender}
									</button>

									<button className='btn btn-sm btn-link mr-1'
										disabled={!session.access_token}
										onClick={(e) => {
											addToPortfolio([{programId:program.programId}], session.access_token).then((response) => {
												setShowToast(true);
												setToastMessage(text.addedToPortfolio);
												e.target.blur();
											});
										}}>
										<i className='fa-solid fa-plus-circle mr-1'></i>
										{text.addToPortfolio}
									</button>

									<button className='btn btn-sm btn-link'
										disabled={!session.access_token}
										onClick={(e) => {
											addToWatchlist([{programId:program.programId}], session.access_token).then((response) => {
												setShowToast(true);
												setToastMessage(text.addedToWatchlist);
												e.target.blur();
											});
										}}>
										<i className='fa-solid fa-eye mr-1'></i>
										{text.addToWatchlist}
									</button>

									<button className='btn btn-sm btn-link'
										disabled={!session.access_token||disablePrint}
										onClick={printPage}>
										<i className='fa-solid fa-print mr-1'></i>
										{text.printPage}
									</button>

									<button className='btn btn-sm btn-link'
										disabled={!session.access_token}
										onClick={() => {
											toggleShowRequestForm();
										}}>
										<i className='fa-solid fa-file-alt mr-1'></i>
										{text.freeReport}
									</button>

								</div>

							</div>

						<div className={'row'}>

							<div className='col-12'>
							{performanceChartOptions &&
								<figure className='mb-5 d-flex justify-content-center'>

									<HighchartsReact
										options={performanceChartOptions}
										highcharts={Highstock}
										constructorType='stockChart'
										containerProps={{id:'performanceChart'}}
										callback={(chart) => {addWaterMark(chart);setPerformanceChart(chart);}}
									/>

								</figure>
							}
							</div>

						</div>

						{monthlyReturnsCSVData && <>
							<hr className='page-break' hidden={true}/>

							<div className='d-flex mt-5'>

								<h3 className='mb-0'>{text.monthlyPerformance}</h3>

								<CSVLink
									className='btn btn-link btn-sm ml-auto d-print-none'
									filename={`${programUri}-performance.csv`}
									data={monthlyReturnsCSVData}>
									<i className='fa-solid fa-file-spreadsheet mr-1'></i>
									{text.exportData}
								</CSVLink>

							</div>

							<PerformanceTable
								lang={lang}
								returns={monthlyReturns}
								showRows={4}
								/>

						{program.accountingNotes &&
							<div className='text-muted small mb-3' dangerouslySetInnerHTML={{__html: he.decode(program.accountingNotes)}}></div>
						}

							<p className='text-muted text-center'>
								<small>{text.performanceDisclaimer}</small>
							</p>
						</>}
						{!isIndex && (<>
							<div className={'row mt-5'}>

								<div className='col-12'>

									<h3>{text.periodReturns}</h3>

									<Table
										lang={lang}
										columns={[
										{
											field: 'programIndex',
											headerName: text.programIndex,
										},
										{
											field: 'lastReturn',
											headerName: dateLastReturn ? (text[dateFormat(parseDate(dateLastReturn), 'MMM')] || dateFormat(parseDate(dateLastReturn), 'MMM')) : '',
											type: 'float',
											format: 'onlyMinus',
										},
										{
											field: 'qtrYearReturn',
											headerName: text.qtrYearReturn,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'ytdReturn',
											headerName: text.YTD,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'fullYearReturn',
											headerName: text.fullYearReturn,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'threeYearReturn',
											headerName: text.threeYearReturn,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'fiveYearReturn',
											headerName: text.fiveYearReturn,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'tenYearReturn',
											headerName: text.tenYearReturn,
											type: 'percent',
											format: 'onlyMinus',
										},
										{
											field: 'cumulativeROR',
											headerName: dateFormat(subMonths(new Date(program.dateInception), 1), 'en' === lang ? "'Since' M/Y" : "'自' M/Y '起'"),
											type: 'float',
											format: 'onlyMinus',
										},
									]}
										rows={periodReturns}
										/>

								</div>

							</div>

						{(!!program.allocationStrategies.length || !!program.compositions.length) &&
							<div className={'row mb-5 pb-5 iasg-print-cols-2 mt-print-5 mb-print-0 pb-print-0'}>
							{!!program.allocationStrategies.length &&
								<div className='col-12 col-xl-6 mb-5 pb-5 pb-xl-0 mb-xl-0 mb-print-0 pb-print-0'>

									<h3 className='mb-3'>{text.strategy}</h3>

									<div className='card shadow h-100'>

										<div className='card-body d-flex align-items-center py-0'>

											<div className='row flex-fill align-items-center iasg-print-cols-2'>

												<div className='col-12 col-md-6'>
												{strategyPieChartOptions &&
													<figure className='mb-2'>
														<HighchartsReact
															options={strategyPieChartOptions}
															highcharts={Highcharts}
															containerProps={{id:'strategyPieChart'}}
														/>
													</figure>
												}
												</div>

												<div className='col-12 col-md-6'>

													<div className='table-responsive'>

														<table className='table table-sm mb-0'>
															<tbody>
															{program.allocationStrategies.map((allocationStrategy,i) =>
																<tr key={i}>
																	<th scope='row' className='py-1'>
																		<a className='d-block' href={`/managed-futures/performance?filterByAllocationStrategyTypesId=${allocationStrategy.allocationStrategyTypeId}`}>
																			<i className='fa-solid fa-square mr-1' style={{color:CHART_COLORS.default[i] || CHART_COLORS.default[(i - CHART_COLORS.default.length)]}}></i>
																			{allocationStrategy.title}
																		</a>
																	</th>
																	<td className='py-1 text-right' style={{width:'1%'}}>
																		{(allocationStrategy.value * 100).toFixed(2)}%
																	</td>
																</tr>
															)}
															</tbody>
														</table>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>
							}
							{!!program.compositions.length &&
								<div className='col-12 col-xl-6'>

									<h3 className='mb-3'>{text.composition}</h3>

									<div className='card shadow h-100'>

										<div className='card-body d-flex align-items-center py-0'>

											<div className='row flex-fill align-items-center iasg-print-cols-2'>

												<div className='col-12 col-md-6'>

													<figure className='mb-2'>
													{compositionPieChartOptions &&
														<HighchartsReact
															options={compositionPieChartOptions}
															highcharts={Highcharts}
															containerProps={{id:'compositionPieChart'}}
														/>
													}
													</figure>

												</div>

												<div className='col-12 col-md-6'>

													<div className='table-responsive'>

														<table className='table table-sm mb-0'>
															<tbody>
															{program.compositions.map((composition,i) =>
																<tr key={i}>
																	<th scope='row' className='py-1'>
																		<a className='d-block' href={`/managed-futures/performance?filterByCompositionTypesId=${composition.compositionTypeId}`}>
																			<i className='fa-solid fa-square mr-1' style={{color:CHART_COLORS.default[i] || CHART_COLORS.default[(i - CHART_COLORS.default.length)]}}></i>
																			{composition.title}
																		</a>
																	</th>
																	<td className='py-1 text-right' style={{width:'1%'}}>
																		{(composition.value * 100).toFixed(2)}%
																	</td>
																</tr>
															)}
															</tbody>
														</table>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>
							}
							</div>
						}
							<hr className='page-break' hidden={true}/>

							<div className={'row my-5 pt-3 iasg-print-cols-2'}>

								<div className='col-12 col-md-6'>

									<h6 className='table-heading mb-0'>
										{text.accountAndFees}
									</h6>

									<div className='table-responsive mb-4'>
										<table className='table table-sm table-striped'>
											<tbody>
											{[
												{
													label: text.programTypeTitle,
													value: program.programTypeTitle
												},
												{
													label: text.minimumInvestment,
													value: <NumberFormat value={(program.investmentMin > 1 ? (program.investmentMin / 1000) : 0)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={getSymbolFromCurrency(program.currency)}
																renderText={(value, props) => <div>{value}k</div>}/>
												},
												{
													label: text.managementFee,
													value: ((program.feeManagement ? program.feeManagement : 0) * 100).toFixed(2) + '%'
												},
												{
													label: text.performanceFee,
													value: ((program.feePerformance ? program.feePerformance : 0) * 100).toFixed(2) + '%'
												},
												{
													label: text.usInvest,
													value: program.usInvest ? text.yes : text.no
												},
												{
													label: text.investorRequirements,
													value: program.investorRequirementsTitle
												},
												{
													label: text.highwaterMark,
													value: <span style={{textTransform:'capitalize'}}>{program.highwaterMark}</span>
												},
												{
													label: text.subscriptionFrequency,
													value: program.subscriptionFrequencyTitle
												},
												{
													label: text.redemptionFrequency,
													value: program.redemptionFrequencyTitle
												},
											].map((row,i) =>
												<tr key={i}>
													<td>{row.label}</td>
													<td className='text-right'>
														{row.value}
													</td>
												</tr>
											)}
											</tbody>
										</table>
									</div>

									<h6 className='table-heading mb-0'>
										{text.decisionMaking}
									</h6>

									<div className='table-responsive'>
										<table className='table table-sm table-striped'>
											<tbody>
											{program.methodologies.map((methodology,i) =>
												<tr key={i}>
													<td>
														<a className='d-block' href={`/managed-futures/performance?filterByMethodologyTypesId=${methodology.methodologyTypeId}`}>{methodology.title}</a>
													</td>
													<td className='text-right'>
														{(methodology.value * 100).toFixed(2)}%
													</td>
												</tr>
											)}
											</tbody>
										</table>
									</div>

								</div>

								<div className='col-12 col-md-6'>

									<h6 className='table-heading mb-0'>
										{text.trading}
									</h6>

									<div className='table-responsive mb-4'>
										<table className='table table-sm table-striped'>
											<tbody>
											{[
												{
													label: text.tradingFrequency,
													value: program.roundturnYearMillion + ' RT/YR/$M'
												},
												{
													label: text.avgMarginEquityRatio,
													value: program.marginEquityRatio ? (program.marginEquityRatio * 100).toFixed(2) + '%' : ''
												},
												{
													label: text.wddExpected,
													value: ((program.wddExpected ? program.wddExpected : 0) * -100).toFixed(2) + '%'
												},
												{
													label: text.worstPeak2Trough,
													value: (program.worstPeak2Trough * 100).toFixed(2) + '%'
												},
												{
													label: text.sectorFocus,
													value:  <a className='d-block' href={`/managed-futures/performance?filterByProgramsSectorTypesId=${program.sectorFocusTypeId}`}>{program.programFocusTypeTitle}</a>
												},
											].map((row,i) =>
												<tr key={i}>
													<td>{row.label}</td>
													<td className='text-right'>
														{row.value}
													</td>
												</tr>
											)}
											</tbody>
										</table>
									</div>

									<h6 className='table-heading mb-0'>
										{text.holdingPeriods}
									</h6>

									<div className='table-responsive mb-4'>
										<table className='table table-sm table-striped'>
											<tbody>
											{program.holdings.map((holding,i) =>
												<tr key={i}>
													<td>
														{holding.title}
													</td>
													<td className='text-right'>
														{(holding.value * 100).toFixed(2)}%
													</td>
												</tr>
											)}
											</tbody>
										</table>
									</div>

								</div>

							</div>
						</>)}
						</Tab>

					{!isIndex &&
						<Tab
							className='py-4'
							eventKey='strategy'
							title={text.strategy}>

							<hr className='page-break' hidden={true}/>

						{!!program.description && <>
							<h4>
								{text.summary}
							</h4>

							<div className='mb-4' dangerouslySetInnerHTML={{__html: he.decode(program.description)}}></div>
						</>}
						{!!program.strategyInvestment && <>
							<h4>
								{text.investorStrategy}
							</h4>

							<div className='mb-4' dangerouslySetInnerHTML={{__html: he.decode(program.strategyInvestment)}}></div>
						</>}
						{!!program.strategyRisk && <>
							<h4>
								{text.riskManagement}
							</h4>

							<div className='mb-4' dangerouslySetInnerHTML={{__html: he.decode(program.strategyRisk)}}></div>
						</>}
						</Tab>
					}
						<Tab
							className='py-4 d-print-none'
							eventKey='charts'
							title={text.charts}>

							<hr className='page-break' hidden={true}/>
						{!!cumulativeReturnsChartOptions && !!rollingChartOptions && <>

							<div className='row'>

								<div className={'col-12'}>

									<div className='form-row align-items-center mb-4'>

										<div className='col-12 mb-3 mb-xl-0 col-xl-auto'>
											<ButtonGroup
												className='mb-0 px-3'
												aria-label='Change Chart type to:'>
											{[
												{id:'cumulativeReturns',label:text.cumulativeReturns,className:''},
												{id:'rolling',label:text.rolling,className:'px-4'},
											].map((type) =>
												<Button
													key={type.id}
													className={'rounded-pill mx-n3 px-5'}
													variant={type.id===chartType?'primary':'outline-primary'}
													onClick={(e) => {
														setChartType(type.id);
														e.currentTarget.blur();
													}}>
													<span className={type.className}>{type.label}</span>
												</Button>
											)}
											</ButtonGroup>
										</div>
									{'cumulativeReturns' === chartType &&
										<div className='col-12 col-xl-auto ml-xl-auto d-print-none'>
											<div className='form-row align-items-center justify-content-xl-end'>
												<div className={'col-auto order-5 order-xl-1 fade' + (comparisonChartLoading ? ' show' : '')}>
													<span className={'text-primary'}>
														<i className='fa-solid fa-circle-notch fa-spin'></i>
													</span>
												</div>
												<div className='col-12 col-md-auto order-1 order-xl-2'>
													<label className='form-text m-md-0' htmlFor='compareChartTo'>
														{text.compareTo}
													</label>
												</div>
												<div className='col-12 col-md-5 order-2 order-xl-3'>
													<AsyncTypeahead
														filterBy={filterBy}
														id='compareChartTo'
														isLoading={isSearching}
														labelKey='programName'
														ref={compareChartSearch}
														minLength={3}
														onChange={(selected) => {
															if( !selected.length ) return;
															let program = selected[0];
															if( comparisonCharts.find(obj => { return obj.programId === program.programId; }) ) return;
															addComparisonChart({
																programId: program.programId,
																title: program.programName,
																comparisonType: 1,
															});
															compareChartSearch.current.clear();
														}}
														onSearch={handleProgramSearch}
														options={searchResults}
														placeholder={text.programSearchPlaceholder}
														renderMenuItemChildren={(option, props) => (
															<>
																<small className='groupName'>{option.groupName}</small>
																<div className='programName'>{option.programName}</div>
															</>
														)}
														/>
												</div>
												<div className='col-12 col-md-auto order-3 order-xl-4'>
													<label className='form-text m-md-0' htmlFor='chartIndex'>{text.or}</label>
												</div>
												<div className='col-12 col-md-4 order-4 order-xl-5'>
												{programsCompareItems &&
													<select className='custom-select' id='chartIndex'
														onChange={(e) => handleChartComparison(e)}>
														<option value={''}>{text.selectAnIndex}</option>
													{programsCompareItems.items.filter((program) => program.programType === 1).map((program,i) =>
														<option
															key={program.programId}
															data-color={CHART_COLORS.indexes[i]}
															data-type={program.programType}
															disabled={comparisonCharts.find(obj => { return obj.programId === program.programId; })}
															value={program.programId}>{program.title}</option>
													)}
														<option disabled>{`——— ${text.siteTitle} Indexes ———`}</option>
													{programsCompareItems.items.filter((program) => program.programType === 2).map((program,i) =>
														<option
															key={program.programId}
															data-color={CHART_COLORS.iasgIndexes[i]}
															data-type={program.programType}
															disabled={comparisonCharts.find(obj => { return obj.programId === program.programId; })}
															value={program.programId}>{program.title}</option>
													)}
													</select>
												}
												</div>
											</div>
										</div>
									}
									</div>
								{'cumulativeReturns' === chartType &&
									<aside>
									{cumulativeReturnsChartOptions &&
										<figure className='mb-5 d-flex justify-content-center'>
											<HighchartsReact
												options={cumulativeReturnsChartOptions}
												highcharts={Highstock}
												constructorType='stockChart'
												containerProps={{id:'cumulativeReturns'}}
												callback={(chart) => {addWaterMark(chart);setCumulativeReturnsChart(chart);}}
											/>
										</figure>
									}
										<div className='mb-4'>
										{comparisonCharts.map((index,i) =>
											<button
												key={index.programId}
												onClick={(e) => {removeComparisonChart(index);}}
												className='btn btn-outline-dark btn-sm btn-tag mr-2 mb-2 p-0'>
													<span className='tag-border' style={{background:!!index.color?index.color:CHART_COLORS.default[i+1] || CHART_COLORS.default[((i+1) - CHART_COLORS.default.length)]}}></span>
													<span className='ml-2'>{index.title}</span>
													<i className='fa-solid fa-times mx-2'></i>
											</button>
										)}
										</div>

									</aside>
								}
								{'rolling' === chartType &&
									<aside>
									{rollingChartOptions &&
										<figure className='mb-5 d-flex justify-content-center'>
											<HighchartsReact
												options={rollingChartOptions}
												highcharts={Highstock}
												constructorType='stockChart'
												containerProps={{id:'rolling'}}
												callback={(chart) => {addWaterMark(chart);setRollingChart(chart);}}
											/>
										</figure>
									}
									</aside>
								}
								</div>

							</div>

							<hr className='my-5 page-break'/>
						</>}
						{aumCumulativeReturnsChartOptions && <>
							<div className='row'>

								<div className='col-12'>

									<h5 className='mb-3'>{text.aumCumulativeReturns}</h5>

									<figure className='mb-5 d-flex justify-content-center'>

										<HighchartsReact
											options={aumCumulativeReturnsChartOptions}
											highcharts={Highstock}
											constructorType='stockChart'
											containerProps={{id:'aumCumulativeReturns'}}
											callback={(chart) => {addWaterMark(chart);setAumCumulativeReturnsChart(chart);}}
										/>

									</figure>

								</div>

							</div>

							<hr className='my-5 page-break'/>
						</>}
							<div className='row'>

								<div className='col-12'>

									<h5 className='mb-3'>{text.distribution}</h5>

									<figure className='mb-5 d-flex justify-content-center'>
									{distributionChartOptions &&
										<HighchartsReact
											options={distributionChartOptions}
											highcharts={Highcharts}
											containerProps={{id:'distribution'}}
											callback={(chart) => {addWaterMark(chart);setDistributionChart(chart);}}
										/>
									}
									</figure>

								</div>

							</div>

							<p className='text-muted'>
								<small>{text.performanceDisclaimer}</small>
							</p>

						</Tab>

						<Tab
							className='py-4 d-print-none'
							eventKey='statistics-ratios'
							title={text.statsAndRatios}>

							<hr className='page-break' hidden={true}/>

							<StatsAndRatios
								text={text}
								session={session}
								program={program}
								statsType={statsType}
								setStatsType={setStatsType}
								comparisonStats={comparisonStats}
								setComparisonStats={setComparisonStats}
								setComparisonStatsLoading={setComparisonStatsLoading}
								parseStatisticsRatios={parseStatisticsRatios}
								monthlyStats={monthlyStats}
								annualStats={annualStats}
								comparisonStatsLoading={comparisonStatsLoading}
								AsyncTypeahead={AsyncTypeahead}
								filterBy={filterBy}
								isSearching={isSearching}
								compareStatsSearch={compareStatsSearch}
								handleProgramSearch={handleProgramSearch}
								searchResults={searchResults}
								programsCompareItems={programsCompareItems}
								/>

							<p className='text-muted'>
								<small>{text.performanceDisclaimer}</small>
								<br/>
								<small>{text.statsDisclaimer}</small>
							</p>

						</Tab>

						<Tab
							className='py-4 d-print-none'
							eventKey='performance-tables'
							title={text.performanceTables}>

							<hr className='page-break' hidden={true}/>

						{!!twaData.length && <>

							<h4 className='text-center'>{text.timeWindowAnalysis}</h4>

							<div className={'table-responsive mb-5'}>
								<table className='table table-sm table-striped'>
									<thead>
										<tr>
											<th scope='col'></th>
										{twaCols.map((col) =>
											<th scope='col' key={col.months} className='text-right'>{col.headerName}</th>
										)}
										</tr>
									</thead>
									<tbody>
									{twaProps.map((prop) =>
										<tr key={prop.field}>
											<th scope='row'>{prop.headerName}</th>
										{twaData.map((data,i) =>
											<td key={i} className='text-right'>{data[prop.field]?.toFixed(2)}</td>
										)}
										</tr>
									)}
									</tbody>
								</table>
							</div>

						</>}

							<div className='row iasg-print-cols-2'>

								<div className='col-12 col-lg-4'>

									<h4 className='d-flex align-items-center justify-content-center'>

										<span>{text.drawdownReportHeading}</span>

										<OverlayTrigger
											placement={'right'}
											overlay={
												<Popover>
													<Popover.Content>
														{text.drawdownReportTooltip}
													</Popover.Content>
												</Popover>
											}>
											<i className='fa-solid fa-question-circle text-muted ml-1 d-print-none' style={{fontSize:'1rem'}}></i>
										</OverlayTrigger>

									</h4>

									<Table
										lang={lang}
										columns={[
											{
												field: 'magnitude',
												headerName: text.magnitude,
												type: 'float',
												format: 'plusMinus',
											},
											{
												field: 'length',
												headerName: text.lengthMonths,
												type: 'int',
											},
											{
												field: 'recovery',
												headerName: text.recoveryMonths,
												type: 'int',
											},
											{
												field: 'peak',
												headerName: text.peak,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
											{
												field: 'valley',
												headerName: text.valley,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
										]}
										rows={drawDownReport}
										showRows={10}
										/>

								</div>

								<div className='col-12 col-lg-4'>

									<h4 className='text-center'>{text.consecutiveGains}</h4>

									<Table
										lang={lang}
										columns={[
											{
												field: 'run',
												headerName: text.runUp,
												type: 'float',
												format: 'plusMinus',
											},
											{
												field: 'length',
												headerName: text.lengthMonths,
												type: 'int',
											},
											{
												field: 'dateStart',
												headerName: text.dateStart,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
											{
												field: 'dateEnd',
												headerName: text.dateEnd,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
										]}
										rows={program.consecutiveGains}
										showRows={10}
										/>

								</div>

								<div className='col-12 col-lg-4'>

									<h4 className='text-center'>{text.consecutiveLosses}</h4>

									<Table
										lang={lang}
										columns={[
											{
												field: 'run',
												headerName: text.runUp,
												type: 'float',
												format: 'plusMinus',
											},
											{
												field: 'length',
												headerName: text.lengthMonths,
												type: 'int',
											},
											{
												field: 'dateStart',
												headerName: text.dateStart,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
											{
												field: 'dateEnd',
												headerName: text.dateEnd,
												type: 'date',
												dateFormat: 'M/yyyy',
												className: 'text-right',
											},
										]}
										rows={program.consecutiveLosses}
										showRows={10}
										/>

								</div>

							</div>

							<p className='text-muted mb-5'>
								<small>{text.performanceDisclaimer}</small>
							</p>

						</Tab>

					{!isIndex &&
						<Tab
							className='py-4 d-print-none'
							eventKey='badges'
							title={text.badges}>

							<hr className='page-break' hidden={true}/>

							<h4>{text.topPerformerBadges}</h4>

							<Table
								lang={lang}
								sticky={false}
								showRows={10}
								columns={[
									{
										field: 'portfolioNameRanked',
										headerName: 'Index',
									},
									{
										field: 'awardName',
										headerName: 'Award Type',
										className: 'text-center',
									},
									{
										field: 'rank',
										headerName: 'Rank',
										className: 'text-center',
									},
									{
										field: 'performance',
										headerName: 'Performance',
										type: 'float',
										format: 'plusMinus',
										className: 'text-center',
									},
									{
										field: 'dateEnd',
										headerName: 'Period',
										type: 'date',
										dateFormat: 'M/yyyy',
										className: 'text-center',
									},
									{
										field: 'badge',
										headerName: '',
									},
								]}
								rows={program.awardList.sort((a,b) => {
										return b.dateEnd < a.dateEnd ? -1 : ( b.dateEnd > a.dateEnd ? 1 : 0 )
									}).map((award) => {
										return {
											...award,
											programId: program.programId,
										}
									})
								}
								/>

							<p className='text-muted'>
								<small>{text.performanceDisclaimer}</small>
							</p>

						</Tab>
					}
					{session.Admin &&
						<Tab
							className='py-4 d-print-none'
							eventKey='users'
							title={text.logs}>
						{ !logs &&
							<div className='py-3' style={{padding:'.75rem .5rem'}}>{text.loading}</div>
						}
						{ logs && !logs.length &&
							<div className='alert alert-warning'>{`No logs found…`}</div>
						}
						{ logs && <>
							<div className='row no-gutters align-items-center py-3 bg-white border-bottom'>
							{ !! logs.length && <>
								{!CSVLogs &&
									<div className='col-auto'>
										<button
											type='button'
											disabled={logsExport}
											className='btn btn-link btn-sm ml-1'
											onClick={(e) => {
												setLogsExport(true);
												e.currentTarget.blur();
											}}>
										{logsExport && <>
											<i className='fa-solid fa-fw fa-circle-notch fa-spin mr-1'></i>
											{`Preparing Export…`}
										</>}
										{!logsExport && <>
											<i className='fa-solid fa-fw fa-file-download mr-1'></i>
											{`Export Logs`}
										</>}
										</button>
									</div>
								}
								{CSVLogs &&
									<div className='col-auto'>
										<CSVLink
											className='btn btn-link btn-sm ml-1'
											filename={`${groupUri}-${programUri}-user-logs.csv`}
											data={CSVLogs.items}>
											<i className='fa-solid fa-fw fa-file-download mr-1'></i>
											{`Download CSV`}
										</CSVLink>
									</div>
								}
							</>}
							{ logsTotalPages > 1 && <>
								<div className='col-auto ml-xl-auto pt-3 pt-xl-0'>
									<div className='form-inline' hidden={logsTotalCount <= logsPageSize}>
										<div className='form-group pr-2'>
											<i className='fa-solid fa-circle-notch fa-spin text-primary mr-2' hidden={!logsLoading}></i>
											<label className='pr-2'>
												<b>{text.rows}:</b>
											</label>
											<select className='custom-select custom-select-sm'
												value={logsPageSize}
												onChange={(e) => {setLogsPageSize(e.currentTarget.value);setLogsPageNumber(1);}}>
											{[10,25,50,100].map((size) =>
												<option key={size} value={size}>{size}</option>
											)}
											</select>
										</div>
										<span className='pr-3'>
											{logsPageNumber <= 1 ? 1 : (logsPageNumber - 1) * logsPageSize + 1} – {(logsPageSize * logsPageNumber) > logsTotalCount ? logsTotalCount : (logsPageSize * logsPageNumber)} {text.of} {logsTotalCount}
										</span>
										<span className='pr-2'>
											<b>{text.page}</b>
										</span>
										<span className='pr-2'>
											<input className='form-control form-control-sm'
												type='number'
												step='1'
												min='1'
												max={logsTotalPages}
												value={logsPageNumber}
												onChange={(e) => {setLogsPageNumber(e.currentTarget.value)}}/>
										</span>
										<span className='pr-2'>
											{text.of} {logsTotalPages}
										</span>
										<button className='btn btn-sm btn-primary mr-1'
											disabled={parseInt(logsPageNumber) === 1}
											onClick={(e) => {e.preventDefault();setLogsPageNumber((parseInt(logsPageNumber)-1))}}>
											<i className='fa-solid fa fa-chevron-left mx-1'></i>
										</button>
										<button className='btn btn-sm btn-primary'
											disabled={parseInt(logsPageNumber) === logsTotalPages}
											onClick={(e) => {e.preventDefault();setLogsPageNumber((parseInt(logsPageNumber)+1))}}>
											<i className='fa-solid fa fa-chevron-right mx-1'></i>
										</button>
									</div>
								</div>
							</>}
							</div>
						</>}
						{!!logs.length && <>
							<div className={'table-responsive sticky-thead mb-3'}>
								<table className='table table-sm table-striped'>
									<thead>
										<tr>
										{logsColumns.map(column =>
											<th key={column.field} scope='col' style={{
												cursor: (column.sortable ? 'pointer' : 'default'),
												width: 'actions' === column.field ? '1%' : 'auto',
											}}
												onClick={(e) => {
													if( !column.sortable ) return;
													switch(column.field) {
														case 'name':
															setLogsSortBy('lastName');
														break;
														default:
															setLogsSortBy(column.field);
													}
													setLogsOrderBy('DESC' === logsOrderBy ? 'ASC' : 'DESC');
												}}>
												<span>{column.headerName}</span>
											{column.field === logsSortBy &&
												<i className={ 'DESC' === logsOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
											}
											{'name' === column.field && 'lastName' === logsSortBy &&
												<i className={ 'DESC' === logsOrderBy ? 'fas fa-caret-down pl-1' : 'fas fa-caret-up pl-1' }></i>
											}
											</th>
										)}
										</tr>
									</thead>
									<tbody>
									{logs.map(log =>
										<tr key={log.dateCreated}>
										{logsColumns.map(column =>
											<td key={`${log.dateCreated}-${column.field}`} style={{width:'40%'}}>
											{'name' === column.field && log.userId &&
												<div>
													<A className='d-block' href={`/admin/users/${log.userId}`}>
														{log.firstName} {log.lastName}<i className='fa-solid fa-edit text-success ml-1' style={{fontSize:'90%'}}></i>
													</A>
													<span>{log.email}</span>
												</div>
											}
											{'phone' === column.field && log.userId &&
												<div>
													<a className='d-block' href={`tel:${log.phone}`}>{log.phone}</a>
												</div>
											}
											{'email' === column.field && log.userId &&
												<div>
													<a className='d-block' href={`mailto:${log.email}`}>{log.email}</a>
												</div>
											}
											{'dateCreated' === column.field &&
												<div style={{whiteSpace:'nowrap'}}>
													{dateFormat(parseDate(log.dateCreated), 'MM/dd/yyyy, H:mm')}
												</div>
											}
											</td>
										)}
										</tr>
									)}
									</tbody>
								</table>
							</div>
						</>}
						</Tab>
					}
						<Tab
							className='py-4 d-print-none'
							eventKey='all'
							title={text.showAll}>
						</Tab>

					</Tabs>

				</div>

			</div>

		</div>
	);
}

export default Program;
