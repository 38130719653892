/*
 * Tables string translation
 *
 * @link https://translate.google.com/?sl=en&tl=zh-CN&text=Hello%20world!&op=translate
 *
 */
export const labels = {
	'en': {
		groupProgram: 'CTA / Program',
		portfolioName: 'CTA/Program Index',
		past12Months: 'Past 12 Months',
		compoundReturn: 'CAROR',
		compoundedAnnualROR: 'CAROR',
		maxDrawDown: 'WDD',
		asset: 'Programs',
		assets: 'AUM',
		investmentMin: 'Min Inv',
		dateArchived: 'Date Archived',
		dateInception: 'Inception',
		dateLastReturn: 'Last Return',
		fullYearReturn: '1yr',
		threeYearReturn: '3yr',
		fiveYearReturn: '5yr',
		tenYearReturn: '10yr',
		marginEquityRatio: 'Margin-Equity Ratio',
		sharpe: 'Sharpe *',
		sharpeAnnualized: 'Annualized Sharpe',
		sharpeRatio: 'Sharpe (RFR=1%)',
		correlationsp500: 'S&P Correlation',
		standardDeviation: 'Annualized Std Dev',
		alert: 'Email Alert',
		quantity: 'Amount',
		dateAllocation: 'Allocation Date',
		currentValue: 'Current Value',
		periodReturn: 'Return ％',
		totalValue: 'Total Value',
		blendName: 'Saved Blends',
		chartToggle: 'Chart',
		dateCalculated: 'Last Modified',
		feeManagement: 'Mgmt Fee',
		feePerformance: 'Perf Fee',
		cashLevel: 'Cash Level',
		leverage: 'Leverage',
		pctAllocated: '% Allocated',
		minimumInvestment: 'Minimum Investment',
		managementFee: 'Management Fee',
		performanceFee: 'Performance Fee',
		volatilityAnnualized: 'Annualized Volatility',
		worstDrawdown: 'Worst Drawdown',
		programIndex: 'Program / Index',
		qtrYearReturn: 'Qtr',
		programTypeTitle: 'Type',
		tradingLevelIncrease: 'Trading Level Incremental Increase',
		fundingMaxNotional: 'CTA Max Funding Factor',
		averageCommission: 'Average Commission',
		usInvest: 'Available to US Investors',
		highwaterMark: 'High Water Mark',
		subscriptionFrequency: 'Subscription Frequency',
		redemptionFrequency: 'Redemption Frequency',
		tradingFrequency: 'Trading Frequency',
		investorRequirements: 'Investor Requirements',
		lockupPeriod: 'Lock-up Period',
		avgMarginEquityRatio: 'Avg. Margin-Equity Ratio',
		wddExpected: 'Targeted Worst DD',
		worstPeak2Trough: 'Worst Peak-to-Trough',
		sectorFocus: 'Sector Focus',
		copyBlend: 'Duplicate',
		Year: 'Year',
		Jan: 'Jan',
		Feb: 'Feb',
		Mar: 'Mar',
		Apr: 'Apr',
		May: 'May',
		Jun: 'Jun',
		Jul: 'Jul',
		Aug: 'Aug',
		Sep: 'Sep',
		Oct: 'Oct',
		Nov: 'Nov',
		Dec: 'Dec',
		YTD: 'YTD',
		DD: 'DD',
		magnitude: 'Depth',
		recoveryMonths: <>
			<span>{`Recovery`}<br/>{`(Months)`}</span>
		</>,
		peak: 'Peak',
		valley: 'Valley',
		runUp: 'Run-up',
		lengthMonths: <>
			<span>{`Length`}<br/>{`(Months)`}</span>
		</>,
		dateStart: 'Start',
		dateEnd: 'End',
		isArchived: 'Archived',
		isDeleted: 'Deleted',
		isClosed: 'Closed',
	},
	'zh-hans': {
		groupProgram: 'CTA / 程序',
		portfolioName: 'CTA/程序索引',
		past12Months: '过去 12 个月',
		compoundReturn: '复合年回报率',
		compoundedAnnualROR: '复合年回报率',
		maxDrawDown: '最糟糕的回撤',
		asset: '程式',
		assets: '管理资产的价值',
		investmentMin: '最低投资',
		dateArchived: '存档日期',
		dateInception: '成立',
		dateLastReturn: '最终回报',
		fullYearReturn: '1年',
		threeYearReturn: '3年',
		fiveYearReturn: '5年',
		tenYearReturn: '10年',
		marginEquityRatio: '保证金权益比率',
		sharpe: '年化夏普比率*',
		sharpeAnnualized: '年化夏普比率',
		sharpeRatio: '夏普比率',
		correlationsp500: '标准普尔相关系数',
		standardDeviation: '年度标准差',
		alert: '电子邮件警报',
		quantity: '数量',
		dateAllocation: '分配日期',
		currentValue: '当前值',
		periodReturn: '返回 ％',
		totalValue: '总价值',
		blendName: '保存的混合',
		chartToggle: '图表',
		dateCalculated: '计算日期',
		feeManagement: '管理费',
		feePerformance: '表现费',
		cashLevel: '现金水平',
		leverage: '杠杆作用',
		pctAllocated: '已分配百分比',
		minimumInvestment: '最低投资',
		managementFee: '管理费',
		performanceFee: '表演费',
		volatilityAnnualized: '年化波动率',
		worstDrawdown: '最糟糕的回撤',
		programIndex: '节目/索引',
		qtrYearReturn: '本季度',
		programTypeTitle: '类型',
		tradingLevelIncrease: '交易水平递增',
		fundingMaxNotional: 'CTA 最大融资系数',
		averageCommission: '平均佣金',
		usInvest: '可供美国投资者使用',
		highwaterMark: '高水位',
		subscriptionFrequency: '订阅频率',
		redemptionFrequency: '赎回频率',
		tradingFrequency: '交易频率',
		investorRequirements: '投资者要求',
		lockupPeriod: '锁定期',
		avgMarginEquityRatio: '平均保证金权益比率',
		wddExpected: '有针对性的最坏损失',
		worstPeak2Trough: '最差的峰谷',
		sectorFocus: '行业聚焦',
		copyBlend: '复制',
		Year: '年',
		Jan: '一月',
		Feb: '二月',
		Mar: '三月',
		Apr: '四月',
		May: '五月',
		Jun: '六月',
		Jul: '七月',
		Aug: '八月',
		Sep: '九月',
		Oct: '十月',
		Nov: '十一月',
		Dec: '十二月',
		YTD: '年初至今',
		DD: 'DD',
		magnitude: '深度',
		recoveryMonths: <>
			<span>{`恢复`}<br/>{`(月)`}</span>
		</>,
		peak: '顶峰',
		valley: '谷',
		runUp: '助跑',
		lengthMonths: <>
			<span>{`时长`}<br/>{`(月)`}</span>
		</>,
		dateStart: '开始',
		dateEnd: '结尾',
		isArchived: '已存档',
		isDeleted: '已删除',
		isClosed: '合拢的',
	},
};
