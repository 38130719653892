export const labels = {
	'en': {
		pageTitle: 'Reset your password',
		pageContent: '',
		'Invalid token.': 'There was an issue with updating your password. Please click or copy the link in the email or request a new link.',
	},
	'zh-hans': {
		pageTitle: '重置你的密码',
		pageContent: '',
		'Invalid token.': '更新密码时出现问题。 请单击或复制电子邮件中的链接或请求新链接。',
	}
};
