import { createContext } from 'react';

// Constants
import {
	API_URL,
	WP_AJAX_URL,
} from '../helpers/Constants';

// UserContext
export const UserContext = createContext({
	isLoggedIn: false,
	login: () => {},
	logout: () => {}
});

// authenticate
export const authenticate = async (username, password) => {

	const body = new FormData();

	body.append( 'action', 'iasg_auth' );
	body.append( 'username', username );
	body.append( 'password', password );

	const response = await fetch(`${WP_AJAX_URL}`, {
		method: 'POST',
		body: body,
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// updateUser
export const updateUser = async (UserId, body, access_token) => {

	const response = await fetch(`${API_URL}/userAccounts/${UserId}`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteAccount
export const deleteAccount = async (UserId, access_token) => {

	const response = await fetch(`${API_URL}/userAccounts/${UserId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// editPassword
export const editPassword = async (body, access_token) => {

	const response = await fetch(`${API_URL}/userAccounts/editpassword`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	// return response.json was throwing `Uncaught (in promise) SyntaxError: Unexpected end of JSON input`
	return response.text().then(function(text) {
		return text ? JSON.parse(text) : {success:true}
	});

};

// mailchimpApiRequest
export const mailchimpApiRequest = async (props) => {

	const body = new FormData();

	body.append( 'action', 'iasg_mailchimp' );

	for (var prop in props) {
		body.append( prop, props[prop] );
	}

	const response = await fetch(`${WP_AJAX_URL}`, {
		method: 'POST',
		body: body,
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// transactionalEmailRequest
export const transactionalEmailRequest = async (props) => {

	const body = new FormData();

	body.append( 'action', 'iasg_email' );

	for (var prop in props) {
		body.append( prop, props[prop] );
	}

	const response = await fetch(`${WP_AJAX_URL}`, {
		method: 'POST',
		body: body,
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// putRoles
export const putRoles = async (UserId, body, access_token) => {

	const response = await fetch(`${API_URL}/userAccounts/${UserId}/addroles`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteRoles
export const deleteRoles = async (UserId, body, access_token) => {

	const response = await fetch(`${API_URL}/userAccounts/${UserId}/removeroles`, {
		method: 'DELETE',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
