/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// PasswordForm
function PasswordForm({
	text,
	initialValues,
	onSubmit,
}) {

	// form schema
	const schema = yup.object().shape({
		NewPassword: yup.string().min(6, text.PasswordMinLength.replace('{min', '${min')).matches( (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/)), text.PasswordStrength).required(text.PasswordRequired),
		PasswordConfirmation: yup.string().oneOf([yup.ref('NewPassword'), null], text.PasswordMismatch).required(text.PasswordRequired),
	});

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='form-row'>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='NewPassword'>{text.NewPassword}*</Form.Label>
							<Form.Control
								type='password'
								name='NewPassword'
								id='NewPassword'
								autoComplete='off'
								value={values.NewPassword}
								onChange={handleChange}
								isValid={touched.NewPassword && !errors.NewPassword}
								isInvalid={touched.NewPassword && errors.NewPassword}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.NewPassword}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

					<div className='col-12 col-sm-6'>

						<Form.Group>
							<Form.Label htmlFor='PasswordConfirmation'>{text.PasswordConfirmation}*</Form.Label>
							<Form.Control
								type='password'
								name='PasswordConfirmation'
								id='PasswordConfirmation'
								value={values.PasswordConfirmation}
								onChange={handleChange}
								isValid={touched.PasswordConfirmation && !errors.PasswordConfirmation}
								isInvalid={touched.PasswordConfirmation && errors.PasswordConfirmation}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.PasswordConfirmation}
							</Form.Control.Feedback>
						</Form.Group>

					</div>

				</div>

				<Form.Group>
					<button type='submit' className='btn btn-primary btn-block'>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default PasswordForm;
