
function Header({
	lang='en',
	session={},
	logout=()=>{},
	toggleShowLogin=()=>{},
	WP_BASE_URL='',
}) {

	// attributes
	const classes = [
		'menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children dropdown nav-item',
		'menu-item menu-item-type-custom menu-item-object-custom nav-item',
		'dropdown-toggle nav-link',
		'dropdown-item',
	];
	const itemType = 'https://www.schema.org/SiteNavigationElement';

    return (<>
        <header className='bg-white' id='site-header' data-headroom data-offset={0}>

			<nav className='navbar navbar-expand-xl bg-white border-bottom d-none d-xl-block py-3 py-xl-3' id='desktop-nav'>

				<div className='container'>

					<a className='navbar-brand clearfix' href='/' title={`IASG`}>
						<img src={`${WP_BASE_URL}/wp-content/themes/iasg/img/iasg-logo.png`} alt={`IASG`} width='143' height='30.03'/>
					</a>

					<div className='collapse navbar-collapse'>

						<div className='mr-auto'>

							<ul id='primary-menu' className='navbar-nav align-items-xl-center'>

								<li itemScope='itemscope' itemType={itemType}
									id='menu-item-7805'
									className={classes[0]}>

									<a href={`/tools`} data-toggle='dropdown' aria-expanded='false' className={classes[2]} id='menu-item-dropdown-7805'>
										<span>{`Tools`}</span>
									</a>

									<ul className='dropdown-menu' aria-labelledby='menu-item-dropdown-7805' role='menu'>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/tools/blender`} className={classes[3]}>
												<span>{`Blender`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/tools/saved-blends`} className={classes[3]}>
												<span>{`Saved Blends`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/tools/portfolio`} className={classes[3]}>
												<span>{`Portfolio`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/tools/watchlist`} className={classes[3]}>
												<span>{`Watchlists`}</span>
											</a>
										</li>
									</ul>
								</li>

								<li itemScope='itemscope' itemType={itemType}
									id='menu-item-100'
									className={classes[0]}>

									<a href={`/managed-futures/performance`} data-toggle='dropdown' aria-expanded='false' className={classes[2]} id='menu-item-dropdown-100'>
										<span>{`Managed Futures`}</span>
									</a>

									<ul className='dropdown-menu' aria-labelledby='menu-item-dropdown-100' role='menu'>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/managed-futures/performance`} className={classes[3]}>
												<span>{`Performance`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/managed-futures/market-quotes`} className={classes[3]}>
												<span>{`Market Quotes`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/managed-futures/returns`} className={classes[3]}>
												<span>{`Returns`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/managed-futures/new-listings`} className={classes[3]}>
												<span>{`New Listings`}</span>
											</a>
										</li>
										<li itemScope='itemscope' itemType={itemType} className={classes[1]}>
											<a href={`/managed-futures/archived-programs`} className={classes[3]}>
												<span>{`Archived Programs`}</span>
											</a>
										</li>
									</ul>

								</li>

							</ul>

						</div>

						<div className='ml-auto mr-2'>
							<div className='d-flex'>
								<div className='d-xxl-none'>
									
								</div>
								<div className='d-none d-xxl-block'>
									
								</div>
							</div>
						</div>

						<div className='d-flex' id='session-links'>
						{!!session.access_token &&
							<ul className='navbar-nav'>
	
								<li className='nav-item dropdown'>
	
									<a className='nav-link dropdown-toggle'
										href={`/tools/dashboard`} id='MyIASGMenuToggle' data-toggle='dropdown' aria-expanded='false'>
										<span>{`My IASG`}</span>
									</a>
	
									<ul className='dropdown-menu dropdown-menu-right' aria-labelledby='MyIASGMenuToggle'>
										<li className='nav-item'>
											<a className={classes[3]} href={`/tools/dashboard`}>
												{`My IASG`}
											</a>
										</li>
										<li className='nav-item'>
											<a className={classes[3]} href={`/profile`}>
												{`Profile`}
											</a>
										</li>
									{session.Admin &&
										<li className='nav-item'>
											<a className={classes[3]} href={`/admin`}>
												{`Admin`}
											</a>
										</li>
									}
										<li className='nav-item'>
											<button className={classes[3]} onClick={logout}>
												{`Log Out`}
											</button>
										</li>
									</ul>
	
								</li>
	
							</ul>
						}
						{!session.access_token && <>
							<button className='btn btn-outline-dark mr-2' onClick={toggleShowLogin}>
								{`Log In`}
							</button>

							<a className='btn btn-primary'
								href={`/register`}>
								<span>{`Register`}</span>
							</a>
						</>}
						</div>

					</div>

				</div>

			</nav>

			<nav className='navbar bg-white shadow-sm d-xl-none' id='mobile-nav'>

				<a className='nav-link ml-n3' data-toggle='modal' data-target='#mobileNavModal' href='#mobileNavModal'>
					<i className='fa-solid fa-bars'></i>
				</a>
				<a className='d-block' href={`/`} title={`IASG`}>
					<img src={`${WP_BASE_URL}/wp-content/themes/iasg/img/iasg-logo.png`} alt={`IASG`} width='76' height='16'/>
				</a>
				<a className='nav-link mr-n3' data-toggle='modal' data-target='#searchModal' href='#searchModal'>
					<i className='fa-solid fa-search'></i>
				</a>

			</nav>

		</header>
	</>);

}

export default Header;
