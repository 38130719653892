import React, {
	useEffect,
	useState,
} from 'react';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
	InputGroup,
	Collapse,
	Toast,
	OverlayTrigger,
	Popover,
} from 'react-bootstrap';


/*
 * react-csv
 *
 * @link https://www.npmjs.com/package/react-csv
 *
 */
import {
	CSVLink,
} from 'react-csv';


/*
 * React-DatePicker
 *
 * @link https://reactdatepicker.com/#example-range-month-picker
 * @link https://github.com/Hacker0x01/react-datepicker
 *
 */
import DatePicker from 'react-datepicker';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
	FieldArray,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// Constants
import {
	YEARS,
} from '../../helpers/Constants';


// Helpers
import {
	parseDate,
	dateFormat,
	addMonths,
	subMonths,
	prepInvestmentReturns,
} from '../../helpers/Helpers';


// API
import {
	updateMonthlyReturns,
	uploadMonthlyReturns,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';


// PerformanceForm
function PerformanceForm({
	lang,
	session,
	initialValues,
}) {

	const text = {...labelsGlobal[lang],...labelsForms[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');


	// section toggles
	const [generalDataOpen,setGeneralDataOpen] = useState(true);
	const [performanceDataOpen,setPerformanceDataOpen] = useState(true);
	const [currentYear,setCurrentYear] = useState( (new Date()).getMonth() === 0 ? ( (new Date()).getFullYear() - 1 ) : (new Date()).getFullYear() );


	// CSV upload
	const [csvFileName, setCsvFileName] = useState('…');


	// form schema
	const schema = yup.object().shape({

		programId: yup.string().required(text.required),
		dateInception: yup.string().required(text.required),

		investmentReturns: yup.array().of(
			yup.object().shape({
				dateReturn: yup.string(),
				monthReturn: yup.number().nullable(),
				cumulativeROR: yup.number().nullable(),
				vami: yup.number().nullable(),
				assets: yup.number().nullable(),
				dateCreated: yup.string(),
			})
		),

		AcceptedAgreement: yup.boolean().required().oneOf([true], 'You must certify that all information is accurate'),

	});


	// onSubmit handler
	const onSubmit = (values, formikBag) => {

		// uploadMonthlyReturns
		if( values.csv ) {

			const body = new FormData();

			body.append( 'csv', values.csv );

			uploadMonthlyReturns(values.programId, body, session.access_token).then((response) => {

				window.scrollTo(0, 0);

				setCsvFileName('…');
				setShowToast(true);
				setToastMessage(text.performanceDataSaved);

				let year = (new Date(response[0].dateReturn)).getFullYear();

				formikBag.setFieldValue('dateInception', response[0].dateReturn);

				setCurrentYear(year);

				let today = new Date(), yearToday = today.getFullYear();
				let dateLastReturn = new Date( response[response.length-1].dateReturn ), yearLastReturn = dateLastReturn.getFullYear();

				if( yearToday > yearLastReturn ) {

					let addReturns = [];

					for( let y = yearLastReturn; y < yearToday; y++ ) {

						addReturns = [
							...addReturns,
							...returnsArray(y+1)
						];

					}

					formikBag.setFieldValue('investmentReturns', [
						...prepInvestmentReturns(response),
						...addReturns,
					]);

				}
				else {
					formikBag.setFieldValue('investmentReturns', prepInvestmentReturns(response));
				}

				formikBag.setFieldValue('csv', false);
				formikBag.setFieldValue('AcceptedAgreement', false);

			});

		}
		// updateMonthlyReturns
		else {

			// format `dateInception` for comparison
			const dateInception = parseInt( dateFormat(parseDate(values.dateInception), 'yyyyMM') );

			// filter out any values before `dateInception`
			const investmentReturns = values.investmentReturns.filter((investmentReturn) => {
				return parseInt(dateFormat(parseDate(investmentReturn.dateReturn), 'yyyyMM')) >= dateInception;
			});

			updateMonthlyReturns(values.programId, investmentReturns, session.access_token).then((response) => {
				window.scrollTo(0, 0);
				setShowToast(true);
				setToastMessage(text.performanceDataSaved);
				// reset 'AcceptedAgreement'
				setTimeout(() => {
					formikBag.setFieldValue('AcceptedAgreement', false);
				}, 3000);
			});
		}

	}


	// returnSchema
	const returnSchema = {
		assets: null,
		cumulativeROR: null,
		dateCreated: '',
		dateReturn: '',
		monthReturn: null,
		vami: null,
	};

	// returnsArray
	const returnsArray = (year) => {
		let dateCreated = dateFormat((new Date()), 'yyyy-MM-dd') + 'T00:00:00';
		let returns = [];
		for( let i = 1; i <= 12; i++ ) {
			let month = (i<10?'0':'') + (i);
			returns.push({
				...returnSchema,
				dateCreated: dateCreated,
				dateReturn: `${year}-${month}-01T00:00:00`,
			});
		}
		return returns;
	};


	// setupReturnsData
	const setupReturnsData = (year, values) => {

		let hasReturns = values.investmentReturns.length;
		let dateFirstReturn = hasReturns ? new Date( values.investmentReturns[0].dateReturn ) : addMonths((new Date()), 12), yearFirstReturn = dateFirstReturn.getFullYear();

		if( year < yearFirstReturn ) {
			let firstReturns = [];
			for( let y = year; y < yearFirstReturn; y++ ) {
				firstReturns = [
					...firstReturns,
					...returnsArray(y)
				];
			}
			return [
				...firstReturns,
				...values.investmentReturns
			];
		}

		if( year > yearFirstReturn ) {
			let filteredReturns = values.investmentReturns.filter((test) => {
				let dateReturn = new Date( test.dateReturn ), yearReturn = dateReturn.getFullYear()
				return yearReturn >= year;
			});
			return filteredReturns;
		}

		return values.investmentReturns;

	};


	// default to incomplete performance year…
	useEffect(() => {

		let hasReturns = initialValues.investmentReturns.length;

		if( hasReturns ) {

			const [lastReturn] = initialValues.investmentReturns.slice(-1);

			let dateLastReturn = new Date( lastReturn.dateReturn ), yearLastReturn = dateLastReturn.getFullYear();

			const lastReturns = initialValues.investmentReturns.filter((r) => {
				let date = new Date( r.dateReturn ), year = date.getFullYear();
				return year === yearLastReturn && r.assets !== null && r.monthReturn !== null;
			});

			if( lastReturns.length < 12) {
				setCurrentYear( yearLastReturn );
			}

		}

	}, [
		initialValues,
		setCurrentYear,
	]);


	// massage investmentReturns :\
	useEffect(() => {

		let hasReturns = initialValues.investmentReturns.length;

		if( hasReturns ) {

			const [lastReturn] = initialValues.investmentReturns.slice(-1);

			let today = new Date(), yearToday = today.getFullYear();

			let dateLastReturn = new Date( lastReturn.dateReturn ), yearLastReturn = dateLastReturn.getFullYear();

			if( yearToday > yearLastReturn ) {
				let addReturns = [];
				for( let y = yearLastReturn; y < yearToday; y++ ) {
					addReturns = [
						...addReturns,
						...returnsArray(y+1)
					];
				}
				initialValues.investmentReturns = [
					...initialValues.investmentReturns,
					...addReturns,
				];
			}
		}

	});


	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-5'
				noValidate
				onSubmit={handleSubmit}>

				<div
					className='position-relative'
					aria-live='polite'
					aria-atomic='true'>

					<div
						className='position-absolute d-flex justify-content-center'
						style={{top:'-3.25rem',left:0,right:0}}>

						<Toast
							show={showToast}
							onClose={() => {setShowToast(false);}}
							delay={3000}
							autohide
							className='border-success position-absolute mt-n2'>

							<Toast.Body
								className='text-success text-center py-1'>
								<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
								{toastMessage}
							</Toast.Body>

						</Toast>

					</div>

				</div>

				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3'
					aria-controls='program-information'
					aria-expanded={generalDataOpen}
					onClick={() => setGeneralDataOpen(!generalDataOpen)}
				>
					<span className='h5'>{`General Data`}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (generalDataOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={generalDataOpen}>

					<div className='p-3' id='general-data'>
					{values.investmentReturns && values.investmentReturns.length === 0 &&
						<div className='alert alert-warning'>
							{`Upload a CSV or select the inception date for this program to get started…`}
						</div>
					}
						<div className='row'>
							<div className='col-12 col-md-auto'>
								<Form.Group>
									<Form.Label htmlFor='dateInception'>{`Inception Date`}*</Form.Label>
									<div>
										<DatePicker
											id='dateInception'
											autoComplete={'off'}
											selected={values.dateInception ? new Date(values.dateInception) : ''}
											minDate={new Date('1/1/1957')}
											dateFormat='M/d/yyyy'
											onChange={(date) => {
												let year = (new Date(date)).getFullYear();
												setFieldValue('dateInception', dateFormat(date, 'yyyy-MM-dd') + 'T00:00:00');
												setFieldValue('investmentReturns', setupReturnsData(year, values));
												setCurrentYear(year);
											}}
											showMonthDropdown
											showYearDropdown
											maxDate={new Date()}
											dropdownMode='select'
											className='form-control'
											isValid={touched.dateInception && !errors.dateInception}
											isInvalid={touched.dateInception && errors.dateInception}
										/>
										<Form.Control.Feedback type='invalid'>
											{errors.dateInception}
										</Form.Control.Feedback>
									</div>
								</Form.Group>
							</div>
							<div className='col-12 col-md-9'>
								<Form.Group>
									<Form.Label htmlFor='csv'>
										{`Upload Data`}
										<OverlayTrigger
											placement={'right'}
											overlay={
												<Popover>
													<Popover.Content>
														{`Upload a comma-delimited file (CSV) that contains your program’s performance data. The file must contain a “Returns” column (%) and the corresponding date in either two columns — “Month” and “Year” — or a single “Date” column. You may also include a column labeled “Assets”. To distinguish a return as estimated you may either include an “E” after to the return value or mark it in an “Estimated” column.`}
													</Popover.Content>
												</Popover>
											}>
											<i className='fa-solid fa-question-circle text-muted ml-1' style={{fontSize:'80%'}}></i>
										</OverlayTrigger>
									</Form.Label>
									<div className='form-row'>
										<div className='col-auto'>
											<Form.File 
												type='file'
												name='csv'
												id='csv'
												label={csvFileName}
												onChange={(e) => {
													setCsvFileName(e.currentTarget.files[0].name);
													setFieldValue('dateInception', values.dateInception ? values.dateInception : ( dateFormat( (new Date()), 'yyyy-MM-dd') + 'T00:00:00' ) );
													setFieldValue('csv', e.currentTarget.files[0]);
												}}
												custom
											/>
											<CSVLink
												className='btn btn-link text-secondary btn-sm ml-auto pl-0 pt-2'
												filename={`performance-template.csv`}
												data={[
													['Date','Assets','Returns'],
													['2/1/2021',1854921,'1.22%'],
													['3/1/2021',1854963,'0.24%'],
													['4/1/2021',1836125,'1.07%'],
													['5/1/2021',1888331,'2.84%'],
													['6/1/2021',1918381,'3.96%'],
													['7/1/2021',1970971,'3.52%'],
												]}>
												<i className='fa-solid fa-file-csv mr-1'></i>
												{`View Sample CSV`}
											</CSVLink>
										</div>
										<div className='col-auto'>
											<button
												type='submit'
												disabled={!values.AcceptedAgreement}
												className='btn btn-primary'
											>{`Upload`}</button>
											<button
												hidden={!values.csv}
												type='button'
												className='btn btn-link text-danger'
												onClick={(e) => {
													e.preventDefault();
													setCsvFileName('…');
													setFieldValue('csv', false);
												}}
											>{`Cancel`}</button>
										</div>
									</div>
									<Form.Group className='mt-3' hidden={!values.csv}>
										<Form.Check 
											custom
											type='checkbox'
											name='AcceptedAgreement'
											id='AcceptedAgreement-CSV'
											value={false}
											checked={values.AcceptedAgreement}
											onChange={(e) => setFieldValue('AcceptedAgreement', !values.AcceptedAgreement)}
											label={<>
												{`I certify that all information I have furnished to ${text.siteTitle} is accurate, to the best of my knowledge.`}
											</>}
										/>
										<small className='pl-4 text-danger' hidden={!errors.AcceptedAgreement}>{`You must certify that all information is accurate`}</small>
									</Form.Group>
								</Form.Group>
							</div>
						</div>

					</div>

				</Collapse>
			{values.investmentReturns && values.investmentReturns.length > 0 && <>
				<button
					type='button'
					className='btn btn-light btn-block text-left text-dark rounded-0 px-3 mt-3'
					aria-controls='program-investment-information'
					aria-expanded={performanceDataOpen}
					onClick={() => setPerformanceDataOpen(!performanceDataOpen)}
				>
					<span className='h5'>{`Performance Data`}</span>
					<i className={'fas float-right mt-1 fa-chevron-' + (performanceDataOpen ? 'up' : 'down')}></i>
				</button>

				<Collapse in={performanceDataOpen}>

					<div className='p-3' id='performance-data'>

						<Form.Group>
							<Form.Label htmlFor='currentYear'>{`Select Year`}</Form.Label>
							<Form.Control
								as='select'
								custom
								name='currentYear'
								id='currentYear'
								value={currentYear}
								onChange={(e) => {
									let year = e.currentTarget.value;
									setCurrentYear(year);
								}}
							>
							{YEARS.filter((year) => {return year >= (new Date(values.dateInception)).getFullYear()}).map((year) =>
								<option key={`currentYear-${year}`} value={year}>{year}</option>
							)}
							</Form.Control>
						</Form.Group>

						<FieldArray
							name='investmentReturns'
							render={arrayHelpers => {

								const investmentReturns = values.investmentReturns;

								return (
									<div>
									{investmentReturns && investmentReturns.length > 0 ? (
										<div className='table-responsive'>
											<table className='table table-striped mb-0'>
												<thead>
													<tr>
														<th scope='col'>
															{`Month`}
														</th>
														<th scope='col'>
															{`% Change`}
														</th>
														<th scope='col'>
															{`AUM`}
														</th>
													</tr>
												</thead>
												<tbody>
												{investmentReturns.map((type, index) => {
													const isFuture = parseInt(dateFormat(parseDate(values.investmentReturns[index].dateReturn), 'yyyyMM')) > parseInt(dateFormat(subMonths((new Date()), 1), 'yyyyMM'));
													const isPast = parseInt(dateFormat(parseDate(values.investmentReturns[index].dateReturn), 'yyyyMM')) < parseInt(dateFormat(new Date(values.dateInception), 'yyyyMM'));
													return (
													<tr key={index} hidden={values.investmentReturns[index].dateReturn.substr(0, 4) !== `${currentYear}`}>
														<td>
															<Form.Label
																className='mb-0'
																htmlFor={`investmentReturns-${index}-monthReturn`}>
																{dateFormat(parseDate(values.investmentReturns[index].dateReturn), 'MMMM')}
															</Form.Label>
														</td>
														<td className='text-right' style={{width:'12rem'}}>
															<InputGroup>
																<Form.Control
																	className='text-right'
																	type='number'
																	step={.01}
																	disabled={isPast||isFuture}
																	name={`investmentReturns[${index}][monthReturn]`}
																	id={`investmentReturns-${index}-monthReturn`}
																	value={(isPast||isFuture)?'':(values.investmentReturns[index].monthReturn===null?'':values.investmentReturns[index].monthReturn)}
																	onChange={handleChange}
																	aria-label={`% Change`}
																	aria-describedby={`investmentReturns-${index}-addon`}
																	/>
																<InputGroup.Text id={`investmentReturns-${index}-addon`}>{`%`}</InputGroup.Text>
															</InputGroup>
														</td>
														<td className='text-right' style={{width:'12rem'}}>
															<Form.Control
																className='text-right'
																type='number'
																min={0}
																step={1}
																disabled={isPast||isFuture}
																name={`investmentReturns[${index}][assets]`}
																id={`investmentReturns-${index}-assets`}
																value={(isPast||isFuture)?'':(values.investmentReturns[index].assets===null?'':values.investmentReturns[index].assets)}
																onChange={handleChange}
																/>
														</td>
													</tr>
												)})}
												</tbody>
											</table>
										</div>
									) : null}
									</div>
								);
							}}
						/>

					</div>

				</Collapse>
			</>}
				<Form.Group className='mt-3 py-3 px-3'>
					<Form.Check 
						custom
						type='checkbox'
						name='AcceptedAgreement'
						id='AcceptedAgreement-Performance'
						value={false}
						checked={values.AcceptedAgreement}
						onChange={(e) => setFieldValue('AcceptedAgreement', !values.AcceptedAgreement)}
						label={<>
							{`I certify that all information I have furnished to ${text.siteTitle} is accurate, to the best of my knowledge.`}
						</>}
					/>
					<small className='pl-4 text-danger' hidden={!errors.AcceptedAgreement}>{`You must certify that all information is accurate`}</small>
				</Form.Group>

				<Form.Group>
					<button
						type='submit'
						disabled={!values.AcceptedAgreement}
						className='btn btn-primary btn-block mt-3'>
						<i className='fa fa-chevron-right pr-2'></i>
						<span>{text.saveChanges}</span>
					</button>
				</Form.Group>

				<p className='text-muted'>{text.requiredFields}</p>

			</Form>
		)}
		</Formik>
	)

};

export default PerformanceForm;
