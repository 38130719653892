// Constants
import {
	API_URL,
} from '../helpers/Constants';

// addBlend
export const addBlend = async (body, access_token) => {

	const response = await fetch(`${API_URL}/blends`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// saveBlend
export const saveBlend = async (blendId, blendName, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/save?blendName=${encodeURIComponent(blendName)}`, {
		method: 'PUT',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// renameBlend
export const renameBlend = async (blendId, newName, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/rename?newName=${encodeURIComponent(newName)}`, {
		method: 'PUT',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// copyBlend
export const copyBlend = async (body, access_token) => {

	const response = await fetch(`${API_URL}/blends/${body.blendId}/copy`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteBlend
export const deleteBlend = async (blendId, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// addToBlend
export const addToBlend = async (blendId, body, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/orders/multiple`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response;

};

// deleteFromBlend
export const deleteFromBlend = async (blendId, portfolioOrderId, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/orders/${portfolioOrderId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// patchOrder
export const patchOrder = async (blendId, blendOrderId, data, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/orders/${blendOrderId}`, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// setBlendNormalized
export const setBlendNormalized = async (blendId, isNormalized, access_token) => {

	const response = await fetch(`${API_URL}/blends/${blendId}/normalization?isNormalized=${isNormalized}`, {
		method: 'PUT',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
