import React, {
	useEffect,
} from 'react';


// Constants
import {
	GROUP_PENDING_STATUS_ID,
} from '../helpers/Constants';


// forms
import {
	GroupForm,
} from './forms/Forms';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';


// AddCTA
function AddCTA({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// set page title
	useEffect(() => {
		document.title = [`Add CTA`, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/tools/add-cta`;
		}
		else if( session.Groups || session.Roles.split(',').indexOf('CTA Manager') === -1 ) {
			window.location = `/tools/dashboard`;
		}
	}, [
		lang,
		session
	]);

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col-12 col-md-10 col-lg-8 col-xl-7'>

					<h1 className='mb-4'>{`Add CTA`}</h1>

					<GroupForm
						lang={lang}
						session={session}
						initialValues={{
							groupName: '',
							webUrl: '',
							descriptionLong: '',
							groupStatusId: GROUP_PENDING_STATUS_ID,
							groupsTypes: [],
							groupsMemberships: [
								{
									membershipTypeId: '35a5073e-b8df-4204-8c2e-1f88a64d8852',
									membershipUrl: null,
									title: 'NFA',
									info: '',
								},
							],
							platforms: [
								'9279b5f5-88cc-4dc8-ab01-4b75d69dc0ca',
								'946886be-19fb-4bd1-a245-f69efb3227f1',
							],
							contacts: [],
							AcceptedAgreement: false,
						}}
						callback={(data) => {
							window.location = `/groups/${data.groupUri}`;
						}}
						/>

				</div>

			</div>

		</div>
	)

}

export default AddCTA;
