import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useQueries,
} from 'react-query';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	navigate,
} from 'hookrouter';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Modal,
	Toast,
} from 'react-bootstrap';


// Constants
import {
	SAVED_BLENDS_COLUMNS,
} from '../helpers/Constants';


// API
import {
	getRequest,
	addBlend,
	copyBlend,
} from '../api/Api';


// partials
import {
	Loader,
	BlendsTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsBlender } from '../i18n/Blender';


// SavedBlends
function SavedBlends({
	lang,
	session,
}) {

	const text = {...labelsGlobal[lang], ...labelsBlender[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage] = useState('');

	// data
	const [blendsQueryKey, setBlendsQueryKey] = useState( (new Date()).getTime() );
	const [columns] = useState(SAVED_BLENDS_COLUMNS);
	const [rows, setRows] = useState([]);
	const [orderBy,setOrderBy] = useState('DESC');
	const [sortBy,setSortBy] = useState('dateCreated');
	const [currentBlend, setCurrentBlend] = useState(false);
	const [blendModified] = useState(
		localStorage.getItem('blendModified') === 'true'
	);
	
	// blendToLoad
	const [blendtoLoad, setBlendToLoad] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	// functions
	const _cancelSetCurrentBlend = () => {
		setBlendToLoad(false);
		setShowConfirm(false);
	};

	const _setCurrentBlend = (blend) => {

		// trying to load a blend when the loaded blend isn't saved…
		if( blendModified ) {
			setBlendToLoad(blend);
			setShowConfirm(true);
		}
		// blend already loaded, go to blender…
		else if( blend.blendName === loadedBlend.blendName ) {
			navigate(`/tools/blender`);
		}
		// create new blend…
		else if( !blend.blendId ) {
			addBlend({}, session.access_token).then((response) => {
				setCurrentBlend(response);
			});
		}
		// load the selected blend…
		else {
			setCurrentBlend(blend);
		}

	};


	const _copyBlend = (blend) => {

		const b = document.querySelectorAll(`button[data-blend="${blend.blendId}"]`)[0];
		const c = b.querySelectorAll('.fa-copy')[0];
		const s = b.querySelectorAll('.fa-spin')[0];

		b.disabled = true;
		c.hidden = !c.hidden;
		s.hidden = !s.hidden;

		copyBlend(blend, session.access_token).then((response) => {

			b.disabled = false;
			c.hidden = !c.hidden;
			s.hidden = !s.hidden;

			setBlendsQueryKey( (new Date()).getTime() );

		});

	};

	// run our queries
	const [
		{
			isError,
		},
		{
			data: loadedBlend,
		},
	] = useQueries([
		{
			queryKey: ['blends', {
				pageSize: 1000,
			}, blendsQueryKey],
			queryFn: () => getRequest('/blends', {
				pageSize: 1000,
			}, session.access_token),
			onSuccess: (response) => {
				setRows(response.items.filter(b => [2,3].indexOf(b.blendStatusId) > -1).map(b => b.blendStatusId === 1 ? {...b, blendName: b.blendName + (blendModified ? ' (Unsaved)' : '')} : {...b}));
			},
			enabled: !!session.access_token
		},
		{
			queryKey: ['currentBlend', session.access_token],
			queryFn: () => getRequest('/blends/getcurrent/minimal', {}, session.access_token),
			enabled: !!session.access_token
		},
		{
			queryKey: ['blend', currentBlend, session.access_token],
			queryFn: () => getRequest(`/blends/${currentBlend.blendId}/Load`, {}, session.access_token),
			onSuccess: (response) => {
				localStorage.setItem( 'blendModified', false );
				navigate(`/tools/blender`);
			},
			enabled: !!currentBlend
		},
	]);


	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});


	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/tools/saved-blends`;
		}
	}, [
		lang,
		session
	]);


	if ( !session.access_token || !rows ) {
		return (
			<Loader/>
		)
	}

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	return (
		<div className={'container'}>

			<Modal show={showConfirm} onHide={_cancelSetCurrentBlend} centered>

				<Modal.Body className='p-5'>

					<button
						type='button'
						className='close mt-n4 mr-n4'
						style={{fontSize:'1rem'}}
						onClick={_cancelSetCurrentBlend}>
						<i className='fa-solid fa-times'></i>
					</button>
	
					<div className='alert alert-warning'>
						<h6 className='d-flex align-items-center pt-2'>
							<i className='fa-solid fa-exclamation-triangle text-warning mr-1' style={{fontSize:'80%'}}></i>
							<b>{text.unsavedChanges}</b>
						</h6>
					{!blendtoLoad.blendId &&
						<p className='m-0'>
							{text.creatingWillDelete}
						</p>
					}
					{!!blendtoLoad.blendId &&
						<p className='m-0'>
							{text.loadingWillDelete}
						</p>
					}
					</div>

					<a
						href={`/tools/blender`}
						className='btn btn-block btn-outline-primary'
						onClick={_cancelSetCurrentBlend}>
						{text.viewCurrentWorksheet}
						<i className='fa fa-chevron-right ml-2' style={{fontSize:'80%'}}></i>
					</a>

					<button
						type='button'
						className='btn btn-block btn-danger mt-3'
						onClick={() => {
							if( !blendtoLoad.blendId ) {
								addBlend({}, session.access_token).then((response) => {
									setCurrentBlend(response);
								});
							}
							else {
								setCurrentBlend(blendtoLoad);
							}
						}}>
					{!blendtoLoad.blendId && <>
						{text.deleteAndCreate}
					</>}
					{!!blendtoLoad.blendId && <>
						{text.deleteAndLoad}
					</>}
					</button>

				</Modal.Body>

			</Modal>

			<div
				className='position-relative'
				aria-live='polite'
				aria-atomic='true'>

				<div
					className='position-absolute d-flex justify-content-center'
					style={{left:0,right:0}}>

					<Toast
						show={showToast}
						onClose={() => {setShowToast(false);}}
						delay={3000}
						autohide
						className='border-success position-absolute mt-3'>

						<Toast.Body
							className='text-success text-center py-1'>
							<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
							{toastMessage}
						</Toast.Body>

					</Toast>

				</div>

			</div>

			<div className='row'>

				<div className='col-12'>

					<div className='row align-items-center mb-4'>
						<div className='col-12 col-md-6'>
							<h1 className='mb-md-0'>
								{text.pageTitle}
							</h1>
						</div>
						<div className='col-12 col-md-auto ml-md-auto'>
							<button type='button' className='btn btn-sm btn-success' onClick={(e) => {
								e.preventDefault();
								_setCurrentBlend({blendId:false});
							}}>
								<i className='fa fa-chevron-right mr-1' style={{fontSize:'90%'}}></i>
								{text.createNewBlend}
							</button>
						</div>
					</div>
				{!!rows.length && <>
					<BlendsTable
						lang={lang}
						session={session}
						columns={columns}
						rows={rows}
						sortBy={sortBy}
						orderBy={orderBy}
						toggleSortBy={(field) => {
							setRows(('DESC' === orderBy) ? rows.sort((a,b) => {
								return a[field] < b[field] ? -1 : ( a[field] > b[field] ? 1 : (0) )
							}) : rows.sort((a,b) => {
								return b[field] < a[field] ? -1 : ( b[field] > a[field] ? 1 : (0) )
							}));
							setSortBy(field);
							setOrderBy('DESC' === orderBy ? 'ASC' : 'DESC');
						}}
						copyBlend={_copyBlend}
						setCurrentBlend={_setCurrentBlend}
						setRows={setRows}
						/>

					<p className='text-muted'>
						<small>{text.annualizedDisclaimer}</small>
					</p>

					{text.blenderDisclaimer}
				</>}
				</div>

			</div>

		</div>
	)

};

export default SavedBlends;
