// Constants
import {
	PROGRAM_ARCHIVE_STATUS_ID,
	PROGRAM_PENDING_STATUS_ID,
	PROGRAM_DELETED_STATUS_ID,
	PROGRAM_CLOSED_STATUS_ID,
	PROGRAM_STATUS_TYPES,
} from './Constants';

export const isArchived = (programStatusId, label='Archived') => {

	if( programStatusId && PROGRAM_ARCHIVE_STATUS_ID === programStatusId )

		return (
			<span className='badge badge-warning ml-1' style={{verticalAlign:'.05rem'}}>{label}</span>
		);

	return false;

};

export const isDeleted = (programStatusId, label='Deleted') => {

	if( programStatusId && PROGRAM_DELETED_STATUS_ID === programStatusId )

		return (
			<span className='badge badge-danger ml-1' style={{verticalAlign:'.05rem'}}>{label}</span>
		);

	return false;

};

export const isPending = (programStatusId) => {

	return programStatusId && PROGRAM_PENDING_STATUS_ID === programStatusId;

};

export const isClosed = (membershipStatusId, label='Closed') => {

	if( membershipStatusId && PROGRAM_CLOSED_STATUS_ID === membershipStatusId )

		return (
			<span className='badge badge-danger ml-1' style={{verticalAlign:'.05rem'}}>{label}</span>
		);

	return false;

};


export const isMaxDD = (isMaxDD) => {

	if( isMaxDD )

		return (
			<span className='badge text-danger ml-1' style={{verticalAlign:'.05rem'}}>{`DD`}</span>
		);

	return false;

};

export const isNew = (isNew) => {

	if( isNew )

		return (
			<i className='fa-solid fa-circle ml-1 text-primary' style={{fontSize:'80%'}}></i>
		);

	return false;

};

export const status = (programStatusId) => {

	let found = PROGRAM_STATUS_TYPES.find((type) => {return type.programStatusId === programStatusId.toLowerCase()});

	return found ? found.programStatus : '';

};
