// Constants
import {
	API_URL,
} from '../helpers/Constants';

// addProgram
export const addProgram = async (body, access_token) => {

	const response = await fetch(`${API_URL}/programs`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// patchProgram
export const patchProgram = async (programId, data, access_token) => {

	const response = await fetch(`${API_URL}/programs/${programId}`, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// updateProgram
export const updateProgram = async (body, access_token) => {

	const response = await fetch(`${API_URL}/programs`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// updateMonthlyReturns
export const updateMonthlyReturns = async (programId, body, access_token) => {

	const response = await fetch(`${API_URL}/programsMonthlyReturns/${programId}`, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// uploadMonthlyReturns
export const uploadMonthlyReturns = async (programId, body, access_token) => {

	const response = await fetch(`${API_URL}/programsMonthlyReturns/${programId}/upload`, {
		method: 'POST',
		body: body,
		headers: {
			'Authorization': 'Bearer ' + access_token,
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteProgram
export const deleteProgram = async (programId, access_token) => {

	const response = await fetch(`${API_URL}/programs/${programId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// postSavedSearches
export const postSavedSearches = async (data, access_token) => {

	const response = await fetch(`${API_URL}/programsSavedSearches`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteSavedSearches
export const deleteSavedSearches = async (programSavedSearchId, access_token) => {

	const response = await fetch(`${API_URL}/programsSavedSearches/${programSavedSearchId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// addProgramToPlatform
export const addProgramToPlatform = async (platformId, programId, access_token) => {

	const response = await fetch(`${API_URL}/programsPlatform`, {
		method: 'PUT',
		body: JSON.stringify({
			platformId: platformId,
			programId: programId,
			isManageable: true,
		}),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

// deleteProgramFromPlatform
export const deleteProgramFromPlatform = async (platformId, programId, access_token) => {

	const response = await fetch(`${API_URL}/programsPlatform/${platformId}/${programId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json'
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
