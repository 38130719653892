import React, {
	useState,
} from 'react';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// API
import {
	transactionalEmailRequest,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';


// RequestForm
function RequestForm({
	lang,
	session,
	group,
	program=false,
	confirmEmail,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang]};

	// error handling
	const [submitError] = useState(false);
	const [received, setReceived] = useState(false);

	// form schema
	const schema = yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		email: yup.string().email().required(text.invalidEmail),
		phone: yup.string().matches( (new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)), text.invalidPhone ).nullable().required(text.invalidPhone),
	});

	// submit handler
	const handleRequestSubmit = (values, formikBag) => {

		let params = {
			UserId: session.UserId || '',
			Note: `A request has been submitted for additional information regarding ${group.groupName}` + (program ? ` - ${program.programName}` : ''),
			groupName: group.groupName,
			groupUri: group.groupUri,
		};

		params = program ? {
			...params,
			programName: program.programName,
			programUri: program.programUri,
		} : params;

		// transactionalEmailRequest
		transactionalEmailRequest({
			...values,
			...params,
			template: confirmEmail,
			callback: 'iasg_zoho',
		}).then((response) => {

			setReceived(true);

			setTimeout(() => {
				formikBag.resetForm();
				setReceived(false);
			}, 30000);

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={handleRequestSubmit}
			initialValues={{
				firstName: session?.Profile?.firstName,
				lastName: session?.Profile?.lastName,
				email: session?.email,
				phone: session?.Profile?.phone,
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				noValidate
				onSubmit={handleSubmit}>

				<div className='card bg-light'>

					<div className='card-body'>

					{received && <>
						<h5 className='text-success'>{text.thankYou}</h5>

						{text.requestReceived}
					</>}
					{!received && <>
						<h5 className='text-success'>{text.getResearchReportHeading}</h5>

						<p>{text.getResearchReportContent.replace('{groupName}', group.groupName)}</p>

						<div className='alert alert-danger' hidden={!submitError}>
							{submitError}
						</div>

						<Form.Group>
							<Form.Control
								size='sm'
								type='text'
								name='firstName'
								id='firstName'
								placeholder={text.firstName}
								value={values.firstName}
								onChange={handleChange}
								isValid={touched.firstName && !errors.firstName}
								isInvalid={touched.firstName && errors.firstName}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Control
								size='sm'
								type='text'
								name='lastName'
								id='lastName'
								placeholder={text.lastName}
								value={values.lastName}
								onChange={handleChange}
								isValid={touched.lastName && !errors.lastName}
								isInvalid={touched.lastName && errors.lastName}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Control
								size='sm'
								type='email'
								name='email'
								id='email'
								placeholder={text.Email}
								value={values.email}
								onChange={handleChange}
								isValid={touched.email && !errors.email}
								isInvalid={touched.email && errors.email}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Control
								size='sm'
								type='tel'
								name='phone'
								id='phone'
								placeholder={text.phone}
								value={values.phone}
								onChange={handleChange}
								isValid={touched.phone && !errors.phone}
								isInvalid={touched.phone && errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								{errors.phone}
							</Form.Control.Feedback>
						</Form.Group>

						<button type='submit' className='btn btn-success btn-sm btn-block' disabled={isSubmitting&&!submitError}>
							<i className='fa fa-chevron-right pr-2'></i>
							<span>{text.getResearchReport}</span>
						</button>
					</>}
					</div>

				</div>

			</Form>
		)}
		</Formik>
	)
}

export default RequestForm;
