import React, {
	useEffect,
	useState,
	useRef,
} from 'react';


/*
 * React Query
 *
 * @link https://react-query.tanstack.com/reference/useQueries
 *
 */
import {
	useMutation,
	useQueries,
	useQueryClient,
} from 'react-query';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Toast,
} from 'react-bootstrap';


/*
 * React Bootstrap Typeahead
 *
 * @link https://github.com/ericgio/react-bootstrap-typeahead
 * @link https://ericgio.github.io/react-bootstrap-typeahead
 *
 */
import {
	AsyncTypeahead,
} from 'react-bootstrap-typeahead';


// Constants
import {
	PORTFOLIO_COLUMNS,
	PROGRAM_ACTIVE_STATUS_ID,
	PROGRAM_ARCHIVE_STATUS_ID,
} from '../helpers/Constants';


// API
import {
	getRequest,
	addToPortfolio,
	patchPortfolio,
} from '../api/Api';


// partials
import {
	Loader,
	PortfolioTable,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsPortfolio } from '../i18n/Portfolio';


// Portfolio
function Portfolio({
	lang,
	session,
}) {

	const text = {...labelsGlobal[lang], ...labelsPortfolio[lang]};

	// error handling
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	// search
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const filterBy = () => true;

	// data
	const [columns] = useState(PORTFOLIO_COLUMNS);
	const [rows,setRows] = useState([]);

	// run our queries
	const queryClient = useQueryClient();

	const [
		{
			isLoading,
			isError,
		},
	] = useQueries([
		{
			queryKey: ['portfolios', {
				pageSize: 100,
			}, session.access_token],
			queryFn: () => getRequest('/portfolios', {
				pageSize: 100,
			}, session.access_token),
			onSuccess: (response) => {
				// set page title
				document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
				// setRows
				setRows(response.items);
			},
			enabled: !!session.access_token
		},
	]);

	// functions
	const addProgram = useMutation(programId => addToPortfolio([{programId:programId}], session.access_token), {
		onSuccess: () => {
			queryClient.invalidateQueries('portfolios');
			setShowToast(true);
			setToastMessage(text.addedToPortfolio);
		},
	});

	const updatePortfolio = useMutation(params => patchPortfolio(params.portfolioOrderId, params.patch, session.access_token), {
		onSuccess: () => {
			queryClient.invalidateQueries('portfolios');
		},
	});

	// handleProgramSearch
	const programSearch = useRef(null);
	const handleProgramSearch = (query) => {
		// get roles
		const roles = session.Roles ? session.Roles.split(',') : [];
		// set filterByProgramStatusIds based on session
		const filterByProgramStatusIds = ( session.Admin || roles.indexOf('CTA Manager') > -1 ) ? [
			PROGRAM_ACTIVE_STATUS_ID,
			PROGRAM_ARCHIVE_STATUS_ID,
		] : [
			PROGRAM_ACTIVE_STATUS_ID,
		];
		// isSearching=true
		setIsSearching(true);
		// run our search query
		getRequest(`/programs/search/${query}`, {
			filterByProgramStatusIds: filterByProgramStatusIds.join('&filterByProgramStatusIds=')
		}, session.access_token).then((response) => {
			setSearchResults(response);
			setIsSearching(false);
		});
	};


	// access + redirects
	useEffect(() => {
		if( !session.access_token && !session.refresh_token ) {
			window.location = `/login?redirect=/tools/portfolio`;
		}
	}, [
		lang,
		session,
	]);


	if ( isLoading || !session.access_token || !rows ) {
		return (
			<Loader/>
		)
	}

	if (isError) {
		return (
			<div className={'container'}>
				<div>{text.loading}</div>
			</div>
		)
	}

	return (
		<div className={'container'}>

			<div
				className='position-relative'
				aria-live='polite'
				aria-atomic='true'>

				<div
					className='position-absolute d-flex justify-content-center'
					style={{left:0,right:0}}>

					<Toast
						show={showToast}
						onClose={() => {setShowToast(false);}}
						delay={3000}
						autohide
						className='border-success position-absolute mt-3'>

						<Toast.Body
							className='text-success text-center py-1'>
							<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
							{toastMessage}
						</Toast.Body>

					</Toast>

				</div>

			</div>

			<div className='row'>

				<div className='col-12'>

					<h1 className='mb-4'>
						{text.pageTitle}
					</h1>

					<div className='row mb-5 align-items-center'>
						<div className='col-12 col-md-auto pr-0'>
							{text.addToYourPortfolio}
						</div>
						<div className='col-12 col-md-6 col-lg-4 col-xl-3'>
							<AsyncTypeahead
								filterBy={filterBy}
								id='programSearch'
								isLoading={isSearching}
								labelKey='programName'
								ref={programSearch}
								minLength={3}
								onChange={(selected) => {
									if( !selected.length ) return;
									let program = selected[0];
									if( rows.find(obj => { return obj.programId === program.programId; }) ) {
										setShowToast(true);
										setToastMessage(program.programName + ' is already in your Portfolio :)');
										programSearch.current.clear();
										return;
									}
									addProgram.mutate(program.programId, {
										onSuccess: () => {
											programSearch.current.clear();
										},
									});
								}}
								onSearch={handleProgramSearch}
								options={searchResults}
								placeholder={text.programSearchPlaceholder}
								renderMenuItemChildren={(option, props) => (
									<>
										<small className='groupName'>{option.groupName}</small>
										<div className='programName'>{option.programName}</div>
									</>
								)}
								/>
						</div>
					</div>

					<PortfolioTable
						lang={lang}
						session={session}
						columns={columns}
						rows={rows}
						setRows={setRows}
						updatePortfolio={updatePortfolio}
						/>

					<p className='text-muted'>
						<small>{text.performanceDisclaimer}</small>
					</p>

				</div>

			</div>

		</div>
	)

};

export default Portfolio;
