import React, {
	useEffect,
	useRef,
} from 'react';


// Modal
import {
	Modal,
} from 'react-bootstrap';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';


// ConfirmModal
function ConfirmModal({
	lang='en',
	showConfirm,
	handleCloseConfirm,
	confirmCallback,
	message=false,
}) {

	const text = {...labelsGlobal[lang]};

	const confirmButton = useRef(null);

	useEffect(() => {
		// focus confirm button on render
		if( confirmButton.current )
			confirmButton.current.focus();
	});

	return (
		<Modal show={showConfirm} onHide={handleCloseConfirm} centered>

			<Modal.Body className='p-5'>

				<button
					type='button'
					className='close mt-n4 mr-n4'
					style={{fontSize:'1rem'}}
					onClick={handleCloseConfirm}>
					<i className='fa-solid fa-times'></i>
				</button>

				<h5 className='mb-4'>{message ? message : text.confirmDelete}</h5>

				<button
					ref={confirmButton}
					type='button'
					className='btn btn-primary mr-2'
					onClick={confirmCallback}>
					{text.yes}
				</button>

				<button
					type='button'
					className='btn btn-outline-primary'
					onClick={handleCloseConfirm}>
					{text.no}
				</button>

			</Modal.Body>

		</Modal>
	)

}

export default ConfirmModal;
