import React, {
	useState,
	useEffect,
} from 'react';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
} from 'hookrouter';


/*
 * Draggable
 *
 * @link https://github.com/atlassian/react-beautiful-dnd
 *
 */
import {
	DragDropContext,
	Droppable,
	Draggable,
} from 'react-beautiful-dnd';


/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/utilities/transitions/#collapse
 * @link https://react-bootstrap.github.io/components/dropdowns/
 * @link https://react-bootstrap.github.io/components/toasts/
 *
 */
import {
	Dropdown,
	Toast,
} from 'react-bootstrap';


/*
 * React-DatePicker
 *
 * @link https://reactdatepicker.com/#example-range-month-picker
 * @link https://github.com/Hacker0x01/react-datepicker
 *
 */
import DatePicker from 'react-datepicker';


// Constants
import {
	GROUP_ACTIVE_STATUS_ID,
	PROGRAM_ACTIVE_STATUS_ID,
	YEARS,
	AUM_VALUES,
	INVESTMENT_VALUES,
	ytdReturnHeaderName,
} from '../helpers/Constants';


// API
import {
	getRequest,

	addToWatchlist,
	addToPortfolio,

	postSavedSearches,
} from '../api/Api';


// helpers
import {
	debounce,
	dateFormat,
	subMonths,
} from '../helpers/Helpers';


// partials
import {
	AddToBlenderModal,
	Loader,
	ProgramsTable,
	Pagination,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsBlender } from '../i18n/Blender';
import { labels as labelsPrograms } from '../i18n/Programs';
import { labels as labelsTables } from '../i18n/Tables';


// Programs
function Programs({
	lang,
	session={},
	context='performance',
	cols,
	sort,
	order='DESC',
	labels=[],
	filterByProgramStatusIds=PROGRAM_ACTIVE_STATUS_ID,
	filterByDateApprovedStart=null,
}) {

	const text = {...labelsGlobal[lang], ...labelsBlender[lang], ...labelsPrograms[lang], ...labelsTables[lang], ...labels[lang]};

	// error handling
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastDelay, setToastDelay] = useState(3000);
	const [toastMessage, setToastMessage] = useState('');

	// queryParams
	const [queryParams, setQueryParams] = useQueryParams();
	const [searchParams, setSearchParams] = useState({});

	// Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		filterByYearStartMin = '',
		filterByYearStartMax = '',
		filterByDatePerformanceMin = '',
		filterByDatePerformanceMax = '',
		filterByProgramTypesId = '',
		filterByCARORMin = '',
		filterByMaxRisk = '',
		filterByAUMMin = '',
		filterByAUMMax = '',
		filterByInvestmentMin = '',
		filterByInvestmentMax = '',
		filterByProgramsSectorTypesId = '',
		filterByInvestorRequirementsTypesId = '',
		filterByBlendCorrelationId = '',
		filterByAllocationStrategyTypesId = '',
		filterByMethodologyTypesId = '',
		filterByCompositionTypesId = '',
		sortBy = sort,
		orderBy = order,
		pageNumber = 1,
		pageSize = 25,
	} = queryParams;

	// data
	const [selectedPrograms, setSelectedPrograms] = useState([]);
	const [programs, setPrograms] = useState({});
	const [columns, setColumns] = useState(cols);
	const [draggableColumns, setDraggableColumns] = useState(cols.filter((col) => {return !col.alwaysVisible && !!col.visible;}));
	const [rows, setRows] = useState([]);

	// blender
	const [showAddToBlender, setShowAddToBlender] = useState(false);

	// Program Type
	const [programsTypes, setProgramsTypes] = useState([]);

	// Sector Focus
	const [programsSectorFocusTypes, setProgramsSectorFocusTypes] = useState([]);

	// Investor Requirements
	const [programsInvestorRequirementsTypes, setProgramsInvestorRequirementsTypes] = useState([]);

	// Blend Correlation
	const [currentBlend, setCurrentBlend] = useState(false);
	const [blends, setBlends] = useState([]);

	// canViewCorrelations
	const canViewCorrelations = () => {

		const roles = ( session.Roles || '' ).split(',');

		return [
			'Administrator',
			'Broker',
			'CTA Manager',
			'Member Plus',
		].filter(e => roles.indexOf(e) !== -1).length > 0;

	};

	// checkboxes
	const [programsAllocationStrategyTypes, setProgramsAllocationStrategyTypes] = useState([]);
	const [programsMethodologyTypes, setProgramsMethodologyTypes] = useState([]);
	const [programsCompositionTypes, setProgramsCompositionTypes] = useState([]);

	// sort and paging
	const [totalPages, setTotalPages] = useState(1);

	// functions
	const addSelectedToWatchlist = (options = {}) => {
		let objects = selectedPrograms.map((program) => {
			return {programId:program.programId,...options};
		});
		addToWatchlist(objects, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(options.alert === true ? text.addedAlert : text.addedToWatchlist);
			deSelectAllPrograms();
		});
	};

	const addSelectedToPortfolio = (options = {}) => {
		let objects = selectedPrograms.map((program) => {
			return {programId:program.programId,...options};
		});
		addToPortfolio(objects, session.access_token).then((response) => {
			setShowToast(true);
			setToastMessage(text.addedToPortfolio);
			deSelectAllPrograms();
		});
	};

	const selectProgram = (program) => {

		let selectedRows = rows.map(row => {
			return row === program ? { ...row, selected: !row.selected } : { ...row};
		});

		setRows(selectedRows);
		setSelectedPrograms(selectedRows.filter((row) => {return row.selected}));

	};

	const deSelectAllPrograms = () => {

		let updatedRows = rows.map(row => {
			return { ...row, selected: false };
		});

		setRows(updatedRows);
		setSelectedPrograms([]);

	};

	const selectAllPrograms = (selected) => {

		let selectedRows = rows.map(row => {
			return { ...row, selected: selected };
		});

		setRows(selectedRows);
		setSelectedPrograms(selected ? selectedRows : []);

	};

	const toggleColumn = (field) => {

		let visibleColumns = columns.map(column => {
			return column.field === field ? { ...column, visible: !column.visible } : { ...column};
		});

		setColumns(visibleColumns);

		setDraggableColumns(visibleColumns.filter((col) => {return !col.alwaysVisible && !!col.visible;}));

		// save column preferences
		localStorage.setItem( `columns-${context}`, JSON.stringify( visibleColumns.map(column => {
			return {'field':column.field,'visible':column.alwaysVisible||column.visible,'order':column.order||0};
		}) ) );

	};

	const updateColumnOrder = (startIndex, endIndex) => {

		let orderedColumns = reorder(draggableColumns, startIndex, endIndex);

		setDraggableColumns(orderedColumns);

		let updatedColumns = columns.map(column => {
			let pref = orderedColumns.find(f=>f.field===column.field);
			return { ...column, order: pref ? orderedColumns.indexOf(pref) : 0 };
		});

		setColumns(updatedColumns);

		// save column preferences
		localStorage.setItem( `columns-${context}`, JSON.stringify( updatedColumns.map(column => {
			return {'field':column.field,'visible':column.alwaysVisible||column.visible,'order':column.order||0};
		}) ) );

	};

	const toggleSortBy = (field) => {

		let params = {};

		if( field === sortBy ) {
			params.orderBy = 'DESC' === orderBy ? 'ASC' : 'DESC';
		}

		setQueryParams({sortBy:field,...params});

	};

	const toggleAllocationStrategyTypesId = (filter) => {

		let allocationStrategyTypes = programsAllocationStrategyTypes.map(item => {
			return item === filter ? { ...item, selected: !item.selected } : { ...item};
		});

		setProgramsAllocationStrategyTypes(allocationStrategyTypes);

		let filterByAllocationStrategyTypesId = allocationStrategyTypes.filter((item) => {
			return item.selected;
		}).map((item) => item.allocationStrategyTypeId).join(',');

		setQueryParams({filterByAllocationStrategyTypesId:filterByAllocationStrategyTypesId});

	};

	const toggleMethodologyTypesId = (filter) => {

		let methodologyTypes = programsMethodologyTypes.map(item => {
			return item === filter ? { ...item, selected: !item.selected } : { ...item};
		});

		setProgramsMethodologyTypes(methodologyTypes);

		let filterByMethodologyTypesId = methodologyTypes.filter((item) => {
			return item.selected;
		}).map((item) => item.methodologyTypeId).join(',');

		setQueryParams({filterByMethodologyTypesId:filterByMethodologyTypesId});

	};

	const toggleCompositionTypesId = (filter) => {

		let compositionTypes = programsCompositionTypes.map(item => {
			return item === filter ? { ...item, selected: !item.selected } : { ...item};
		});

		setProgramsCompositionTypes(compositionTypes);

		let filterByCompositionTypesId = compositionTypes.filter((item) => {
			return item.selected;
		}).map((item) => item.compositionTypeId).join(',');

		setQueryParams({filterByCompositionTypesId:filterByCompositionTypesId});

	};

	const updateMonthReturnHeaderName = (DateMin, DateMax) => {

		let headerName = dateFormat(subMonths(new Date(), 1), 'MMM');

		if( DateMin && DateMax ) {
			headerName = <>{dateFormat( new Date(DateMin), 'M/yyyy' )} – <br/>{dateFormat( new Date(DateMax), 'M/yyyy' )}</>;
		}

		let cols = columns.map(column => {
			switch(column.field) {
				case 'monthReturn':
					return { ...column, headerName: headerName };
				case 'ytdReturn':
					return DateMin && DateMax ? { ...column, headerName: 'YTD' } : { ...column, headerName: ytdReturnHeaderName };
				default:
					return { ...column};
			}
		});

		setColumns(cols);

	};

	const saveSearch = (params) => {

		postSavedSearches({
			filtersObject: JSON.stringify(params)
		}, session.access_token).then((response) => {
			setToastDelay(5000);
			setShowToast(true);
			setToastMessage(<>
				<b>{`Search Saved!`}</b>
				<a className='text-primary d-block mt-2' href={`/tools/dashboard/saved-searches`}>{`View your saved searches`}</a>
			</>);
			setSearchParams(null);
		});

	};

	const noResultsMessage = () => {
		return (<>
			<div className='alert alert-warning my-3'>
				{`No Results. Try updating or `}
				<a className='alert-link' href={`/managed-futures/performance`}>{`clearing your filters…`}</a>
			</div>
		</>);
	};

	const reorder = (list, startIndex, endIndex) => {

		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);

		result.splice(endIndex, 0, removed);

		return result;

	};

	// set page title and body class
	useEffect(() => {
		document.title = [text.seoTitle, text.siteTitle].join(text.titleSeparator);
		document.body.classList.remove('overflow-hidden');
	});

	// debounce updateMonthReturnHeaderName
	useEffect(() => {

		const debouncedUpdateMonthReturnHeaderName = debounce(() => {

			updateMonthReturnHeaderName(filterByDatePerformanceMin,filterByDatePerformanceMax);

		}, 0);

		window.addEventListener('load', debouncedUpdateMonthReturnHeaderName);

	});

	// do we need a checkbox column?
	useEffect(() => {
		if( columns.find( column => column.type === 'checkbox' && column.visible === !(!!session.access_token) ) ) {
			setColumns(columns.map(column => {
				return column.type === 'checkbox' ? { ...column, visible: !!session.access_token } : { ...column};
			}));
		}
	}, [
		columns,
		session.access_token,
	]);

	// do we need a correlation column?
	useEffect(() => {
		if( columns.find( column => column.field === 'correlation' && column.visible === !(!!filterByBlendCorrelationId) ) ) {
			setColumns(columns.map(column => {
				return column.field === 'correlation' ? { ...column, visible: !!filterByBlendCorrelationId } : { ...column};
			}));
		}
	}, [
		columns,
		filterByBlendCorrelationId,
	]);

	// update columns based on user preference
	useEffect(() => {

		const columnPrefs = JSON.parse( localStorage.getItem( `columns-${context}` ) || JSON.stringify( [] ) );

		if( columnPrefs.length ) {

			setColumns(c => c.map(column => {
				let pref = columnPrefs.find(f=>f.field===column.field);
				return pref ? { ...column, visible: pref.visible, order: pref.order||0 } : { ...column };
			}));

		}

	}, [
		setColumns,
		context,
	]);

	// update draggableColumns
	useEffect(() => {
		setDraggableColumns(columns.filter((col) => {return !col.alwaysVisible && !!col.visible;}).sort((a,b) => {if((a?.order||0)<(b?.order||0)) return -1;if((a?.order||0)>(b?.order||0)) return 1;return 0;}));
	}, [
		columns,
		setDraggableColumns,
	]);

	// get/set programs{Filter}Types
	useEffect(() => {

		getRequest('/programsTypes').then((response) => {
			setProgramsTypes(response.items);
		});

		getRequest('/programsSectorFocusTypes', {sortBy:'sectorFocus'}).then((response) => {
			setProgramsSectorFocusTypes(response.items);
		});

		getRequest('/programsInvestorRequirementsTypes').then((response) => {
			setProgramsInvestorRequirementsTypes(response.items);
		});

		if( session.access_token ) {
			getRequest('/blends/getcurrent/minimal', {}, session.access_token).then((response) => {
				setCurrentBlend(response);
			});
			getRequest('/blends/minimal', {}, session.access_token).then((response) => {
				if( response.items.length ) {
					setBlends(response.items);
				}
			});
		}
		else {
			setBlends([]);
		}

		getRequest('/programsAllocationStrategyTypes', {sortBy:'allocationStrategy'}).then((response) => {
			let allocationStrategyArray = filterByAllocationStrategyTypesId.split(',');
			let allocationStrategyTypes = response.items.map(item => {
				return { ...item, selected: allocationStrategyArray.indexOf(item.allocationStrategyTypeId) > -1 };
			});
			setProgramsAllocationStrategyTypes(allocationStrategyTypes);
		});

		getRequest('/programsMethodologyTypes', {sortBy:'methodology'}).then((response) => {
			let methodologyArray = filterByMethodologyTypesId.split(',');
			let methodologyTypes = response.items.map(item => {
				return { ...item, selected: methodologyArray.indexOf(item.methodologyTypeId) > -1 };
			});
			setProgramsMethodologyTypes(methodologyTypes);
		});

		getRequest('/programsCompositionTypes', {sortBy:'composition'}).then((response) => {
			let compositionArray = filterByCompositionTypesId.split(',');
			let compositionTypes = response.items.map(item => {
				return { ...item, selected: compositionArray.indexOf(item.compositionTypeId) > -1 };
			});
			setProgramsCompositionTypes(compositionTypes);
		});

	}, [
		session,
		filterByAllocationStrategyTypesId,
		filterByMethodologyTypesId,
		filterByCompositionTypesId,
	]);

	useEffect(() => {

		setSelectedPrograms([]);
		setIsLoading(true);

		let stateParams = {
			filterByYearStartMin: filterByYearStartMin,
			filterByYearStartMax: filterByYearStartMax,
			filterByProgramTypesId: filterByProgramTypesId,
			filterByCARORMin: filterByCARORMin,
			filterByMaxRisk: filterByMaxRisk,
			filterByAUMMin: filterByAUMMin,
			filterByAUMMax: filterByAUMMax,
			filterByInvestmentMin: filterByInvestmentMin,
			filterByInvestmentMax: filterByInvestmentMax,
			filterByProgramsSectorTypesId: filterByProgramsSectorTypesId,
			filterByInvestorRequirementsTypesId: filterByInvestorRequirementsTypesId,
			filterByBlendCorrelationId: filterByBlendCorrelationId,
		};

		// performance date filters
		if( filterByDatePerformanceMin && filterByDatePerformanceMax ) {

			stateParams = {
				...stateParams,
				filterByDatePerformanceMin: filterByDatePerformanceMin,
				filterByDatePerformanceMax: filterByDatePerformanceMax
			};

		}

		if( filterByAllocationStrategyTypesId.length ) {
			stateParams = {
				...stateParams,
				filterByAllocationStrategyTypesId: filterByAllocationStrategyTypesId.split(',').join('&filterByAllocationStrategyTypesId=')
			};
		}

		if( filterByMethodologyTypesId.length ) {
			stateParams = {
				...stateParams,
				filterByMethodologyTypesId: filterByMethodologyTypesId.split(',').join('&filterByMethodologyTypesId=')
			};
		}

		if( filterByCompositionTypesId.length ) {
			stateParams = {
				...stateParams,
				filterByCompositionTypesId: filterByCompositionTypesId.split(',').join('&filterByCompositionTypesId=')
			};
		}

		// don’t allow anyone to change these params!
		let staticParams = {
			filterByGroupStatusIds: GROUP_ACTIVE_STATUS_ID,
			filterByProgramStatusIds: filterByProgramStatusIds || '',
			filterByDateApprovedStart: filterByDateApprovedStart || '',
		};

		let params = null;

		for (var prop in stateParams) {
			if(stateParams[prop]) {
				params = params ? params : {};
				params[prop] = stateParams[prop];
			}
		}

		setSearchParams(params);

		stateParams = {
			...stateParams,
			...staticParams,
			sortBy: sortBy,
			orderBy: orderBy,
			pageSize: pageSize,
			pageNumber: pageNumber,
		};

		/**
		 * if filterByProgramTypesId is empty, default to
		 * Mutual Funds, Funds and Managed Accounts
		 */
		stateParams = stateParams.filterByProgramTypesId ? stateParams : {
			...stateParams,
			filterByProgramTypesId: [
				'3DE844D6-1370-4B51-ACF0-670516FC00B5',
				'517067EE-C27F-4E40-BF04-E22512AB2EDE',
				'97F42B3E-7383-4DEF-810B-E6EEA42FFEEE',
			].join('&filterByProgramTypesId=')
		};

		// remove any blank stateParams before we fire off our get request…
		let getQueryParams = Object.fromEntries(Object.entries(stateParams).filter(([_, v]) => v !== ''));

		getRequest('/programsListings', getQueryParams, session.access_token)
			.then((response) => {
				setPrograms(response);
				setRows(response.items.map(row => {
					return { ...row, selected: false };
				}));
				setTotalPages( Math.ceil(response.totalCount / pageSize) );
				// always scroll to top…
				window.scrollTo(0, 0);
				// always scroll tables to the top…
				let tables = document.querySelectorAll('.table-responsive');
				if( tables.length ) tables[0].scrollTo(0, 0);
				/**
				 * TO-DO? unset toggleAllPrograms.checked
				 *
				 * document.getElementById('toggleAllPrograms').checked = false;
				 */
				setIsLoading(false);
				setIsLoaded(true);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				setIsLoading(false);
				setIsLoaded(true);
				setError(error);
			}
		);

	}, [
		session,
		filterByYearStartMin,
		filterByYearStartMax,
		filterByDatePerformanceMin,
		filterByDatePerformanceMax,
		filterByProgramTypesId,
		filterByCARORMin,
		filterByMaxRisk,
		filterByAUMMin,
		filterByAUMMax,
		filterByInvestmentMin,
		filterByInvestmentMax,
		filterByProgramsSectorTypesId,
		filterByInvestorRequirementsTypesId,
		filterByBlendCorrelationId,
		filterByAllocationStrategyTypesId,
		filterByMethodologyTypesId,
		filterByCompositionTypesId,
		sortBy,
		orderBy,
		pageNumber,
		pageSize,
		filterByProgramStatusIds,
		filterByDateApprovedStart,
	]);


	// prevent 'save search' prompt on intial load :\
	useEffect(() => {
		setSearchParams(null);
	}, [
		setSearchParams
	]);


	if (!isLoaded) {
		return (
			<Loader/>
		)
	}

	if( error )

		return (
			<div className={'container'}>
				<div className='alert alert-danger'>
					{text.errorMessage}
				</div>
			</div>
		);

	else

	return (
		<div className={'container'} style={{display:(isLoaded ? 'block' : 'none')}}>
		{!!session.access_token &&
			<AddToBlenderModal
				lang={lang}
				session={session}
				programs={selectedPrograms}
				showModal={showAddToBlender}
				setShowModal={setShowAddToBlender}
				setShowToast={setShowToast}
				setToastMessage={setToastMessage}
				/>
		}
			<div
				className='position-relative'
				aria-live='polite'
				aria-atomic='true'>

				<div
					className='position-absolute d-flex justify-content-center'
					style={{left:0,right:0}}>

					<Toast
						show={showToast}
						onClose={() => {setShowToast(false);setToastDelay(3000);}}
						delay={toastDelay}
						autohide
						className='border-success position-absolute mt-3'>

						<Toast.Body
							className='text-success text-center py-1'>
							<i className='far fa-check-square mr-1' style={{fontSize:'90%'}}></i>
							{toastMessage}
						</Toast.Body>

					</Toast>

				</div>

			</div>

			<h6 className='mb-0'>
				{text.pageSubTitle}
			</h6>

			<h1 className='mb-4'>
				{text.pageTitle}
			</h1>

			<div className='row' style={{ opacity: ( !isLoading ? 1 : .5) }}>

				<div className={'col-12'}>

					<div className='sticky-top sticky-top-screener'>

						<div className={'d-flex align-items-center py-3 bg-white border-bottom'}>

							<Pagination
								totalCount={programs.totalCount}
								totalPages={totalPages}
								pageSize={pageSize}
								pageNumber={pageNumber}
								setQueryParams={setQueryParams}
								lang={lang}
								/>

							<div className={'pr-3 d-none d-lg-' + (selectedPrograms.length ? 'block' : 'none')}>

								<button className='btn btn-sm btn-link mr-1'
									disabled={!session.access_token}
									onClick={(e) => {
										addSelectedToWatchlist({alert:true});
										e.target.blur();
									}}>
									<i className='fa-solid fa-bell mr-1'></i>
									{text.addAlert}
								</button>

								<button className='btn btn-sm btn-link mr-1'
									disabled={!session.access_token}
									onClick={(e) => {
										setShowAddToBlender(true);
										e.target.blur();
									}}>
									<i className='fa-solid fa-plus-circle mr-1'></i>
									{text.addToBlender}
								</button>

								<button className='btn btn-sm btn-link mr-1'
									disabled={!session.access_token}
									onClick={(e) => {
										addSelectedToPortfolio();
										e.target.blur();
									}}>
									<i className='fa-solid fa-plus-circle mr-1'></i>
									{text.addToPortfolio}
								</button>

								<button className='btn btn-sm btn-link'
									disabled={!session.access_token}
									onClick={(e) => {
										addSelectedToWatchlist();
										e.target.blur();
									}}>
									<i className='fa-solid fa-eye mr-1'></i>
									{text.addToWatchlist}
								</button>

							</div>

							<div className={'text-primary fade' + (isLoading ? ' show' : '')}>
								<i className='fa-solid fa-circle-notch fa-spin'></i>
							</div>

							<div className={'ml-auto d-flex'}>

								<Dropdown className='dropdown-table-options' id='column-options' hidden={!session.access_token}>

									<Dropdown.Toggle variant='btn-link btn-sm' id='column-options-toggle' bsPrefix={'iasg-toggle'}>
										<i className='fa-sharp fa-regular fa-table-columns pr-2'></i>
										<span>{text.columns}</span>
									</Dropdown.Toggle>

									<Dropdown.Menu className='p-4 shadow' id='column-options-dropdown' align='left'>

										<div className='row'>

											<div className='col-12 col-md-6'>

												<div className='form-group mb-0'>
													<h6 className='badge badge-dark'>{text.columns}</h6>
												{columns.filter((column) => {return !column.alwaysVisible;}).map((column) =>
													<div key={column.field} className='custom-control custom-checkbox'>
														<input type='checkbox' className='custom-control-input' id={`show-${column.field}`}
															checked={column.visible!==false}
															onChange={(e) => {toggleColumn(column.field)}}/>
														<label className='custom-control-label' htmlFor={`show-${column.field}`}>{text[column.field]||column.headerName}</label>
													</div>
												)}
												</div>

											</div>

											<div className='col-12 col-md-6'>

												<div className='form-group mb-0'>
													<h6 className='badge badge-dark'>{text.columnOrder}</h6>
												<DragDropContext onDragEnd={(result) => {
													if (!result.destination) return;
													updateColumnOrder(result.source.index, result.destination.index);
												}}>
													<Droppable droppableId='columnsDroppable'>
													{(provided, snapshot) => (
														<div
															{...provided.droppableProps}
															ref={provided.innerRef}
															style={{
																position:'relative',
																width:'100%',
																background:snapshot.isDraggingOver ? "white" : "white",
																borderTop:'1px solid #ccc',
																fontSize:'.875rem',
															}}
														>
														{draggableColumns.map((column,index) =>
															<Draggable
																key={column.field}
																draggableId={column.field}
																index={index}
																>
																{(provided, snapshot) => (
																<div
																	className='d-flex align-items-center position-static p-1'
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={{
																		position:'relative',
																		borderBottom:'1px solid #ccc',
																		background:snapshot.isDragging ? "#eee" : "white",
																		cursor:snapshot.isDragging ? "grabbing" : "grab",
																		zIndex:snapshot.isDragging?1:0,
																		...provided.draggableProps.style,
																	}}>
																	<i className='fa-light fa-grip-lines mr-2'></i>
																	<div>{text[column.field]||column.headerName}</div>
																</div>
																)}
															</Draggable>
														)}
														</div>
													)}
													</Droppable>
												</DragDropContext>
												</div>

											</div>

										</div>

									</Dropdown.Menu>

								</Dropdown>

								<Dropdown className='dropdown-table-options' id='table-options' hidden={!session.access_token}>

									<Dropdown.Toggle variant='btn-link btn-sm' id='table-options-toggle' bsPrefix={'iasg-toggle'}>
										<i className='fa-solid fa-sliders-h pr-2'></i>
										<span>{text.filters}</span>
									</Dropdown.Toggle>

									<Dropdown.Menu className='p-4 shadow' align='right'>

										<div className='row'>

											<div className='col-6 col-md-4'>

												<h6 className='badge badge-dark'>{`Performance`}</h6>

												<div className='form-group'>
													<label htmlFor='filterByYearStartMin'>{text.startDate}</label>
													<div className='row no-gutters'>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByYearStartMin'
																style={{width:'auto'}}
																value={filterByYearStartMin}
																onChange={(e) => {setQueryParams({filterByYearStartMin:e.currentTarget.value,pageNumber:1})}}>
															{YEARS.reverse().map((year) =>
																<option key={`filterByYearStartMin-${year}`} value={year}>{year}</option>
															)}
															</select>
														</div>
														<div className='col-auto px-1'>
															<span className='form-text'>{text.to}</span>
														</div>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByYearStartMax'
																style={{width:'auto'}}
																value={filterByYearStartMax}
																onChange={(e) => {setQueryParams({filterByYearStartMax:e.currentTarget.value,pageNumber:1})}}>
																<option value={''}></option>
															{YEARS.reverse().map((year) =>
																<option key={`filterByYearStartMax-${year}`} value={year}>{year}</option>
															)}
															</select>
														</div>
													</div>
												</div>

												<div className='form-group'>

													<label>{text.performanceDate}</label>

													<div className='d-flex'>

														<div style={{width:'6rem'}}>

															<DatePicker
																selected={filterByDatePerformanceMin?(new Date(filterByDatePerformanceMin)):false}
																onChange={(date) => {
																	let DateMin = date ? dateFormat( new Date(date), 'yyyy/MM/dd' ) : '';
																	setQueryParams({
																		filterByDatePerformanceMin: DateMin
																	});
																	updateMonthReturnHeaderName(DateMin,filterByDatePerformanceMax);
																}}
																isClearable
																placeholderText="..."
																selectsStart
																dateFormat="MM/yyyy"
																showMonthYearPicker
																className='form-control form-control-sm'
																renderCustomHeader={({ date, changeYear }) => {
																	return (
																		<select
																			value={date.getFullYear()}
																			className='custom-select custom-select-sm'
																			style={{width:'auto'}}
																			onChange={({ target: { value } }) => changeYear(value)}>
																		{YEARS.reverse().map((year) =>
																			<option key={`minYear-${year}`} value={year}>{year}</option>
																		)}
																		</select>
																	)
																}}
															/>

														</div>

														<div className='px-1'>
															<span className='form-text'>{text.to}</span>
														</div>

														<div style={{width:'6rem'}}>

															<DatePicker
																selected={filterByDatePerformanceMax?(new Date(filterByDatePerformanceMax)):false}
																onChange={(date) => {
																	let DateMax = date ? dateFormat( new Date(date), 'yyyy/MM/dd' ) : '';
																	setQueryParams({
																		filterByDatePerformanceMax: DateMax
																	});
																	updateMonthReturnHeaderName(filterByDatePerformanceMin,DateMax);
																}}
																isClearable
																placeholderText="..."
																selectsStart
																dateFormat="MM/yyyy"
																showMonthYearPicker
																className='form-control form-control-sm'
																maxDate={new Date()}
																renderCustomHeader={({ date, changeYear }) => {
																	return (
																		<select
																			value={date.getFullYear()}
																			className='custom-select custom-select-sm'
																			style={{width:'auto'}}
																			onChange={({ target: { value } }) => changeYear(value)}>
																		{YEARS.map((year) =>
																			<option key={`minYear-${year}`} value={year}>{year}</option>
																		)}
																		</select>
																	)
																}}
															/>

														</div>

													</div>

													<p className='mt-n2 mb-1' hidden={(!filterByDatePerformanceMin&&!filterByDatePerformanceMax)||(filterByDatePerformanceMin&&filterByDatePerformanceMax)}>
														<small className='text-danger'>{`invalid date range`}</small>
													</p>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByProgramTypesId'>{text.programType}</label>

													<select className='custom-select custom-select-sm' id='filterByProgramTypesId'
														value={filterByProgramTypesId}
														onChange={(e) => {setQueryParams({filterByProgramTypesId:e.currentTarget.value,pageNumber:1})}}>
														<option value={''}>{`Any`}</option>
													{programsTypes.map((filter) =>
														<option key={`filter-${filter.programTypeId}`} value={filter.programTypeId}>{filter.programType}</option>
													)}
													</select>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByCARORMin'>{text.compoundedAROR}</label>

													<select className='custom-select custom-select-sm' id='filterByCARORMin'
														value={filterByCARORMin}
														onChange={(e) => {setQueryParams({filterByCARORMin:e.currentTarget.value,pageNumber:1})}}>
														<option value={''}>{`Any`}</option>
														<option value='100'>{`Above 100%`}</option>
														<option value='90'>{`Above 90%`}</option>
														<option value='80'>{`Above 80%`}</option>
														<option value='70'>{`Above 70%`}</option>
														<option value='60'>{`Above 60%`}</option>
														<option value='50'>{`Above 50%`}</option>
														<option value='40'>{`Above 40%`}</option>
														<option value='30'>{`Above 30%`}</option>
														<option value='20'>{`Above 20%`}</option>
														<option value='10'>{`Above 10%`}</option>
														<option value='0'>{`Above 0%`}</option>
														<option disabled>{`——————`}</option>
														<option value='-2'>{`Below 0%`}</option>
													</select>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByMaxRisk'>{text.maxRisk}</label>

													<div className='row no-gutters'>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByMaxRisk'
																style={{width:'auto'}}
																value={filterByMaxRisk}
																onChange={(e) => {setQueryParams({filterByMaxRisk:e.currentTarget.value,pageNumber:1})}}>
																<option value={''}></option>
																<option value='-100'>&gt; 50%</option>
																<option value='-50'>50%</option>
																<option value='-45'>45%</option>
																<option value='-40'>40%</option>
																<option value='-35'>35%</option>
																<option value='-30'>30%</option>
																<option value='-25'>25%</option>
																<option value='-20'>20%</option>
																<option value='-15'>15%</option>
																<option value='-10'>10%</option>
																<option value='-5'>5%</option>
																<option value='0'>0%</option>
															</select>
														</div>

														<div className='col-auto pl-1'>
															<span className='form-text'>{`Drawdown`}</span>
														</div>

													</div>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByAUMMin'><abbr title={text.AUM}>{text.AUMAbbr}</abbr></label>

													<div className='row no-gutters'>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByAUMMin'
																style={{width:'auto'}}
																value={filterByAUMMin}
																onChange={(e) => {setQueryParams({filterByAUMMin:e.currentTarget.value,pageNumber:1})}}>
																<option value={''}>{`Any`}</option>
															{AUM_VALUES.map((filter) =>
																<option key={`filterByAUMMin-${filter.value}`} value={filter.value}>{filter.label}</option>
															)}
															</select>
														</div>
														<div className='col-auto px-1'>
															<span className='form-text'>{text.to}</span>
														</div>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByAUMMax'
																style={{width:'auto'}}
																value={filterByAUMMax}
																onChange={(e) => {setQueryParams({filterByAUMMax:e.currentTarget.value,pageNumber:1})}}>
																<option value={''}>{`Any`}</option>
															{AUM_VALUES.map((filter) =>
																<option key={`filterByAUMMin-${filter.value}`} value={filter.value}>{filter.label}</option>
															)}
															</select>
														</div>
													</div>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByInvestmentMin'>{text.minimumInvestment}</label>

													<div className='row no-gutters'>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByInvestmentMin'
																style={{width:'auto'}}
																value={filterByInvestmentMin}
																onChange={(e) => {setQueryParams({filterByInvestmentMin:e.currentTarget.value,pageNumber:1})}}>
																<option value={''}>$0</option>
															{INVESTMENT_VALUES.map((filter) =>
																<option key={`filterByInvestmentMin-${filter.value}`} value={filter.value}>{filter.label}</option>
															)}
																<option value={1000001}>&gt; $1M</option>
															</select>
														</div>
														<div className='col-auto px-1'>
															<span className='form-text'>{text.to}</span>
														</div>
														<div className='col-auto'>
															<select className='custom-select custom-select-sm' id='filterByInvestmentMax'
																style={{width:'auto'}}
																value={filterByInvestmentMax}
																onChange={(e) => {setQueryParams({filterByInvestmentMax:e.currentTarget.value,pageNumber:1})}}>
																<option value={0}>$0</option>
															{INVESTMENT_VALUES.map((filter) =>
																<option key={`filterByInvestmentMax-${filter.value}`} value={filter.value}>{filter.label}</option>
															)}
																<option value={''}>&gt; $1M</option>
															</select>
														</div>
													</div>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByProgramsSectorTypesId'>{text.sectorFocus}</label>

													<select className='custom-select custom-select-sm' id='filterByProgramsSectorTypesId'
														value={filterByProgramsSectorTypesId}
														onChange={(e) => {setQueryParams({filterByProgramsSectorTypesId:e.currentTarget.value,pageNumber:1})}}>
														<option value={''}>{`Any`}</option>
													{programsSectorFocusTypes.map((filter) =>
														<option key={`filter-${filter.sectorFocusTypeId}`} value={filter.sectorFocusTypeId}>{filter.sectorFocus}</option>
													)}
													</select>

												</div>

												<div className='form-group'>

													<label htmlFor='filterByInvestorRequirementsTypesId'>{text.investorRequirements}</label>

													<select className='custom-select custom-select-sm' id='filterByInvestorRequirementsTypesId'
														value={filterByInvestorRequirementsTypesId}
														onChange={(e) => {setQueryParams({filterByInvestorRequirementsTypesId:e.currentTarget.value,pageNumber:1})}}>
														<option value={''}>{`Any`}</option>
													{programsInvestorRequirementsTypes.map((filter) =>
														<option key={`filter-${filter.investorRequirementTypeId}`} value={filter.investorRequirementTypeId}>{filter.investorRequirementType}</option>
													)}
													</select>

												</div>
											{canViewCorrelations() && !!blends.length &&
												<div className='form-group'>

													<label htmlFor='filterByBlendCorrelationId'>{text.blendCorrelation}</label>

													<select className='custom-select custom-select-sm' id='filterByBlendCorrelationId'
														value={filterByBlendCorrelationId}
														onChange={(e) => {
															setQueryParams({
																filterByBlendCorrelationId: e.currentTarget.value,
																sortBy: e.currentTarget.value ? 'correlation' : 'monthReturn',
																orderBy: 'DESC',
																pageNumber: 1,
															});
															setColumns(columns.map(column => {
																return column.field === 'correlation' ? {...column, visible: !!e.currentTarget.value} : {...column};
															}));
														}}>
														<option value={''}>{`Not Specified`}</option>
													{currentBlend &&
														<option value={currentBlend.blendId}>{text.currentBlend}</option>
													}
													{blends.map((blend) =>
														<option key={`filter-${blend.blendId}`} value={blend.blendId}>{blend.blendName}</option>
													)}
													</select>

												</div>
											}
											</div>

											<div className='col-6 col-md-4'>

												<div className='form-group'>

													<h6 className='badge badge-dark'>{text.investorStrategy}</h6>

												{programsAllocationStrategyTypes.map((filter) =>
													<div key={`filter-${filter.allocationStrategyTypeId}`} className='custom-control custom-checkbox'>
														<input type='checkbox' className='custom-control-input' id={`filter-${filter.allocationStrategyTypeId}`} value={filter.allocationStrategyTypeId}
															checked={filter.selected}
															onChange={(e) => {toggleAllocationStrategyTypesId(filter)}}/>
														<label className='custom-control-label' htmlFor={`filter-${filter.allocationStrategyTypeId}`}>{filter.allocationStrategy}</label>
													</div>
												)}
												</div>

												<div className='form-group'>

													<h6 className='badge badge-dark'>{text.decisionMaking}</h6>

												{programsMethodologyTypes.map((filter) =>
													<div key={`filter-${filter.methodologyTypeId}`} className='custom-control custom-checkbox'>
														<input type='checkbox' className='custom-control-input' id={`filter-${filter.methodologyTypeId}`} value={filter.methodologyTypeId}
															checked={filter.selected}
															onChange={(e) => {toggleMethodologyTypesId(filter)}}/>
														<label className='custom-control-label' htmlFor={`filter-${filter.methodologyTypeId}`}>{filter.methodology}</label>
													</div>
												)}
												</div>

											</div>

											<div className='col-6 col-md-4'>

												<div className='form-group'>

													<h6 className='badge badge-dark'>{text.composition}</h6>

												{programsCompositionTypes.map((filter) =>
													<div key={`filter-${filter.compositionTypeId}`} className='custom-control custom-checkbox'>
														<input type='checkbox' className='custom-control-input' id={`filter-${filter.compositionTypeId}`} value={filter.compositionTypeId}
															checked={filter.selected}
															onChange={(e) => {toggleCompositionTypesId(filter)}}/>
														<label className='custom-control-label' htmlFor={`filter-${filter.compositionTypeId}`}>{filter.composition}</label>
													</div>
												)}
												</div>

											{!!session.access_token &&
												<div className='d-flex'>
												{!!searchParams &&
													<button
														type='button'
														className='btn btn-success btn-sm btn-block mr-1'
														onClick={() => saveSearch(searchParams)}>{`Save Search`}</button>
												}
												{(!!searchParams || !rows.length) &&
													<button
														type='button'
														className='btn btn-outline-dark btn-sm btn-block mt-0 ml-1'
														onMouseOver={(e) => {e.currentTarget.querySelector('.fa-arrow-rotate-left').classList.add('fa-spin','fa-spin-reverse')}}
														onMouseOut={(e) => {e.currentTarget.querySelector('.fa-arrow-rotate-left').classList.remove('fa-spin','fa-spin-reverse')}}
														onClick={() => setQueryParams({
															filterByYearStartMin: '',
															filterByYearStartMax: '',
															filterByDatePerformanceMin: '',
															filterByDatePerformanceMax: '',
															filterByProgramTypesId: '',
															filterByCARORMin: '',
															filterByMaxRisk: '',
															filterByAUMMin: '',
															filterByAUMMax: '',
															filterByInvestmentMin: '',
															filterByInvestmentMax: '',
															filterByProgramsSectorTypesId: '',
															filterByInvestorRequirementsTypesId: '',
															filterByBlendCorrelationId: '',
															filterByAllocationStrategyTypesId: '',
															filterByMethodologyTypesId: '',
															filterByCompositionTypesId: '',
															sortBy: sort,
															orderBy: order,
															pageNumber: 1,
															pageSize: 25,
														})}>
															<i className='fa-sharp fa-solid fa-arrow-rotate-left mr-1'></i>
															<span>{`Reset`}</span>
														</button>
												}
												</div>
											}
											</div>

										</div>

									</Dropdown.Menu>

								</Dropdown>

							</div>

						</div>

						<ProgramsTable
							lang={lang}
							columns={columns}
							rows={rows}
							sortBy={sortBy}
							orderBy={orderBy}
							toggleSortBy={toggleSortBy}
							selectRow={selectProgram}
							selectAll={selectAllPrograms}
							headerOnly={true}
							noResultsMessage={noResultsMessage}
							/>

					</div>

				{!!programs.totalCount &&
					<ProgramsTable
						lang={lang}
						columns={columns}
						rows={rows}
						alwaysDisplayReturns={(filterByDatePerformanceMin && filterByDatePerformanceMax)}
						sortBy={sortBy}
						orderBy={orderBy}
						toggleSortBy={toggleSortBy}
						selectRow={selectProgram}
						selectAll={selectAllPrograms}
						noResultsMessage={noResultsMessage}
						/>
				}
					<div className={'d-flex align-items-center mb-3'}>

						<div className='d-flex text-muted'>
							<small className='pr-3'>
								<i className='fa-solid fa-lg fa-caret-up mr-1 text-success'></i>
								{text.performanceHigh}
							</small>
							<small className='pr-3'>
								<i className='fa-solid fa-lg fa-caret-down mr-1 text-danger'></i>
								{text.performanceLow}
							</small>
							<small className='pr-3'>
								<i className='fa-solid fa-circle mr-1 text-primary'></i>
								{text.last30Days}
							</small>
							<small className='pr-3'>
								<span className='badge text-danger mr-1' style={{verticalAlign:'.05rem'}}>{`DD`}</span>
								{text.newMaxDD}
							</small>
						</div>

						<div className='form-inline ml-auto' hidden={!session.access_token}>
							<div className='form-group pr-2'>
								<label className='pr-2'>
									<b>{text.rows}:</b>
								</label>
								<select className='custom-select custom-select-sm'
									value={pageSize}
									onChange={(e) => {setQueryParams({pageSize:e.currentTarget.value,pageNumber:1});}}>
								{[25,50,100].map((size) =>
									<option key={size} value={size}>{size}</option>
								)}
								</select>
							</div>
						</div>

					</div>

				</div>

			</div>

		</div>
	)
}

export default Programs;
