// Constants
import {
	API_URL,
} from '../helpers/Constants';

export const addToPortfolio = async (objects = [], access_token) => {

	const response = await fetch(`${API_URL}/portfolios/multiple`, {
		method: 'PUT',
		body: JSON.stringify(objects),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response;

};

export const deleteFromPortfolio = async (portfolioOrderId, access_token) => {

	const response = await fetch(`${API_URL}/portfolios/${portfolioOrderId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + access_token
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};

export const patchPortfolio = async (portfolioOrderId, data, access_token) => {

	const response = await fetch(`${API_URL}/portfolios/${portfolioOrderId}`, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: {
			'Authorization': 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		}
	});

	if ( [200,201,202].indexOf(response.status) === -1 ) {
		throw new Error('Network response was not ok')
	}

	return response.json();

};
