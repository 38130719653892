import React, {
	useState,
} from 'react';

/*
 * React-Bootstrap
 *
 * @link https://react-bootstrap.github.io/components/forms/
 *
 */
import {
	Form,
} from 'react-bootstrap';


/*
 * Formik
 *
 * @link https://formik.org/docs/api/formik
 *
 */
import {
	Formik,
} from 'formik';


/*
 * Yup
 *
 * @link https://github.com/jquense/yup
 *
 */
import * as yup from 'yup';


// API
import {
	apiRequest,
} from '../../api/Api';


// i18n
import { labels as labelsGlobal } from '../../i18n/Global';
import { labels as labelsForms } from '../../i18n/Forms';
import { labels as labelsForgotPassword } from '../../i18n/ForgotPassword';


// ForgotPasswordForm
function ForgotPasswordForm({
	lang,
	session,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsForgotPassword[lang]};

	// error handling
	const [successMessage, setSuccessMessage] = useState(false);
	const [resetError, setResetError] = useState(false);

	// form schema
	const schema = yup.object().shape({
		email: yup.string().email().required(text.invalidEmail),
	});

	// login submit handler
	const handleResetSubmit = (values) => {

		setSuccessMessage(false);
		setResetError(false);

		// make API call
		apiRequest({
			method: 'GET',
			path: `/userAccounts/getforgotpasswordtoken/?email=` + encodeURIComponent(values.email),
			lang: lang,
			email: values.email,
		}).then((response) => {

			// gaslighting…
			if( response.status === 404 ) {

				setResetError(text.emailNotFound);

			}
			else {

				setSuccessMessage(text.emailFound);

			}

		});

	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={handleResetSubmit}
			initialValues={{
				email: '',
			}}
		>
		{({
			handleSubmit,
			handleChange,
			handleBlur,
			values,
			touched,
			isValid,
			isSubmitting,
			errors,
			setFieldValue,
		}) => (
			<Form
				className='py-4'
				noValidate
				onSubmit={handleSubmit}>

				<div className='alert alert-danger' hidden={!resetError}>
					{resetError}
				</div>

				<div className='alert alert-success' hidden={!successMessage}>
					{successMessage}
				</div>

				<Form.Group>
					<Form.Label htmlFor='email'>{text.Email}</Form.Label>
					<Form.Control
						type='email'
						name='email'
						id='email'
						value={values.email}
						onChange={handleChange}
						isValid={touched.email && !errors.email}
						isInvalid={touched.email && errors.email}
					/>
					<Form.Control.Feedback type='invalid'>
						{errors.email}
					</Form.Control.Feedback>
				</Form.Group>

				<button type='submit' className='btn btn-primary btn-block' disabled={isSubmitting&&!resetError&&!successMessage}>
					<i className='fa fa-chevron-right pr-2'></i>
					<span>{text.emailMe}</span>
				</button>

			</Form>
		)}
		</Formik>
	)
}

export default ForgotPasswordForm;
