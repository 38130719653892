import React, {
	useEffect,
} from 'react';


/*
 * React Hook Router
 *
 * @link https://github.com/Paratron/hookrouter
 *
 */
import {
	useQueryParams,
} from 'hookrouter';


// forms
import {
	LoginForm,
} from './forms/Forms';


// partials
import {
	Loader,
} from './partials/Partials';


// i18n
import { labels as labelsGlobal } from '../i18n/Global';
import { labels as labelsForms } from '../i18n/Forms';
import { labels as labelsLogin } from '../i18n/Login';


// Login
function Login({
	lang,
	session,
	setShowLogin,
	suspended=false,
}) {

	// text labels
	const text = {...labelsGlobal[lang], ...labelsForms[lang], ...labelsLogin[lang]};

	// queryParams
	const [queryParams] = useQueryParams();

	// Use object destructuring and a default value if the param is not yet present in the URL.
	const {
		redirect = '',
	} = queryParams;

	// set page title
	useEffect(() => {
		document.title = [text.pageTitle, text.siteTitle].join(text.titleSeparator);
	});

	// access + redirects
	useEffect(() => {
		if( session.access_token ) {
			window.location = redirect ? redirect : `/managed-futures/performance`;
		}
	}, [
		lang,
		session,
		redirect,
	]);

	if ( session.access_token ) {
		return (
			<Loader/>
		)
	}

	return (
		<div className='container py-5'>

			<div className='row justify-content-center'>

				<div className='col' style={{maxWidth:'30rem'}}>

					<h1 className='mb-4'>{text.pageTitle}</h1>
				{suspended &&
					<div className='alert alert-warning'>
						<h5>{text.suspendedHeading||`We’ve temporarily suspended your account`}</h5>
						{text.suspended||`If you’d like to regain full access to IASG’s database and tools, please reach out to us at (312) 561-3145. We’ll be more than happy to reopen your account.`}
					</div>
				}
					<LoginForm
						lang={lang}
						redirect={redirect}
						setShowLogin={setShowLogin}
						/>

				</div>

			</div>

		</div>
	)

}

export default Login;
