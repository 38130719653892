import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if( 'localhost' === window.location.hostname ) {
	const script = document.createElement('script');
	script.src = 'https://kit.fontawesome.com/25be56b787.js';
	script.crossorigin = 'anonymous';
	document.body.appendChild(script);
}

ReactDOM.render(
	<App />,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
