export const Loader = () => {

	return (
		<div className='modal d-block' tabIndex='-1'>

			<div className='modal-dialog modal-fullscreen'>

			    <div className='modal-content'>

					<div className='modal-body d-flex align-items-center justify-content-center'>
						<i className='fa-solid fa-circle-notch fa-spin fa-3x text-primary' style={{opacity:.75}}></i>
					</div>

			    </div>

			</div>

		</div>
	)

};
